import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL } from "../../config/config";


// const INITIAL_STATE = {
//     loading: false,
//     influencerProfiles : [],
//  error: 
// }


export const fetchBrandProfile = createAsyncThunk("brand_Profile/fetchBrandProfile", async (id) => {
    let response = await axios.get(`${BASE_URL}/brandProfile/${id}`);
    let data = await response.data.brand;
    return data;

});



const brandProfileReducer = createSlice({
    name: "brand_Profile",
    initialState: {
        loading: false,
        brand_Profile: {},
        error: ""
    },
    extraReducers: {
        [fetchBrandProfile.fulfilled]: (state, action) => {
            state.brand_Profile = action.payload;
            state.loading = false;
        },
        [fetchBrandProfile.pending]: (state) => {
            state.loading = false;
        },
        [fetchBrandProfile.rejected]: (state, action) => {
            state.error = action.error.message
        }
    }
});

export default brandProfileReducer.reducer;