import React, { useState } from "react";
import "./StepThree.css";
import toast from "react-hot-toast";
import Loading from "../../../../components/Loading/Loading";
import { Notify } from "../../../../components/Notify";

const StepThree = ({ step, setStep, influencerDescription, setInfluencerDescription }) => {
  const [loading, setLoading] = useState(false);

  const descriptionHandler = () => {
    setLoading(true);
    if (!influencerDescription) {
      setLoading(false);
      Notify("error", "Enter Your Description!");
    } else if (influencerDescription.length < 30) {
      setLoading(false);
      Notify("error", "Describe YourSelf with 30 character!");
    } else {
      setTimeout(() => {
        setLoading(false);
        setStep(step + 1);
      }, 2000)
    }
  };

  return (
    <div className="stepThree">
      <div>
        <h1 className="stepThree-heading">
          Describe yourself and your content
        </h1>

        <div className="input_Container">
          <textarea
            cols={8}
            rows={8}
            value={influencerDescription}
            onChange={(e) => setInfluencerDescription(e.target.value)}
            placeholder="Who are you and what type of content do you create? Who is your audience? Be specific as this will help you show up in searches."
          ></textarea>
        </div>

        {/* signUp Button */}
        <div className="continue_container">
          {
            loading ? (
              <button className="continueBtn">
                <Loading />
              </button>
            ) : (
              <button className="continueBtn" onClick={descriptionHandler}>
                Continue
              </button>
            )
          }

        </div>
      </div>
    </div>
  );
};

export default StepThree;
