import React from 'react'

const HashLoader = () => {
    return (
        <div className='loader_Container'>
            <div className="lds-facebook">
                <div></div>
                <div></div>
                <div></div>
            </div>
        </div>
    )
}

export default HashLoader