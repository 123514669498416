import React, { useState } from "react";
import "./CollabsCafeFAQS.css";

const CollabsCafeFAQS = ({
  Quesone,
  AnsOne,
  QuesTwo,
  AnsTwo,
  QuesThree,
  AnsThree,
  QuesFour,
  AnsFour,
  QuesFive,
  AnsFive,
  QuesSix,
  AnsSix,
  QuesSeven,
  AnsSeven,
  QuesEight,
  AnsEight,
}) => {
  const accourdion = [
    {
      id: 0,
      question: Quesone,
      answer: AnsOne,
    },
    {
      id: 1,
      question: QuesTwo,
      answer: AnsTwo,
    },
    {
      id: 2,
      question: QuesThree,
      answer: AnsThree,
    },
    {
      id: 3,
      question: QuesFour,
      answer: AnsFour,
    },
    {
      id: 4,
      question: QuesFive,
      answer: AnsFive,
    },
    {
      id: 5,
      question: QuesSeven,
      answer: AnsSeven,
    },
    {
      id: 6,
      question: QuesEight,
      answer: AnsEight,
    },
  ];

  return (
    <div className="collabsCafeFAQS">
      {accourdion[6].question === undefined
        ? accourdion.slice(0, 7).map((accord) => {
            return (
              <div key={accord.id}>
                <Accourdion
                  id={accord.id}
                  question={accord.question}
                  answer={accord.answer}
                  accourdion={accourdion}
                />
              </div>
            );
          })
        : accourdion.map((accord) => {
            return (
              <div key={accord.id}>
                <Accourdion
                  id={accord.id}
                  question={accord.question}
                  answer={accord.answer}
                  accourdion={accourdion}
                />
              </div>
            );
          })}
    </div>
  );
};

const Accourdion = ({ accourdion, question, answer }) => {
  const [openAccourdion, setOpenAccourdion] = useState(false);
  return (
    <div>
      <div
        className="collabsCafeFAQS_cont"
        onClick={() => setOpenAccourdion(!openAccourdion)}
      >
        <p className="collabsCafeFAQS_title">{question}</p>
        <p className={openAccourdion ? "clickesArrow" : "plusArrow"}>+</p>
      </div>
      {openAccourdion && (
        <div className="accoudion_Desc">
          <p>{answer}</p>
        </div>
      )}
      {/* Line separetor */}
      <div className="line_separator" />
    </div>
  );
};

export default CollabsCafeFAQS;
