import React, { useState } from "react";
import "./CreatorSignUp.css";
import { useDispatch } from "react-redux";
import { currentUser } from "../../Redux/Reducers/UserReducer";
import { useNavigate, useLocation } from "react-router-dom";
import { createUserWithEmailAndPassword, onAuthStateChanged, deleteUser } from "firebase/auth";
import { auth } from "../../config/firebase-config";
import axios from "axios";
import { BASE_URL } from "../../config/config";
import Loading from "../../components/Loading/Loading";
import { Notify } from "../../components/Notify";

const CreatorSignUp = () => {
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { state } = useLocation();
  const dispatch = useDispatch();
  // console.log("state", state.yourName)



  const signUpHandler = async () => {
    //  ***** check if there is input empty then toast will populate , if fileds okay than auth will apply ******
    setLoading(true);
    if (!fullName) {
      Notify("error", "Enter Your Full Name!");
      setLoading(false);
    } else if (!email) {

      Notify("error", "Enter Your Email!");
      setLoading(false);
    } else if (!password) {

      Notify("error", "Enter Your Password!");
      setLoading(false);
    }
    else {
      try {
        // **** first will create an account in Firebase *****
        const inflencer = await createUserWithEmailAndPassword(auth, email, password);
        // **** will send Registered User Data to Database *****

        const postInfluencer = {
          uid: inflencer.user.uid,
          userName: state.yourName,
          fullName: fullName,
          email: inflencer.user.email,
          verified: inflencer.user.emailVerified,
          profileCompleted: false,
          role: "Influencer",
          password: password
        }
        await axios.post(`${BASE_URL}/registerInfluencer`, postInfluencer).then((result) => {
        console.log("result", result?.data);
          // **** after 3 seconds it will navigate to email auth *****
          Notify("success", result?.data?.msg);
          setLoading(false);
          navigate("/stepEmailOtpVerify");
          dispatch(currentUser(
            {
              userName: postInfluencer.userName,
              fullName: postInfluencer.fullName,
              email: inflencer.user.email,
              uid: inflencer.user.uid,
              verified: inflencer.user.emailVerified,
              profileCompleted: false,
              role: postInfluencer.role,
            }
          ));


        }).catch((error) => {
          Notify("error", error.message);
          setLoading(false);
        })

      } catch (error) {
        error.message = error.message.replace(/\(/g, "");
        error.message = error.message.replace(/\)/g, "");
        error.message = error.message.replace(/\-/g, " ");
        Notify("error", error.message.slice(21).toUpperCase());
        setLoading(false);
      }
    }



  };
  return (
    <div className="creator_signup">
      <div className='w-70'>

        {/* <Slider {...slider}>
                    <div>
                        <img src={Bg01} alt="bg" />
                    </div>
                    <div>
                        <img src={Bg02} alt="bg" />

                    </div>
                    <div>
                        <img src={Bg03} alt="bg" />

                    </div>
                </Slider> */}
      </div>
      <div className="w-30">
        <h1 className="creator_signup-heading">Create Your Page</h1>
        <div className="input_Container">
          <input type="text" placeholder="Full Name"
            value={fullName}
            onChange={(e) => setFullName(e.target.value)}
          />
        </div>
        <div className="input_Container">
          <input type="email" placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <div className="input_Container">
          <input type="password" placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
        {/* signUp Button */}
        <div className="signUpBtn_container">
          {loading ? (
            <button className="signUpBtn">
              <Loading />
            </button>

          ) : (

            <button className="signUpBtn" onClick={signUpHandler}>
              Sign Up
            </button>
          )}
        </div>
        <p className="creator_signup_label">
          By signing up, you agree to our <span className="terms">Terms</span>{" "}
          and <span className="terms">Privacy Policy</span>.
        </p>
        {/* {user.email} */}
      </div>
    </div>
  );
};

export default CreatorSignUp;
