import { Menu, MenuItem } from '@mui/material';
import dayjs from 'dayjs';
import React from 'react'
import { useSelector } from 'react-redux';

var relativeTime = require('dayjs/plugin/relativeTime')
dayjs.extend(relativeTime)

const Notifications = ({ openNotify,
    openNotifications,
    handleNotifyClose, notifications }) => {

    const { allNotifications } = useSelector((state) => state.Chat);

    return (
        <div>
            <Menu
                anchorEl={openNotifications}
                id="account-menu"
                open={openNotify}
                onClose={handleNotifyClose}
                onClick={handleNotifyClose}

                PaperProps={{
                    elevation: 0,
                    sx: {
                        overflow: 'scroll',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: 1.5,
                        height: 350,
                        '& .MuiAvatar-root': {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1,
                        },
                        '&:before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: 'background.paper',
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: 0,
                        },
                    },
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
                {
                    notifications.length > 0 ? (
                        notifications.map((v, index) => {
                            return (
                                <MenuItem key={index}>
                                    <div>
                                        <p className="menuItems">You Have a New Message from {v?.userData?.fullName}</p>
                                        <p className="menuItems">Created At ({v?.date ? dayjs(v?.date).fromNow() : dayjs(v?.createdAt).fromNow()})</p>
                                    </div>
                                    <hr />
                                </MenuItem>
                            )
                        })
                    ) : (
                        <MenuItem>
                            <p className="menuItems">No Notifications Available</p>
                        </MenuItem>
                    )
                }
                {/* {
                    notifications.length > 5 ? (
                        <MenuItem>
                            <p style={{ color: "#05a081", fontWeight: "bold" }} className="menuItems">See All</p>
                        </MenuItem>
                    ) : (
                        null
                    )
                } */}
            </Menu>
        </div>
    )
}

export default Notifications;