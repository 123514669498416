import React, { useState, useCallback } from 'react';
import "./CropImage.css";
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import Cropper from 'react-easy-crop'
import { Slider } from '@mui/material';
import getCroppedImg from './Crop';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

function BootstrapDialogTitle(props) {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
}


// const CROP_AREA_ASPECT = 3 / 2;


const CropImage = ({ openModal, setOpenModal, image, setImage }) => {
    const [open, setOpen] = useState(openModal);

    // Crop State
    const [crop, setCrop] = useState({ x: 0, y: 0 })
    // const [rotation, setRotation] = useState(0)
    const [zoom, setZoom] = useState(1)
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)
    const [croppedImage, setCroppedImage] = useState(null)

    const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
        setCroppedAreaPixels(croppedAreaPixels)
    }, [])

    

    const showCroppedImage = useCallback(async () => {
        try {
            const croppedImagess = await getCroppedImg(
                image,
                croppedAreaPixels,
            )
            // console.log('donee', { croppedImagess })
            // setCroppedImage(croppedImagess);
            setImage(croppedImagess);
            setOpenModal(false)

        } catch (e) {
            setOpenModal(false)
        }
    }, [croppedAreaPixels])

    const onClose = useCallback(() => {
        setCroppedImage(null)
        setOpenModal(false)
    }, [])


    return (
        <div>
            
            <BootstrapDialog
                onClose={onClose}
                aria-labelledby="customized-dialog-title"
                open={open}
            >
                <BootstrapDialogTitle id="customized-dialog-title" onClose={onClose}>
                    Crop Your Image
                </BootstrapDialogTitle>
                <DialogContent dividers>
                    <div className='dialogBox' >
                        <Cropper
                            image={image}
                            crop={crop}
                            // rotation={rotation}
                            zoom={zoom}
                            aspect={4 / 3}
                            onCropChange={setCrop}
                            // onRotationChange={setRotation}
                            onCropComplete={onCropComplete}
                            onZoomChange={setZoom}
                        />

                    </div>
                    <div >
                        <p className='zoomLabel'>Zoom</p>
                        <Slider
                            value={zoom}
                            min={1}
                            max={3}
                            step={0.1}
                            aria-label="Temperature"
                            // size='small'
                            valueLabelDisplay="auto"
                            style={{color: "#05a081", margin: "0px 5px 5px 5px"}}
                            // classes={{ root: classes.slider }}
                            onChange={(e, zoom) => setZoom(zoom)}
                        />
                    </div>
                    
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={showCroppedImage}
                        variant="contained"
                        // color="primary"
                        style={{background: "#05a081"}}
                    >
                        Crop
                    </Button>
                </DialogActions>
            </BootstrapDialog>
        </div>
    )
}

// const Output = ({ croppedArea, image }) => {
//     const scale = 100 / croppedArea.width;
//     const transform = {
//         x: `${-croppedArea.x * scale}%`,
//         y: `${-croppedArea.y * scale}%`,
//         scale,
//         width: "calc(100% + 0.5px)",
//         height: "auto"
//     };

//     const imageStyle = {
//         transform: `translate3d(${transform.x}, ${transform.y}, 0) scale3d(${transform.scale},${transform.scale},1)`,
//         width: transform.width,
//         height: transform.height
//     };

//     return (
//         <div
//             className="output"
//         // style={{ paddingBottom: `${100 / CROP_AREA_ASPECT}%` }}
//         >
//             <img src={image} alt="" style={imageStyle} />
//         </div>
//     );
// };



export default CropImage