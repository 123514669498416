import React, { useState } from "react";
import "./StepFour.css";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Loading from "../../../../components/Loading/Loading";
import { Notify } from "../../../../components/Notify";

const StepFour = ({ step, setStep, influencerGender, setInfluencerGender }) => {
  const [loading, setLoading] = useState(false);

  const handleChange = (event) => {
    setInfluencerGender(event.target.value);
  };

  const genderHandler = () => {
    // alert(influencerGender);
    setLoading(true);
    if (!influencerGender) {
      setLoading(false);
      Notify("error", "Enter Your Gender!");
    } else {
      setTimeout(() => {
        setLoading(false);
        setStep(step + 1);
      }, 2000)
    }
  }

  return (
    <div className="stepFour">
      <div>
        <h1 className="stepFour-heading">What's your gender?</h1>

        <RadioGroup
          
          value={influencerGender}
          onChange={handleChange}
        >
          <div className="input_Container">
            <FormControlLabel
              className="radioBtn"
              value="male"
              control={
                <Radio
                  sx={{
                    color: "#05a081",
                    "&.Mui-checked": {
                      color: "#05a081",
                    },
                  }}
                />
              }
              label="Male"
            />
          </div>

          <div className="input_Container">
            <FormControlLabel
              className="radioBtn"
              value="female"
              control={
                <Radio
                  sx={{
                    color: "#05a081",
                    "&.Mui-checked": {
                      color: "#05a081",
                    },
                  }}
                />
              }
              label="Female"
            />
          </div>
          <div className="input_Container">
            <FormControlLabel
              className="radioBtn"
              value="others"
              control={
                <Radio
                  sx={{
                    color: "#05a081",
                    "&.Mui-checked": {
                      color: "#05a081",
                    },
                  }}
                />
              }
              label="Others"
            />
          </div>
        </RadioGroup>

        {/* signUp Button */}
        <div className="continue_container">
          {
            loading ? (
              <button className="continueBtn" ><Loading /></button>
            ) : (
              <button className="continueBtn" onClick={genderHandler}>Continue</button>
            )
          }
        </div>
        <p className="stepOne_label">
          <abbr title="Brands sometimes look for specific gender(s) when searching. This is how they can filter for it.">
            Why do you ask this?
          </abbr>
        </p>
      </div>
    </div>
  );
};

export default StepFour;
