import React, { useState } from "react";
import "./StepTen.css";

const StepTen = ({ phoneNumber, setPhoneNumber, toogle }) => {

  return (
    <div className="stepTen">
      <h1 className="stepTen-heading">
        Add your mobile number to be notified when you receive an order
      </h1>
      {/* Phone Number Input */}
      <div>
        <input disabled={toogle ? true : false} className="phoneInput" type="tel" name="phone" placeholder="Phone Number 3152899756"
          value={phoneNumber}
          onChange={(e) => setPhoneNumber(e.target.value)}
        />
        <p className="formatPhone">Should be 10 Digit: <span className="number">3152899756</span></p>
      </div>
      {/* signUp Button */}
      {/* <div className="continue_container" >
        <button className="continueBtn" onClick={phoneNumberHandler}>Continue</button>
      </div> */}
    </div>
  );
};

export default StepTen;
