import React, { useState } from "react";
import "./BrandRequestView.css";
import { HiLocationMarker } from "react-icons/hi";
import { FaUserAlt } from "react-icons/fa";
import { useLocation } from "react-router-dom";
import numeral from "numeral";
import { AiFillInstagram } from "react-icons/ai";
import { IoLogoTiktok, IoLogoFacebook } from "react-icons/io5";
import { BsTwitter, BsLink45Deg, BsYoutube } from "react-icons/bs";
import moment from "moment";
import Loading from "../../components/Loading/Loading";
import { Notify } from "../../components/Notify";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { approveInfluencerRequest, deniedInfluencerRequest, fetchInfluencerRequest } from "../../Redux/Reducers/InfluencersRequests";


const BrandRequestView = () => {
  const user = useSelector((state) => state.User.user);

  const [value, setValue] = React.useState("1");
  const [loading, setLoading] = useState(false);
  const { state } = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { influ } = state;




  const acceptOfferHandler = async (id) => {
    setLoading(true);
    dispatch(approveInfluencerRequest(id)).then((res) => {
      if (res?.type === "influencerRequest/approveInfluencerRequest/fulfilled") {
        Notify("success", res?.payload?.msg);
        // Get All Updated Requests
        // dispatch(fetchInfluencerRequest(user.uid));
        setLoading(false);
        navigate("/brands-Request");
      } else if (res?.type === "influencerRequest/approveInfluencerRequest/rejected") {
        Notify("error", res?.payload);
        setLoading(false);
      }
    });

  }


  const deniedOfferHandler = (id) => {
    setLoading(true);
    dispatch(deniedInfluencerRequest(id)).then((res) => {
      if (res?.type === "influencerRequest/deniedInfluencerRequest/fulfilled") {
        Notify("success", res?.payload?.msg);
        // Get All Updated Requests
        // dispatch(fetchInfluencerRequest(user.uid));
        setLoading(false);
        navigate("/brands-Request")
      } else if (res?.type === "influencerRequest/deniedInfluencerRequest/rejected") {
        Notify("error", res?.payload);
        setLoading(false);
      }
    });
  }

  return (
    <div className="main_brand_view">
      <div className="brandRequestStatus_Date">

        <div className={
          influ?.isApproved ? "brand_Request_offer_status_approved" :
            influ?.isDenied ? "brand_Request_offer_status_denied" :
              "brand_Request_offer_status"}>
          {
            influ?.isApproved ? "Approved" :
              influ?.isDenied ? "Denied" :
                "New"
          }
        </div>

        <div className="brand_view_createdAt">
          <div>Created At: {moment(influ.createdAt).format("MM-DD-YYYY")}</div>
        </div>
      </div>
      <div className="brand_view_card">
        <div className="brand_view_first_section">
          <img
            src={influ?.influencer_profile_image}
            alt="profile_image"
          />
          <div className="brand_view_detail">
            <h2>Brand Name: {influ?.brandName}</h2>
            {/* <div>
              <HiLocationMarker size={20} />
              <span>Platform: {influ?.platform}</span>
            </div> */}
          </div>
          <div className="brand_designation">
            <p>Platform: {influ?.platform}</p>
          </div>
          <div className="brand_price">
            <p>Rs: {numeral(influ?.price).format("00.00")}</p>
          </div>
          <div className="brand_btn_section_row">

            {!influ?.isDenied && !influ?.isApproved &&
              (
                loading ? (
                  <div className='accept_btn' ><Loading /></div>
                ) : (
                  <div className="view_profile_btn" onClick={() => acceptOfferHandler(influ?._id)}>Accept</div>
                  // <div className='accept_btn' onClick={() => acceptOfferHandler(influ?._id)}>Accept</div>
                )
              )
            }
            {!influ?.isDenied && <div className="accept_btn" onClick={() => Notify("success", "You Are Eligible for Chat")}>Chat 💬</div>}



            {!influ?.isDenied && !influ?.isApproved &&
              (
                loading ? (
                  <div className='decline_btn' ><Loading /></div>
                ) : (
                  <div className="decline_btn" onClick={() => deniedOfferHandler(influ?._id)}>Declined</div>
                  // <div className='decline_btn' onClick={() => deniedOfferHandler(influ?._id)}>Declined</div>
                )
              )

            }

          </div>
          {/* <div className="brand_view_work">
            <p>WORK</p>
            <hr />
          </div>
          <div className="brand_view_work_detail">
            <h4>Spotify New York</h4>
            <div className="brand_view_primary">
              <div>Primary</div>
            </div>
          </div>
          <p>LOUzvaezdjf7JTv0Ptc2njLcbie29mKOjDU1PHQD7i89EearRoBhmCZQ00Bo40bMBthFOv</p> */}
        </div>

        <div className="brand_view_second_section">
          {/* <div className="brand_view_detail">
            <h2>Jeremy Rose</h2>
            <div>
            <HiLocationMarker size={20} />
            <span>New York, NY</span>
            </div>
          </div>
          <div className="brand_designation">
            <p>Product Designer</p>
          </div>
          <div className="brand_btn_section_row">
            <div className="view_profile_btn">Accept</div>
            <div className="accept_btn">Chat 💬</div>
            <div className="decline_btn">Declined</div>
          </div> */}


          <div className="brand_view_work_detail">
            <h4>Description 👇</h4>
            {/* <div className="brand_view_primary">
              <div>Primary</div>
            </div> */}
          </div>
          <p>
            {influ?.summarize}
          </p>
          <div className="brand_about_detail">
            <div className="brand_about_section">
              <FaUserAlt size={22} />
              <h2>About</h2>
            </div>
            <hr />
            <div>
              <p>Social Links 👇</p>
              <div className="brandRequest_View_SocialLinks">
                {/* <div>Email:</div> */}
                {influ?.instagram && <div><AiFillInstagram size={25} /></div>}
                {influ?.facebook && <div><IoLogoFacebook size={25} /></div>}
                {influ?.tiktok && <div><IoLogoTiktok size={25} /></div>}
                {influ?.twitter && <div><BsTwitter size={25} /></div>}
                {influ?.website && <div><BsLink45Deg size={25} /></div>}
                {influ?.youtube && <div><BsYoutube size={25} /></div>}
              </div>
              {/* <div className="brand_contact_info">
                <div>Email:</div>
                <div>abc@gmail.com</div>
              </div>
              <div className="brand_contact_info">
                <div>Email:</div>
                <div>abc@gmail.com</div>
              </div>
              <div className="brand_contact_info">
                <div>Email:</div>
                <div>abc@gmail.com</div>
              </div> */}
            </div>
            <br />
            {/* <div>
              <p>ABOUT INFORMATION</p>
              <div className="brand_contact_info">
                <div>Email:</div>
                <div>abc@gmail.com</div>
              </div>
              <div className="brand_contact_info">
                <div>Email:</div>
                <div>abc@gmail.com</div>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BrandRequestView;