import React, { useEffect, useState } from 'react'
import { AiFillInstagram } from "react-icons/ai";
import { IoLogoTiktok, IoLogoFacebook } from "react-icons/io5";
import { BsTwitter, BsLink45Deg, BsYoutube } from "react-icons/bs";
import { useSelector, useDispatch } from "react-redux";



const ExampleProfile = () => {

    const user = useSelector((state) => state.User.user);
    const path = window.location.pathname.toLowerCase();
    const [influencerProfile, setInfluencerProfile] = useState(null);
    const [packageData, setPackageData] = useState(null);
    const [loader, setLoader] = useState(true);
    const [selected, setSelected] = useState(0);
    const [selectPlatform, setSelectPlatform] = useState("All");
    const [openProfile, setOpenProfile] = useState(false);
    const dispatch = useDispatch();



    const packagesHandler = (event, i) => {
        // event.isSelected = true;
        setPackageData(event);
        setSelected(i);
        // console.log("packageData==>",packageData.isSelected );
    };

    const selectPlatformHandler = (event) => {
        setSelectPlatform(event.target.value);
    };






    const faqs = [
        {
            id: 0,
            faqsQuestion: "Whydfh njsdrny",
            faqsDescription: "ehynjdydtyufmytgf"
        },
        {
            id: 1,
            faqsQuestion: "Whydfh njsdrny",
            faqsDescription: "ehynjdydtyufmytgf"
        },
        {
            id: 2,
            faqsQuestion: "Whydfh njsdrny",
            faqsDescription: "ehynjdydtyufmytgf"
        },
    ]

    const packages = [
        {
            platform: "Tiktok",
            contentType: "Photo Ad",
            packageOffer: "Tiktok Video",
            packageDescription: "he eager parameter accepts either a single trans",
            packagePrice: "3000"
        },
        {
            platform: "User Generated",
            contentType: "Photo Ad",
            packageOffer: "User Generated Video",
            packageDescription: "he eager parameter accepts either a single trans",
            packagePrice: "4000"
        },
        {
            platform: "Youtube",
            contentType: "Photo Ad",
            packageOffer: "Youtube Video",
            packageDescription: "he eager parameter accepts either a single trans",
            packagePrice: "5000"
        },
        {
            platform: "Instagram",
            contentType: "Photo Ad",
            packageOffer: "Instagram Video",
            packageDescription: "he eager parameter accepts either a single trans",
            packagePrice: "6000"
        },
    ]


    useEffect(() => {
        setPackageData(packages[0])
    }, [])

    return (
        <div className="userProfile">
            <h1 className="stepOne-heading">Example Profile</h1>

            {/* {user && user.email === influencerProfile.email && <div className="editInfluencerProfile_Contaier">
                <BiEdit className="editInfluencerProfile" />
                <Link className="editInfluencerProfile_link" to="/edit-influencer-profile">
                    <span>Edit Profile</span>
                </Link>
            </div>} */}
            {/* <ShareInfluProfile userName={influencerProfile.userName} /> */}
            {/* Images Section */}
            <div className="images_Container">

                <div className="coverImage_Container">
                    <div className="file_area">
                        <img
                            className="image image0"

                            src={
                                "http://res.cloudinary.com/drg7uoyge/image/upload/v1667245911/influencersProfile/rx3clrmxvkscp9jpve1y.avif"
                            }
                            alt="coverImage.png"
                        />
                    </div>
                </div>

                <div className="Images_Cont">
                <div className='img-row-sq'>
                    <div className="image_One">
                        <div className="file_area">
                            <img
                                className="image image1"
                                // onClick={() => setOpenProfile(!openProfile)}
                                src={
                                    "http://res.cloudinary.com/drg7uoyge/image/upload/v1667245911/influencersProfile/rx3clrmxvkscp9jpve1y.avif"
                                }
                                alt="ImageOne.png"
                            />
                        </div>
                    </div>



                    <div className="image_Two">
                        <div className="file_area">
                            <img
                                className="image image2"
                                src={
                                    "http://res.cloudinary.com/drg7uoyge/image/upload/v1667245911/influencersProfile/rx3clrmxvkscp9jpve1y.avif"
                                }
                                alt="ImageTwo.png"
                            />
                        </div>
                    </div>
</div>

                    <div className="image_Three">
                        <div className="file_area">
                            <img
                                className="image image3"
                                src={
                                    "http://res.cloudinary.com/drg7uoyge/image/upload/v1667245911/influencersProfile/rx3clrmxvkscp9jpve1y.avif"
                                }
                                alt="ImageThree.png"
                            />
                        </div>
                    </div>

                </div>
            </div>

            {/* userProfile_Details */}
            <div className="userProfile_Container">
                <div className="userProfile_desc">
                    <p className="userProfile_Title">
                        This is Title
                    </p>
                    <p className="userProfile_description">
                        This is Description
                    </p>
                </div>
                <div className="userProfile_Images_links_Detail">
                    <div className="userProfiles_Details">
                        {/* User Profile Image */}
                        <img
                            // onClick={() => setOpenProfile(!openProfile)}
                            className="userProfile_influencer_Image"
                            src="http://res.cloudinary.com/drg7uoyge/image/upload/v1667245911/influencersProfile/rx3clrmxvkscp9jpve1y.avif"
                            alt="User_Profile.png"
                        />
                        {/* {
                            openProfile && <ProfileImageModal openProfile={openProfile}
                                setOpenProfile={setOpenProfile}
                                image={influencerProfile.profileImage.url}
                            />
                        } */}
                        {/* User Profile Name */}
                        <div className="userProfile_influencer_Name">
                            Rehman Khan
                        </div>
                        <div className="userProfile_influencer_location">
                            Karachi
                        </div>
                        <div className="userProfile_influencer_Social_Icons_Container">

                            <a
                                href="www.facebook.com"
                                target="_blank"
                            >
                                <AiFillInstagram className="userProfile_influencer_Social_Icons" />
                            </a>


                            <a
                                href="www.facebook.com"
                                target="_blank"
                            >
                                <IoLogoTiktok className="userProfile_influencer_Social_Icons" />
                            </a>


                            <a
                                href="www.facebook.com"
                                target="_blank"
                            >
                                <BsTwitter className="userProfile_influencer_Social_Icons" />
                            </a>


                            <a
                                href="www.facebook.com"
                                target="_blank"
                            >
                                <IoLogoFacebook className="userProfile_influencer_Social_Icons" />
                            </a>


                            <a
                                href="www.facebook.com"
                                target="_blank"
                            >
                                <BsYoutube className="userProfile_influencer_Social_Icons" />
                            </a>


                            <a
                                href="www.facebook.com"
                                target="_blank"
                            >
                                <BsLink45Deg className="userProfile_influencer_Social_Icons" />
                            </a>

                        </div>
                    </div>
                </div>
            </div>
            {/* userProfile_Packages */}
            <div className="userProfile_Packages_Container">
                <div className="userProfile_Packages">
                    {/* userProfile_Packages_Heading */}
                    <div className="userProfile_Packages_heading">
                        <p>Packages</p>
                        <select
                            name="selectPlatform"
                            value={selectPlatform}
                            onChange={selectPlatformHandler}
                            className="userProfile_Packages_Select"
                        >
                            <option value="All">All</option>
                            {packages.map((plat, i) => {
                                return (
                                    <option key={i} value={plat.platform}>
                                        {plat.platform}
                                    </option>
                                );
                            })}
                        </select>
                    </div>
                    {/* userProfile_Packages_Card */}

                    {
                        // if selectPlatform equal to All then all will populate
                        selectPlatform === "All"
                            ? packages.map((plat, i) => {
                                return (
                                    <div
                                        className="userProfile_Packages_Card"
                                        key={i}
                                        onClick={() => packagesHandler(plat, i)}
                                    >
                                        <p className="userProfile_Packages_Card_platform">
                                            {plat.platform}
                                        </p>
                                        <div className="userProfile_Packages_Card_Title_Cont">
                                            <p>{plat.packageOffer}</p>
                                            <div className="circle_selected_Cont">
                                                <p>Rs: {plat.packagePrice}</p>

                                                <div
                                                    className={
                                                        selected === i
                                                            ? "circle_selected"
                                                            : "circle_Not_selected"
                                                    }
                                                ></div>
                                            </div>
                                        </div>
                                        <div className="userProfile_Packages_Card_desc">
                                            {plat.packageDescription}
                                        </div>
                                    </div>
                                );
                            })
                            : // if selectPlatform equal to Platforms then Platforms will populate
                            packages
                                .filter((plat) => plat.platform === selectPlatform)
                                .map((plat, i) => {
                                    return (
                                        <div
                                            className="userProfile_Packages_Card"
                                            key={i}
                                            onClick={() => packagesHandler(plat, i)}
                                        >
                                            <p className="userProfile_Packages_Card_platform">
                                                {plat.platform}
                                            </p>
                                            <div className="userProfile_Packages_Card_Title_Cont">
                                                <p>{plat.packageOffer}</p>
                                                <div className="circle_selected_Cont">
                                                    <p>Rs: {plat.packagePrice}</p>

                                                    <div
                                                        className={
                                                            selected === i
                                                                ? "circle_selected"
                                                                : "circle_Not_selected"
                                                        }
                                                    ></div>
                                                </div>
                                            </div>
                                            <div className="userProfile_Packages_Card_desc">
                                                {plat.packageDescription}
                                            </div>
                                        </div>
                                    );
                                })
                    }
                </div>
                <div className="userProfile_Packages_Select_Cont">
                    <p className="userProfile_Packages_Select_title">
                        This is Title
                        {/* {influencerProfile && influencerProfile.influencerTitle} */}
                    </p>
                    {/* <select
                            className="packages_inputSelect"
                            value={packageData.offering}
                        >
                            {state.packages.map((option, i) => {
                                return (
                                    <option key={i} defaultValue={option.offering}>{option.offering}</option>
                                )
                            })}
                            
                        </select> */}
                    <p className="userProfile_Packages_Select_Offer">
                        {packageData && packageData.packageOffer}
                    </p>
                    <p className="userProfile_Packages_Select_Price">

                        {packageData && `Rs: ${packageData.packagePrice}`}
                    </p>
                    <p className="userProfile_Packages_Select_desc">
                        {packageData && packageData.packageDescription}
                    </p>
                    {/* Continue Button */}
                    <div className="continue_container">
                        <button className="continueBtn">Continue</button>
                    </div>
                    <p className="stepOne_label">Send Custom Offer</p>
                </div>
                {/* FAQS */}
                <div>
                    <p className="faq">FAQ</p>
                    {faqs.map((accord, index) => {
                        return (
                            <div
                                key={index}
                                style={{ padding: "20px 0px 20px 0px" }}
                            >
                                <Accourdion
                                    id={index}
                                    question={accord.faqsQuestion}
                                    answer={accord.faqsDescription}
                                // accourdion={accourdion}
                                />
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    )
}



const Accourdion = ({ question, answer }) => {
    const [openAccourdion, setOpenAccourdion] = useState(false);
    return (
        <div>
            <div
                style={{ cursor: "pointer" }}
                // className="collabsCafeFAQS_cont"
                onClick={() => setOpenAccourdion(!openAccourdion)}
            >
                <p className="collabsCafeFAQS_title">{question}</p>
            </div>
            {openAccourdion && (
                <div className="accoudion_Desc">
                    <p>{answer}</p>
                </div>
            )}
            {/* Line separetor */}
            <div className="line_separator" />
        </div>
    );
};
export default ExampleProfile