import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';

export const CustomizedButton = styled(Button)(({ theme }) => ({
    color: theme.palette.getContrastText("#05a081"),
    backgroundColor: "#05a081",
    '&:hover': {
      backgroundColor: "#05a081",
    },
    width: 200,
    height: 45,
  }));