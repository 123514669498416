import React, { useEffect, useState } from 'react';
import "./AllInfluencers.css";
import { useSelector, useDispatch } from 'react-redux';
import { Link, useSearchParams, useNavigate } from 'react-router-dom';
import { AiOutlineHeart } from "react-icons/ai"
import { fetchInfluencerProfile } from "../../Redux/Reducers/fetchInfluencerProfile";
import numeral from 'numeral';
import HashLoader from '../HashLoader';
import PaginationComponent from "../../components/Pagination/Pagination";
import Checkbox from '@mui/material/Checkbox';
import FavoriteBorder from '@mui/icons-material/FavoriteBorder';
import Favorite from '@mui/icons-material/Favorite';
import { FavoritesList, fetcheFavoritesList } from '../../Redux/Reducers/BrandFavoritesReducer';
import { Notify } from '../Notify';
import { Button } from '@mui/material';

const label = { inputProps: { 'aria-label': 'Checkbox demo' } };






const AllInfluencers = ({ influencers, setInfluencers, page, setPage }) => {

    // const values = useSelector((state) => state.searchBox.value);
    // const influencersReducer = useSelector((state) => state.searchBox);

    const brand = useSelector((state) => state.User.brand);
    const dispatch = useDispatch();
    const influencerprofiles = useSelector((state) => state.influencers);
    let [profiles, setProfiles] = useState([]);
    let [searchParams, setSearchParams] = useSearchParams();
    let [count, setCount] = useState(20);
    // const [influencers, setInfluencers] = useState([]);
    // let [page, setPage] = useState(
    //     searchParams.get("page") || 1
    // );
    const navigate = useNavigate();
    // const query = useQuery();
    // const history = useHistory();
    // const page = searchParams.get("page") || 1;
    const search = searchParams.get("searchQuery");
    let { influencerProfiles } = influencerprofiles;


    // Favorites Change
    const addFavoritesChange = (check, id) => {
        if (brand) {
            if (check) {
                const findInflu = influencerProfiles.verifiedProfiles.find((val) => val._id === id);

                const data = {
                    isChecked: check,
                    brandId: brand._id,
                    influencerList: findInflu
                }

                dispatch(FavoritesList(data)).then((res) => {
                    if (res?.type === "brand_Favorites/FavoritesList/fulfilled") {
                        Notify("success", res?.payload?.msg);
                        dispatch(fetchInfluencerProfile());
                    } else if (res?.type === "brand_Favorites/FavoritesList/rejected") {
                        Notify("error", res?.payload);
                    }
                })
            }
        } else {
            Notify("error", "Register As a Brand!")
        }
    }


    const cardRedirect = (e) => {
        let name = e.userName.replace(/\s+/g, '');
        // //  console.log("name==>", name);
        // navigate(`/${name}`);
        return <a href={`www.collabscafe.com/${name}`}></a>
    }



    const loadMore = () => {
        if (count < influencers.length) {
            setCount(count + 24);
        } else {
            return;
        }
    }




    useEffect(() => {
        dispatch(fetchInfluencerProfile()).then((res) => {
            if (res?.type === "influencers/fetchInfluencerProfile/fulfilled") {
                setProfiles(res?.payload?.verifiedProfiles);
            }
        });
        return;
    }, [dispatch])


    // useEffect(() => {
    //     if (influencersReducer) {
    //         setInfluencers(influencersReducer?.influ?.data)
    //     }
    // }, [influencersReducer])

    // useEffect(() => {
    //     if (brand) {
    //         dispatch(fetcheFavoritesList(brand._id));
    //     }
    //     return;
    // }, [brand])

    return (
        <div >

            <div className="allInfluencer_listing-row">
                {/* Card Influencer */}
                {
                    influencers && influencers.slice(0, count)?.map((influ, index) => {
                        return (

                            <div key={index} className="allInfluencer_featured-profile-listing-holder"
                            // onClick={() => cardRedirect(influ)}
                            >
                                <a
                                    href={`/${influ.userName.replace(/\s+/g, '')}`}
                                    target='_blank'
                                    style={{ textDecoration: "none" }}
                                >
                                    <div className="allInfluencer_profile-listing-img-holder">
                                        <div className="heart_list">
                                            <Checkbox
                                                checked={brand && brand._id === influ?.isFavorite?.brandID ? true : false}
                                                onChange={(e) => addFavoritesChange(e.target.checked, influ._id)}
                                                size={"medium"}
                                                {...label} icon={<FavoriteBorder />} checkedIcon={<Favorite />} color={"error"} />
                                        </div>
                                        {/* <div className="heart"> <AiOutlineHeart onClick={() => addToFavorities(influ._id)} color='white' size={26} /></div> */}
                                        <img
                                            loading="lazy"
                                            className="allInfluencer_profile-listing-img"
                                            src={influ.contentImage[0] && influ.contentImage[0].url}
                                            alt="  creator Brad Sousa being photographed"
                                        />
                                        <div className="allInfluencer_profile-listing-owner-holder">
                                            <div className="allInfluencer_profile-listing-owner-info-holder">
                                                <div className="allInfluencer_profile-listing-owner-name">
                                                    {influ.fullName}

                                                    {/* <img
                                                                className="allInfluencer_verified-badge"
                                                                alt=""
                                                                src="https://d5ik1gor6xydq.cloudfront.net/websiteImages/creatorMarketplace/verifiedBadge.svg"
                                                            /> */}
                                                </div>
                                                <div className="allInfluencer_profile-listing-owner-location">
                                                    {influ.influencerCity}
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {/* ****** platform Price ****** */}
                                    <div className="allInfluencer_card_platform_price">
                                        <p className="allInfluencer_platform">{influ.packages[0].platform}</p>
                                        <p className="price"><span className="currency">Rs:</span> {numeral(influ.packages[0].packagePrice).format('0,00.00')}</p>
                                    </div>
                                    {/* ****** Description ***** */}
                                    <p className="allInfluencer_description">
                                        {influ.influencerTitle.slice(0, 90)} {influ.influencerTitle.length > 90 ? <span style={{ fontSize: 12, color: "black" }}>...See More</span> : ""}
                                    </p>
                                </a>
                            </div>
                        )
                    })


                }

                {/* {
                    
                   
                        <div style={{ display: "flex", justifyContent: "center" }}>
                            <HashLoader />
                        </div>
          
                } */}
            </div>
            {
                influencers && influencers.length > 20 ? (
                    <div style={{ display: "flex", justifyContent: "center", marginTop: 30 }}>
                        <Button
                            disabled={count >= influencers.length ? true : false}
                            variant="contained" className="mr-8 approve_btn" onClick={loadMore}
                            style={{ background: "#05a081" }}
                        >Load More</Button>
                        {/* <button onClick={loadMore}>Load More</button> */}
                    </div>
                ) : null
            }
            {/* <div className='pagination_container'>
                <PaginationComponent
                    influencers={influencers}
                    page={page}
                    setPage={setPage} />
            </div> */}

        </div>
    )
}

export default AllInfluencers