import React, { useState } from "react";
import "./StepTwo.css";
import toast from "react-hot-toast";
import Loading from "../../../../components/Loading/Loading";
import { AddInfluencerProfile } from "../../../../Redux/Reducers/InfluencerProfileReducer";
import { Notify } from "../../../../components/Notify";

const StepTwo = ({ step, setStep, influencerTitle, setInfluencerTitle }) => {

  const [loading, setLoading] = useState(false);

  const titleHandler = () => {
    setLoading(true);
    if (!influencerTitle) {
      Notify("error", "Enter Your Title!");
      setLoading(false);
    } else {
      // dispatch(AddInfluencerProfile(title));
      // console.log("title", title);
      setTimeout(() => {
        setLoading(false);
        setStep(step + 1);
      }, 2000)
    }
  };
  return (
    <div className="stepOne">
      <div>
        <h1 className="stepOne-heading">
          Summarize yourself, this is your title shown on your profile
        </h1>
        {/* <p className="stepEmailOtpVerify_desc">
          We sent an email to ziaalikhan052@gmail.com. Check your inbox and
          enter the 6-digit code to verify your email.
        </p> */}
        <div className="input_Container">
          <input
            type="text"
            placeholder="E.g Fitness Content Creator & Student Athlete"
            value={influencerTitle}
            onChange={(e) => setInfluencerTitle(e.target.value)}
          />
        </div>

        {/* signUp Button */}
        <div className="continue_container">
          {
            loading ? (
              <button className="continueBtn" >
                <Loading />
              </button>
            ) : (
              <button className="continueBtn" onClick={titleHandler}>
                Continue
              </button>
            )
          }

        </div>
        {/* <p className="stepOne_label">I don't see my city</p> */}
      </div>
    </div>
  );
};

export default StepTwo;
