import React from "react";
import Money from "../../images/money.png";
import Check from "../../images/check.png";
import Chat from "../../images/chat.png";
import Sell from "../../images/sell.png";
import Link from "../../images/link.png";
import Tax from "../../images/tax.png";

const CollabsCafeGuideCreator = () => {
  return (
    <div className="collabsCafeGuide">
      <div className="collabsCafe_guide_Container">
        <div className="collabsCafe_guide_Card">
          <img className="collabsCafe_guide_Icon" src={Sell} alt="cost.png" />
          <p className="collabsCafe_guide_heading">Get Brand Deals</p>
          <p className="collabsCafe_guide_Desc">
            Get discovered by thousands of brands on our marketplace looking to
            work with you.
          </p>
        </div>
        <div className="collabsCafe_guide_Card">
          <img className="collabsCafe_guide_Icon" src={Check} alt="cost.png" />
          <p className="collabsCafe_guide_heading">Manage Collabs</p>
          <p className="collabsCafe_guide_Desc">
            Easily keep track of brand deals and deadlines. Submit deliverables
            directly through the platform.
          </p>
        </div>
        <div className="collabsCafe_guide_Card">
          <img className="collabsCafe_guide_Icon" src={Money} alt="cost.png" />
          <p className="collabsCafe_guide_heading">Always Get Paid</p>
          <p className="collabsCafe_guide_Desc">
            Funds are collected upfront and transferred to your bank account
            when you complete the collaboration.
          </p>
        </div>
        <div className="collabsCafe_guide_Card">
          <img className="collabsCafe_guide_Icon" src={Link} alt="cost.png" />
          <p className="collabsCafe_guide_heading">Custom Link</p>
          <p className="collabsCafe_guide_Desc">
            Share your custom URL in your link in bio and with brands. Drive
            traffic to your page and get more brand deals.
          </p>
        </div>
        <div className="collabsCafe_guide_Card">
          <img className="collabsCafe_guide_Icon" src={Chat} alt="cost.png" />
          <p className="collabsCafe_guide_heading">Instant Chat</p>
          <p className="collabsCafe_guide_Desc">
            Receive messages from brands and communicate instantly throughout
            the whole collaboration.
          </p>
        </div>
       
      </div>
    </div>
  );
};

export default CollabsCafeGuideCreator;
