import React, { useEffect, useState } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { BsFillArrowRightCircleFill, BsFillArrowLeftCircleFill } from "react-icons/bs"
import { useNavigate } from "react-router-dom";
import numeral from "numeral";
import HashLoader from "../HashLoader";


function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <BsFillArrowRightCircleFill className={className}
      style={{ ...style, display: "block", color: "#05a081", width: "25px", height: "30px" }}
      onClick={onClick} />
  );
}
function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <BsFillArrowLeftCircleFill className={className}
      style={{ ...style, display: "block", color: "#05a081", width: "25px", height: "30px" }}
      onClick={onClick} />
  );
}


const YoutubeInfluencers = ({ profilesList }) => {
  const [youtube_profiles, setYoutubeProfiles] = useState([]);
  const navigate = useNavigate();



  const cardRedirect = (e) => {
    let name = e.userName.replace(/\s+/g, '');;
    navigate(`/${name}`, { state: e });
  }


  useEffect(() => {
    if (profilesList && profilesList.length > 0) {
      const filterYoutubeProfiles = profilesList.filter((elem) => {
        return elem?.packages?.some((ele) => {
          return ele.platform === "Youtube";
        });
      });
      if (filterYoutubeProfiles.length > 0) {
        setYoutubeProfiles(filterYoutubeProfiles);
      } else {
        setYoutubeProfiles([]);
      }

    }
  }, [profilesList]);

  return (
    <div className="influencers">
      <h1 className="influencers_heading">YouTube</h1>
      <div className="influencers_heading_desc">
        <p className="influencers_heading_desc_One">Hire YouTube influencers</p>
        <p className="influencers_seeAll">See All</p>
      </div>
      {/* ******* Image Card ******* */}
      <div className="influencers_listing-row">

        {
          youtube_profiles && youtube_profiles.length > 0 ? (
            youtube_profiles.slice(0, 6).map((influ) => {
              return (
                <div className="influencers_featured-profile-listing-holder" key={influ._id} onClick={() => cardRedirect(influ)}>
                  <div className="influencers_profile-listing-img-holder">
                    <img
                      loading="lazy"
                      className="influencers_profile-listing-img"
                      src={influ.contentImage[0] && influ.contentImage[0].url}
                      alt="  creator Brad Sousa being photographed"
                    />
                    <div className="influencers_profile-listing-owner-holder">
                      <div className="influencers_profile-listing-owner-info-holder">
                        <div className="influencers_profile-listing-owner-name">
                          {influ.fullName}
                          {/* <img
              className="influencers_verified-badge"
              alt=""
              src="https://d5ik1gor6xydq.cloudfront.net/websiteImages/creatorMarketplace/verifiedBadge.svg"
            /> */}
                        </div>
                        <div className="influencers_profile-listing-owner-location">
                          {influ.influencerCity}
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* ****** platform Price ****** */}
                  <div className="card_platform_price">
                    <p className="platform">{influ.packages[0].platform}</p>
                    <p className="price"><span className="currency">Rs:</span> {numeral(influ.packages[0].packagePrice).format('0,00.00')}</p>
                  </div>
                  {/* ****** Description ***** */}
                  <p className="description">
                    {influ.influencerTitle.slice(0, 90)}{influ.influencerTitle.length > 90 ? <span style={{ color: "black", fontSize: 12 }}>... see More</span> : ""}
                  </p>
                </div>
              )
            })
          ) : (
            <>
              <div style={{ display: "flex", justifyContent: "center", margin: "0 auto" }}>
                <HashLoader />
              </div>
            </>
          )
        }





      </div>
    </div>
  );
};

export default YoutubeInfluencers;
