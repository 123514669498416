import React, { useEffect, useState } from 'react'
import "./Influencers.css";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { SubFooter, Footer, GenderFiltered, CountryFiltered, MoreFilteres, AllInfluencers } from "../index";
import toast from "react-hot-toast";
import { ImSearch } from 'react-icons/im';
// import { Add_Search_Box } from "../../Redux/Reducers/SearchBoxReducer";
import { options, platforms } from "../../components/SearchBox/optionsData";
import { citiesData } from "../CreatePage/Steps/StepOne/citiesData";
import { Chip } from '@mui/material';
import HashLoader from '../../components/HashLoader';
import { searchInfluencers } from '../../Redux/Reducers/SearchBoxReducer';

const Influencers = () => {
    const values = useSelector((state) => state.searchBox);
    const influencersReducer = useSelector((state) => state.searchBox);
    const All_Influencers = useSelector((state) => state.influencers.influencerProfiles.verifiedProfiles);

    const { state } = useLocation();

    const [citiesdata, setCitiesData] = useState(citiesData);
    // States for Open 
    const [openCountry, setOpenCountry] = useState(false);
    const [openGender, setOpenGender] = useState(false);
    const [openMoreFilters, setOpenMoreFilters] = useState(false);
    // For Select Box 
    // const data = useSelector((state) => state.searchBox);
    // Get Exact Values from Home Page Select Box
    const [inputVal, setInputVal] = useState(state?.category.length ? state?.category.toString().replace(/and/g, "&").split(",") : []);
    const [platformInput, setPlatformInput] = useState(state?.platform);
    const [influencers, setInfluencers] = useState([]);
    const [open, setOpen] = useState(false);
    const [openPlatform, setOpenPlatform] = useState(false);
    // Get Country,Gender,More Filter Data
    const [country, setCountry] = useState(state?.country ? state?.country : "");
    const [gender, setGender] = useState(state?.gender ? state?.gender : "");
    const [price, setPrice] = useState(state?.price ? state?.price : "");
    const [followers, setFollowers] = useState(state?.followers ? state?.followers : "");
    const [page, setPage] = useState(1);

    const navigate = useNavigate();
    const dispatch = useDispatch();

    // Close Outside Popup
    const closeOutSide = () => {
        if (openCountry) {
            setOpenCountry(false);
        }
        if (openGender) {
            setOpenGender(false);
        }
        if (openMoreFilters) {
            setOpenMoreFilters(false);
        }
    }

    // ****** category Options Handler ******
    const optionsHandler = (val) => {
        const value = val;
        const check = inputVal ? inputVal.find((val) => val === value) : [];
        if (value !== check) {
            setInputVal([...inputVal, value]);
        }
        else {
            toast.error(`The selected category ${check} is Added!`);
        }
    };

    // ****** Platform Input Handler ******
    const platformsHandler = (event) => {
        setPlatformInput(event);
        setOpenPlatform(false);
    }
    // ****** Close Options when outside clicks ******
    const closeOutside = () => {
        if (open) {
            setOpen(false);
        }
        if (openPlatform) {
            setOpenPlatform(false);
        }
    }


    // Format Categories
    let inputValueTrimed = inputVal.length ? inputVal.toString().replace(/&/g, "and").split(",") : [];
    for (let i = 0; i < inputValueTrimed.length; i++) {
        if (inputVal.length > 0) {
            inputValueTrimed[i] = "&ct=" + inputValueTrimed[i]
        }
    }


    // ****** Search Data ******
    const searchHandler = () => {
        let query = `/influencers?${platformInput ? `p=${platformInput}` : ""}${inputVal.length > 0 ? `${inputValueTrimed.toString().replace(/,/g, "")}` : ""}${country ? `&c=${country}` : ""}${gender ? `&g=${gender}` : ""}${price ? `&pr=${price}` : ""}${followers ? `&f=${followers}` : ""}`
        dispatch(searchInfluencers(query.slice(12)));
        navigate(query, {
            state: {
                platform: platformInput,
                category: inputValueTrimed && inputValueTrimed.map((v) => v.replace(/&ct=/g, "")),
            }
        });

    }



    // Get All Search Categories
    // const getCategories = () => {
    //     return (
    //         <>
    //             {values.inputVal.map((cat, i) => {
    //                 return (
    //                     <span key={i}>{cat} | </span>
    //                 )
    //             })}
    //         </>
    //     )
    // }

    // if city, gender
    useEffect(() => {
        if (country || gender || price || followers) {
            let query = `/influencers?${platformInput ? `p=${platformInput}` : ""}${inputVal.length > 0 ? `${inputValueTrimed.toString().replace(/,/g, "")}` : ""}${country ? `&c=${country}` : ""}${gender ? `&g=${gender}` : ""}${price ? `&pr=${price}` : ""}${followers ? `&f=${followers}` : ""}`
            dispatch(searchInfluencers(query.slice(12)));
            navigate(query, {
                state: {
                    platform: platformInput,
                    category: inputValueTrimed && inputValueTrimed.map((v) => v.replace(/&ct=/g, "")),
                    country: country,
                    gender: gender,
                    price: price,
                    followers: followers,
                    page: page,
                }
            });
        }

    }, [country, gender, price, followers])



    useEffect(() => {
        if (influencersReducer) {
            setInfluencers(influencersReducer?.influ?.data)
        }
    }, [influencersReducer])

    useEffect(() => {
        let query = `/influencers?${platformInput ? `p=${platformInput}` : ""}${inputVal.length > 0 ? `${inputValueTrimed.toString().replace(/,/g, "")}` : ""}${country ? `&c=${country.replace(/\s/g, '')}` : ""}${gender ? `&g=${gender.replace(/\s/g, '')}` : ""}${price ? `&pr=${price.replace(/\s/g, '')}` : ""}${followers ? `&f=${followers.replace(/\s/g, '')}` : ""}`
        dispatch(searchInfluencers(query.slice(12)));
    }, [])



    return (
        <div >
            {/* SearchBox */}
            <div className="searchBox" onClick={closeOutside}>
                <div className="searchBox_Container">
                    <div className="search_input_container">
                        <div className="search_Input_div">
                            <p className="input_label">Platform</p>
                            <input
                                type="text"
                                name="platformInput"
                                value={platformInput}
                                readOnly={true}
                                // onChange={(e) => setInputVal(e.target.value)}
                                className="search_Input"
                                placeholder={platformInput ? undefined : "Search Platform"}
                                onClick={() => setOpenPlatform(!openPlatform)}
                            />
                            {
                                openPlatform && (
                                    <div className="options_container">
                                        {
                                            platforms.map((plat, i) => {
                                                return (
                                                    <p key={i} className="platforms" onClick={() => platformsHandler(plat)}>{plat}</p>
                                                )
                                            })
                                        }
                                    </div>
                                )
                            }

                        </div>
                        <div className="search_Input_div">
                            <p className="input_label">Category</p>
                            <input
                                type="text"
                                name="inputVal"
                                value={inputVal}
                                onChange={(e) => setInputVal(e.target.value)}
                                className="search_Input"
                                placeholder="Search Category."
                                onClick={() => setOpen(!open)}
                            />

                            {open && (
                                <>
                                    <div className="options_container"
                                        onClick={e => {
                                            // do not close modal if anything inside modal content is clicked
                                            e.stopPropagation();
                                        }}
                                    >
                                        <p className="options_Heading">Popular <span>🔥</span></p>
                                        <div className="options">
                                            {options.map((val, i) => {
                                                return (
                                                    <div className="option_inner" key={i}>
                                                        <p onClick={() => optionsHandler(val)}>
                                                            <Chip label={val} variant="outlined" />
                                                        </p>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    </div>
                                </>
                            )}

                        </div>

                        <div className="searchButton">
                            <button onClick={searchHandler}>< ImSearch /></button>
                        </div>
                    </div>
                </div>
            </div>
            {/*  SearchBox End*/}
            <div >
                <div onClick={closeOutSide} className='inflencers_Search'>
                    <div className='filtersBtn_Cont'>
                        <CountryFiltered
                            openCountry={openCountry}
                            setOpenCountry={setOpenCountry}
                            citiesdata={citiesdata}
                            setCitiesData={setCitiesData}
                            country={country}
                            setCountry={setCountry}
                        />
                    </div>
                    <div className='filtersBtn_Cont'>
                        <GenderFiltered
                            openGender={openGender}
                            setOpenGender={setOpenGender}
                            gender={gender}
                            setGender={setGender}
                        />
                    </div>
                    <div className='filtersBtn_Cont'>
                        <MoreFilteres
                            openMoreFilters={openMoreFilters}
                            setOpenMoreFilters={setOpenMoreFilters}
                            price={price}
                            setPrice={setPrice}
                            followers={followers}
                            setFollowers={setFollowers}
                        />
                    </div>
                    <div className='clear_All'>
                        <button onClick={() => {
                            dispatch(searchInfluencers("")).then((res) => {
                                setInputVal([]);
                                setPlatformInput(null);
                                navigate("/influencers");

                            })
                        }}>Clear All</button>
                    </div>

                </div>
            </div>
            {/*  */}
            <div className='influencers_card'>
                <h1 className='brandSignup-heading'>Influencers ({influencers && influencers.length})</h1>
                {/* <h1 className='brandSignup-heading'>{values.platformInput ? `${values.platformInput} |` : null} {values.inputVal ? getCategories() : null} {values.country ? `${values.country} |` : null} {values.gender ? `${values.gender} |` : null} {values.price ? `${values.price} |` : null} {values.followers ? `${values.followers} |` : null} Influencers</h1> */}
                {
                    influencersReducer.loading ? (
                        <div style={{ display: "flex", justifyContent: "center" }}>
                            <HashLoader />
                        </div>
                    ) : (
                        <AllInfluencers
                            influencers={influencers}
                            setInfluencers={setInfluencers}
                            page={page}
                            setPage={setPage}
                        />
                    )
                }

            </div>

            <SubFooter />
            <Footer />
        </div>
    )
}





export default Influencers;