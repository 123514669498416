import React, { useEffect, useState } from "react";
import "./StepEight.css";
import { Notify } from "../../../../components/Notify";
import Loading from "../../../../components/Loading/Loading";


const StepEight = ({ step, setStep, packagesInputs, setPackagesInput }) => {

  const [loading, setLoading] = useState(false);



  let handleChange = (i, e) => {
    let newFormValues = [...packagesInputs];
    newFormValues[i][e.target.name] = e.target.value;
    setPackagesInput(newFormValues);
  };


  let addFormFields = () => {
    if (packagesInputs.length < 5) {


      setPackagesInput([
        ...packagesInputs,
        {
          platform: "",
          contentType: "",
          packageOffer: "",
          packageDescription: "",
          packageDeadline: "",
          packagePrice: "",
          packageDeadline: "2 Days",
        },
      ]);
    }
  };

  let removeFormFields = (i) => {
    let newFormValues = [...packagesInputs];
    newFormValues.splice(i, 1);
    if (packagesInputs.length > 1) {
      setPackagesInput(newFormValues);
    }
  };

  // const getItem = () => {
  //   alert(JSON.stringify(packagesInputs));
  // };

  // console.log("Inner==>", packagesInputs.platform);
  const pacakagesHandler = () => {
    setLoading(true);
    const id = packagesInputs.length - 1;

    let price_is_Ok = true;
    packagesInputs.map((v, i) => {
      if (v.packagePrice < 2500) {
        setLoading(false);
         Notify("error", `Minimum Package Price is 2500 Rupees!`);
         price_is_Ok = false;
      } 
    })

    if (!packagesInputs[id].platform) {
      setLoading(false);
      price_is_Ok = false;
      Notify("error", "Enter Your Packages Platform!");
    } else if (!packagesInputs[id].contentType) {
      setLoading(false);
      price_is_Ok = false;
      Notify("error", "Enter Your Packages ContentType!");
    } else if (!packagesInputs[id].packageOffer) {
      setLoading(false);
      price_is_Ok = false;
      Notify("error", "Enter Your Packages Offer!");
    } else if (!packagesInputs[id].packageDescription) {
      setLoading(false);
      price_is_Ok = false;
      Notify("error", "Enter Your Packages Description!");
    } else if (packagesInputs[id].packageDescription.length < 10) {
      setLoading(false);
      price_is_Ok = false;
      Notify("error", "Packages Description Should be greater than 10 characters!");
    }
    else if (!packagesInputs[id].packagePrice) {
      setLoading(false);
      price_is_Ok = false;
      Notify("error", "Enter Your Packages Price!");
    } 
    // else if (packagesInputs[id].packagePrice < 2500) {
    //   setLoading(false);
    //   Notify("error", "Minimum Package Price is 2500 Rupees!");
    // } 

   
    
    if(price_is_Ok) {
      setTimeout(() => {
        setLoading(false);
        setStep(step + 1);
      }, 2000);
    }

    // else if (packagesInputs[id].packagePrice < 2500) {
    //   console.log("ID", id);
    //   // setLoading(false);
    //   // Notify("error", "Minimum Package Price is 2500 Rupees!");
    // }

  }



  return (
    <div className="stepEight">
      <h1 className="stepEight-heading">Add your packages</h1>

      <p className="stepEight-desc">
        These are services brands can purchase from you. This can be anything
        from a shoutout on your social media to original content creation.
        Unsure what to charge?{" "}
        {/* <span className="useCalculator">Use our rate calculator</span>. */}
      </p>

      {/* Cards */}
      <div className="packages_cards_Container">
        {packagesInputs.map((element, index) => {
          return (
            <div className="packages_cards" key={index}>
              <div className="packages_cards_Items">
                <div className="stepEight_Cont">
                  <p className="packages_heading">Package {index + 1}</p>
                  {index >= 1 && (
                    <p
                      className="deletePackage"
                      onClick={() => removeFormFields(index)}
                    >
                      +
                    </p>
                  )}
                </div>
                {/* User Generated Content Select */}

                <div>
                  <select
                    className="packages_inputSelect"
                    name="platform"
                    value={element.platform || ""}
                    onChange={(e) => handleChange(index, e)}
                  // onChange={(e) => handlePackages(e)}
                  >
                    <option value="" disabled="" hidden>
                      Choose Platform
                    </option>
                    <option value="All">All</option>
                    <option  >
                      Tiktok
                    </option>
                    <option value="Instagram">Instagram</option>
                    <option value="User Generated Content">User Generated Content</option>
                    <option value="Youtube">Youtube</option>
                  </select>
                </div>
                {/* User Generated Content Select Two */}

                {packagesInputs[index].platform ? (
                  <div>
                    <select
                      className="packages_inputSelect"
                      name="contentType"
                      value={element.contentType || ""}
                      onChange={(e) => handleChange(index, e)}
                    >
                      <option value="" disabled="" hidden>
                        Choose Content Type
                      </option>

                      <option value="Product Video">Product Video</option>
                      <option value="Product Photo">Product Photo</option>
                      <option value="Video Ad">Video Ad</option>
                      <option value="Photo Ad">Photo Ad</option>
                      <option value="Tutorial">Tutorial</option>
                      <option value="Testimonial / Review">
                        Testimonial / Review
                      </option>
                      <option value="UnBoxing">UnBoxing</option>
                      <option value="Blog">Blog</option>
                      <option value="Other">Other</option>
                    </select>
                  </div>
                )
                  : null
                }

                {/* package Offering Input */}
                <div>
                  <input
                    type="text"
                    className="packages_input"
                    placeholder="What is This Package Offering?"
                    name="packageOffer"
                    value={element.packageOffer}
                    onChange={(e) => handleChange(index, e)}
                  />
                </div>
                {/* package Description */}
                <div>
                  <textarea
                    className="package_description"
                    cols={7}
                    rows={7}
                    name="packageDescription"
                    placeholder="What is Included in the package? How many posts or photos? What will the buyer will getting?"
                    value={element.packageDescription}
                    onChange={(e) => handleChange(index, e)}
                  ></textarea>
                </div>
                {/* Package Time Deadline */}
                <div>
                    <select
                      className="packages_inputSelect"
                      name="packageDeadline"
                      value={element.packageDeadline || ""}
                      onChange={(e) => handleChange(index, e)}
                    >
                      <option value="" disabled="" hidden>
                        2 Days
                      </option>

                      <option value="2 Days">2 Days</option>
                      <option value="3 Days">3 Days</option>
                      <option value="4 Days">4 Days</option>
                      <option value="5 Days">5 Days</option>
                      <option value="6 Days">6 Days</option>
                      <option value="7 Days">
                       7 Days
                      </option>
                      <option value="8 Days">8 Days</option>
                      <option value="9 Days">9 Days</option>
                      <option value="10 Days">10 Days</option>
                    </select>
                  </div>
                {/* Package Price */}
                <div>
                  <input
                    type="number"
                    className="packages_input"
                    placeholder="Price (PKR)"
                    name="packagePrice"
                    value={element.packagePrice || ""}
                   
                    onChange={(e) => handleChange(index, e)}
                  />
                </div>

                {
                  packagesInputs[index].packagePrice > 0 && packagesInputs[index].packagePrice < 2500 && (
                    <p className="price_error">Minimum Package Price is 2500 Rupees</p>
                  )
                }
                {/* text fees Deduction */}
                {/* <div>
                  <p className="collabsFeesDeduction">
                    Collabscafe takes a 15% fee
                  </p>
                </div> */}
              </div>
            </div>
          );
        })}
      </div>
      <div className="addMoreButtonCont">

        <p className="addMoreButton" onClick={addFormFields}>Add More Item</p>
      </div>
      {/* <button onClick={getItem}>Add More Item</button> */}
      {/* signUp Button */}
      <div className="continue_container">
        {
          loading ? (
            <button className="continueBtn" ><Loading /></button>
          ) : (
            <button className="continueBtn" onClick={pacakagesHandler}>Continue</button>
          )
        }
      </div>
    </div>
  );
};

export default StepEight;


