import React, { useState, useEffect } from "react";
import "./Header.css";
import { Link, useNavigate } from "react-router-dom";
import CollabsCafeLogo from "../../images/logoSidebar.png";
import { useSelector, useDispatch } from "react-redux";
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import PersonIcon from '@mui/icons-material/Person';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import PaymentOutlinedIcon from '@mui/icons-material/PaymentOutlined';
import ExitToAppRoundedIcon from '@mui/icons-material/ExitToAppRounded';
import DashboardIcon from '@mui/icons-material/Dashboard';
import { logout } from "../../Redux/Reducers/UserReducer";
import { useLocation } from 'react-router-dom'
import { signOut } from "firebase/auth";
import { auth } from "../../config/firebase-config";
import toast from "react-hot-toast";
import { Notify } from "../../components/Notify";
import { logoutBrand } from "../../Redux/Reducers/UserReducer";
import { IoGitPullRequest } from "react-icons/io5";
import { RiMessage2Fill } from "react-icons/ri";
import { FcMoneyTransfer } from "react-icons/fc";
import { AiOutlineFolderAdd } from "react-icons/ai";
import Notifications from "../Notifications/Notifications";

const Header = ({ notifications }) => {
  const location = useLocation();
  const navigate = useNavigate();
  // let user = false;
  const user = useSelector((state) => state.User.user);
  const brand = useSelector((state) => state.User.brand);
  const influencerProfile = useSelector((state) => state.User.influencerProfile);
  const { allNotifications } = useSelector((state) => state.Chat);

  const dispatch = useDispatch();




  const [anchorEl, setAnchorEl] = useState(null);
  const [openNotifications, setOpenNotifications] = useState(null);
  const open = Boolean(anchorEl);
  const openNotify = Boolean(openNotifications);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleNotifications = (event) => {
    setOpenNotifications(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleNotifyClose = () => {
    setOpenNotifications(null);
  };

  const profile = () => {
    // if Influencer
    if (user?.verified) {
      // navigate("/profile");
      navigate(`/${user.userName}`);
    } else {
      navigate("/stepEmailOtpVerify");
    }

    // if Brand
    if (brand) {
      if (brand?.profileCompleted) {
        navigate(`/brand/${brand?.brand}`);
      } else {
        navigate("/profile-complete");
      }
    }
  }

  const accountFunc = () => {
    navigate("/account");
  }

  const userLogout = async () => {
    try {
      await signOut(auth);
      Notify("success", "Successfuly Loged out.");
      if (user) {
        dispatch(logout());
      } else if (brand) {
        dispatch(logoutBrand());
      }
      navigate("/");
      setTimeout(() => {
        window.location.reload();
      }, 1000)
    } catch (error) {
      Notify("error", "Soory It has Some Problem.");
    }
    // dispatch(logout());
  }
  // check which Role is Loged in
  let roles = brand || user;

  // console.log(brand || user);
  return (
    <div className="header">
      <div>
        <Link to="/">
          <img
            className="header_logo"
            src={CollabsCafeLogo}
            alt="logo.png"
          />
        </Link>
      </div>
      {
        roles ? (
          <>

            <div className="header_Navs_LoggedIn">
              <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
                <div className="header_Navs">
                  <Link to="/">Explore</Link>
                  {/* Notifications */}

                  {/* Notifications */}
                  <Link onClick={handleNotifications}>Notifications ({notifications.length})</Link>
                  {
                    user && (
                      <>
                        <Link to="/influencer-orders">Orders</Link>
                      </>
                    )
                  }
                  {
                    brand && (
                      <>
                        <Link to="/orders">Orders</Link>
                        {/* <Link to="">Campaigns</Link> */}
                        <Link to="/lists" className="join_Influencer">
                          Lists
                        </Link>
                      </>
                    )
                  }

                </div>
                <IconButton
                  onClick={handleClick}
                  size="small"
                  sx={{ ml: 2 }}
                  aria-controls={open ? 'account-menu' : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? 'true' : undefined}
                >
                  {
                    influencerProfile ? (
                      <img style={{ borderRadius: "50%" }} width={38} height={38} src={influencerProfile && influencerProfile.profileImage.url} />

                    ) : (
                      <Avatar sx={{ width: 36, height: 36 }}>{roles === user ? user?.email?.slice(0, 1).toUpperCase() : brand?.email?.slice(0, 1).toUpperCase()}</Avatar>
                    )
                  }

                </IconButton>
              </Box>

              <Notifications openNotify={openNotify} openNotifications={openNotifications} handleNotifyClose={handleNotifyClose} notifications={notifications} />
              <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                  elevation: 0,
                  sx: {
                    overflow: 'visible',
                    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                    mt: 1.5,
                    '& .MuiAvatar-root': {
                      width: 32,
                      height: 32,
                      ml: -0.5,
                      mr: 1,
                    },
                    '&:before': {
                      content: '""',
                      display: 'block',
                      position: 'absolute',
                      top: 0,
                      right: 14,
                      width: 10,
                      height: 10,
                      bgcolor: 'background.paper',
                      transform: 'translateY(-50%) rotate(45deg)',
                      zIndex: 0,
                    },
                  },
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
              >
                {/* {
                  brand && brand.role === "Admin" && (
                    <MenuItem onClick={() => navigate("admin-dashboard")}>
                      <DashboardIcon className="headerIcons" /> <p className="menuItems">Admin Dashboard</p>
                    </MenuItem>
                  )
                } */}
                <MenuItem onClick={profile}>
                  <PersonIcon className="headerIcons" /> <p className="menuItems">Profile</p>
                </MenuItem>
                {/* <MenuItem>
                  <PersonIcon className="headerIcons" /> <p className="menuItems">Notifications</p>
                </MenuItem> */}
                {/* {
                  user && (
                    <MenuItem onClick={() => navigate("/brands-Request")}>
                      <IoGitPullRequest className="headerIcons" /> <p className="menuItems">Brands Requests</p>
                    </MenuItem>
                  )
                } */}
                {
                  user && (
                    <MenuItem onClick={() => navigate("/brands-Request")}>
                      <IoGitPullRequest className="headerIcons" /> <p className="menuItems">Brands Requests</p>
                    </MenuItem>
                  )
                }
                {
                  user && (
                    <MenuItem onClick={() => navigate("/influencer-orders")}>
                      <AiOutlineFolderAdd className="headerIcons" /> <p className="menuItems">Orders</p>
                    </MenuItem>
                  )
                }
                {
                  brand && (
                    <MenuItem onClick={() => navigate("/orders")}>
                      <AiOutlineFolderAdd className="headerIcons" /> <p className="menuItems">Orders</p>
                    </MenuItem>
                  )
                }
                {
                  user && (
                    <MenuItem onClick={() => navigate("/influencer-billing")}>
                      <FcMoneyTransfer className="headerIcons" /> <p className="menuItems">Billing</p>
                    </MenuItem>
                  )
                }
                {
                  brand && (
                    <MenuItem onClick={() => navigate("/your-offers")}>
                      <PaymentOutlinedIcon className="headerIcons" /> <p className="menuItems">Your Custom Offers</p>
                    </MenuItem>
                  )
                }
                {
                  brand && (
                    <MenuItem onClick={() => navigate("/billing")}>
                      <PaymentOutlinedIcon className="headerIcons" /> <p className="menuItems">Billing</p>
                    </MenuItem>
                  )
                }

                <MenuItem onClick={() => navigate("/chat")}>
                  <RiMessage2Fill className="headerIcons" /> <p className="menuItems">Messages</p>
                </MenuItem>

                <MenuItem onClick={accountFunc}>
                  <AccountCircleIcon className="headerIcons" /> <p className="menuItems">Account</p>
                </MenuItem>
                <Divider />


                <MenuItem onClick={userLogout}>

                  <ExitToAppRoundedIcon className="headerIcons" />
                  <p className="menuItems">Sign Out</p>

                </MenuItem>

              </Menu>



            </div>
          </>



        ) : (

          <div className="header_Navs">
            <Link to="/">Explore</Link>
            <a href={"#howWorks"}>How It Works</a>
            <Link to="/login">Login</Link>
            <Link to="/brand">Join as Brand</Link>
            <Link to="/creator" className="join_Influencer">
              Join as Influencer
            </Link>

          </div>
        )
      }

    </div>
  );
};

export default Header;
