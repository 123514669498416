import React, { useState, useEffect } from 'react'
import "./InfluencerBilling.css";
import { useSelector, useDispatch } from 'react-redux';
import { fetchOrders } from '../../Redux/Reducers/Orders';
import numeral from 'numeral';
import dayjs from 'dayjs';
import { Alert, Button, Chip, Stack } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';


const InfluencerBilling = ({ CURRENT_USER_PROFILE }) => {
    // const ordersReducer = useSelector((state) => state.orders);
    const [orderList, setOrderList] = useState([]);

    const dispatch = useDispatch();
    const navigate = useNavigate();



    let totalBillingSum = () => {
        let total_earning = 0;
        orderList?.map((value) => {
            total_earning += parseFloat(value.order_package_price);
        });
        return {
            total_earning: numeral(total_earning).format("00.00")
        }

    }


    const handlePaymentRequest = () => {
        if(CURRENT_USER_PROFILE.total_earnings <=  CURRENT_USER_PROFILE.taken_amount_from_cc) {
            Swal.fire({
                icon: "error",
                title: "OOPS...",
                text: "Your Have Paid All Your Earnings, No Available Earnings!",
                confirmButtonColor: "#05a081",
            });
        }else {
            navigate(`/influencer-payment-request/${CURRENT_USER_PROFILE && CURRENT_USER_PROFILE._id}/${CURRENT_USER_PROFILE && CURRENT_USER_PROFILE.userName}`, { state: {...CURRENT_USER_PROFILE, total_earning: CURRENT_USER_PROFILE && CURRENT_USER_PROFILE.total_earnings} , })
        }
    }

    useEffect(() => {
        if (CURRENT_USER_PROFILE) {
            dispatch(fetchOrders(CURRENT_USER_PROFILE._id)).then((res) => {
                if (res?.type === "orders/fetchOrders/fulfilled") {
                    const filtered = res?.payload.filter((v) => v.payment_status === "Success");
                    setOrderList(filtered);
                }
            })
        }
    }, [CURRENT_USER_PROFILE])


    return (
        <div>
            <Alert severity="info" >
                You can Request a Payment once a week - Create a Payment Request - <strong>check it out!</strong>
            </Alert>
            <div className='Billing_header'>
                <h1>
                    Billing
                </h1>
                <div className='total_row'>
                    <div className='total_holder'>
                        <div className='total_title'>Total Earnings</div>
                        <div className='total_num'>{CURRENT_USER_PROFILE && numeral(CURRENT_USER_PROFILE.total_earnings).format("00,00")} Rs</div>
                    </div>
                    <div className='total_holder'>
                        <div className='total_title'>Taken Amount</div>
                        <div className='total_num'>{CURRENT_USER_PROFILE && numeral(CURRENT_USER_PROFILE.taken_amount_from_cc).format("00,00")} Rs</div>
                    </div>
                </div>
                <div>
                    <Button onClick={handlePaymentRequest} style={{ fontSize: 12, backgroundColor: "#05a081" }} size='medium' variant="contained">
                        Payment Request
                    </Button>
                </div>
                {/* <form className='input_form'>
                    <select className='input type-input'>
                        <option value="">All Spend</option>
                        <option value="">Declined</option>
                        <option value="">Refunded</option>
                    </select>
                    <select className='input type-input'>
                        <option value="2023">2023</option>
                        <option value="2024">2024</option>
                        <option value="2025">2025</option>
                        <option value="2026">2026</option>
                        <option value="2027">2027</option>
                        <option value="2028">2028</option>
                        <option value="2029">2029</option>
                    </select>
                    <select className='input type-input'>
                        <option value="">All Months</option>
                        <option value="">January</option>
                        <option value="" selected>Feburary</option>
                        <option value="" selected>March</option>
                        <option value="" selected>April</option>
                        <option value="" selected>May</option>
                        <option value="" selected>June</option>
                        <option value="" selected>July</option>
                        <option value="" selected>August</option>
                        <option value="" selected>September</option>
                        <option value="" selected>October</option>
                        <option value="" selected>November</option>
                        <option value="" selected>December</option>
                    </select>
                </form> */}
                <div className='orders_holder'>
                    <div className='order_table'>
                        <div className='order_holder'>
                            <div className='order_title orderDate'>Date</div>
                            <div className='order_title orderDetail'>Details</div>
                            <div className='order_title orderStatus'>Status</div>
                            <div className='order_title orderAmount'>Amount</div>
                        </div>
                        {
                            orderList && orderList.map((v, i) => {
                                return (
                                    <div key={i} className='order_holder_items'>
                                        <div className='order_title_items orderDate'>{dayjs(v.createdAt).format("DD-MM-YYYY")}</div>
                                        <div className='order_title_items orderDetail'>
                                            {v.order_package_description.slice(0, 50)}
                                        </div>
                                        {
                                            v.payment_status === "Success" ? (
                                                <div className='order_title_items orderStatus'>
                                                    {
                                                        v.order_status === "PENDING" ? (
                                                            <Chip label={`${v.order_status}`} color="warning" variant="outlined" />
                                                        ) : v.order_status === "COMPLETED" ? (
                                                            <Chip label={`${v.order_status}`} color="success" variant="outlined" />
                                                        ) : v.order_status === "DENIED" ? (
                                                            <Chip label={`${v.order_status}`} color="error" variant="outlined" />
                                                        ) : <Chip label={`${v.order_status}`} color="primary" variant="outlined" />
                                                    }
                                                </div>
                                            ) : (
                                                <Chip label={`${v.payment_status}`} color="error" variant="outlined" />
                                            )
                                        }

                                        <div className='order_title_items orderAmount'>{v.order_package_price} Rs</div>
                                    </div>
                                )
                            })
                        }
                        {/* <div className='no_orders'>
                        You have no payments for the time period selected.
                    </div> */}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default InfluencerBilling;