import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL } from "../../config/config";



export const fetchInfluencerRequest = createAsyncThunk("influencerRequest/fetchInfluencerRequest", async (id) => {
    let response = await axios.get(`${BASE_URL}/getOfferRequest/${id}`);
    let data = await response.data.data;
    return data;
});

export const fetchBrandOffers = createAsyncThunk("brandoffers/fetchBrandOffers", async (id) => {
    let response = await axios.get(`${BASE_URL}/get-brand-offers/${id}`);
    let data = await response.data.data;
    return data;
});


export const approveInfluencerRequest = createAsyncThunk("influencerRequest/approveInfluencerRequest",
    async (id, { rejectWithValue }) => {
        try {
            let response = await axios.patch(`${BASE_URL}/acceptOfferRequest/${id}`);
            let data = await response.data;
            return data;
        } catch (error) {
            return rejectWithValue(error?.response?.data?.msg);
        }

    }
);

export const deniedInfluencerRequest = createAsyncThunk("influencerRequest/deniedInfluencerRequest",
    async (id, { rejectWithValue }) => {
        try {
            let response = await axios.patch(`${BASE_URL}/deniedOfferRequest/${id}`);
            let data = await response.data;
            return data;
        } catch (error) {
            return rejectWithValue(error?.response?.data?.msg);
        }

    }
);

const influencerRequestReducer = createSlice({
    name: "influencerRequest",
    initialState: {
        loading: false,
        influencer_Request: [],
        approve_influencer_Request: {},
        denied_influencer_Request: {},
        error: ""
    },
    extraReducers: {
        [fetchInfluencerRequest.fulfilled]: (state, action) => {
            state.influencer_Request = action.payload;
            state.loading = false;
        },
        [fetchInfluencerRequest.pending]: (state) => {
            state.loading = false;
        },
        [fetchInfluencerRequest.rejected]: (state, action) => {
            state.error = action.error.message
        },
        [fetchBrandOffers.fulfilled]: (state, action) => {
            state.brandoffers = action.payload;
            state.loading = false;
        },
        [fetchBrandOffers.pending]: (state) => {
            state.loading = false;
        },
        [fetchBrandOffers.rejected]: (state, action) => {
            state.error = action.error.message
        },
        [approveInfluencerRequest.fulfilled]: (state, action) => {
            state.approve_influencer_Request = action.payload;
            state.loading = false;
        },
        [approveInfluencerRequest.pending]: (state) => {
            state.loading = false;
        },
        [approveInfluencerRequest.rejected]: (state, action) => {
            state.error = action.error.message
        },
        [deniedInfluencerRequest.fulfilled]: (state, action) => {
            state.denied_influencer_Request = action.payload;
            state.loading = false;
        },
        [deniedInfluencerRequest.pending]: (state) => {
            state.loading = false;
        },
        [deniedInfluencerRequest.rejected]: (state, action) => {
            state.error = action.error.message
        },
    }
});

export default influencerRequestReducer.reducer;