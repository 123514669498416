import React from 'react'
import CircularProgress from '@mui/material/CircularProgress';

const Loading = () => {
    return (
        <div>
            <CircularProgress size={20} style={{ color: "white" }} />
        </div>
    )
}

export default Loading