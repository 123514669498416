import React from "react";
import "./SubFooter.css";
import { Link } from "react-router-dom";
import Jazzcash from "../../images/jazzcash.svg";
import EasyPaisa from "../../images/easy-paisa.svg";
// import visa from "../../images/visa.svg";
import unionpay from "../../images/unionpay.svg";
import visa from "../../images/visa.svg";
import payfast from "../../images/payfast.svg";
import pci from "../../images/pci.svg";
import master from "../../images/master.svg";
import { FaWhatsapp } from "react-icons/fa"


const SubFooter = () => {
  return (
    <div>
      <div className="line"> </div>
      <div className="subFooter_Container">
        <div className="subFooter_Item_Row">
          {/* <p className="subFooter_Item_heading">V</p> */}
          <div className="subFooter_Items">
            <div className="verifiedLogos">
              <img width={85} height={60} src={master} />
              <img width={85} height={60} src={visa} />
              <img width={85} height={60} src={unionpay} />
              <img width={85} height={60} src={payfast} />
              <img width={85} height={60} src={pci} />
              <img width={85} height={60} src={Jazzcash} />
              <img width={85} height={60} src={EasyPaisa} />
            </div>
          </div>
        </div>
        <div className="subFooter_Item_Row">
          <p className="subFooter_Item_heading">Resources</p>
          <div className="subFooter_Items">
            <p>
              <Link to="/influencers">Search All Influencers</Link>
            </p>
            <p>
              <Link to="/our-team">Our Team</Link>
            </p>
            {/* <p>
              <Link to="">Affiliate Program</Link>
            </p>
            <p>
              <Link to="">TikTok Ebook For Brands</Link>
            </p>
            <p>
              <Link to="">2022 Influencer Marketing Report</Link>
            </p> */}
          </div>
        </div>
        {/* <div className="subFooter_Item_Row">
          <p className="subFooter_Item_heading">Tools</p>
          <div className="subFooter_Items">
            <p>
              <Link to="">Blog</Link>
            </p>
            <p>
              <Link to="">Creator Hub</Link>
            </p>
            <p>
              <Link to="">Affiliate Program</Link>
            </p>
            <p>
              <Link to="">TikTok Ebook For Brands</Link>
            </p>
            <p>
              <Link to="">2022 Influencer Marketing Report</Link>
            </p>
          </div>
        </div> */}
        {/* <div className="subFooter_Item_Row">
          <p className="subFooter_Item_heading">Discover</p>
          <div className="subFooter_Items">
            <p>
              <Link to="">Blog</Link>
            </p>
            <p>
              <Link to="">Creator Hub</Link>
            </p>
            <p>
              <Link to="">Affiliate Program</Link>
            </p>
            <p>
              <Link to="">TikTok Ebook For Brands</Link>
            </p>
            <p>
              <Link to="">2022 Influencer Marketing Report</Link>
            </p>
          </div>
        </div> */}
        <div className="subFooter_Item_Row">
          <p className="subFooter_Item_heading">Support</p>
          <div className="subFooter_Items">
            <p>
              <a href="https://wa.me/923262655222" target="_blank" style={{ color: "#128C7E" }}>

                <FaWhatsapp size={20} />
              </a>
              {/* https://wa.me/923262655222 */}

              {/* <Link to="">Blog</Link> */}
            </p>
            <p>
              hello@collabscafe.com
              {/* <Link to="">Blog</Link> */}
            </p>
            {/* <p>
              <Link to="">Creator Hub</Link>
            </p>
            <p>
              <Link to="">Affiliate Program</Link>
            </p>
            <p>
              <Link to="">TikTok Ebook For Brands</Link>
            </p>
            <p>
              <Link to="">2022 Influencer Marketing Report</Link>
            </p> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubFooter;