import React, { useState, useEffect, useRef } from 'react'
import "./Checkout.css";
import { CustomizedButton } from "../../components/CustomizedButton";
import Loading from '../../components/Loading/Loading';
import { json, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import axios from 'axios';
import { BASE_URL, CLIENT_BASE_URL } from "../../config/config";
import { fetchOrders } from '../../Redux/Reducers/Orders';
import { Backdrop, CircularProgress } from '@mui/material';
import HashLoader from '../../components/HashLoader';
import dayjs from 'dayjs';
import CollabsCafeLogo from "../../images/logoSidebar.png";
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import FeaturedInfluencers from '../../components/FeaturedInfluencers/FeaturedInfluencers';
import HowCollabsCafeWorks from '../../components/HowCollabsCafeWorks/HowCollabsCafeWorks';
import Banner from '../../components/Banner/Banner';
import SubFooter from '../../components/SubFooter/SubFooter';
import Footer from '../../components/Footer/Footer';
import CollabsCafeGuide from '../../components/CollabsCafeGuide/CollabsCafeGuide';


const Checkout = ({ CURRENT_USER_PROFILE }) => {
  const steps = ["Place Order", "Submit Requirements"];
  const [activeStep, setActiveStep] = useState(0);
  const { state } = useLocation();
  // const influencer_Profile = useSelector((state) => state.User.influencerProfile);
  const brand_Profile = useSelector((state) => state.brand_Profile.brand_Profile);
  const dispatch = useDispatch();
  const { influencerProfile, packageData } = state;
  const [token, setToken] = useState(null);
  const [loading, setLoading] = useState(false);
  const buttonRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [requirments, setRequirments] = useState("");


  function generateRandomString(length) {
    let result = '';
    const num = '121232342453675876979887667746343235677878232323';
  
    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * num.length);
      result += num.charAt(randomIndex);
    }
  
    return result;
  }
  
  const basket_id = generateRandomString(20);

  const createOrder = async () => {
    const order_Data = {
      influencer_profile: influencerProfile?.profileImage.url,
      influencer_userName: influencerProfile?.userName,
      brand_profile: brand_Profile?.profileImage.url,
      create_order_id: brand_Profile?._id,
      recieve_order_id: influencerProfile?._id,
      create_order_email: brand_Profile?.email,
      create_order_name: brand_Profile?.fullName,
      recieve_order_email: influencerProfile?.email,
      recieve_order_name: influencerProfile?.fullName,
      order_content_type: packageData?.contentType,
      order_package_description: packageData?.packageDescription,
      order_package_offer: packageData?.packageOffer,
      order_package_price: packageData?.packagePrice,
      order_package_price_include_tax: parseFloat(packageData?.packagePrice) + parseFloat(packageData?.packagePrice * (15 / 100)),
      order_deadline: packageData?.packageDeadline,
      order_platform: packageData?.platform,
      order_requirments: requirments,
      influencer_uid: influencerProfile?.uid,
      brand_uid: brand_Profile?.uid,
      basket_id: basket_id
    };

    await axios.post(`${BASE_URL}/create-order`, order_Data).then((res) => {
      if(res?.data?.msg === "Order Successfully Created") {

        buttonRef.current.click();
      }
      // alert("Order Has Been Created");
    })
  
    // window.localStorage.setItem("orderData", JSON.stringify(order_Data));
  }

 
 
  useEffect(() => {
    dispatch(fetchOrders(CURRENT_USER_PROFILE._id)).then((res) => {
      if (res?.type === "orders/fetchOrders/fulfilled") {
        console.log("RES FETCH ORDERS", res?.payload);
      }
    })
  }, [CURRENT_USER_PROFILE]);

  const getToken = async () => {
    setLoading(true);
    const response = await axios.get(`${BASE_URL}/token`);
    const html = await response.data.data;
    if (html) {
      setToken(html);
      setTimeout(() => {
        setLoading(false);
      }, 2000);
    }
  };



  useEffect(() => {
    getToken();
  }, []);


  return (
    <div >
      <div className='checkout_main'>
       
        <div className='checkout_detail'>
          <div>
            <p>Content Type: {packageData?.contentType}</p>
            <p>Package Description: {packageData?.packageDescription}</p>
            <p>Package Offer: {packageData?.packageOffer}</p>
            <p>Subtotal: RS {packageData?.packagePrice} </p>
            <p>Platform: {packageData?.platform}</p>
            <p>Collabscafe Fees 15%: RS {packageData?.packagePrice * (15 / 100)}</p>
            <p>Total: RS {parseFloat(packageData?.packagePrice) + parseFloat(packageData?.packagePrice * (15 / 100))}</p>
          </div>
          <div>
          
            <form method="post" action="https://ipg1.apps.net.pk/Ecommerce/api/Transaction/PostTransaction">
              <div className="row">
                <div className="col-lg-6">
                  <div style={{ display: "none" }} className="form-group">
                    <label>Merchant ID</label>
                    <input className="form-control" TYPE="TEXT" NAME="MERCHANT_ID" VALUE="18313" />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div style={{ display: "none" }} className="form-group">
                    <label>Merchant Name</label>
                    <input className="form-control" TYPE="TEXT" NAME="MERCHANT_NAME" value='My Merchant' />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6">
                  <div style={{ display: "none" }} className="form-group" >
                    <label>Token</label>
                    <input className="form-control" TYPE="TEXT" NAME="TOKEN" VALUE={token} data-toggle="tooltip" role="tooltip" title="Temporary Token" />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div style={{ display: "none" }} className="form-group">
                    <label>Proccode</label>
                    <input readonly="readonly" className="form-control" TYPE="TEXT" NAME="PROCCODE" VALUE="00" />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6">

                  <div style={{ display: "none" }} className="form-group">
                    <label>Amount</label>
                    <input className="form-control" TYPE="TEXT" NAME="TXNAMT" VALUE={parseFloat(packageData?.packagePrice) + parseFloat(packageData?.packagePrice * (15 / 100))} />
                  </div>
                </div>
                <div style={{ display: "none" }} className="col-lg-6">
                  <div className="form-group">
                    <label>Customer Mobile Number</label>
                    <input className="form-control" TYPE="TEXT" NAME="CUSTOMER_MOBILE_NO" VALUE={brand_Profile && brand_Profile?.phoneNumber} />
                  </div>
                </div>
              </div>

              <div className="row">
                <div style={{ display: "none" }} className="col-lg-6">
                  <div className="form-group">
                    <label>Customer Email</label>
                    <input className="form-control" TYPE="TEXT" NAME="CUSTOMER_EMAIL_ADDRESS" VALUE={brand_Profile && brand_Profile?.email} />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div style={{ display: "none" }} className="form-group">
                    <label>Signature</label>
                    <input className="form-control" TYPE="TEXT" NAME="SIGNATURE" VALUE="RANDOMSTRINGVALUE" />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6">
                  <div style={{ display: "none" }} className="form-group">
                    <label>Version</label>
                    <input className="form-control" TYPE="TEXT" NAME="VERSION" VALUE="MY_VER_1.0" />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="description_form">
                    <p>Requirments:</p>
                    <textarea placeholder='Enter Your Requirments' TYPE="TEXT" NAME="TXNDESC" VALUE={requirments} rows="5" cols="40"
                      onChange={(e) => setRequirments(e.target.value)}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6">
                  <div style={{ display: "none" }} className="form-group">
                    <label>Success CallBack URL</label>
                    <input className="form-control" TYPE="TEXT" NAME="SUCCESS_URL" VALUE={`${CLIENT_BASE_URL}/payment-success`} />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div style={{ display: "none" }} className="form-group">
                    <label>Failure CallBack URL</label>
                    <input className="form-control" TYPE="TEXT" NAME="FAILURE_URL" VALUE={`${CLIENT_BASE_URL}/payment-error`} />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6">
                  <div style={{ display: "none" }} className="form-group">
                    <label>Basket ID/Order ID</label>
                    <input className="form-control" TYPE="TEXT" NAME="BASKET_ID" VALUE={basket_id} />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div style={{ display: "none" }} className="form-group">
                    <label>Order Date</label>
                    <input className="form-control" TYPE="TEXT" NAME="ORDER_DATE" VALUE={dayjs().format("YYYY-MM-DD")} />
                  </div>
                </div>
              </div>
              <div style={{ display: "none" }} className="form-group">
                <label>Checkout URL</label>
                <input className="form-control" TYPE="TEXT" NAME="CHECKOUT_URL" VALUE="your-merchant-site.com/order/backend/confirm" />
              </div>
              <Backdrop
                sx={{ color: '#05a081', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
              // onClick={handleClose}
              >
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <img
                    className="header_logo"
                    src={CollabsCafeLogo}
                    alt="logo.png"
                  />
                  <HashLoader />
                </div>
                {/* <CircularProgress color="inherit" /> */}
              </Backdrop>
              <div style={{ display: "none" }} className="form-group">
                <button ref={buttonRef} type='submit'>pay Now</button>
                {/* <input className="btn btn-primary" TYPE="SUBMIT" value="PAY NOW" /> */}
              </div>

            </form>
            <div className='payNow_btn'>
              <Button onClick={createOrder} style={{ background: "#05a081" }} variant="contained">pay Now</Button>
            </div>
          </div>
        </div>

      
      </div>
      <HowCollabsCafeWorks />
      <CollabsCafeGuide />
   
      <SubFooter />
      <Footer />
    </div>
  )
}

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const CustomizedDialogs = ({ open, setOpen, submitPayment }) => {


  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
          Important Note
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <Typography gutterBottom>
            1. Your Order is Created in Our System, Now you need to Pay the Amount of your Order.
          </Typography>
          <Typography gutterBottom>
            2. If your Amount payed Successfully, Influencer will get the Order.
          </Typography>
          <Typography gutterBottom>
            3. Influencer will start work.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" className="mr-8 approve_btn"
            style={{ background: "#05a081" }}
            onClick={submitPayment}
          >Pay Your Amount</Button>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
}

export default Checkout
