import React, { useState } from "react";
import "./StepNine.css";
import { Notify } from "../../../../components/Notify";
import Loading from "../../../../components/Loading/Loading";

const StepNine = ({ step, setStep, faqs, setFaqs }) => {

  const [loading, setLoading] = useState(false);

  let handleChange = (i, e) => {
    let newFormValues = [...faqs];
    newFormValues[i][e.target.name] = e.target.value;
    setFaqs(newFormValues);
  };

  let addMoreFaqs = () => {
    if (faqs.length < 3) {
      setFaqs([
        ...faqs,
        {
          faqsQuestion: "",
          faqsDescription: ""
        },
      ]);
    }
  };

  const removeFaqs = (i) => {
    let newFormValues = [...faqs];
    newFormValues.splice(i, 1);
    if (faqs.length > 1) {
      setFaqs(newFormValues);
    }
  }

  // const getItem = () => {
  //   alert(JSON.stringify(faqs));
  // };

  const faqsHandler = () => {
    setLoading(true);
    const id = faqs.length - 1;
    if (!faqs[id].faqsQuestion) {
      setLoading(false);
      Notify("error", "Enter Your FAQ Question!");
    } else if (!faqs[id].faqsDescription) {
      setLoading(false);
      Notify("error", "Enter Your FAQ Description!");
    } else if (faqs[id].faqsDescription.length < 10) {
      setLoading(false);
      Notify("error", "FAQ Description Should be greater then 10 characters!");
    } else {
      setTimeout(() => {
        setLoading(false);
        setStep(step + 1);
      }, 2000)
    }
    // setStep(step + 1);
  }

  return (
    <div className="stepNine">
      <h1 className="stepNine-heading">
        Add FAQ's to answer questions about you and your services
      </h1>

      <div className="faqsCardContainer">
        {faqs.map((element, index) => {
          return (

            <div className="faq_card" key={index}>
              <div className="faq_content">
                <div className="faq_HeadingContainer">
                  <p className="faq_heading">FAQ</p>
                  {
                    index >= 1 && (
                      <p
                        className="deleteFaqs"
                        onClick={() => removeFaqs(index)}
                      >
                        +
                      </p>
                    )
                  }
                </div>
                <div>
                  <input
                    className="faq_Ques_Input"
                    type="text"
                    placeholder="E.g. What brands have you worked with?"
                    name="faqsQuestion"
                    value={element.faqsQuestion || ""}
                    onChange={(e) => handleChange(index, e)}
                  />
                </div>
                <div>
                  <textarea
                    className="faq_Ques_description"
                    cols={8}
                    rows={8}
                    placeholder="I have worked with FashionNova, HiSmile and more."
                    name="faqsDescription"
                    value={element.faqsDescription || ""}
                    onChange={(e) => handleChange(index, e)}
                  ></textarea>
                </div>
              </div>
            </div>

          )
        })}



      </div>



      <div className="addMoreButtonCont">
        <p className="addMoreButton" onClick={() => addMoreFaqs()}>Add More Item</p>
      </div>
      {/* signUp Button */}
      <div className="continue_container">
        {
          loading ? (
            <button className="continueBtn"><Loading /></button>
          ) : (
            <button className="continueBtn" onClick={faqsHandler}>Continue</button>
          )
        }
      </div>
    </div>
  );
};

export default StepNine;
