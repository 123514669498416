import React, { useState, useEffect } from "react";
import "./Creator.css";
import HeroVideo from "../../images/collabscafevideo.mp4";
import {
  SubFooter,
  Footer,
  Banner,
  HowCollabsCafeWorks,
  CollabsCafeGuideCreator,
  CollabsCafeFAQS,
  LovedCreators,
  FeaturedInfluencers,
  SeenIn,
} from "../index";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { BASE_URL } from "../../config/config";
import { Notify } from "../../components/Notify";
import Loading from "../../components/Loading/Loading";
import { Helmet } from "react-helmet";

const Creator = () => {
  const [loading, setLoading] = useState(false);
  let [yourName, setYourName] = useState("");
  const navigate = useNavigate();

  let userName = yourName.replace(/\s/g, "").toLowerCase();

  const yourNameHandler = async () => {
    setLoading(true);
    if (!yourName) {
      Notify("error", "Please Enter Your Name.");
      setLoading(false);
    } else {
      try {
        const response = await axios.get(`${BASE_URL}/api/verify_Influencer_UserName/${userName}`);
        const data = await response.data;
        if (response.data.message === "User Name Already In Use.") {
          Notify("error", response.data.message);
          setLoading(false);
        } else {
          // toast.success(response.data.message);
          setTimeout(() => {
            navigate(`/creator-signup/${userName}`, { state: { yourName: userName } });
            setLoading(false);
          }, 2000)
        }

      } catch (error) {
        Notify("error", error.message);
        setLoading(false);
      }
      // setSearchParams(`${result}`);
      // alert(result);
    }
  };

  // const verifyUserName = async () => {
  //   const response = await axios.get(`/api/verify_Influencer_UserName/${userName}`);
  //   const data = await response.data;
  //   console.log("Data=>", data);
  // }


  // useEffect(() => {
  //   if(yourName) {
  //     verifyUserName();
  //   }
  // }, [yourName])

  return (
    <div>
         <Helmet>
                <title>Collabscafe | Influencer SignUp</title>
            </Helmet>
      <div className="creator_Container">
        <div className="creator_cont">
          {/* ***** heading ****** */}
          <div className="creator_cont_heading">
            <h1>Power Your Brand Deals</h1>
            <p>
              The simple way to sell, manage, and get paid for your Instagram,
              TikTok, and YouTube brand deals
            </p>
          </div>
        </div>
        {/* creator Input */}
        <div className="creator_Input_Maincontainer">
          <div className="creator_Input_container">
            <p>collabscafe.com/</p>
            <input
              type="text"
              placeholder="username"
              value={yourName}
              onChange={(event) => setYourName(event.target.value)}
            />
            {
              loading ? (
                <button ><Loading /></button>

              ) : (
                <button onClick={yourNameHandler}>Submit</button>
              )
            }

          </div>
        </div>
        {/* video Guide */}
        <div className="video_Guide_Container">
          <div className="video_guide">
            <video autoPlay muted loop>
              <source src="https://res.cloudinary.com/drg7uoyge/video/upload/v1673468605/collabscafevideo_gf3q14.mp4" type="video/mp4" />
            </video>
          </div>
        </div>
      </div>

      <LovedCreators />
      <FeaturedInfluencers />
      <SeenIn />
      <HowCollabsCafeWorks />
      <CollabsCafeGuideCreator />
      <CollabsCafeFAQS
        Quesone={"How do I get paid?"}
        AnsOne={"Payments are made directly through our website. We use Stripe as our payment processor and support all major credit & debit cards. Once you complete an order, we will deposit the money into your bank account."}
        QuesTwo={"How much does it cost?"}
        AnsTwo={"There is no up-front cost. We take a 15% transaction fee when you make a sale."}
        QuesThree={"Is my payment guaranteed?"}
        AnsThree={"Yes, we collect the payment from the buyer and hold it until the order is complete. This ensures that both sides are protected during every transaction."}
        QuesFour={"Can I decline orders?"}
        AnsFour={"Yes, you are able to accept or decline an order. This gives you the freedom to only work with brands that align with you."}
        QuesFive={"What platforms does Collabscafe support?"}
        AnsFive={"Currently you can list your services for Instagram, TikTok, YouTube, Twitch, Twitter and UGC."}
        QuesSeven={"Are there binding contracts?"}
        AnsSeven={"No, we do not ask you to sign any contracts. We are a self-serve platform, you are free to manage your own deals without our involvement. We simply provide the platform for you to advertise your services to brands."}
        QuesEight={"Are you an agency?"}
        AnsEight={"No, we are not an agency. We are a platform for you to advertise your services, and manage your own brand deals."}
      />
      <Banner 
       heading={"Find and Hire Influencers"}
       subHeading={"Search Instagram, TikTok, and YouTube influencers"}
       btn={"Search Influencers"}
       link={""}
      />
      <SubFooter />
      <Footer />
    </div>
  );
};

export default Creator;
