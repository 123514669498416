import React, { useState } from 'react'
import "./AllBrands.css";
import SideBar from "../../../components/SideBar/SideBar";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import IconButton from '@mui/material/IconButton';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';


const AllBrands = () => {

    const data = [
        {
            id: 0, image: "https://avatars.githubusercontent.com/u/64406037?v=4", name: "Zia Khan"
        },
        {
            id: 1, image: "https://avatars.githubusercontent.com/u/64406037?v=4", name: "Muddasir Khan"
        },
        {
            id: 2, image: "https://avatars.githubusercontent.com/u/64406037?v=4", name: "Rehman Khan"
        },
        {
            id: 3, image: "https://avatars.githubusercontent.com/u/64406037?v=4", name: "Faris Khan"
        },
    ]
    return (
        <div>
            <SideBar />
            <div className="content">

                <div className='content_Inner'>
                    <h1 className="account-heading">All Brands</h1>
                    <div className='allInfluencers_Container'>
                        {data.map((val) => {
                            return (
                                <BrandProfileExpand id={val.id} image={val.image} name={val.name} key={val.id} />
                            )
                        })}
                    </div>
                </div>
            </div>
        </div>
    )
}

const BrandProfileExpand = ({ id, image, name }) => {
    const [expand, setExpand] = useState(false);
    return (
        <div>
            <div className='allInfluencers_dropdownContainer' onClick={() => setExpand(!expand)}>
                {/* profile Image */}
                <div className='allInfluencers_Profile_Cont'>
                    <img className='allInfluencers_Profile' src={image} />
                    {/* profile Name */}
                    <p className='allInfluencers_ProfileName'>{name}</p>
                </div>

                {/* profile Name */}
                <IconButton >
                    {
                        expand ? <ExpandLessIcon /> : <ExpandMoreIcon />
                    }

                </IconButton>
            </div>
            {
                expand && (
                    <div className='expandInfluencerProfile'>
                        <div>
                            <div className='profiles_menues'>User Name: <span>ziaali052</span></div>
                            <div className='profiles_menues'>Full Name: <span>Zia Khan</span></div>
                            <div className='profiles_menues'>Email: <span>ziaalikhan052@gmail.com</span></div>
                            <div className='profiles_menues'>Role: <span>Influencer</span></div>
                            <div className='profiles_menues'>Verified: <span>false</span></div>
                            <div className='profiles_menues'>City: <span>SIndh, Karachi</span></div>
                            <div className='profiles_menues'>Title: <span>Gym Lover | Content Creator</span></div>
                            <div className='profiles_menues'>Description: <span>Google has created over 2,100 official Material icons, each in five different "themes" (see below). For each SVG icon, we export the respective React component from the</span></div>
                        </div>
                        <div>
                            <div className='profiles_menues'>Gender: <span>Male</span></div>
                            <div className='profiles_menues'>Social Links: <span>Fb</span></div>
                            <div className='profiles_menues'>Niches: <span>Life Style | Tecnology</span></div>
                            {/* <div className='profiles_menues'>Packages: <span></span></div>
                <div className='profiles_menues'>Faqs: <span>ziaali052</span></div> */}
                            <div className='profiles_menues'>Phone Number: <span>03152899756</span></div>
                            {/* <p className='profiles_menues'>Zia Khan</p>
                <p className='profiles_menues'>Zia Khan</p> */}
                        </div>
                    </div>
                )
            }
            <div className='separator'></div>
        </div>
    )
}


export default AllBrands