import Swal from 'sweetalert2'

export const Notify = (type, title) => {
   return Swal.fire({
    position: 'top',
        icon: type,
        title: title,
        
        showConfirmButton: false,
        timer: 1500
      })
}