import React, { useState } from "react";
import "./SearchBox.css";
import toast from "react-hot-toast";
import { ImSearch } from 'react-icons/im';
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { options, platforms } from "./optionsData";
import Chip from '@mui/material/Chip';
import { searchInfluencers } from "../../Redux/Reducers/SearchBoxReducer";

const SearchBox = () => {
  const [inputVal, setInputVal] = useState([]);
  const [platformInput, setPlatformInput] = useState("");
  const [open, setOpen] = useState(false);
  const [openPlatform, setOpenPlatform] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // ****** category Options Handler ******
  const optionsHandler = (val) => {
    const value = val;
    const check = inputVal ? inputVal.find((val) => val === value) : [];
    if (value !== check) {
      setInputVal([...inputVal, value]);
    }
    else {
      toast.error(`The selected category ${check} is Added!`);
    }
  };

  // ****** Platform Input Handler ******
  const platformsHandler = (event) => {
    setPlatformInput(event);
    setOpenPlatform(false);
  }

  // ****** Close Options when outside clicks ******
  const closeOutside = () => {
    if (open) {
      setOpen(false);
    }
    if (openPlatform) {
      setOpenPlatform(false);
    }
  }

  // ****** Search Data ******
  const searchHandler = () => {

    let inputValueTrimed = inputVal.length ? inputVal.toString().replace(/&/g, "and").split(",") : [];

    for (let i = 0; i < inputValueTrimed.length; i++) {
      if (inputVal.length > 0) {
        inputValueTrimed[i] = "&ct=" + inputValueTrimed[i]
      }
      // return item;
      // console.log("item", item);
      // return valTrimed = item;
    }
    // console.log("platformInput", platformInput);
    // console.log("inputValueTrimed", inputValueTrimed);


    let query = `/influencers?${platformInput ? `p=${platformInput}` : ""}${inputVal.length > 0 ? `${inputValueTrimed.toString().replace(/,/g, "")}` : ""}`
    console.log("query", query)
    dispatch(searchInfluencers(query.slice(12)));
    navigate(query, {
      state: {
        platform: platformInput,
        category: inputValueTrimed.length > 0 && inputValueTrimed.map((v) => v.replace(/&ct=/g, "")),
      }
    });
    // }
  }

  return (
    <div className="searchBox" onClick={closeOutside}>
      <div className="searchBox_Container" >
        <div className="search_input_container">
          <div className="search_Input_div">
            <p className="input_label">Platform</p>
            <input
              type="text"
              value={platformInput}
              readOnly={true}
              // onChange={(e) => setInputVal(e.target.value)}
              className="search_Input"
              placeholder={platformInput ? undefined : "Search a platform"}
              onClick={() => setOpenPlatform(!openPlatform)}
            />
            {
              openPlatform && (
                <div className="options_container" >
                  {
                    platforms.map((plat, i) => {
                      return (
                        <p key={i} className="platforms" onClick={() => platformsHandler(plat)}>{plat}</p>
                      )
                    })
                  }
                </div>
              )
            }

          </div>
          <div className="filter_Seperator">

          </div>
          <div className="search_Input_div">
            <p className="input_label">Category</p>
            <input
              type="text"
              value={inputVal}
              onChange={(e) => setInputVal(e.target.value)}
              className="search_Input"
              placeholder="Search Category."
              onClick={() => setOpen(!open)}
            />

            {open && (
              <>
                <div className="options_container"
                  onClick={e => {
                    // do not close modal if anything inside modal content is clicked
                    e.stopPropagation();
                  }}
                >
                  {/* <p className="options_Heading">Trending 
                 
                  </p> */}
                  {/* <div className="options">
                    <div className="option_inner" >
                      <p>
                        <Chip label={'Lifestyle'} variant="outlined" />
                      </p>
                    </div>
                    <div className="option_inner" >
                      <p>
                        <Chip label={'Model'} variant="outlined" />
                      </p>
                    </div>
                    <div className="option_inner" >
                      <p>
                        <Chip label={'Beauty'} variant="outlined" />
                      </p>
                    </div>
                  </div> */}
                  <p className="options_Heading">Popular  <span>🔥</span></p>
                  <div className="options">
                    {options.map((val, i) => {
                      return (
                        <div className="option_inner" key={i}>
                          {/* <p onClick={() => optionsHandler(val)}>{val}</p> */}
                          <p onClick={() => optionsHandler(val)}>
                            <Chip label={val} variant="outlined" />
                          </p>

                        </div>
                      );
                    })}
                  </div>
                </div>
              </>
            )}

          </div>

          <div className="searchButton">
            <button onClick={searchHandler}>< ImSearch /></button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SearchBox;
