import React from "react";
import "./Banner.css";
import BannerImage from "../../images/banner.png";
import { useNavigate } from "react-router-dom";

const Banner = ({ heading, subHeading, btn, link }) => {
  const navigate = useNavigate();

  return (
    <div className="banner">
      <div className="banner_container">
        <div
          className="banner_bg_Image"
          style={{
            backgroundImage: `url('${BannerImage}')`,
            backgroundPosition: "center",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            width: "100%",
            height: "100%",
          }}
        >
          <div className="banner_Items">
            <p className="banner_Heading">{heading}</p>
            <p className="banner_desc">{subHeading}</p>
            <div className="button_Container">
              <button onClick={() => navigate("/influencers")} className="searchInflencer_Btn">{btn}</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Banner;
