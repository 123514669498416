import React, { useState } from 'react'
import "./Favourites.css";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { SubFooter, Footer, GenderFiltered, CountryFiltered, MoreFilteres, AllInfluencers } from "../index";
import { AiOutlineHeart } from "react-icons/ai"

const Favourites = () => {

    // For Select Box 
    const data = useSelector((state) => state.searchBox.value);
    // Get Exact Values from Home Page Select Box
    const [inputVal, setInputVal] = useState(data.inputVal);
    const [platformInput, setPlatformInput] = useState(data.platformInput);
    const [open, setOpen] = useState(false);
    const [openPlatform, setOpenPlatform] = useState(false);



    const navigate = useNavigate();
    const dispatch = useDispatch();

    // ****** category Options Handler ******


    // Get All Categories
    const values = useSelector((state) => state.searchBox.value);

    console.log("values", values);
    const getCategories = () => {
        return (
            <>
                {values.inputVal.map((cat , i) => {
                    return (
                        <span key={i}>{cat} | </span>
                    )
                })}
            </>
        )
    }




    return (
        <div className='influencers_card'>


            {/*  */}
            <h1 className='brandSignup-heading'>{values.platformInput ? `${values.platformInput} |` : null} {values.inputVal ? getCategories() : null} Favourites</h1>
            <div className="allInfluencer_listing-row">
                {/* Card Influencer */}
                <div className="allInfluencer_featured-profile-listing-holder" >
                    <div className="allInfluencer_profile-listing-img-holder">
                        <div className= "heart"> <AiOutlineHeart color='white' size={26}/></div>
                        <img
                            loading="lazy"
                            className="allInfluencer_profile-listing-img"
                            src="https://d5ik1gor6xydq.cloudfront.net/sellers/17035/16276272240166497.jpg"
                            alt="  creator Brad Sousa being photographed"
                        />
                        <div className="allInfluencer_profile-listing-owner-holder">
                            <div className="allInfluencer_profile-listing-owner-info-holder">
                                <div className="allInfluencer_profile-listing-owner-name">
                                    Zia Khan
                                    <img
                                        className="allInfluencer_verified-badge"
                                        alt=""
                                        src="https://d5ik1gor6xydq.cloudfront.net/websiteImages/creatorMarketplace/verifiedBadge.svg"
                                    />
                                </div>
                                <div className="allInfluencer_profile-listing-owner-location">
                                    sindh, Karachi
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* ****** platform Price ****** */}
                    <div className="allInfluencer_card_platform_price">
                        <p className="allInfluencer_platform">Instagram</p>
                        <p className="allInfluencer_price">90$</p>
                    </div>
                    {/* ****** Description ***** */}
                    <p className="allInfluencer_description">
                        Hello this is title lorem ipsum
                    </p>
                </div>
                {/* Card Influencer */}
                <div className="allInfluencer_featured-profile-listing-holder" >
                    <div className="allInfluencer_profile-listing-img-holder">
                    <div className= "heart"> <AiOutlineHeart color='white' size={26}/></div>

                        <img
                            loading="lazy"
                            className="allInfluencer_profile-listing-img"
                            src="https://d5ik1gor6xydq.cloudfront.net/sellers/17035/16276272240166497.jpg"
                            alt="  creator Brad Sousa being photographed"
                        />
                        <div className="allInfluencer_profile-listing-owner-holder">
                            <div className="allInfluencer_profile-listing-owner-info-holder">
                                <div className="allInfluencer_profile-listing-owner-name">
                                    Zia Khan
                                    <img
                                        className="allInfluencer_verified-badge"
                                        alt=""
                                        src="https://d5ik1gor6xydq.cloudfront.net/websiteImages/creatorMarketplace/verifiedBadge.svg"
                                    />
                                </div>
                                <div className="allInfluencer_profile-listing-owner-location">
                                    sindh, Karachi
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* ****** platform Price ****** */}
                    <div className="allInfluencer_card_platform_price">
                        <p className="allInfluencer_platform">Instagram</p>
                        <p className="allInfluencer_price">90$</p>
                    </div>
                    {/* ****** Description ***** */}
                    <p className="allInfluencer_description">
                        Hello this is title lorem ipsum
                    </p>
                </div>


                <div className="allInfluencer_featured-profile-listing-holder" >
                    <div className="allInfluencer_profile-listing-img-holder">
                    <div className= "heart"> <AiOutlineHeart color='white' size={26}/></div>

                        <img
                            loading="lazy"
                            className="allInfluencer_profile-listing-img"
                            src="https://d5ik1gor6xydq.cloudfront.net/sellers/17035/16276272240166497.jpg"
                            alt="  creator Brad Sousa being photographed"
                        />
                        <div className="allInfluencer_profile-listing-owner-holder">
                            <div className="allInfluencer_profile-listing-owner-info-holder">
                                <div className="allInfluencer_profile-listing-owner-name">
                                    Zia Khan
                                    <img
                                        className="allInfluencer_verified-badge"
                                        alt=""
                                        src="https://d5ik1gor6xydq.cloudfront.net/websiteImages/creatorMarketplace/verifiedBadge.svg"
                                    />
                                </div>
                                <div className="allInfluencer_profile-listing-owner-location">
                                    sindh, Karachi
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* ****** platform Price ****** */}
                    <div className="allInfluencer_card_platform_price">
                        <p className="allInfluencer_platform">Instagram</p>
                        <p className="allInfluencer_price">90$</p>
                    </div>
                    {/* ****** Description ***** */}
                    <p className="allInfluencer_description">
                        Hello this is title lorem ipsum
                    </p>
                </div>



                <div className="allInfluencer_featured-profile-listing-holder" >
                    <div className="allInfluencer_profile-listing-img-holder">
                    <div className= "heart"> <AiOutlineHeart color='white' size={26}/></div>

                        <img
                            loading="lazy"
                            className="allInfluencer_profile-listing-img"
                            src="https://d5ik1gor6xydq.cloudfront.net/sellers/17035/16276272240166497.jpg"
                            alt="  creator Brad Sousa being photographed"
                        />
                        <div className="allInfluencer_profile-listing-owner-holder">
                            <div className="allInfluencer_profile-listing-owner-info-holder">
                                <div className="allInfluencer_profile-listing-owner-name">
                                    Zia Khan
                                    <img
                                        className="allInfluencer_verified-badge"
                                        alt=""
                                        src="https://d5ik1gor6xydq.cloudfront.net/websiteImages/creatorMarketplace/verifiedBadge.svg"
                                    />
                                </div>
                                <div className="allInfluencer_profile-listing-owner-location">
                                    sindh, Karachi
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* ****** platform Price ****** */}
                    <div className="allInfluencer_card_platform_price">
                        <p className="allInfluencer_platform">Instagram</p>
                        <p className="allInfluencer_price">90$</p>
                    </div>
                    {/* ****** Description ***** */}
                    <p className="allInfluencer_description">
                        Hello this is title lorem ipsum
                    </p>
                </div>

                
            </div>

            <SubFooter />
            <Footer />
        </div>
    )
}





export default Favourites;