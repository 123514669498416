import React, { useState } from 'react'
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { Button } from '@mui/material';
import { AiOutlineArrowRight, AiOutlineArrowLeft } from 'react-icons/ai';


const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

function BootstrapDialogTitle(props) {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
}

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};

const ProfileGalleyImages = ({ openGalleryImages, setopenGalleryImages, images }) => {

    const [image_length, setimage_length] = useState(0);

    const handleClose = () => {
        setopenGalleryImages(false);
    };

    const previosImage = () => {
        setimage_length(image_length - 1);
    };

    const nextImage = () => {
        setimage_length(image_length + 1);
    };


    const buttonStyling = {
        width: "40px",
        height: "40px",
        borderRadius: "50%",
        backgroundColor: "#05a081",
        border: "none",
        outline: "none",
        cursor: "pointer"
    }

    return (
        <div>
            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={openGalleryImages}
            >
                <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
                    Profile Images
                </BootstrapDialogTitle>
                <DialogContent dividers>
                    <img style={{ borderRadius: '2px' }} width={"100%"} height={"100%"} src={images[image_length].url} />
                </DialogContent>
                <DialogContent>
                    <div style={{ display: "flex", justifyContent: "end", gap: "10px" }}>
                        <button
                            disabled={image_length === 0}
                            onClick={previosImage}
                            style={buttonStyling}
                        >
                            <AiOutlineArrowLeft size={20} color='white' />
                        </button>
                        <button
                            disabled={image_length === images.length - 1}
                            onClick={nextImage}
                            style={buttonStyling}>
                            <AiOutlineArrowRight size={20} color='white' />
                        </button>
                    </div>
                </DialogContent>

            </BootstrapDialog>
        </div>
    )
}

export default ProfileGalleyImages;



