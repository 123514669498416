import React from 'react'
import "./ContentNeeds.css";
import campaign from "../../images/campaign.png";


const ContentNeeds = () => {
  return (
    <div className='campaignGuide'>
            <div className='campaignGuide_Container'>
                <div className='campaignGuide_Image'>
                    <img src={campaign} alt="marketPlace.png" />
                </div>
                <div className='campaignGuide_desc'>
                    <h1 className='campaignGuide_heading'>One Place for All of Your Content Needs</h1>
                    <div className='campaignGuide_Item'>
                        <p className='campaignGuide_Item_Title'>Sponsored Posts</p>
                        <p className='campaignGuide_Item_desc'>Purchase sponsored content on Instagram, TikTok, and YouTube.</p>
                    </div>
                    <div className='campaignGuide_Item'>
                        <p className='campaignGuide_Item_Title'>Product Tutorials</p>
                        <p className='campaignGuide_Item_desc'>Use influencers to create high-quality product tutorials.</p>
                    </div>
                    <div className='campaignGuide_Item'>
                        <p className='campaignGuide_Item_Title'>Testimonials</p>
                        <p className='campaignGuide_Item_desc'>Get influencers to generate testimonials for your product or business.</p>
                    </div>

                </div>

            </div>
        </div>
  )
}

export default ContentNeeds;