import React, { useState, useEffect } from 'react'
import "./InfluencerOrder.css";
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { fetchOrders } from '../../Redux/Reducers/Orders';
import { useSelector, useDispatch } from 'react-redux';
import { Notify } from '../../components/Notify';
import Loading from '../../components/Loading/Loading';
import HashLoader from '../../components/HashLoader';
import { Alert, Avatar, Box, CircularProgress } from '@mui/material';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import numeral from 'numeral';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import axios from 'axios';
import { BASE_URL } from '../../config/config';
import Swal from 'sweetalert2';
import { createUserChat } from '../../Redux/Reducers/ChatReducer';
import { useNavigate } from 'react-router-dom';


const InfluencerOrder = ({ CURRENT_USER_PROFILE }) => {
    const ordersReducer = useSelector((state) => state.orders);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [orderList, setOrderList] = useState([]);
    const [openOrder, setOpenOrder] = useState(false);
    const [openOrderData, setOpenOrderData] = useState({});

    const dispatch = useDispatch();
    const navigate = useNavigate();





    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };



    const openOrderDetalHandler = (row) => {
        setOpenOrder(!openOrder)
        setOpenOrderData(row)

    }

    function getOrderList() {
        dispatch(fetchOrders(CURRENT_USER_PROFILE._id)).then((res) => {
            if (res?.type === "orders/fetchOrders/fulfilled") {
                const filtered = res?.payload && res?.payload?.filter((v) => v.payment_code === "000" || v.payment_code === "00");
                if (filtered !== undefined && filtered.length > 0) {
                    setOrderList(filtered);
                }
            }
        })
    }


    const orderStatusHandler = async (id, order_status) => {
        Swal.fire({
            title: 'Are You Confirm?',
            inputAttributes: {
                autocapitalize: 'off'
            },
            backdrop: true,
            showCancelButton: true,
            confirmButtonText: 'Confirm',
            confirmButtonColor: "#05a081",
            showLoaderOnConfirm: true,
            preConfirm: async () => {
                await axios.patch(`${BASE_URL}/order-Status`, { id, order_status }).then((res) => {
                    setTimeout(() => {
                        getOrderList();
                    }, 1000);
                })
            },
            allowOutsideClick: () => !Swal.isLoading()
        })
    };


    const goToChat = (row) => {
        const data = {
            firstId: row.influencer_uid,
            secondId: row.brand_uid
        }

        dispatch(createUserChat(data)).then((res) => {
            navigate("/chat");
        });
    }


    useEffect(() => {
        if (CURRENT_USER_PROFILE) {
            getOrderList();
        }
    }, [CURRENT_USER_PROFILE])




    return (
        <div className='List_header'>
            <div className='list_title'>Orders <span style={{ fontSize: 16 }}>({orderList && orderList.length})</span></div>

            {
                ordersReducer.loading ? (
                    <div style={{ widh: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <Box sx={{ display: 'flex' }}>
                            <CircularProgress
                                size={30}
                            />
                        </Box>
                    </div>) : (
                    orderList.length > 0 ? (
                        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                            <TableContainer sx={{ maxHeight: 440 }}>
                                <Table stickyHeader aria-label="sticky table">
                                    <TableHead>
                                        <TableRow>

                                            <TableCell>
                                                Order Date
                                            </TableCell>

                                            <TableCell>
                                                Profile
                                            </TableCell>
                                            <TableCell>
                                                Name
                                            </TableCell>
                                            <TableCell>
                                                Email
                                            </TableCell>

                                            <TableCell>
                                                Package Price
                                            </TableCell>
                                            <TableCell>
                                                Paid Amount
                                            </TableCell>
                                            <TableCell>
                                                Order Deadline
                                            </TableCell>
                                            <TableCell>
                                                Order Status
                                            </TableCell>
                                            <TableCell>
                                                Change Order Status
                                            </TableCell>
                                            <TableCell>
                                                Cancel Order
                                            </TableCell>
                                            <TableCell>
                                                Chat
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {
                                            orderList.length > 0 && orderList
                                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                .map((row, i) => {


                                                    return (

                                                        <TableRow hover role="checkbox" tabIndex={-1} key={row._id}>
                                                            <TableCell onClick={() => openOrderDetalHandler(row)}>
                                                                {dayjs(row?.createdAt).format("DD-MM-YYYY")}
                                                            </TableCell>
                                                            <TableCell onClick={() => openOrderDetalHandler(row)}>
                                                                <Avatar alt="profile Image" src={row?.brand_profile} />
                                                            </TableCell>
                                                            <TableCell onClick={() => openOrderDetalHandler(row)}>
                                                                {row.create_order_name}
                                                            </TableCell>
                                                            <TableCell onClick={() => openOrderDetalHandler(row)}>
                                                                {row.create_order_email}
                                                            </TableCell>

                                                            <TableCell onClick={() => openOrderDetalHandler(row)}>
                                                                {row.order_package_price} RS
                                                            </TableCell>
                                                            <TableCell onClick={() => openOrderDetalHandler(row)}>
                                                                {numeral(parseFloat(row.order_package_price) + parseFloat(row.order_package_price * (15 / 100))).format("00.00")} RS
                                                            </TableCell>
                                                            <TableCell>
                                                                <Chip style={{ width: 90, fontSize: 10 }} label={`${row.order_deadline}`} color="success" variant="outlined" />
                                                            </TableCell>
                                                            <TableCell onClick={() => openOrderDetalHandler(row)}>
                                                                {console.log("row.order_status", row.order_status)}
                                                                <Stack direction="row" spacing={1}>
                                                                    {
                                                                        row.order_status === "PENDING" ? (
                                                                            <Chip style={{ width: 90, fontSize: 10 }} label={`${row.order_status}`} color="warning" variant="outlined" />
                                                                        ) : row.order_status === "COMPLETED" ? (
                                                                            <Chip style={{ width: 90, fontSize: 10 }} label={`${row.order_status}`} color="success" variant="outlined" />
                                                                        ) : row.order_status === "DENIED" ? (
                                                                            <Chip style={{ width: 90, fontSize: 10 }} label={`${row.order_status}`} color="error" variant="outlined" />
                                                                        ) : <Chip style={{ width: 90, fontSize: 10 }} label={`${row.order_status}`} color="primary" variant="outlined" />
                                                                    }
                                                                </Stack>
                                                            </TableCell>

                                                            <TableCell>
                                                                {row.order_status === "CREATED" ? (
                                                                    <Button style={{ width: 120, fontSize: 10 }} onClick={() => orderStatusHandler(row._id, "ACCEPTED")} size='small' variant="contained" color="success">
                                                                        Accept Order
                                                                    </Button>
                                                                ) : row.order_status === "DENIED" ? (
                                                                    <Button disabled={row.order_status === "DENIED"} style={{ width: 120, fontSize: 10 }} onClick={() => orderStatusHandler(row._id, "COMPLETED")} size='small' variant="contained" color="success">
                                                                        DENIED
                                                                    </Button>
                                                                ) : (
                                                                    <Button disabled={row.order_status === "COMPLETED"} style={{ width: 120, fontSize: 10 }} onClick={() => orderStatusHandler(row._id, "COMPLETED")} size='small' variant="contained" color="success">
                                                                        Completed
                                                                    </Button>
                                                                )}
                                                            </TableCell>
                                                            <TableCell>
                                                                <Button disabled={row.order_status === "DENIED" || row.order_status === "COMPLETED"} onClick={() => orderStatusHandler(row._id, "DENIED")} style={{ width: 120, fontSize: 10 }} size='small' variant="contained" color="error">
                                                                    Cancel Order
                                                                </Button>
                                                            </TableCell>
                                                            <TableCell>
                                                                <Button disabled={row.order_status === "CREATED"}
                                                                    onClick={() => goToChat(row)}
                                                                    style={{ width: 120, fontSize: 10 }} size='small' variant="contained" color="success">
                                                                    Go To Chat
                                                                </Button>
                                                            </TableCell>
                                                        </TableRow>


                                                    );
                                                })
                                        }

                                    </TableBody>
                                </Table>

                            </TableContainer>
                            <TablePagination
                                // rowsPerPageOptions={[10, 25, 100]}
                                component="div"
                                count={orderList && orderList.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        </Paper>
                    ) : (
                        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: 100 }}>
                            <p>No Orders</p>
                        </div>
                    )


                )
            }

            {
                openOrder ? <CustomizedDialogs setOpenOrder={setOpenOrder} openOrderData={openOrderData} orderStatusHandler={orderStatusHandler} /> : null
            }

        </div>
        // <div className='List_header'>
        //   <div className='no_list'>Your orders will be displayed here. To create an order, purchase a package from an influencer.</div>
        //   <div className='explore_influencer' onClick={() => navigate("/influencers")}>Explore Influencer</div>

        // </div>
    )
}


export default InfluencerOrder;


const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

function BootstrapDialogTitle(props) {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
}

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};

function CustomizedDialogs({ setOpenOrder, openOrderData, orderStatusHandler }) {
    const [open, setOpen] = React.useState(true);



    const handleClose = () => {
        setOpen(false);
        setOpenOrder(false);
    };

    return (
        <div>

            <BootstrapDialog
                // maxWidth="xl"
                fullWidth={true}
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
            >
                <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
                    Order Detail ({openOrderData?.create_order_name})
                </BootstrapDialogTitle>
                <Alert severity="info" >
                    We've billed ({openOrderData?.create_order_name}) a total of PKR ({openOrderData?.order_package_price}), which we've secured within our Escrow system. Once you've effectively fulfilled and delivered your order, we'll initiate the processing of your payment for release.
                </Alert>
                <DialogContent dividers>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                        <img style={{ borderRadius: 50 }} width={100} height={100} src={openOrderData?.brand_profile} />
                    </div>
                </DialogContent>
                <DialogContent dividers>
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <Chip style={{ width: 130, fontSize: 10 }} label={`Order Deadline: ${openOrderData.order_deadline}`} size='small' color="success" variant="outlined" />
                        {
                            openOrderData.order_status === "PENDING" ? (
                                <Chip style={{ width: 90, fontSize: 10 }} label={`${openOrderData.order_status}`} size='small' color="warning" variant="outlined" />
                            ) : openOrderData.order_status === "COMPLETED" ? (
                                <Chip style={{ width: 90, fontSize: 10 }} label={`${openOrderData.order_status}`} size='small' color="success" variant="outlined" />
                            ) : openOrderData.order_status === "DENIED" ? (
                                <Chip style={{ width: 90, fontSize: 10 }} label={`${openOrderData.order_status}`} size='small' color="error" variant="outlined" />
                            ) : <Chip style={{ width: 90, fontSize: 10 }} label={`${openOrderData.order_status}`} size='small' color="primary" variant="outlined" />
                        }
                    </div>
                    <div className='order_detail'>
                        <div>
                            <div className='order_detail_field_name'>Order Date: <span className='order_detail_field_detail'>{dayjs(openOrderData?.createdAt).format("DD-MM-YYYY")}</span></div>
                            <div className='order_detail_field_name'>Order Time: <span className='order_detail_field_detail'>{dayjs(openOrderData?.createdAt).format("hh-mm-A")}</span></div>
                            <div className='order_detail_field_name'>Brand Name: <span className='order_detail_field_detail'>{openOrderData?.create_order_name}</span></div>
                            <div className='order_detail_field_name'>Brand Email: <span className='order_detail_field_detail'>{openOrderData?.create_order_email}</span></div>
                            <div className='order_detail_field_name'>Order Content Type: <span className='order_detail_field_detail'>{openOrderData?.order_content_type}</span></div>
                            <div className='order_detail_field_name'>Order Platform: <span className='order_detail_field_detail'>{openOrderData?.order_platform}</span></div>
                            <div className='order_detail_field_name'>Order Package Description: <span className='order_detail_field_detail'>{openOrderData?.order_package_description}</span></div>
                        </div>
                        <div>
                            <div className='order_detail_field_name'>Order Package Offer: <span className='order_detail_field_detail'>{openOrderData?.order_package_offer}</span></div>
                            <div className='order_detail_field_name'>Order Package Price: <span className='order_detail_field_detail'>{openOrderData?.order_package_price} Rs</span></div>
                            <div className='order_detail_field_name'>Order Package Total Price: <span className='order_detail_field_detail'>{openOrderData?.order_package_price_include_tax} Rs</span></div>
                        </div>
                    </div>
                </DialogContent>

                <DialogContent dividers>
                    <div style={{ display: "flex", justifyContent: "end", gap: 20 }}>
                        <Button disabled={openOrderData.order_status === "CREATED"} onClick={() => {
                            setOpen(false);
                            setOpenOrder(false);
                        }} size='small' variant="contained" color="success">
                            Go To Chat
                        </Button>
                        {openOrderData.order_status === "CREATED" ? (
                            <Button onClick={() => {
                                orderStatusHandler(openOrderData._id, "ACCEPTED")
                                setOpen(false);
                                setOpenOrder(false);
                            }} size='small' variant="contained" color="success">
                                Accept Order
                            </Button>
                        ) : openOrderData.order_status === "DENIED" ? (
                            <Button disabled={openOrderData.order_status === "DENIED"} onClick={() => {
                                orderStatusHandler(openOrderData._id, "COMPLETED")
                                setOpen(false);
                                setOpenOrder(false);
                            }} size='small' variant="contained" color="success">
                                DENIED
                            </Button>
                        ) : (
                            <Button disabled={openOrderData.order_status === "COMPLETED"} onClick={() => {
                                orderStatusHandler(openOrderData._id, "COMPLETED")
                                setOpen(false);
                                setOpenOrder(false);
                            }} size='small' variant="contained" color="success">
                                Completed
                            </Button>
                        )}
                        <Button disabled={openOrderData.order_status === "DENIED" || openOrderData.order_status === "COMPLETED"} onClick={() => {
                            orderStatusHandler(openOrderData._id, "DENIED")
                            setOpen(false);
                            setOpenOrder(false);
                        }} size='small' variant="contained" color="error">
                            Cancel Order
                        </Button>
                    </div>
                </DialogContent>

            </BootstrapDialog>
        </div>
    );
}