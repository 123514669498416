import React, { useState } from 'react';
import { AiFillInstagram, AiFillYoutube } from "react-icons/ai";
import { IoLogoTiktok, IoLogoFacebook } from "react-icons/io5";
import { ImTwitter } from "react-icons/im";
import { BsLink45Deg } from "react-icons/bs";
import { Notify } from "../../../../components/Notify";
import Loading from "../../../../components/Loading/Loading";
import { useSelector } from 'react-redux';


const Social = ({
    socialLink,
    setSocialLink
    //   socialLink,
    //   setSocialLink,
    //   influFb,
    //   influFbFollower,
    //   influInsta,
    //   influInstaFollower,
    //   influTiktok,
    //   influTiktokFollower,
    //   influTwitter,
    //   influTwitterFollower,
    //   influWebsite,
    //   influYoutube,
    //   influYoutubeFollower,
}) => {
    const brand_Profile = useSelector((state) => state.brand_Profile.brand_Profile);


    const [openInsta, setOpenInsta] = useState(false);
    const [opentiktok, setOpentiktok] = useState(false);
    const [openyoutube, setOpenYoutube] = useState(false);
    const [opentwitter, setOpenTwitter] = useState(false);
    const [openfacebook, setOpenFacebook] = useState(false);
    const [openwebsite, setOpenWebsite] = useState(false);

    //   const { influencerInsta, influencerTiktok, influencerYoutube, influencerTwitter, influencerFacebook, influencerWebsite } = socialLink;
    //   const socialLinkHandler = () => {
    //     setLoading(true);
    //     if (influencerInsta === "https://www.instagram.com/" && influencerTiktok === "https://www.tiktok.com/" && !influencerYoutube && !influencerTwitter && !influencerFacebook && !influencerWebsite) {
    //       setLoading(false);
    //       Notify("error", "Please Select At least One Social Link");
    //     } else {
    //       setTimeout(() => {
    //         setLoading(false);
    //       }, 2000)
    //     }
    //   };


    const {
        brandFacebook,
        brandFacebookFollower,
        brandInsta,
        brandInstaFollower,
        brandTiktok,
        brandTiktokFollower,
        brandTwitter,
        brandTwitterFollower,
        brandWebsite,
        brandYoutube,
        brandYoutubeFollower
    } = brand_Profile.socialLink && brand_Profile.socialLink[0];


    console.log("brandInsta", brandInsta)

    return (
        <div className="updateSocialMedia">
            <div>
                <h1 className="stepFive-heading">Add your social channels</h1>
                {/* Instagram */}
                {!openInsta ? (
                    <div
                        className="input_Container_social"
                        onClick={() => setOpenInsta(true)}
                    >
                        <div className="input_container_Item">
                            <AiFillInstagram className="icons" />
                            <p className="items_text">Add Instagram</p>
                        </div>
                    </div>
                ) : (
                    <div className="inputs_cont">
                        <input
                            type="text"
                            className="inputName"
                            placeholder="Instagram UserName"
                            name="brandInsta"
                            defaultValue={brandInsta || ""}
                            // value={socialLink.brandInsta}
                            onChange={(e) => setSocialLink({ ...socialLink, brandInsta: e.target.value })}
                        />

                    </div>
                )}
                {/* TikTok */}
                {!opentiktok ? (
                    <div
                        className="input_Container_social"
                        onClick={() => setOpentiktok(true)}
                    >
                        <div className="input_container_Item">
                            <IoLogoTiktok className="icons" />
                            <p className="items_text">Add Tiktok</p>
                        </div>
                    </div>
                ) : (
                    <div className="inputs_cont">
                        <input
                            type="text"
                            className="inputName"
                            placeholder="Tiktok UserName"
                            name="brandTiktok"
                            defaultValue={brandTiktok || ""}
                            onChange={(e) => setSocialLink({ ...socialLink, brandTiktok: e.target.value })}
                        />

                    </div>
                )}
                {/* Youtube */}
                {!openyoutube ? (
                    <div
                        className="input_Container_social"
                        onClick={() => setOpenYoutube(true)}
                    >
                        <div className="input_container_Item">
                            <AiFillYoutube className="icons" />
                            <p className="items_text">Add YouTube</p>
                        </div>
                    </div>
                ) : (
                    <div className="inputs_cont">
                        <input
                            type="text"
                            className="inputName"
                            placeholder="YouTube URL"
                            name="brandYoutube"
                            defaultValue={brandYoutube || ""}
                            onChange={(e) => setSocialLink({ ...socialLink, brandYoutube: e.target.value })}

                        />

                    </div>
                )}
                {/* Twitter */}
                {!opentwitter ? (
                    <div
                        className="input_Container_social"
                        onClick={() => setOpenTwitter(true)}
                    >
                        <div className="input_container_Item">
                            <ImTwitter className="icons" />
                            <p className="items_text">Add Twitter</p>
                        </div>
                    </div>
                ) : (
                    <div className="inputs_cont">
                        <input
                            type="text"
                            className="inputName"
                            placeholder="Twitter Url"
                            name="brandTwitter"
                            defaultValue={brandTwitter || ""}
                            onChange={(e) => setSocialLink({ ...socialLink, brandTwitter: e.target.value })}

                        />

                    </div>
                )}

                {/* Facebook */}
                {!openfacebook ? (
                    <div
                        className="input_Container_social"
                        onClick={() => setOpenFacebook(true)}
                    >
                        <div className="input_container_Item">
                            <IoLogoFacebook className="icons" />
                            <p className="items_text">Add Facebook</p>
                        </div>
                    </div>
                ) : (
                    <div className="inputs_cont">
                        <input
                            type="text"
                            className="inputName"
                            placeholder="Facebook Url"
                            name="brandFacebook"
                            defaultValue={brandFacebook || ""}
                            onChange={(e) => setSocialLink({ ...socialLink, brandFacebook: e.target.value })}

                        />

                    </div>
                )}
                {/* Website */}
                {!openwebsite ? (
                    <div
                        className="input_Container_social"
                        onClick={() => setOpenWebsite(true)}
                    >
                        <div className="input_container_Item">
                            <BsLink45Deg className="icons" />
                            <p className="items_text">Add Website</p>
                        </div>
                    </div>
                ) : (
                    <div className="inputs_cont">
                        <input type="text" className="inputName" placeholder="Website Url"
                            name="brandWebsite"
                            defaultValue={brandWebsite || ""}
                            onChange={(e) => setSocialLink({ ...socialLink, brandWebsite: e.target.value })}
                        />
                    </div>
                )}


            </div>
        </div>
    )
}

export default Social