import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { CustomizedButton } from "../../../components/CustomizedButton";
import { BASE_URL } from "../../../config/config";
import { Notify } from "../../../components/Notify";
import axios from 'axios';

const Packages = () => {
  const influencerProfile = useSelector((state) => state.User.influencerProfile);

  const [packagesInputs, setPackagesInput] = useState([]);




  let handleChange = (i, e) => {
    let newFormValues = [...packagesInputs];
    newFormValues[i][e.target.name] = e.target.value
    setPackagesInput(newFormValues);
  };

  let addFormFields = () => {
    if (packagesInputs.length < 5) {


      setPackagesInput([
        ...packagesInputs,
        {
          platform: "",
          contentType: "",
          packageOffer: "",
          packageDescription: "",
          packagePrice: "",
          packageDeadline: "2 Days",
        },
      ]);
    }
  };

  let removeFormFields = (i) => {
    let newFormValues = [...packagesInputs];
    newFormValues.splice(i, 1);
    if (packagesInputs.length > 1) {
      setPackagesInput(newFormValues);
    }
  };


  const UpdatePackages = async () => {
    const id = packagesInputs.length - 1;
    if (!packagesInputs[id].platform) {
      // setLoading(false);
      Notify("error", "Enter Your Packages Platform!");
    } else if (!packagesInputs[id].contentType) {
      // setLoading(false);
      Notify("error", "Enter Your Packages ContentType!");
    } else if (!packagesInputs[id].packageOffer) {
      // setLoading(false);
      Notify("error", "Enter Your Packages Offer!");
    } else if (!packagesInputs[id].packageDescription) {
      // setLoading(false);
      Notify("error", "Enter Your Packages Description!");
    } else if (packagesInputs[id].packageDescription.length < 30) {
      // setLoading(false);
      Notify("error", "Packages Description Should be greater than 30 characters!");
    }
    else if (!packagesInputs[id].packagePrice) {
      // setLoading(false);
      Notify("error", "Enter Your Packages Price!");
    } else if (packagesInputs[id].packagePrice < 2500) {
      // setLoading(false);
      Notify("error", "Minimum Package Price is 2500 Rupees!");
    } else {
      const id = influencerProfile._id;
      try {
        const response = await axios.patch(`${BASE_URL}/updateInfluencerProfilePackage/${id}`, packagesInputs);
        if (response) {
          Notify("success", "Changes Saved!");
          setTimeout(() => {
            // window.location.reload();
          }, 1000)
        }

      } catch (error) {
        Notify("error", error.message);
      }
    }

  }

  useEffect(() => {
    let packages = influencerProfile.packages.map((val) => {
      return {
        platform: val.platform,
        contentType: val.contentType,
        packageOffer: val.packageOffer,
        packageDescription: val.packageDescription,
        packagePrice: val.packagePrice,
        packageDeadline: val.packageDeadline,
      }
    })
    setPackagesInput(packages)
  }, [influencerProfile])

  return (
    <div className="updatePackages">
      <h1 className="stepEight-heading">Add your packages</h1>

      <p className="stepEight-desc">
        These are services brands can purchase from you. This can be anything
        from a shoutout on your social media to original content creation.
        Unsure what to charge?{" "}
      </p>

      {/* Cards */}
      <div className="packages_cards_Container">
        {packagesInputs && packagesInputs.map((element, index) => {
          return (
            <div className="packages_cards" key={index}>
              <div className="packages_cards_Items">
                <div className="stepEight_Cont">
                  <p className="packages_heading">Package {index + 1}</p>
                  {index >= 1 && (
                    <p
                      className="deletePackage"
                      onClick={() => removeFormFields(index)}
                    >
                      +
                    </p>
                  )}
                </div>
                {/* User Generated Content Select */}

                <div>
                  <select
                    className="packages_inputSelect"
                    name="platform"
                    defaultValue={element.platform || ""}
                    onChange={(e) => handleChange(index, e)}
                  // onChange={(e) => handlePackages(e)}
                  >
                    <option value="" disabled="" hidden>
                      Choose Platform
                    </option>
                    <option value="All">All</option>
                    <option  >
                      Tiktok
                    </option>
                    <option value="Instagram">Instagram</option>
                    <option value="User Generated Content">User Generated Content</option>
                    <option value="Youtube">Youtube</option>
                  </select>
                </div>
                {/* User Generated Content Select Two */}

                {packagesInputs[index].platform ? (
                  <div>
                    <select
                      className="packages_inputSelect"
                      name="contentType"
                      defaultValue={element.contentType || ""}
                      onChange={(e) => handleChange(index, e)}
                    >
                      <option value="" disabled="" hidden>
                        Choose Content Type
                      </option>

                      <option value="Product Video">Product Video</option>
                      <option value="Product Photo">Product Photo</option>
                      <option value="Video Ad">Video Ad</option>
                      <option value="Photo Ad">Photo Ad</option>
                      <option value="Tutorial">Tutorial</option>
                      <option value="Testimonial / Review">
                        Testimonial / Review
                      </option>
                      <option value="UnBoxing">UnBoxing</option>
                      <option value="Blog">Blog</option>
                      <option value="Other">Other</option>
                    </select>
                  </div>
                )
                  : null
                }

                {/* package Offering Input */}
                <div>
                  <input
                    type="text"
                    className="packages_input"
                    placeholder="What is This Package Offering?"
                    name="packageOffer"
                    defaultValue={element.packageOffer}
                    onChange={(e) => handleChange(index, e)}
                  />
                </div>
                {/* package Description */}
                <div>
                  <textarea
                    className="package_description"
                    cols={7}
                    rows={7}
                    name="packageDescription"
                    placeholder="What is Included in the package? How many posts or photos? What will the buyer will getting?"
                    defaultValue={element.packageDescription}
                    onChange={(e) => handleChange(index, e)}
                  ></textarea>
                </div>
                {/* Package Time Deadline */}
                <div>
                    <select
                      className="packages_inputSelect"
                      name="packageDeadline"
                      defaultValue={element.packageDeadline || "2 Days"}
                      onChange={(e) => handleChange(index, e)}
                    >
                      <option selected value="" disabled="" hidden>
                        2 Days
                      </option>

                      <option value="2 Days">2 Days</option>
                      <option value="3 Days">3 Days</option>
                      <option value="4 Days">4 Days</option>
                      <option value="5 Days">5 Days</option>
                      <option value="6 Days">6 Days</option>
                      <option value="7 Days">
                       7 Days
                      </option>
                      <option value="8 Days">8 Days</option>
                      <option value="9 Days">9 Days</option>
                      <option value="10 Days">10 Days</option>
                    </select>
                  </div>
                {/* Package Price */}
                <div>
                  <input
                    type="number"
                    className="packages_input"
                    placeholder="Price (PKR)"
                    name="packagePrice"
                    defaultValue={element.packagePrice || ""}
                    onChange={(e) => handleChange(index, e)}
                  />
                </div>
                {/* text fees Deduction */}
                <div>
                  <p className="collabsFeesDeduction">
                    Collabscafe takes a 15% fee
                  </p>
                </div>
              </div>
            </div>
          );
        })}
      </div>
      <div className="addMoreButtonCont">

        <p className="addMoreButton" onClick={addFormFields}>Add More Item</p>
      </div>
      <div className='saveChangesBtn'>
        <CustomizedButton onClick={UpdatePackages} className='saveChanges'>Save</CustomizedButton>
      </div>
    </div>
  )
}

export default Packages;