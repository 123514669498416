import React from 'react'
import { Outlet } from "react-router-dom";
import Header from "./components/Header/Header";

const Layout = ({notifications}) => {
  return (
    <>
    <Header notifications={notifications}/>
    <Outlet />
  </>
  )
}

export default Layout