import React, { useState } from "react";
import "./StepFive.css";
import { AiFillInstagram, AiFillYoutube } from "react-icons/ai";
import { IoLogoTiktok, IoLogoFacebook } from "react-icons/io5";
import { ImTwitter } from "react-icons/im";
import { BsLink45Deg } from "react-icons/bs";
import { Notify } from "../../../../components/Notify";
import Loading from "../../../../components/Loading/Loading";

const StepFive = ({ step, setStep, socialLink, setSocialLink }) => {
  const [loading, setLoading] = useState(false);

  const [openInsta, setOpenInsta] = useState(false);
  const [opentiktok, setOpentiktok] = useState(false);
  const [openyoutube, setOpenYoutube] = useState(false);
  const [opentwitter, setOpenTwitter] = useState(false);
  const [openfacebook, setOpenFacebook] = useState(false);
  const [openwebsite, setOpenWebsite] = useState(false);

  const { influencerInsta, influencerTiktok, influencerYoutube, influencerTwitter, influencerFacebook, influencerWebsite } = socialLink;
  const socialLinkHandler = () => {
    setLoading(true);
    if (!influencerInsta && !influencerTiktok && !influencerYoutube && !influencerTwitter && !influencerFacebook && !influencerWebsite) {
      setLoading(false);
      Notify("error", "Please Select At least One Social Link");
    } else {
      setTimeout(() => {
        setLoading(false);
        setStep(step + 1);
      }, 2000)
    }
  };

  return (
    <div className="stepFive">
      <div>
        <h1 className="stepFive-heading">Add your social channels</h1>
        {/* Instagram */}
        {!openInsta ? (
          <div
            className="input_Container_social"
            onClick={() => setOpenInsta(true)}
          >
            <div className="input_container_Item">
              <AiFillInstagram className="icons" />
              <p className="items_text">Add Instagram</p>
            </div>
          </div>
        ) : (
          <div className="inputs_cont">
            <input
              type="text"
              className="inputName"
              placeholder="Instagram URL"
              value={socialLink.influencerInsta}
              onChange={(e) => setSocialLink({ ...socialLink, influencerInsta: e.target.value })}
            />
            <select className="inputSelect"
              value={socialLink.influencerInstaFollower}
              onChange={(e) => setSocialLink({ ...socialLink, influencerInstaFollower: e.target.value })}
            >
              <option value disabled selected hidden>
                Instagram Followers
              </option>
              <option value="0-1k">0-1k</option>
              <option value="1k-10k">1k-10k</option>
              <option value="10k-50k">10k-50k</option>
              <option value="50k-100k">50k-100k</option>
              <option value="100k-500k">100k-500k</option>
              <option value="500k-1m">500k-1m</option>
              <option value="1m-5m">1m-5m</option>
              <option value="5m-10m">5m-10m</option>
              <option value="10m+">10m+</option>
            </select>
          </div>
        )}
        {/* TikTok */}
        {!opentiktok ? (
          <div
            className="input_Container_social"
            onClick={() => setOpentiktok(true)}
          >
            <div className="input_container_Item">
              <IoLogoTiktok className="icons" />
              <p className="items_text">Add Tiktok</p>
            </div>
          </div>
        ) : (
          <div className="inputs_cont">
            <input
              type="text"
              className="inputName"
              placeholder="Tiktok URL"
              value={socialLink.influencerTiktok}
              onChange={(e) => setSocialLink({ ...socialLink, influencerTiktok: e.target.value })}
            />
            <select className="inputSelect"
              value={socialLink.influencerTiktokFollower}
              onChange={(e) => setSocialLink({ ...socialLink, influencerTiktokFollower: e.target.value })}
            >
              <option value disabled selected hidden>
                Tiktok Followers
              </option>
              <option value="0-1k">0-1k</option>
              <option value="1k-10k">1k-10k</option>
              <option value="10k-50k">10k-50k</option>
              <option value="50k-100k">50k-100k</option>
              <option value="100k-500k">100k-500k</option>
              <option value="500k-1m">500k-1m</option>
              <option value="1m-5m">1m-5m</option>
              <option value="5m-10m">5m-10m</option>
              <option value="10m+">10m+</option>
            </select>
          </div>
        )}
        {/* Youtube */}
        {!openyoutube ? (
          <div
            className="input_Container_social"
            onClick={() => setOpenYoutube(true)}
          >
            <div className="input_container_Item">
              <AiFillYoutube className="icons" />
              <p className="items_text">Add YouTube</p>
            </div>
          </div>
        ) : (
          <div className="inputs_cont">
            <input
              type="text"
              className="inputName"
              placeholder="YouTube URL"
              value={socialLink.influencerYoutube}
              onChange={(e) => setSocialLink({ ...socialLink, influencerYoutube: e.target.value })}

            />
            <select className="inputSelect"
              value={socialLink.influencerYouTubeFollower}
              onChange={(e) => setSocialLink({ ...socialLink, influencerYouTubeFollower: e.target.value })}
            >
              <option value disabled selected hidden>
                YouTube Followers
              </option>
              <option value="0-1k">0-1k</option>
              <option value="1k-10k">1k-10k</option>
              <option value="10k-50k">10k-50k</option>
              <option value="50k-100k">50k-100k</option>
              <option value="100k-500k">100k-500k</option>
              <option value="500k-1m">500k-1m</option>
              <option value="1m-5m">1m-5m</option>
              <option value="5m-10m">5m-10m</option>
              <option value="10m+">10m+</option>
            </select>
          </div>
        )}
        {/* Twitter */}
        {!opentwitter ? (
          <div
            className="input_Container_social"
            onClick={() => setOpenTwitter(true)}
          >
            <div className="input_container_Item">
              <ImTwitter className="icons" />
              <p className="items_text">Add Twitter</p>
            </div>
          </div>
        ) : (
          <div className="inputs_cont">
            <input
              type="text"
              className="inputName"
              placeholder="Twitter Url"
              value={socialLink.influencerTwitter}
              onChange={(e) => setSocialLink({ ...socialLink, influencerTwitter: e.target.value })}

            />
            <select className="inputSelect"
              value={socialLink.influencerTwitterFollower}
              onChange={(e) => setSocialLink({ ...socialLink, influencerTwitterFollower: e.target.value })}
            >
              <option value disabled selected hidden>
                Twitter Followers
              </option>
              <option value="0-1k">0-1k</option>
              <option value="1k-10k">1k-10k</option>
              <option value="10k-50k">10k-50k</option>
              <option value="50k-100k">50k-100k</option>
              <option value="100k-500k">100k-500k</option>
              <option value="500k-1m">500k-1m</option>
              <option value="1m-5m">1m-5m</option>
              <option value="5m-10m">5m-10m</option>
              <option value="10m+">10m+</option>
            </select>
          </div>
        )}

        {/* Facebook */}
        {!openfacebook ? (
          <div
            className="input_Container_social"
            onClick={() => setOpenFacebook(true)}
          >
            <div className="input_container_Item">
              <IoLogoFacebook className="icons" />
              <p className="items_text">Add Facebook</p>
            </div>
          </div>
        ) : (
          <div className="inputs_cont">
            <input
              type="text"
              className="inputName"
              placeholder="Facebook Url"
              value={socialLink.influencerFacebook}
              onChange={(e) => setSocialLink({ ...socialLink, influencerFacebook: e.target.value })}

            />
            <select className="inputSelect"
              value={socialLink.influencerFacebookFollower}
              onChange={(e) => setSocialLink({ ...socialLink, influencerFacebookFollower: e.target.value })}
            >
              <option value disabled selected hidden>
                Facebook Followers
              </option>
              <option value="0-1k">0-1k</option>
              <option value="1k-10k">1k-10k</option>
              <option value="10k-50k">10k-50k</option>
              <option value="50k-100k">50k-100k</option>
              <option value="100k-500k">100k-500k</option>
              <option value="500k-1m">500k-1m</option>
              <option value="1m-5m">1m-5m</option>
              <option value="5m-10m">5m-10m</option>
              <option value="10m+">10m+</option>
            </select>
          </div>
        )}
        {/* Website */}
        {!openwebsite ? (
          <div
            className="input_Container_social"
            onClick={() => setOpenWebsite(true)}
          >
            <div className="input_container_Item">
              <BsLink45Deg className="icons" />
              <p className="items_text">Add Website</p>
            </div>
          </div>
        ) : (
          <div className="inputs_cont">
            <input type="text" className="inputName" placeholder="Website Url"
              value={socialLink.influencerWebsite}
              onChange={(e) => setSocialLink({ ...socialLink, influencerWebsite: e.target.value })}
            />
          </div>
        )}

        {/* signUp Button */}
        <div className="continue_container">
          {
            loading ? (
              <button className="continueBtn" >
                <Loading />
              </button>
            ) : (
              <button className="continueBtn" onClick={socialLinkHandler}>
                Continue
              </button>
            )
          }

        </div>
      </div>
    </div>
  );
};

export default StepFive;
