import React from "react";
import "./HowCollabsCafeWorks.css";
import ImageBg from "../../img.jpg";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { BsFillArrowRightCircleFill, BsFillArrowLeftCircleFill } from "react-icons/bs"

import searchinfluencer from "../../images/searchinfluencer.jpg";
import purchasesecurely from "../../images/purchasesecurely.jpg";
import recievequalitycontent from "../../images/recievequalitycontent.jpg";
import Search from "../../images/1Search.jpg";
import Purchase from "../../images/2Purchase.jpg";
import Receive from "../../images/3Receive.jpg";


const HowCollabsCafeWorks = () => {
  // var slider2 = {
  //   arrows: true,
  //   autoplay: true,
  //   infinite: true,
  //   speed: 250,
  //   slidesToShow: 3,
  //   slidesToScroll: 1,
  //   autoplaySpeed: 2000,
  //   rows: 1,
  //   initialSlide: 0,
  //   nextArrow: <SampleNextArrow />,
  //   prevArrow: <SamplePrevArrow />,
  //   responsive: [
  //     {
  //       breakpoint: 1024,
  //       settings: {
  //         slidesToShow: 3,
  //         slidesToScroll: 3,
  //         infinite: true,
  //         dots: true
  //       }
  //     },
  //     {
  //       breakpoint: 800,
  //       settings: {
  //         slidesToShow: 3,
  //         slidesToScroll: 1,
  //         initialSlide: 0,
  //         arrows: false,
  //         autoplay: false,
  //         infinite: false
  //       }
  //     },
  //     {
  //       breakpoint: 600,
  //       settings: {
  //         slidesToShow: 1,
  //         slidesToScroll: 1,
  //         initialSlide: 0,
  //         arrows: false,
  //         autoplay: false,
  //         infinite: false,
  //         swipeToSlide: true,
  //       }
  //     },
  //     {
  //       breakpoint: 500,
  //       settings: {
  //         slidesToShow: 1,
  //         // slidesToScroll: 1,
  //         // initialSlide: 0,
  //         arrows: false,
  //         autoplay: false,
  //         infinite: false,
  //         centerPadding: "10px",
  //         swipeToSlide: true,
  //       }
  //     },
  //   ]
  // };
  return (
    <div className="collabsCafeWorks" id="howWorks">
      {/* ****** heading ****** */}
      <h1 className="collabsCafeWorks_heading">How Collabscafe Works</h1>
      {/* ****** heading Describtion ****** */}
      <p className="collabsCafeWorks_heading_desc">
        Everything you need to run your influencer campaigns, and more.
      </p>
      {/*  */}
      <div className="collabsCafeWorks_Card_Container">
        {/* <Slider {...slider2}> */}
          {/* card One */}
          
            <div className="collabsCafeWorks_Card_Cont">
              <div className="collabsCafeWorks_Card">
                <div
                  className="collabsCafeWorks_Card_Bg"
                  // style={{
                  //   backgroundImage: `url('${searchinfluencer}')`,
                  //   backgroundPosition: "center",
                  //   backgroundSize: "cover",
                  //   backgroundRepeat: "no-repeat",
                  // }}
                >
                  <img style={{width: "100%", height: "100%"}} src={Search}/>
                 
                </div>
              </div>
            </div>
          
          {/*  card Two*/}
          
            <div className="collabsCafeWorks_Card_Cont">
              <div className="collabsCafeWorks_Card">
                <div
                  className="collabsCafeWorks_Card_Bg"
                  // style={{
                    //   backgroundImage: `url('${ImageBg}')`,
                  //   backgroundPosition: "center",
                  //   backgroundSize: "cover",
                  //   backgroundRepeat: "no-repeat",
                  // }}
                >
                    <img style={{width: "100%", height: "100%"}} src={Purchase}/>
                  {/****** Bg Image text *****/}
                  {/* <div className="collabsCafeWorks_bg_text">
                    <p className="collabsCafeWorks_bg_text_Name">2</p>
                    <p className="collabsCafeWorks_bg_text_Name">
                    Purchase Securely
                    </p>
                    <p className="collabsCafeWorks_bg_desc">
                    Safely purchase through Collabscafe. We hold your payment until
                    the work is completed.
                    </p>
                  </div> */}
                </div>
              </div>
            </div>
          
          {/* Card Three */}

            <div className="collabsCafeWorks_Card_Cont">
              <div className="collabsCafeWorks_Card">
                <div
                  className="collabsCafeWorks_Card_Bg"
                  // style={{
                    //   backgroundImage: `url('${ImageBg}')`,
                  //   backgroundPosition: "center",
                  //   backgroundSize: "cover",
                  //   backgroundRepeat: "no-repeat",
                  // }}
                >
                  
                  <img style={{width: "100%", height: "100%"}} src={Receive}/>


                  {/* ***** Bg Image text **** */}
                  {/* <div className="collabsCafeWorks_bg_text">
                    <p className="collabsCafeWorks_bg_text_Name">3</p>
                    <p className="collabsCafeWorks_bg_text_Name">
                      Receive Quality Content
                    </p>
                    <p className="collabsCafeWorks_bg_desc">
                      Receive your high quality content from influencers directly
                      through the platform.
                    </p>
                  </div> */}
                </div>
              </div>
            </div>
        


        {/* </Slider> */}
      </div>
      {/* CollabsCafe Guide */}

    </div>
  );
};

export default HowCollabsCafeWorks;
