import React, { useState } from 'react'
import { BiDownArrowAlt } from 'react-icons/bi';

const MoreFilteres = ({ openMoreFilters, setOpenMoreFilters, price, setPrice, followers, setFollowers }) => {

    const [changedPrice, setChangedPrice] = useState("");
    const [changedFollowers, setChangedFollowers] = useState("");

    const applyFilters = () => {
        setPrice(changedPrice);
        setFollowers(changedFollowers);
        setOpenMoreFilters(false);
    }
    return (
        <div>
            <p className='filtersBtn' onClick={() => setOpenMoreFilters(!openMoreFilters)}>More Filters <BiDownArrowAlt className='downArrow' /></p>
            {
                openMoreFilters && (
                    <div
                        className="modal-backdrop"
                    >
                        <div
                            className="modal-content"
                            onClick={e => {
                                // do not close modal if anything inside modal content is clicked
                                e.stopPropagation();
                            }}
                        >
                            {/* Close and More */}
                            <div className='modalHeading_Container'>
                                <p className='modalHeading'>More Filters</p>
                                <p className="modalClose" onClick={() => setOpenMoreFilters(false)}>+</p>
                            </div>
                            {/* Price */}
                            <p className='label_filteres_Modal'>Price</p>
                            <select
                                className="fromWhereYouHeard"
                                onChange={(e) => setChangedPrice(e.target.value)}

                            >
                                <option value="" disabled="" hidden="">
                                    {price ? price : "Any"}
                                </option>
                                <option value="2000">Under 2000</option>
                                <option value="3000">Under 3000</option>
                                <option value="5000">Under 5000</option>
                                <option value="10000">Under 10000</option>
                                <option value="10000+">10000+</option>
                            </select>

                            {/* Followers */}
                            <p className='label_filteres_Modal'>Followers</p>
                            <select
                                className="fromWhereYouHeard"
                                onChange={(e) => setChangedFollowers(e.target.value)}
                            >
                                <option value="" disabled="" hidden="">{followers ? followers : "Any"}</option>
                                <option value="0-1k">0-1k</option>
                                <option value="1k-10k">1k-10k</option>
                                <option value="10k-50k">10k-50k</option>
                                <option value="50k-100k">50k-100k</option>
                                <option value="100k-500k">100k-500k</option>
                                <option value="500k-1m">500k-1m</option>
                                <option value="1m-5m">1m-5m</option>
                                <option value="5m-10m">5m-10m</option>
                                <option value="10m+">10m+</option>
                            </select>

                            {/* Filtered Button */}
                            <div className="continue_container">
                                <button className="continueBtn" onClick={applyFilters}>
                                    Apply Filters
                                </button>
                            </div>
                        </div>
                    </div>
                )
            }
        </div>
    )
}

export default MoreFilteres