import React, { useState } from 'react'
import { citiesData } from "../../../CreatePage/Steps/StepOne/citiesData";
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import toast from "react-hot-toast";
import { useDispatch } from 'react-redux';
import { AddInfluencerProfile } from "../../../../Redux/Reducers/InfluencerProfileReducer";
import Loading from "../../../../components/Loading/Loading";
import { Notify } from "../../../../components/Notify";
import Chip from '@mui/material/Chip';

const BrandStepOne = ({ step, setStep, brandCity, setBrandCity }) => {
    const [cities, setCities] = useState(citiesData);
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();


    const handleCity = (value) => {
        if (!value) return;
        const getCityData = cities.find((val) => val.CityName === value);
        if (getCityData) {
            setBrandCity(`${getCityData.ProvinceName}, ${getCityData.CityName}`);
        }
    }

    const locatedHadler = () => {
        setLoading(true);
        if (!brandCity) {
            Notify("error", "Select Your City!")
            setLoading(false);
        } else {
            // dispatch(AddInfluencerProfile(city));
            // alert(city);
            setTimeout(() => {
                setLoading(false);
                setStep(step + 1);
            }, 2000);
        }
    }
    return (
        <div className="stepOne">
            <div>

                <h1 className="stepOne-heading">Let's complete your profile. Where are you located?</h1>
                {/* <p className="stepEmailOtpVerify_desc">
        We sent an email to ziaalikhan052@gmail.com. Check your inbox and
        enter the 6-digit code to verify your email.
      </p> */}
                <div className="input_Container">
                    <Autocomplete
                        className='cityInput'
                        // id="country-select-demo"
                        sx={{ width: "100%" }}
                        size={"small"}
                        options={cities}
                        // autoHighlight
                        getOptionLabel={(option) => option.CityName}
                        onInputChange={(event, value) => {
                            handleCity(value)
                        }}
                        renderOption={(props, option) => (
                            <Box component="li" {...props}>
                                <p className='citiesPara'>{option.ProvinceName}, <span className='cityName'>{option.CityName}</span> </p>
                                {/* <Chip label={option.CityName} variant="outlined" /> </p> */}
                            </Box>
                        )}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                placeholder="Select Your City"
                                // label="Choose a country"
                                inputProps={{
                                    ...params.inputProps,
                                    autoComplete: 'new-password', // disable autocomplete and autofill
                                }}
                            />
                        )}
                    />
                    {/* <input onClick={() => setOpen(!open)} type="text" placeholder="Cities - Enter First 3 letters and Select from Dropdown" /> */}
                </div>



                {/* signUp Button */}
                <div className="continue_container">
                    {
                        loading ? (
                            <button className="continueBtn" >
                                <Loading />
                            </button>
                        ) : (
                            <button className="continueBtn" onClick={locatedHadler}>
                                Continue
                            </button>
                        )
                    }

                </div>
                <p className="stepOne_label">I don't see my city</p>
            </div>
        </div>
    )
}

export default BrandStepOne;