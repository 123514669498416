import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  user: null,
  brand: null,
  influencerProfile: null,
  brandProfile: null,
}

export const userSlice = createSlice({
  name: 'User',
  initialState,
  reducers: {
    currentUser: (state, action) => {
      state.user = action.payload;
    },
    currentBrand: (state, action) => {
      state.brand = action.payload;
    },
    logout: (state) => {
      state.user = null;
    },
    logoutBrand: (state) => {
      state.brand = null;
    },
    currentInfluencerProfile: (state, action) => {
      state.influencerProfile = action.payload;
    },
    currentBrandProfile: (state, action) => {
      state.brandProfile = action.payload;
    }
  },
})

// Action creators are generated for each case reducer function
export const { currentUser, logout, logoutBrand, currentBrand, currentInfluencerProfile, currentBrandProfile } = userSlice.actions;

export default userSlice.reducer;