import React from 'react'
import ReviewProfile from "../../images/review.png";

const InfluencerProfileReview = () => {
  return (
    <div className='ProfileNotApproved'>
        <img src={ReviewProfile} width={"40%"}/>
        <h1 className='profileReview_heading'>Your Profile is Under Review Process</h1>
        <p className='profileReview_text'>Collabscafe will Verify your Profile withIn 2 Bussiness Days, then will send <br /> you an Confirmation Email, Stay Connected</p>
    </div>
  )
}

export default InfluencerProfileReview;