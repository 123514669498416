import React from 'react'
import "./InfluencerGuide.css";
import marketPlace from "../../images/marketplace.png";

const InfluencerGuide = () => {
    return (
        <div className='influencerGuide'>
            <div className='influencerGuide_Container'>
                <div className='influencerGuide_desc'>
                    <h1 className='influencerGuide_heading'>Find and Hire Influencers in Seconds on the Marketplace</h1>
                    <div className='influencerGuide_Item'>
                        <p className='influencerGuide_Item_Title'>Search Influencers</p>
                        <p className='influencerGuide_Item_desc'>Search thousands of vetted Instagram, TikTok, and YouTube influencers.</p>
                    </div>
                    <div className='influencerGuide_Item'>
                        <p className='influencerGuide_Item_Title'>Purchase Securely</p>
                        <p className='influencerGuide_Item_desc'>Safely purchase through Collabscafe. We hold your payment until the work is completed.</p>
                    </div>
                    <div className='influencerGuide_Item'>
                        <p className='influencerGuide_Item_Title'>Receive Quality Content</p>
                        <p className='influencerGuide_Item_desc'>Receive your high-quality content from influencers directly through the platform.</p>
                    </div>

                </div>
                <div className='influencerGuide_Image'>
                    <img src={marketPlace} alt="marketPlace.png"/>
                </div>

            </div>
        </div>
    )
}

export default InfluencerGuide;