import React from "react";
import "./Brand.css";
import Typical from "react-typical";
import { Link } from "react-router-dom";
import {
  Banner, Footer, SubFooter, CollabsCafeFAQS, LovedCreators, SeenIn,
  FeaturedInfluencers, InfluencerGuide, CampaignGuide, ContentNeeds
} from "../index";
import { Helmet } from "react-helmet";


const Brand = () => {
  return (
    <div>
      <Helmet>
        <title>Collabscafe | Brand SignUp</title>
      </Helmet>
      <div className="brand">
        <div className="Brands_hire_heading">
          <h1 className="brandHeading">The Easy Way to Generate</h1>
          <Typical
            className="typical"
            steps={[
              "Product Photos",
              2000,
              "Sponsored Posts",
              2000,
              2000,
              "Video Ads",
              2000,
              "Testimonials",
              2000,
            ]}
            loop={Infinity}
            wrapper="h1"
          />

          <p>
            Find influencers and get unique content for your brand in seconds
          </p>
          <Link to="/brand-Signup">
            <button className="Brand_SignUpBtn">Sign Up</button>
          </Link>
        </div>
      </div>
      <InfluencerGuide />
      <CampaignGuide />
      <ContentNeeds />
      <LovedCreators />
      <SeenIn />
      <FeaturedInfluencers />
      <CollabsCafeFAQS
        Quesone={"How does shipping work?"}
        AnsOne={"Once you place an order, the influencer will send you their shipping info through the chat. You can then use your preferred shipping carrier to send them the product."}
        QuesTwo={"How do I send custom offers?"}
        AnsTwo={"If you have a request that doesn't fit an influencers existing packages you can send them a custom offer. To do this, click the Contact button on the influencers profile and follow the steps."}
        QuesThree={"Can Collabscafe manage an influencer campaign for me?"}
        AnsThree={"Yes, if you would like Collabscafe to plan and manage an influencer campaign for you, email us at hello@collabscafe.com to get started."}
        QuesFour={"How are influencers vetted before joining Collabscafe?"}
        AnsFour={"We verify the identity of each influencer that is listed on the platform. We also do a full audit of their social media to look for signs of fake followers & engagement. We also take into consideration their previous brand deals."}
        QuesFive={"How do I know I will receive the work I paid for?"}
        AnsFive={"Collabscafe holds your money until the work is completed and approved by you. You will have up to 48 hours once the work has been submitted to ask for a revision or open a dispute with Collabscafe."}
        QuesSix={"How long does an influencer have to accept my order?"}
        AnsSix={"Influencers are given 72 hours to accept new orders before they automatically expire."}
        QuesSeven={"What if an influencer declines my order?"}
        AnsSeven={"We do not charge you until an influencer accepts your order. So if your order gets declined no action is required from you."}
      />
      <Banner
        heading={
          "Get Product Photos, Sponsored Posts & Testimonials for Your Brand"
        }
        subHeading={
          "Find influencers, run campaigns, and get unique content for your brand in seconds"
        }
        btn={"Sign Up"}
        link={""}
      />

      <SubFooter />
      <Footer />
    </div>
  );
};

export default Brand;
