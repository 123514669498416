import React from 'react';
import "./EditInfluencerProfile.css";
import EditTabs from "./Tabs/Tabs";
import { useNavigate, Navigate } from 'react-router-dom';
import { useSelector } from "react-redux";

const EditInfluencerProfile = () => {
  const navigate = useNavigate();
  const influencerProfile = useSelector((state) => state.User.influencerProfile);
  const user = useSelector((state) => state.User.user);


  // console.log(influencerProfile);
  // console.log(user);
  // if (user) {
  //   if (influencerProfile) {
  //     if (user.uid !== influencerProfile.uid) {
  //       return <Navigate to="/" replace />
  //     }
  //   }
  // } else {
  //   return <Navigate to="/" replace />
  // }
  //   if (brand) {
  //     if (brand.role === "Brand") {
  //         return <Navigate to="/" replace />
  //     }
  // } else {
  //     return <Navigate to="/" replace />
  // }

  return (
    <div className='editInfluencerProfile-container'>
      <div className='editInfluProfile'>
        <div className='goToProfile' onClick={() => navigate(-1)}>← Back to Profile</div>
        <h1>Edit Your Page</h1>
        <EditTabs />
      </div>
    </div>
  )
}



export default EditInfluencerProfile;