import React, { useEffect } from 'react'
import "./OurTeam.css";
import { AiFillInstagram, AiFillLinkedin } from 'react-icons/ai';
import { IoLogoFacebook, IoLogoTiktok } from 'react-icons/io5';
import { BsTwitter } from 'react-icons/bs';
import Banner from '../../components/Banner/Banner';
import SubFooter from '../../components/SubFooter/SubFooter';
import Footer from '../../components/Footer/Footer';
import Zia from "../../images/zia.jpg"
import Muddasir from "../../images/muddasir.jpg"
import Wasil from "../../images/wasil.jpg"

const OurTeam = () => {


    const teamData = [
        {
            name: "Muddasir Ashfaq Gadit",
            designation: "CEO",
            photo: Muddasir,
        },
        {
            name: "Wasil Gulraiz",
            designation: "Co Founder",
            photo: Wasil,
        },
        {
            name: "Zia Ur Rehman",
            designation: "CTO",
            photo: Zia,
        },
    ];


  
    return (
        <div>
            <h1 className='our-team-heading'>Our Team</h1>
            <p className='our-team-title'>Meet Our Awesome And Expert Team Members</p>

            <div className='our-team-main-card'>
                {
                    teamData.map((v, i) => {
                        return (
                            <div
                                key={i}
                            // style={{
                            //     backgroundImage: `url(${v.photo})`, 
                            //     backgroundPosition: 'center',
                            //     backgroundSize: 'cover',
                            //     backgroundRepeat: 'no-repeat'
                            // }}
                            >
                                <img style={{ width: "100%", height: '80%' }} src={v.photo} />
                                <div>
                                    <p className='our-team-name'>{v.name}</p>
                                    <p className='our-team-designation'>{v.designation}</p>
                                </div>
                                <div className="our_team-social_links">
                                    <div><AiFillInstagram className="insta_icon" /></div>
                                    <div><BsTwitter className="twitter_icon" /></div>
                                    <div><IoLogoFacebook className="fb_icon" /></div>
                                    <div><AiFillLinkedin className="twitter_icon" /></div>
                                </div>

                            </div>

                        )
                    })
                }
            </div>


            <SubFooter />
            <Footer />
        </div>
    )
}

export default OurTeam