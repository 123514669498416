import React, { useState } from 'react'
import "./InfluencerPaymentRequest.css"
import { useLocation, useNavigate } from 'react-router-dom'
import HashLoader from '../../components/HashLoader';
import { Alert, Button } from '@mui/material';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Swal from 'sweetalert2';
import axios from 'axios';
import { BASE_URL } from '../../config/config';
import { Notify } from '../../components/Notify';

const InfluencerPaymentRequest = ({ CURRENT_USER_PROFILE }) => {
    const influencer_detail = useLocation();
    const navigate = useNavigate();
    const [accountType, setAccountType] = useState(null);
    const [accountNumber, setAccountNumber] = useState(null);
    const [bankName, setBankName] = useState(null);
    const [accountName, setAccountName] = useState(null);
    const [amount, setAmount] = useState(null);

    const handleChange = (event) => {
        setAccountNumber(null);
        setBankName(null);
        setAccountName(null);
        setAccountType(event.target.value);
    };


    function submitData(data) {
        let exacAmount = CURRENT_USER_PROFILE.total_earnings - CURRENT_USER_PROFILE.taken_amount_from_cc;
        if (amount > exacAmount) {
            Swal.fire({
                icon: "error",
                title: "OOPS...",
                text: `Your Have Rs ${exacAmount} in Your Wallet!`,
                confirmButtonColor: "#05a081",
            });
        } else if (amount < 0) {
            Swal.fire({
                icon: "error",
                title: "OOPS...",
                text: `Your Amount is in Minus!`,
                confirmButtonColor: "#05a081",
            });
        } else {
            Swal.fire({
                title: 'Are You Confirm?',
                inputAttributes: {
                    autocapitalize: 'off'
                },
                backdrop: true,
                showCancelButton: true,
                confirmButtonText: 'Confirm',
                confirmButtonColor: "#05a081",
                showLoaderOnConfirm: true,
                preConfirm: async () => {
                    await axios.post(`${BASE_URL}/create-pay-request`, data).then((res) => {
                        Notify("success", res?.data?.msg);
                        setTimeout(() => {
                            navigate("/influencer-billing");
                        }, 1000)
                    }).catch((err) => {
                        Notify("error", err?.response?.data?.msg)
                    })
                },
                allowOutsideClick: () => !Swal.isLoading()
            })
        }
    }

    const submit_payment_request = () => {
        const data = {
            profileId: influencer_detail.state?._id,
            profile: influencer_detail.state?.profileImage.url,
            userName: influencer_detail.state?.userName,
            fullName: influencer_detail.state?.fullName,
            email: influencer_detail.state?.email,
            phoneNumber: influencer_detail.state?.phoneNumber,
            total_wallet_amount: influencer_detail.state?.total_earning,
            accountType,
            accountNumber,
            bankName,
            accountName,
            amount
        }

        if (!accountType) {
            Notify("warning", "Select Account Type");
        } else if (accountType === "Account") {
            if (!bankName) {
                Notify("warning", "Enter Your Bank Name");
            } else if (!accountName) {
                Notify("warning", "Enter Your Account Name");
            } else if (!accountNumber) {
                Notify("warning", "Enter Your Account Number");
            } else {
                submitData(data);
            }
        } else if (!accountNumber) {
            Notify("warning", "Enter Your Account Number");
        } else {
            submitData(data);
            // Swal.fire({
            //     title: 'Are You Confirm?',
            //     inputAttributes: {
            //         autocapitalize: 'off'
            //     },
            //     backdrop: true,
            //     showCancelButton: true,
            //     confirmButtonText: 'Confirm',
            //     confirmButtonColor: "#05a081",
            //     showLoaderOnConfirm: true,
            //     preConfirm: async () => {
            //         await axios.post(`${BASE_URL}/create-pay-request`, data).then((res) => {
            //             Notify("success", res?.data?.msg);
            //             setTimeout(() => {
            //                 navigate("/influencer-billing");
            //             }, 1000)
            //         }).catch((err) => {
            //             Notify("error", err?.response?.data?.msg)
            //         })
            //     },
            //     allowOutsideClick: () => !Swal.isLoading()
            // })
        }
    }


    return (
        <div className='InfluencerPaymentRequest_container'>
            <h1>Payment Request</h1>
            <Alert severity="info" >
                If you submit your Payment Request Kindly wait we will Transfer Amount - <strong>check it out!</strong>
            </Alert>
            {
                influencer_detail.state ? (
                    <div>
                        <div className='InfluencerPaymentRequest_details' style={{ marginTop: 10 }}>
                            <div>
                                <label className='InfluencerPaymentRequest_label'>User Name</label>
                                <div className="input_Container" style={{ marginTop: 2 }}>
                                    <input
                                        type="text"
                                        placeholder="User Name"
                                        readOnly
                                        value={influencer_detail.state?.userName}
                                    />
                                </div>
                                <label className='InfluencerPaymentRequest_label'>Email</label>
                                <div className="input_Container" style={{ marginTop: 2 }}>
                                    <input
                                        type="text"
                                        placeholder="Email"
                                        readOnly
                                        value={influencer_detail.state?.email}
                                    />
                                </div>
                                <label className='InfluencerPaymentRequest_label'>Enter Your Amount</label>
                                <div className="input_Container" style={{ marginTop: 2 }}>
                                    <input
                                        type="number"
                                        placeholder="Enter Your Amount which You want to withdraw"
                                        value={amount}
                                        onChange={(e) => {
                                            if (e.target.value <= parseFloat(influencer_detail.state?.total_earning)) {
                                                setAmount(e.target.value);
                                            } else {
                                                Notify("warning", "Your Amount is Greater than Your Earnings!");
                                            }
                                        }}
                                    />
                                </div>


                            </div>
                            <div>
                                <label className='InfluencerPaymentRequest_label'>Full Name</label>
                                <div className="input_Container" style={{ marginTop: 2 }}>
                                    <input
                                        type="text"
                                        placeholder="Full Name"
                                        readOnly
                                        value={influencer_detail.state?.fullName}
                                    />
                                </div>
                                <label className='InfluencerPaymentRequest_label'>Phone Number</label>
                                <div className="input_Container" style={{ marginTop: 2 }}>
                                    <input
                                        type="text"
                                        placeholder="Phone"
                                        readOnly
                                        value={influencer_detail.state?.phoneNumber}
                                    />
                                </div>


                            </div>
                        </div>
                        <div style={{ marginTop: 10 }}>
                            <FormControl>
                                <p className='select_accountType'>Select Your Account Type</p>
                                {/* <FormLabel id="demo-controlled-radio-buttons-group">Select Your Account Type</FormLabel> */}
                                <RadioGroup
                                    className='radios'
                                    value={accountType}
                                    onChange={handleChange}
                                >
                                    <FormControlLabel value="EasyPaisa" control={<Radio />} label="EasyPaisa" />
                                    <FormControlLabel value="JazzCash" control={<Radio />} label="JazzCash" />
                                    <FormControlLabel value="Account" control={<Radio />} label="Account" />
                                    <FormControlLabel value="MasterCard" control={<Radio />} label="MasterCard" />
                                </RadioGroup>
                            </FormControl>
                        </div>
                        {
                            accountType ? (
                                <div className='InfluencerPaymentRequest_details'>
                                    {
                                        accountType === "Account" ? (
                                            <>
                                                <div>
                                                    <label className='InfluencerPaymentRequest_label'>Bank Name</label>

                                                    <div className="input_Container" style={{ marginTop: 2 }}>
                                                        <input
                                                            type="text"
                                                            placeholder={`Enter Your Bank Name`}
                                                            value={bankName}
                                                            onChange={(e) => setBankName(e.target.value)}

                                                        />
                                                    </div>
                                                </div>
                                                <div>
                                                    <label className='InfluencerPaymentRequest_label'>Account Name</label>
                                                    <div className="input_Container" style={{ marginTop: 2 }}>
                                                        <input
                                                            type="text"
                                                            placeholder={`Enter Your Account Name`}
                                                            value={accountName}
                                                            onChange={(e) => setAccountName(e.target.value)}

                                                        />
                                                    </div>
                                                </div>
                                            </>
                                        ) : ""
                                    }
                                    <div>
                                        <label className='InfluencerPaymentRequest_label'>Account Type : ({accountType})</label>

                                        <div className="input_Container" style={{ marginTop: 2 }}>
                                            <input
                                                type="number"
                                                placeholder={`Enter Your (${accountType === "Account" ? `${accountType} OR IBAN` : accountType}) Number`}
                                                value={accountNumber}
                                                onChange={(e) => setAccountNumber(e.target.value)}

                                            />
                                        </div>
                                    </div>
                                </div>
                            ) : null
                        }
                        <div>
                            <div className='payment_request_btn'>
                                <Button onClick={submit_payment_request} style={{ fontSize: 12, backgroundColor: "#05a081", marginTop: 10 }} size='medium' variant="contained">
                                    Create Payment Request
                                </Button>
                            </div>
                        </div>
                    </div>
                ) : (
                    <div style={{ display: "flex", justifyContent: "center" }}>
                        <Button onClick={() => navigate(`/`)} style={{ fontSize: 12, backgroundColor: "#05a081", }} size='medium' variant="contained">
                            Go Back
                        </Button>
                    </div>
                )
            }

        </div>
    )
}

export default InfluencerPaymentRequest;