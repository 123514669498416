import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { fetchBrandOffers } from '../../Redux/Reducers/InfluencersRequests';
import { Box, Button, Chip, Tab, Tabs } from '@mui/material';
import { ImNotification } from 'react-icons/im';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';
const BrandOffers = ({ CURRENT_USER_PROFILE }) => {
    const brandoffers = useSelector((state) => state.influencerRequest.brandoffers);
    const dispatch = useDispatch();
    const [value, setValue] = useState(0);



    const handleChange = (event, newValue) => {
        setValue(newValue);
    };


    // Approved Requests
    const approved = brandoffers && brandoffers.filter((val) => {
        return val.isApproved;
    })
    // Denied Requests
    const denied = brandoffers && brandoffers.filter((val) => {
        return val.isDenied;
    })

    useEffect(() => {
        if (CURRENT_USER_PROFILE) {
            dispatch(fetchBrandOffers(CURRENT_USER_PROFILE.uid));
        }
    }, [CURRENT_USER_PROFILE])

    return (
        <div>
            <Box sx={{ bgcolor: '#05a081', display: "flex", justifyContent: "center" }}>
                <Tabs
                    variant="scrollable"
                    scrollButtons="auto"
                    // TabIndicatorProps={{style: {background: '#05a081'}}}
                    value={value} onChange={handleChange}>
                    <Tab className='tabText' label={`All Requests (${brandoffers && brandoffers.length})`} />
                    <Tab className='tabText' label={`Approved (${approved && approved.length})`} />
                    <Tab className='tabText' label={`Denied (${denied && denied.length})`} />
                </Tabs>
            </Box>
            {value === 0 && <AllOffers brandoffers={brandoffers} />}
            {value === 1 && <ApprovedOffers approved={approved} />}
            {value === 2 && <DeniedOffers denied={denied} />}
        </div>
    )
}


const AllOffers = ({ brandoffers }) => {
    const navigate = useNavigate();

    const createCustomOffer = (data) => {
        navigate(`/brand-request-checkout/${data._id}`, { state: data });
    }

    return (
        brandoffers && brandoffers.length > 0 ? brandoffers.map((v, index) => {
            return (
                <div key={index} className='brand_page'>
                    {/* ClassName check if status approved then approved class if status denied then Denied Class else Offer status class */}

                    <div style={{ display: 'flex', justifyContent: "space-between" }}>
                        <Chip style={{ fontSize: 10, color: "black" }} label={`Date: ${dayjs(v?.createdAt).format("DD-MM-YYYY")}`} color="primary" variant="outlined" />
                        <Chip style={{ fontSize: 10, color: `${v?.isApproved ? "green" : v?.isDenied ? "red" : ""}` }} label={`${v?.isApproved ? "Request Approved" : v?.isDenied ? "Request Denied" : "New Request"}`} color="warning" variant="outlined" />
                    </div>
                    {/* ClassName check if status approved then approved class if status denied then Denied Class else Offer status class */}
                    <div className='brand_card'>
                        <div className='brand_image_section'>
                            <img src={v?.influencer_profile_image} alt="" />
                        </div>
                        <div className='brand_content_section'>
                            <div>
                                <h2 className='price_content'>PKR: {v?.price}</h2>
                                <h2>{v?.platform}</h2>
                            </div>
                            <p>
                                {v?.summarize}
                            </p>
                        </div>
                        <div className='brand_btn_section'>
                            {
                                v?.isApproved ? (
                                    <>
                                        <Button onClick={() => createCustomOffer(v)} style={{ fontSize: 12, backgroundColor: "#05a081" }} size='medium' variant="contained">
                                            Create Order
                                        </Button>
                                        <Button style={{ fontSize: 12, backgroundColor: "#05a081" }} size='medium' variant="contained">
                                            Chat 💬
                                        </Button>
                                    </>

                                ) : null
                            }

                        </div>
                        {/* {
                            v?.isApproved ? (
                                <div className='brand_btn_section'>
                                    <div className='accept_btn' >Create Order</div>
                                </div>
                            ) : null
                        } */}

                    </div>
                </div>
            )
        }) : <div className='not_exists'>
            <ImNotification size={32} />
            No Record
        </div>

    )
}
const ApprovedOffers = ({ approved }) => {
    const navigate = useNavigate();

    const createCustomOffer = (data) => {
        navigate(`/brand-request-checkout/${data._id}`, { state: data });
    }
    return (
        approved && approved.length > 0 ? approved.map((v, index) => {

            return (
                <div key={index} className='brand_page'>
                    {/* ClassName check if status approved then approved class if status denied then Denied Class else Offer status class */}
                    <div style={{ display: 'flex', justifyContent: "space-between" }}>
                        <Chip style={{ fontSize: 10, color: "black" }} label={`Date: ${dayjs(v?.createdAt).format("DD-MM-YYYY")}`} color="primary" variant="outlined" />
                        <Chip style={{ fontSize: 10, color: `${v?.isApproved ? "green" : v?.isDenied ? "red" : ""}` }} label={`${v?.isApproved ? "Request Approved" : v?.isDenied ? "Request Denied" : "New Request"}`} color="warning" variant="outlined" />
                    </div>
                    {/* ClassName check if status approved then approved class if status denied then Denied Class else Offer status class */}
                    <div className='brand_card'>
                        <div className='brand_image_section'>
                            <img src={v?.influencer_profile_image} alt="" />
                        </div>
                        <div className='brand_content_section'>
                            <div>
                                <h2 className='price_content'>PKR: {v?.price}</h2>
                                <h2>{v?.platform}</h2>
                            </div>
                            <p>
                                {v?.summarize}
                            </p>
                        </div>
                        <div className='brand_btn_section'>
                            {
                                v?.isApproved ? (
                                    <>
                                        <Button onClick={() => createCustomOffer(v)} style={{ fontSize: 12, backgroundColor: "#05a081" }} size='medium' variant="contained">
                                            Create Order
                                        </Button>
                                        <Button style={{ fontSize: 12, backgroundColor: "#05a081" }} size='medium' variant="contained">
                                            Chat 💬
                                        </Button>
                                    </>

                                ) : null
                            }

                        </div>
                    </div>
                </div>
            )
        }) : <div className='not_exists'>
            <ImNotification size={32} />
            No Record
        </div>
    )
}

const DeniedOffers = ({ denied }) => {
    return (
        denied && denied.length > 0 ? denied.map((v, index) => {
            return (
                <div key={index} className='brand_page'>
                    {/* ClassName check if status approved then approved class if status denied then Denied Class else Offer status class */}
                    <div style={{ display: 'flex', justifyContent: "space-between" }}>
                        <Chip style={{ fontSize: 10, color: "black" }} label={`Date: ${dayjs(v?.createdAt).format("DD-MM-YYYY")}`} color="primary" variant="outlined" />
                        <Chip style={{ fontSize: 10, color: `${v?.isApproved ? "green" : v?.isDenied ? "red" : ""}` }} label={`${v?.isApproved ? "Request Approved" : v?.isDenied ? "Request Denied" : "New Request"}`} color="warning" variant="outlined" />
                    </div>
                    {/* ClassName check if status approved then approved class if status denied then Denied Class else Offer status class */}
                    <div className='brand_card'>
                        <div className='brand_image_section'>
                            <img src={v?.influencer_profile_image} alt="" />
                        </div>
                        <div className='brand_content_section'>
                            <div>
                                <h2 className='price_content'>PKR: {v?.price}</h2>
                                <h2>{v?.platform}</h2>
                            </div>
                            <p>
                                {v?.summarize}
                            </p>
                        </div>
                        {
                            v?.isApproved ? (
                                <div className='brand_btn_section'>
                                    <div className='accept_btn' >Create Order</div>
                                </div>
                            ) : null
                        }
                    </div>
                </div>
            )
        }) : <div className='not_exists'>
            <ImNotification size={32} />
            No Record
        </div>
    )
}

export default BrandOffers;