import React, { useState, useEffect } from 'react'
import ExampleProfile from '../../components/ExampleProfile/ExampleProfile';
import "./BrandProfile.css";
import { useSearchParams, useNavigate } from "react-router-dom";
import BrandStepOne from './BrandStep/BrandStepOne/BrandStepOne';
import BrandStepTwo from './BrandStep/BrandStepTwo/BrandStepTwo';
import BrandStepThree from './BrandStep/BrandStepThree/BrandStepThree';
import BrandStepFour from './BrandStep/BrandStepFour/BrandStepFour';
import BrandStepFive from './BrandStep/BrandStepFive/BrandStepFive';
import axios from 'axios';
import BrandStepSix from './BrandStep/BrandStepSix/BrandStepSix';
import { Notify } from '../../components/Notify';
import Loading from '../../components/Loading/Loading';
import Profile from "../../images/profile.webp";
import { useDispatch, useSelector } from 'react-redux';
import { BASE_URL, NUM_CHECKER } from '../../config/config';
import { currentBrand, currentBrandProfile } from '../../Redux/Reducers/UserReducer';
import Upload from "../../images/upload.png";


const BrandProfile = () => {
  const brand = useSelector((state) => state.User.brand);
  let [step, setStep] = useState(1);
  let [searchParams, setSearchParams] = useSearchParams();
  const [openProfile, setOpenProfile] = useState(false);
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Brand Data
  const [brandCity, setBrandCity] = useState("");
  const [brandSocial, setBrandSocial] = useState({
    brandInsta: "",
    brandInstaFollower: "0-1k",
    brandTiktok: "",
    brandTiktokFollower: "0-1k",
    brandYoutube: "",
    brandYoutubeFollower: "0-1k",
    brandTwitter: "",
    brandTwitterFollower: "0-1k",
    brandFacebook: "",
    brandFacebookFollower: "0-1k",
    brandWebsite: "",
  });
  const [brandDescription, setBrandDescription] = useState("");
  const [niches, setNiches] = useState([]);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [toogle, setToogle] = useState(false);
  const [profileImage, setProfileImage] = useState(Profile);
  // Content Image
  const [contentImage, setContentImage] = useState([]);
  const [coverImage, setCoverImage] = useState(Upload);
  const [ImageOne, setImageOne] = useState(Upload);
  const [ImageTwo, setImageTwo] = useState(Upload);
  const [ImageThree, setImageThree] = useState(Upload);







  const phoneNumberHandler = () => {
    setLoading(true);
    if (!phoneNumber) {
      Notify("error", "Mobile Number is Required!");
      setLoading(false);
    } else {
      setToogle(true);
      setLoading(false);
      //       https://phonevalidation.abstractapi.com/v1/  
      // ? api_key = YOUR_UNIQUE_API_KEY  
      // & phone = 14154582468
      // axios.get(`${NUM_CHECKER}=${`92${phoneNumber}`}`)
      //   .then(response => {
      //     // console.log(response.data);
      //     const verify = response.data.valid;
      //     if (verify) {
      //       Notify("success", "Mobile Number is Valid Thanks!");
      //       setToogle(true);
      //       setLoading(false);
      //       // console.log(response.data)
      //     } else {
      //       setLoading(false);
      //       Notify("error", "Mobile Number is Not Valid!");
      //     }
      //   })
      //   .catch(error => {
      //     setLoading(false);
      //     Notify("error", error.message);
      //   });
    }

  }

  const createBrandProfile = () => {
    setLoading(true);
    const formData = new FormData();
    formData.append("uid", brand.uid);
    formData.append("fullName", brand.fullName);
    formData.append("email", brand.email);
    formData.append("brand", brand.brand);
    formData.append("brandCity", brandCity);
    formData.append("brandDescription", brandDescription);
    formData.append("socialLink", JSON.stringify(brandSocial));
    formData.append("niches", JSON.stringify(niches));
    formData.append("profileImage", profileImage);
    formData.append("phoneNumber", phoneNumber);
    contentImage.map((val) => {
      return formData.append("contentImage", val);
    })



    axios.post(`${BASE_URL}/createBrandProfile`, formData).then((res) => {
      setLoading(false);
      dispatch(currentBrandProfile(res?.data?.data));
      dispatch(currentBrand({
        brand: brand.brand,
        email: brand.email,
        fromWhereHeard: brand.fromWhereHeard,
        fullName: brand.fullName,
        profileCompleted: true,
        role: brand.role,
        uid: brand.uid
      }))
      Notify("success", res?.data?.msg);
      setTimeout(() => {
        navigate(`/brand/${res?.data?.data?.brand}`);
      }, 2000)
    }).catch((error) => {
      Notify("error", error?.response?.data?.msg);
      setLoading(false);
    })
    // createBrandProfile
  }

  const stepBack = () => {
    setStep(step - 1);
    // setContentImage([]);
  }


  // Close on Outside Click
  const closeOutside = () => {
    openProfile && setOpenProfile(false);

  }

  useEffect(() => {
    setSearchParams({ s: step });
  }, [step, setSearchParams]);


  return (
    <div onClick={closeOutside}>

      {step > 0 && (
        <div className={step === 5 ? "stepsContainerOne" : "stepsContainer"} >
          <div className="steps_cont">
            <div className="stepsBackCont">
              {step > 1 && <p onClick={stepBack}>Back</p>}
              <p onClick={() => setOpenProfile(!openProfile)}>View Example Profile</p>
              {/* Profile Modal */}
              <div>
                {
                  openProfile && (
                    <div
                      className="exampleProfile-backdrop"
                    >
                      <div
                        className="exampleProfile-content"
                        onClick={e => {
                          // do not close modal if anything inside modal content is clicked
                          e.stopPropagation();
                        }}
                      >
                        {/* Close and More */}
                        <div className='exampleProfile_modalHeading_Container'>
                          {/* <p className='exampleProfile_modalHeading'>Example Profile</p> */}
                          <p className="exampleProfile_modalClose" onClick={() => setOpenProfile(false)}>+</p>
                        </div>

                        <ExampleProfile />
                      </div>
                    </div>
                  )
                }
              </div>
            </div>
            <div className="stepsCount">
              <p>{step}/6</p>
            </div>
          </div>

        </div>
      )}

      {step === 1 && <BrandStepOne step={step} setStep={setStep} brandCity={brandCity} setBrandCity={setBrandCity} />}
      {step === 2 && <BrandStepTwo step={step} setStep={setStep} brandSocial={brandSocial} setBrandSocial={setBrandSocial} />}
      {step === 3 && <BrandStepThree step={step} setStep={setStep} brandDescription={brandDescription} setBrandDescription={setBrandDescription} />}
      {step === 4 && <BrandStepFour step={step} setStep={setStep} niches={niches} setNiches={setNiches} />}
      {step === 5 && <BrandStepFive
        step={step} setStep={setStep} profileImage={profileImage} setProfileImage={setProfileImage}
        contentImage={contentImage} setContentImage={setContentImage}
        coverImage={coverImage}
        setCoverImage={setCoverImage}
        ImageOne={ImageOne}
        setImageOne={setImageOne}
        ImageTwo={ImageTwo}
        setImageTwo={setImageTwo}
        ImageThree={ImageThree}
        setImageThree={setImageThree}
      />}
      {step === 6 && <BrandStepSix step={step} setStep={setStep} phoneNumber={phoneNumber} setPhoneNumber={setPhoneNumber} toogle={toogle} />}

      {
        step === 6 && (
          <div className="InfluencerProfileContainer">
            <div className="continue_container" >
              {
                loading ? (
                  <button className="continueBtn" ><Loading /></button>
                ) : (
                  toogle ? (
                    loading ? (
                      <button className="continueBtn" ><Loading /></button>
                    ) : (
                      <button className="continueBtn" onClick={createBrandProfile}>Create Your Profile</button>
                    )
                  ) : (
                    <button className="continueBtn" onClick={phoneNumberHandler}>Continue</button>
                  )
                )
              }

            </div>
          </div>
        )
      }


    </div>
  )
}

export default BrandProfile;