import React from "react";
import "./Footer.css";
import { AiFillInstagram, AiFillLinkedin } from "react-icons/ai";
import { IoLogoTiktok, IoLogoFacebook } from "react-icons/io5";
import { BsTwitter } from "react-icons/bs";


const Footer = () => {
  return (
    <div className="footer">
   
      <div className="footer_Container">
        <div className="copyRights">
          <p>© Collabscafe (PVT) LTD.</p>
          <p>Privacy</p>
          <p>Terms</p>
          <p>Sitemap</p>
        </div>
        <div className="social_Links">
          <a href="https://www.instagram.com/collabscafe" target="_blank" rel="noreferrer">
            <AiFillInstagram className="insta_icon" />
          </a>
          <a href="https://www.tiktok.com/@collabscafe?is_from_webapp=1&sender_device=pc" target="_blank" rel="noreferrer">
            <IoLogoTiktok className="tiktok_icon" />
          </a>
          <a href="https://twitter.com/collabscafe" target="_blank" rel="noreferrer">
            <BsTwitter className="twitter_icon" />
          </a>
          <a href="https://www.facebook.com/collabscafe" target="_blank" rel="noreferrer">
            <IoLogoFacebook className="fb_icon" />
          </a>
          <a href="https://www.linkedin.com/company/collabscafe" target="_blank" rel="noreferrer">
            <AiFillLinkedin className="twitter_icon" />
          </a>
        </div>
      </div>
    </div>
  );
};

export default Footer;
