import React, { useEffect, useState } from "react";
import "./StepSeven.css";
import { MdDelete } from "react-icons/md";
import Upload from "../../../../images/upload.png";
import toast from "react-hot-toast";
import Profile from "../../../../images/profile.webp";
import { Notify } from "../../../../components/Notify";
import Loading from "../../../../components/Loading/Loading";
import CropImage from "../../../../components/CropImage/CropImage";


const StepSeven = ({
  step,
  setStep,
  profileImage,
  setProfileImage,
  contentImage,
  setContentImage,
  coverImage,
  setCoverImage,
  ImageOne,
  setImageOne,
  ImageTwo,
  setImageTwo,
  ImageThree,
  setImageThree
}) => {

  const [loading, setLoading] = useState(false);

  const [profileImagePreview, setprofileImagePreview] = useState("");
  // const [ImageOne, setImageOne] = useState(null);
  // const [ImageTwo, setImageTwo] = useState(null);
  // const [ImageThree, setImageThree] = useState(null);
  const [indexOne, setIndexOne] = useState(null);
  const [indexTwo, setIndexTwo] = useState(null);
  const [indexThree, setIndexThree] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [readText, setReadText] = useState("");



  // useEffect(() => {

  // function ImagesPush() {
  //   if (coverImage !== Upload) {
  //     arr.push(coverImage);
  //     // console.log("CoverImage", coverImage);
  //   }
  //   if (ImageOne !== Upload) {
  //     // console.log("ImageOne", ImageOne);
  //     arr.push(ImageOne);
  //   }
  //   if (ImageTwo !== Upload) {
  //     // console.log("ImageTwo", ImageTwo);
  //     arr.push(ImageTwo);
  //   }
  //   if (ImageThree !== Upload) {
  //     // console.log("ImageThree", ImageThree);
  //     arr.push(ImageThree);
  //   }
  // }
  //   //   // contentImage.push(arr);
  // // }, [coverImage || ImageOne || ImageTwo || ImageThree]);
  // console.log("arr", arr)
  // // console.log("contentImage", contentImage);



  // console.log("coverImage", coverImage)
  // console.log("ImageOne", ImageOne)
  // console.log("ImageTwo", ImageTwo)
  // console.log("ImageThree", ImageThree)

  // Crop State

  const imageMimeType = /image\/(png|jpg|jpeg)/i;



  const handleProfile = (event) => {

    let file = event.target.files[0];
    if (!file.type.match(imageMimeType)) {
      Notify("error", "Image should be in PNG | JPG | JPEG Format");
      return;
    }
    // setProfileImage(file);
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {

      setOpenModal(true);
      setProfileImage(reader.result);
      setReadText("profileImage");
    };
  };

  const handleCover = (event, index) => {
    let fileCover = event.target.files[0];
    if (!fileCover.type.match(imageMimeType)) {
      Notify("error", "Image should be in PNG | JPG | JPEG Format");
      return;
    }
    contentImage[index] = fileCover;

    const reader = new FileReader();
    reader.readAsDataURL(fileCover);
    reader.onloadend = () => {
      setOpenModal(true);
      setCoverImage(reader.result);
      setReadText("coverImage");
    };
  };

  const handleImageOne = (event, index) => {
    let fileCover = event.target.files[0];
    if (!fileCover.type.match(imageMimeType)) {
      Notify("error", "Image should be in PNG | JPG | JPEG Format");
      return;
    }
    contentImage[index] = fileCover;
    // contentImage.push(fileCover);
    setIndexOne(fileCover);

    const reader = new FileReader();
    reader.readAsDataURL(fileCover);
    reader.onloadend = () => {
      setOpenModal(true);
      setImageOne(reader.result);
      setReadText("imageOne");
    };
  };

  const handleImageTwo = (event, index) => {
    let fileCover = event.target.files[0];
    if (!fileCover.type.match(imageMimeType)) {
      Notify("error", "Image should be in PNG | JPG | JPEG Format");
      return;
    }
    contentImage[index] = fileCover;
    setIndexTwo(fileCover);

    const reader = new FileReader();
    reader.readAsDataURL(fileCover);
    reader.onloadend = () => {
      setOpenModal(true);
      setImageTwo(reader.result);
      setReadText("imageTwo");
    };
  };

  const handleImageThree = (event, index) => {
    let fileCover = event.target.files[0];
    if (!fileCover.type.match(imageMimeType)) {
      Notify("error", "Image should be in PNG | JPG | JPEG Format");
      return;
    }
    contentImage[index] = fileCover;
    // contentImage.push(fileCover);
    setIndexThree(fileCover);
    const reader = new FileReader();
    reader.readAsDataURL(fileCover);
    reader.onloadend = () => {
      setOpenModal(true);
      setImageThree(reader.result);
      setReadText("imageThree");
    };
  };



  // Delete Image One
  // const deleteImageOne = () => {
  //   setImageOne(Upload);
  //   let deletedItem = contentImage.filter((val) => val.name !== indexOne.name);
  //   setContentImage(deletedItem);
  // }

  // // Delete Image Two
  // const deleteImageTwo = () => {
  //   setImageTwo(Upload);
  //   let deletedItem = contentImage.filter((val) => val.name !== indexTwo.name);
  //   setContentImage(deletedItem);
  // }

  // // Delete Image Three
  // const deleteImageThree = () => {
  //   setImageThree(Upload);
  //   let deletedItem = contentImage.filter((val) => val.name !== indexThree.name);
  //   setContentImage(deletedItem);
  // }

  const profileImagesHandler = () => {
    setLoading(true);
    if (profileImage === Profile) {
      setLoading(false);
      Notify("error", "Select Your Profile Photo!");

    } else if (coverImage === Upload) {
      setLoading(false);
      Notify("error", "Select Your Cover Photo!");

    } else {
      setTimeout(() => {
        setLoading(false);
        setStep(step + 1);
      }, 2000);
    }
  }


  return (
    <div className="stepSeven">
      <h1 className="stepSeven-heading">
        Add up to 5 images of you and your content
      </h1>
      <div className="profileImage_Container">
        <input
          type="file"
          id="actual-btn"
          style={{ display: "none" }}
          accept='.png, .jpg, .jpeg'
          onChange={(event) => handleProfile(event)}
        />
        <label htmlFor="actual-btn">
          <img className="profile_Image" src={profileImage} alt="Profile.png" />
        </label>
      </div>
      {/* ********  check if profile image cover image then crop will work ******** */}
      {
        readText === "profileImage" ?
          openModal && <CropImage openModal={openModal} setOpenModal={setOpenModal} image={profileImage} setImage={setProfileImage} /> :
          openModal && readText === "coverImage" ?
            <CropImage openModal={openModal} setOpenModal={setOpenModal} image={coverImage} setImage={setCoverImage} /> :
            openModal && readText === "imageOne" ?
              <CropImage openModal={openModal} setOpenModal={setOpenModal} image={ImageOne} setImage={setImageOne} /> :
              openModal && readText === "imageTwo" ?
                <CropImage openModal={openModal} setOpenModal={setOpenModal} image={ImageTwo} setImage={setImageTwo} /> :
                openModal && readText === "imageThree" ?
                  <CropImage openModal={openModal} setOpenModal={setOpenModal} image={ImageThree} setImage={setImageThree} /> : null
      }
      {/* {profileImage && <CropImage openModal={openModal} setOpenModal={setOpenModal} image={profileImage} setImage={setProfileImage} />} */}

      {/* Images Container */}
      <div className="images_Container">
        <div className="coverImage_Container">
          <div className="file_area">
            <div className="cover-img-badge">Cover Image</div>
            <input
              type="file"
              id="cover-btn"
              style={{ display: "none" }}
              accept='.png, .jpg, .jpeg'
              onChange={(event) => handleCover(event, 0)}
            />
            <label htmlFor="cover-btn">
              <img className="image image0" src={coverImage} alt="coverImage.png" />
            </label>
          </div>
        </div>
        {/* Cover Image */}
        {/* {coverImage && <CropImage openModal={openModal} setOpenModal={setOpenModal} image={coverImage} setImage={setCoverImage} />} */}

        <div className="Images_Cont">
          <div className='img-row-sq'>
            <div className="image_One">
              <div className="file_area">
                {/* {ImageOne !== Upload && (
                  <div className="img-badge" onClick={deleteImageOne}>
                    <MdDelete />
                  </div>
                )} */}
                <input
                  type="file"
                  id="imageOne"
                  style={{ display: "none" }}
                  accept='.png, .jpg, .jpeg'
                  onChange={(event) => handleImageOne(event, 1)}
                />
                <label htmlFor="imageOne">
                  <img className="image image1" src={ImageOne} alt="ImageOne.png" />
                </label>
              </div>
            </div>
            <div className="image_Two">
              <div className="file_area">
                {/* {ImageTwo !== Upload && (
                  <div className="img-badge" onClick={deleteImageTwo}>
                    <MdDelete />
                  </div>
                )} */}
                <input
                  type="file"
                  id="ImageTwo"
                  style={{ display: "none" }}
                  accept='.png, .jpg, .jpeg'
                  onChange={(event) => handleImageTwo(event, 2)}
                />
                <label htmlFor="ImageTwo">
                  <img className="image image2" src={ImageTwo} alt="ImageTwo.png" />
                </label>
              </div>
            </div>
          </div>
          <div className="image_Three">
            <div className="file_area">
              {/* {ImageThree !== Upload && (
                <div
                  className="img-badge"
                  onClick={deleteImageThree}
                >
                  <MdDelete />
                </div>
              )} */}
              <input
                type="file"
                id="ImageThree"
                style={{ display: "none" }}
                accept='.png, .jpg, .jpeg'
                onChange={(event) => handleImageThree(event, 3)}
              />
              <label htmlFor="ImageThree">
                <img className="image image3" src={ImageThree} alt="ImageThree.png" />
              </label>
            </div>
          </div>
        </div>
      </div>

      {/* signUp Button */}
      <div className="continue_container">
        {
          loading ? (
            <button className="continueBtn" ><Loading /></button>
          ) : (
            <button className="continueBtn" onClick={profileImagesHandler}>Continue</button>
          )
        }
      </div>
    </div>
  );
};

export default StepSeven;


