import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import Social from "./Components/Social";
import { CustomizedButton } from "../../../components/CustomizedButton";
import axios from 'axios';
import { BASE_URL } from "../../../config/config";
import { Notify } from "../../../components/Notify";

const SocialMedia = () => {

  const influencerProfile = useSelector((state) => state.User.influencerProfile);

  const [socialLink, setSocialLink] = useState({
    influencerInsta: "https://www.instagram.com/",
    influencerInstaFollower: "0-1k",
    influencerTiktok: "https://www.tiktok.com/",
    influencerTiktokFollower: "0-1k",
    influencerYoutube: "",
    influencerYoutubeFollower: "0-1k",
    influencerTwitter: "",
    influencerTwitterFollower: "0-1k",
    influencerFacebook: "",
    influencerFacebookFollower: "0-1k",
    influencerWebsite: "",
  });

  const {
    influencerFacebook,
    influencerFacebookFollower,
    influencerInsta,
    influencerInstaFollower,
    influencerTiktok,
    influencerTiktokFollower,
    influencerTwitter,
    influencerTwitterFollower,
    influencerWebsite,
    influencerYoutube,
    influencerYoutubeFollower
  } = influencerProfile.socialLink && influencerProfile.socialLink[0];

  const UpdateSocialLinks = async () => {
    const id = influencerProfile._id;
    const data = {
      influencerInsta: socialLink.influencerInsta ? socialLink.influencerInsta : influencerInsta,
      influencerInstaFollower: socialLink.influencerInstaFollower ? socialLink.influencerInstaFollower : influencerInstaFollower,
      influencerTiktok: socialLink.influencerTiktok ? socialLink.influencerTiktok : influencerTiktok,
      influencerTiktokFollower: socialLink.influencerTiktokFollower ? socialLink.influencerTiktokFollower : influencerTiktokFollower,
      influencerYoutube: socialLink.influencerYoutube ? socialLink.influencerYoutube : influencerYoutube,
      influencerYoutubeFollower: socialLink.influencerYoutubeFollower ? socialLink.influencerYoutubeFollower : influencerYoutubeFollower,
      influencerTwitter: socialLink.influencerTwitter ? socialLink.influencerTwitter : influencerTwitter,
      influencerTwitterFollower: socialLink.influencerTwitterFollower ? socialLink.influencerTwitterFollower : influencerTwitterFollower,
      influencerFacebook: socialLink.influencerFacebook ? socialLink.influencerFacebook : influencerFacebook,
      influencerFacebookFollower: socialLink.influencerFacebookFollower ? socialLink.influencerFacebookFollower : influencerFacebookFollower,
      influencerWebsite: socialLink.influencerWebsite ? socialLink.influencerWebsite : influencerWebsite,
    }
    try {
      const response = await axios.patch(`${BASE_URL}/updateInfluencerProfileSocial/${id}`, data);
      if (response) {
        Notify("success", "Changes Saved!");
        setTimeout(() => {
          window.location.reload();
        }, 1000)
      }


    } catch (error) {
      Notify("error", error.message);
    }
  }



  return (
    <div className='socialMedia'>
      <Social socialLink={socialLink}
        setSocialLink={setSocialLink}
        influFb={influencerFacebook}
        influFbFollower={influencerFacebookFollower}
        influInsta={influencerInsta}
        influInstaFollower={influencerInstaFollower}
        influTiktok={influencerTiktok}
        influTiktokFollower={influencerTiktokFollower}
        influTwitter={influencerTwitter}
        influTwitterFollower={influencerTwitterFollower}
        influWebsite={influencerWebsite}
        influYoutube={influencerYoutube}
        influYoutubeFollower={influencerYoutubeFollower}
      />
      <div className='saveChangesBtn'>
        <CustomizedButton onClick={UpdateSocialLinks} className='saveChanges'>Save</CustomizedButton>
      </div>
    </div>
  )
}

export default SocialMedia;