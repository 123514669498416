import { configureStore } from '@reduxjs/toolkit';
import userSlice from "./Reducers/UserReducer";
// import InfluencerprofileSlice from "./Reducers/InfluencerProfileReducer";
import influencerProfileReducer from './Reducers/fetchInfluencerProfile'
import brandProfileReducer from './Reducers/fetchBrandProfile'
import influencerRequestReducer from './Reducers/InfluencersRequests';
import brandFavoritesReducer from './Reducers/BrandFavoritesReducer';
import SearchBoxReducer from './Reducers/SearchBoxReducer';
import notification_Slice from './Reducers/MessageNotification';
import ordersReducer from './Reducers/Orders';
import ChatReducer from './Reducers/ChatReducer';

export const store = configureStore({
  reducer: {
    "searchBox": SearchBoxReducer,
    "User": userSlice,
    "influencers": influencerProfileReducer,
    "brand_Profile": brandProfileReducer,
    "influencerRequest": influencerRequestReducer,
    "brand_Favorites": brandFavoritesReducer,
    "Notification" : notification_Slice,
    "orders": ordersReducer,
    "Chat": ChatReducer,
  },
})





// const todoListSlice = createSlice({
//   name: "influencers",
// INITIAL_STATE = {
//   loading: false,
//   influencerProfiles : [],
//   error: ""
// }
//   extraReducers: {
//     [getInfluencerProfile.fulfilled]: (state, action) => {       
//       state.influencerProfiles = action.payload;
//       state.loading = false;
//     },
//     [getInfluencerProfile.pending]: (state) => {
//       state.loading = true;
//     },
//     [getInfluencerProfile.rejected]: (state, action) => {
//       state.influencerProfiles = [];
//       state.error = action.error.message
//     }
//   }
// });

// export default todoListSlice.reducer;