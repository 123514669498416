import React, { useState } from 'react'
import { BiDownArrowAlt } from 'react-icons/bi';
// import { citiesData } from "../../pages/CreatePage/Steps/StepOne/citiesData";

const CountryFiltered = ({ citiesdata, setCitiesData, openCountry, setOpenCountry, country, setCountry }) => {

    const [changeCountry, setChangedCountry] = useState("");

    const handleCountry = () => {
        setCountry(changeCountry);
        setOpenCountry(false);
    }

    return (
        <div>
            <p className='filtersBtn' onClick={() => setOpenCountry(!openCountry)}>City <BiDownArrowAlt className='downArrow' /></p>
            {openCountry && (
                <div onClick={e => {
                    // do not close modal if anything inside modal content is clicked
                    e.stopPropagation();
                }}
                    className='countryOptions'>
                    <select
                        className="fromWhereYouHeard"
                        onChange={(e) => setChangedCountry(e.target.value)}
                    >
                        <option value="" disabled="" hidden="">
                            {country ? country : "All"}
                        </option>
                        {citiesdata.map((option, i) => {
                            return (
                                <option key={i} value={`${option.ProvinceName}, ${option.CityName}`} >{`${option.ProvinceName}, ${option.CityName}`}</option>
                            )
                        })}
                    </select>

                    {/* Clear and Save Button */}
                    <div className='clear_Save_Cont'>
                        {/* <p className='clear'>Clear</p> */}
                        <p className='save' onClick={handleCountry}>Save</p>
                    </div>
                </div>
            )}
        </div>
    )
}

export default CountryFiltered