import React, { useState } from 'react';
// import "./Items.css";
import { CustomizedButton } from "../../../components/CustomizedButton";
import { useDispatch, useSelector } from 'react-redux';
import { citiesData } from "../../CreatePage/Steps/StepOne/citiesData";
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import axios from 'axios';
import { BASE_URL } from "../../../config/config";
import { Notify } from "../../../components/Notify";
import { fetchBrandProfile } from '../../../Redux/Reducers/fetchBrandProfile';
import { currentBrandProfile } from '../../../Redux/Reducers/UserReducer';


const Detail = () => {
    const brand_Profile = useSelector((state) => state.brand_Profile.brand_Profile);
    const profileData = brand_Profile;
    const [cities, setCities] = useState(citiesData);
    const dispatch = useDispatch();

    console.log("profileData", profileData);
    const [formData, setFormData] = useState({
        fullName: "",
        location: "",
        title: "",
        description: "",
        gender: ""
    })


    const handleCity = (value) => {
        if (!value) return;
        const getCityData = cities.find((val) => val.CityName === value);
        setFormData({ ...formData, location: `${getCityData.ProvinceName}, ${getCityData.CityName}` })
        // setInfluencerCity(`${getCityData.ProvinceName}, ${getCityData.CityName}`);
    }


    const UpdateDetails = async () => {
        // console.log("formData===>>>>", formData);
        const id = profileData._id;
        const data = {
            fullName: formData.fullName ? formData.fullName : profileData.fullName,
            location: formData.location ? formData.location : profileData.brandCity,
            description: formData.description ? formData.description : profileData.brandDescription,
        }
        try {
            const response = await axios.patch(`${BASE_URL}/updatebrandProfileDetail/${id}`, data);
            if (response) {
                dispatch(fetchBrandProfile(profileData?.uid)).then((res) => {
                    if (res.type === "brand_Profile/fetchBrandProfile/fulfilled") {
                        Notify("success", "Changes Saved!");
                        dispatch(currentBrandProfile(res?.payload));
                    }
                })
            }
        } catch (error) {
            Notify("error", error.message);
        }
    }


    return (
        <div className='Detail'>
            <div className="updateDetailInput">
                <label className='labelText'>Display Name</label>
                <input type="text" placeholder="Full Name" name="fullName"
                    defaultValue={profileData && profileData.fullName}
                    onChange={(event) => setFormData({ ...formData, fullName: event.target.value })}
                />
            </div>
            <div className="updateDetailInput">
                <label className='labelText'>Location</label>
                <Autocomplete
                    className='cityInput'
                    size='small'
                    // id="country-select-demo"
                    sx={{ width: "102%", fontSize: "14px" }}
                    options={cities}

                    // autoHighlight
                    getOptionLabel={(option) => option.CityName}
                    onInputChange={(event, value) => {
                        handleCity(value)
                    }}
                    renderOption={(props, option) => (
                        <Box component="li" {...props}>
                            <p className='citiesPara'>{option.ProvinceName}, <span className='cityName'>{option.CityName}</span></p>
                        </Box>
                    )}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            // placeholder={"Select Your City"}
                            placeholder={profileData && profileData.brandCity}
                            style={{ fontSize: "14px" }}
                            // className='location_Name'
                            // label="Choose a country"
                            inputProps={{
                                ...params.inputProps,
                                autoComplete: 'new-password', // disable autocomplete and autofill
                            }}
                        />
                    )}
                />

                {/* <input type="text" placeholder="Location" defaultValue={profileData && profileData.influencerCity}/> */}
            </div>
            {/* <div className="updateDetailInput">
                <label className='labelText'>Title</label>
                <input type="text" placeholder="Title" name="title"
                // defaultValue={profileData && profileData.influencerTitle}
                //     onChange={(event) => setFormData({ ...formData, title: event.target.value })}
                />
            </div> */}
            <div className="updateDetailInput">
                <label className='labelText'>Description</label>
                <textarea className='update-Description' name="description" placeholder='Your Description'
                    defaultValue={profileData && profileData.brandDescription}
                    onChange={(event) => setFormData({ ...formData, description: event.target.value })}
                ></textarea>
            </div>

            <div className='saveChangesBtn'>
                <CustomizedButton
                    disabled={!profileData} onClick={UpdateDetails}
                    className='saveChanges'>Save</CustomizedButton>
            </div>
        </div>
    )
}

export default Detail;