import React from "react";
import "./Categories.css";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

import Beauty from "../../images/beauty.jpg";
import Cooking from "../../images/cooking.jpg";
import Education from "../../images/education.jpg";
import Entertainment from "../../images/Entertainment.jpg";
import Gaming from "../../images/gaming.jpg";
import Fitness from "../../images/fitness.jpg";
import Fashion from "../../images/fashion.jpg";
import Music from "../../images/music.jpg";
import Travel from "../../images/travel.jpg";



const Categories = () => {
  var settings = {
    autoplay: true,
    speed: 13000,
    cssEase: "linear",
    slidesToShow: 4,
    slidesToScroll: 4,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      }
    ]
  };
  return (
    <div className="categories">
      <h1 className="categories_heading">Categories</h1>
      {/* ******* Image Card ****** */}
      <div className="categories_Card_Container">
        <Slider {...settings}>


          <div className="categories_Card_Cont">
            <div className="categories_Card">
              <div
                className="categories_Card_Bg"
              >
                <img style={{ width: "100%", height: "100%" }} src={Beauty} />
              </div>
            </div>
          </div>

          <div className="categories_Card_Cont">
            <div className="categories_Card">
              <div
                className="categories_Card_Bg"
              >
                <img style={{ width: "100%", height: "100%" }} src={Cooking} />
              </div>
            </div>
          </div>

          <div className="categories_Card_Cont">
            <div className="categories_Card">
              <div
                className="categories_Card_Bg"
              >
                <img style={{ width: "100%", height: "100%" }} src={Education} />
              </div>
            </div>
          </div>

          <div className="categories_Card_Cont">
            <div className="categories_Card">
              <div
                className="categories_Card_Bg"
              >
                <img style={{ width: "100%", height: "100%" }} src={Entertainment} />
              </div>
            </div>
          </div>

          <div className="categories_Card_Cont">
            <div className="categories_Card">
              <div
                className="categories_Card_Bg"
              >
                <img style={{ width: "100%", height: "100%" }} src={Gaming} />
              </div>
            </div>
          </div>

          <div className="categories_Card_Cont">
            <div className="categories_Card">
              <div
                className="categories_Card_Bg"
              >
                <img style={{ width: "100%", height: "100%" }} src={Fitness} />
              </div>
            </div>
          </div>

          <div className="categories_Card_Cont">
            <div className="categories_Card">
              <div
                className="categories_Card_Bg"
              >
                <img style={{ width: "100%", height: "100%" }} src={Fashion} />
              </div>
            </div>
          </div>

          <div className="categories_Card_Cont">
            <div className="categories_Card">
              <div
                className="categories_Card_Bg"
              >
                <img style={{ width: "100%", height: "100%" }} src={Travel} />
              </div>
            </div>
          </div>

          <div className="categories_Card_Cont">
            <div className="categories_Card">
              <div
                className="categories_Card_Bg"
              >
                <img style={{ width: "100%", height: "100%" }} src={Music} />
              </div>
            </div>
          </div>





        </Slider>
      </div>
    </div>
  );
};

export default Categories;
