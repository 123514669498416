import React, { useState, useEffect, useMemo, useRef } from 'react'
import "./PaymentSuccess.css";
import { AiOutlineCheck } from 'react-icons/ai';
import { Backdrop, Chip } from '@mui/material';
import dayjs from 'dayjs';
import axios from 'axios';
import { BASE_URL } from '../../config/config';
import CollabsCafeLogo from "../../images/logoSidebar.png";
import HashLoader from '../../components/HashLoader';
import { useNavigate } from 'react-router-dom';
import { Notify } from '../../components/Notify';


const PaymentSuccess = ({ brandProfile }) => {
  const queryParams = new URLSearchParams(window.location.search);
  const err_code = queryParams.get("err_code");
  const err_msg = queryParams.get("err_msg");
  const transaction_id = queryParams.get("transaction_id");
  const basket_id = queryParams.get("basket_id");
  const order_date = queryParams.get("order_date");
  const Rdv_Message_Key = queryParams.get("Rdv_Message_Key");
  const Response_Key = queryParams.get("Response_Key");
  const PaymentType = queryParams.get("PaymentType");
  const PaymentName = queryParams.get("PaymentName");
  const validation_hash = queryParams.get("validation_hash");
  const transaction_amount = queryParams.get("transaction_amount");
  const merchant_amount = queryParams.get("merchant_amount");
  const discounted_amount = queryParams.get("discounted_amount");
  const issuer_name = queryParams.get("issuer_name");
  const transaction_currency = queryParams.get("transaction_currency");
  const BillNumber = queryParams.get("BillNumber");
  const CustomerId = queryParams.get("CustomerId");
  const Recurring_txn = queryParams.get("Recurring_txn");

  const [loading, setLoading] = useState(true);
  const [run, setRun] = useState(null);
  const [orderDetail, setOrderDetail] = useState({});
  const navigate = useNavigate();
  const execRun = useRef(false);

  // console.log("brandProfile", brandProfile);

  const orderDataHandle = async () => {
    let orderData = window.localStorage.getItem("orderData");
    console.log("orderData", orderData)
    let customOrder = window.localStorage.getItem("brandCustomOrder");
    // let item = JSON.parse(orderData) || JSON.parse(customOrder);
    let item = JSON.parse(orderData);
    console.log("orderData item", item)
    if (item) {
      item.is_transaction_completed = true;
      item.payment_status = err_msg;
      item.payment_code = err_code;
      item.Response_Key = Response_Key;
      item.transaction_id = transaction_id;
      item.basket_id = basket_id;
      item.PaymentName = PaymentName;
      item.issuer_name = issuer_name;
      item.transaction_currency = transaction_currency;
      item.transaction_amount = transaction_amount;

    } else {
      setTimeout(() => {
        setLoading(false);
      }, 2000);
    }
    if (item) {
      await axios.post(`${BASE_URL}/create-order`, item).then((res) => {
        console.log("orderData res", res)
        setTimeout(() => {
          setLoading(false);
          setOrderDetail(item);
          Notify("success", "Your Order is Successfully Created!")
          window.localStorage.removeItem("orderData");
          window.localStorage.removeItem("brandCustomOrder");
        }, 2000)
        // alert("Order Has Been Created");
        return;
      })
    }
  }

  console.log("orderDetail", orderDetail);

  // useEffect(() => {
  //   if (execRun.current === false) {
  //     console.log("RUNNING USEEFFECT")
  //     orderDataHandle();
  //     return () => {
  //       execRun.current = true;
  //     }
  //   }
  // }, []);

  

  let data = {
    is_transaction_completed: true,
    payment_status: err_msg,
    payment_code: err_code,
    Response_Key: Response_Key,
    transaction_id: transaction_id,
    PaymentName: PaymentName,
    issuer_name: issuer_name,
    transaction_currency: transaction_currency,
    transaction_amount: transaction_amount,
  }

  const orderHandler = async () => {
    axios.patch(`${BASE_URL}/update-order/${basket_id}`, data).then((res) => {
      setLoading(false);
      setOrderDetail(res?.data?.data);
      Notify("success", "Your Order is Successfully Created!")
    })
  }


  const fetchOrderDetail = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/get-one-order/${basket_id}`);
      let res = await response?.data;

      if (res) {
        data.create_order_email = res?.data.create_order_email;
        data.create_order_id = res?.data.create_order_id;
        data.recieve_order_id = res?.data.recieve_order_id;
        data.recieve_order_email = res?.data.recieve_order_email;
        data.order_package_price = res?.data.order_package_price;
        data.create_order_name = res?.data.create_order_name;
        orderHandler();
      }
    } catch (error) {
      console.log("ERROR", error);
    }
  }


  // var ranonce = false;
  // useEffect(() => {
  //   if (!ranonce) {
  //     fetchOrderDetail();
  //     console.log("ZIA KHAN")
  //     //Run you code

  //     ranonce = true
  //   }
  // }, [basket_id])


  useEffect(() => {
    if (run !== null) {
      fetchOrderDetail();
    }
    setRun(true);
  }, [run])


  return (
    <div className="card">
      <Chip style={{ borderRadius: '3px' }} label={`Order Date: (${dayjs(order_date).format("YYYY-MM-DD")})`} size="small" />
      <div className='card_logo_container'>
        <AiOutlineCheck className="card_success_icon" />
      </div>
      <h1 className='card_err_msg'>{err_msg ? err_msg : "Success"}</h1>
      {
        orderDetail ? (
          <div className='card_detail'>
            <div className='card_payment'>
              <h1 className='card_payment_detail'>Order Detail </h1>
              <p>Influencer Email : <span>{orderDetail?.recieve_order_email}</span></p>
              <p>Brand Email : <span>{orderDetail?.create_order_email}</span></p>
              <p>Order Content Type : <span>{orderDetail?.order_content_type}</span></p>
              <p>Order Description : <span>{orderDetail?.order_package_description}</span></p>
              {/* <p>RDV : {Rdv_Message_Key}</p> */}
              <p>Order Package : <span>{orderDetail?.order_package_offer}</span></p>
              <p>Order Amount : <span>{orderDetail?.transaction_amount}</span></p>
              <p>Order Requirements : <span>{orderDetail?.order_requirments}</span></p>
            </div>
            <div className='card_payment'>
              <h1 className='card_payment_detail'>Payment Detail </h1>
              <p>Payment Code : <span>{err_code ? err_code : "N/A"}</span></p>
              <p>Payment Status : <span>{err_msg ? err_msg : "Success"}</span></p>
              <p>Transaction ID : <span>{transaction_id ? transaction_id : "N/A"}</span></p>
              <p>Order Date : <span>{order_date ? order_date : "N/A"}</span></p>
              {/* <p>RDV : {Rdv_Message_Key}</p> */}
              <p>Payment Name : <span>{PaymentName ? PaymentName : "N/A"}</span></p>
              <p>Transaction Amount : <span>{transaction_amount ? transaction_amount : "N/A"}</span></p>
              <p>Bank Name : <span>{issuer_name ? issuer_name : "N/A"}</span></p>
              <p>Currency : <span>{transaction_currency ? transaction_currency : "N/A"}</span></p>
            </div>
          </div>
        ) : (
          <HashLoader />
        )
      }

      <div className="continue_container">
        <button className="continueBtn" onClick={() => navigate("/")}>
          Back to Home
        </button>
      </div>

      <Backdrop
        sx={{ color: '#05a081', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      // onClick={handleClose}
      >
        <div style={{ display: "flex", flexDirection: "column" }}>
          <img
            className="header_logo"
            src={CollabsCafeLogo}
            alt="logo.png"
          />
          <HashLoader />
        </div>
        {/* <CircularProgress color="inherit" /> */}
      </Backdrop>

    </div>
  )
}

export default PaymentSuccess;