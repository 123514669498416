import React from 'react';
import "./CollabsCafeGuide.css";
import Money from "../../images/money.png";
import Check from "../../images/check.png";
import Chat from "../../images/chat.png";
import Secure from "../../images/secure.png";


const CollabsCafeGuide = () => {
  return (
    <div className='collabsCafeGuide'>
        <div className="collabsCafe_guide_Container">
        <div className="collabsCafe_guide_Card">
          <img className="collabsCafe_guide_Icon" src={Money} alt="cost.png" />
          <p className="collabsCafe_guide_heading">No Upfront Cost</p>
          <p className="collabsCafe_guide_Desc">
            Search influencers for free. No subscriptions, contracts or hidden
            fees.
          </p>
        </div>
        <div className="collabsCafe_guide_Card">
          <img className="collabsCafe_guide_Icon" src={Check} alt="cost.png" />
          <p className="collabsCafe_guide_heading">Vetted Influencers</p>
          <p className="collabsCafe_guide_Desc">
            Every influencer is vetted by us. Always receive high-quality,
            professional content.{" "}
          </p>
        </div>
        <div className="collabsCafe_guide_Card">
          <img className="collabsCafe_guide_Icon" src={Chat} alt="cost.png" />
          <p className="collabsCafe_guide_heading">Instant Chat</p>
          <p className="collabsCafe_guide_Desc">
            Instantly chat with influencers and stay in touch throughout the
            whole transaction.
          </p>
        </div>
        {/* <div className="collabsCafe_guide_Card">
          <img className="collabsCafe_guide_Icon" src={Secure} alt="cost.png" />
          <p className="collabsCafe_guide_heading">Secure Purchases</p>
          <p className="collabsCafe_guide_Desc">
            Your money is held safely until you approve the influencer’s work.
          </p>
        </div> */}
        
      </div>
    </div>
  )
}

export default CollabsCafeGuide;