import React from "react";
import "./SeenIn.css";
import Buzzfeed from "../../images/buzzfeed.png";
import Bloomberg from "../../images/bloomberg.png";
import Nyt from "../../images/nyt.png";
import Shopify from "../../images/shopify.png";
import Yahoo from "../../images/yahoo.png";

const SeenIn = () => {
  return (
    <div className="seenIn">
      <p className="seenIn_Heading">As seen in</p>
      <div className="seenIn_Images_Container">
        <div>
          <img src={Buzzfeed} className="seenInImages" alt="Buzzfeed.png"/>
        </div>
        <div>
          <img src={Bloomberg} className="seenInImages" alt="Bloomberg.png"/>
        </div>
        <div>
          <img src={Nyt} className="seenInImages" alt="Nyt.png"/>
        </div>
        <div>
          <img src={Yahoo} className="seenInImages" alt="Yahoo.png" />
        </div>
        <div>
          <img src={Shopify} className="seenInImages" alt="Shopify.png"/>
        </div>
      </div>
    </div>
  );
};

export default SeenIn;
