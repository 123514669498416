import React, { useEffect, useState } from "react";
import "./TiktokInfluencer.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { BsFillArrowRightCircleFill, BsFillArrowLeftCircleFill } from "react-icons/bs"
import { useNavigate } from "react-router-dom";
import HashLoader from "../HashLoader";
import numeral from "numeral";


function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <BsFillArrowRightCircleFill className={className}
      style={{ ...style, display: "block", color: "#05a081", width: "25px", height: "30px" }}
      onClick={onClick} />
  );
}
function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <BsFillArrowLeftCircleFill className={className}
      style={{ ...style, display: "block", color: "#05a081", width: "25px", height: "30px" }}
      onClick={onClick} />
  );
}


const TiktokInfluencer = ({ profilesList }) => {
  // var slider2 = {
  //   arrows: true,
  //   autoplay: true,
  //   infinite: true,
  //   speed: 250,
  //   slidesToShow: 5,
  //   slidesToScroll: 1,
  //   autoplaySpeed: 2000,
  //   rows: 1,
  //   initialSlide: 0,
  //   nextArrow: <SampleNextArrow />,
  //   prevArrow: <SamplePrevArrow />,
  //   responsive: [
  //     {
  //       breakpoint: 1024,
  //       settings: {
  //         slidesToShow: 3,
  //         slidesToScroll: 3,
  //         infinite: true,
  //         dots: true
  //       }
  //     },
  //     {
  //       breakpoint: 800,
  //       settings: {
  //         slidesToShow: 3,
  //         slidesToScroll: 1,
  //         initialSlide: 0,
  //         arrows: false,
  //         autoplay: false,
  //         infinite: false
  //       }
  //     },
  //     {
  //       breakpoint: 600,
  //       settings: {
  //         slidesToShow: 3,
  //         slidesToScroll: 1,
  //         initialSlide: 0,
  //         arrows: false,
  //         autoplay: false,
  //         infinite: false,
  //         swipeToSlide: true,
  //       }
  //     },
  //     {
  //       breakpoint: 500,
  //       settings: {
  //         slidesToShow: 2,
  //         // slidesToScroll: 1,
  //         // initialSlide: 0,
  //         arrows: false,
  //         autoplay: false,
  //         infinite: false,
  //         centerPadding: "10px",
  //         swipeToSlide: true,
  //       }
  //     },
  //   ]
  // };


  const [tiktok_profiles, setTiktokProfiles] = useState([]);
  const navigate = useNavigate();



  const cardRedirect = (e) => {
    let name = e.userName.replace(/\s+/g, '');;
    navigate(`/${name}`, { state: e });
  }


  useEffect(() => {
    if (profilesList && profilesList.length > 0) {
      const filterTiktokProfiles = profilesList.filter((elem) => {
        return elem?.packages?.some((ele) => {
          return ele.platform === "Tiktok";
        });
      });
      if (filterTiktokProfiles.length > 0) {
        setTiktokProfiles(filterTiktokProfiles);
      } else {
        setTiktokProfiles([]);
      }

    }
  }, [profilesList]);



  return (
    <div className="influencers">
      <h1 className="influencers_heading">Tiktok</h1>
      <div className="influencers_heading_desc">
        <p className="influencers_heading_desc_One">Hire TikTok influencers</p>
        <p className="influencers_seeAll">See All</p>
      </div>
      {/* ******* Image Card ******* */}
      <div className="influencers_listing-row">
        {
          tiktok_profiles && tiktok_profiles.length > 0 ? (
            tiktok_profiles.slice(0, 6).map((influ) => {
              return (
                <div className="influencers_featured-profile-listing-holder" key={influ._id} onClick={() => cardRedirect(influ)}>
                  <div className="influencers_profile-listing-img-holder">
                    <img
                      loading="lazy"
                      className="influencers_profile-listing-img"
                      src={influ.contentImage[0] && influ.contentImage[0].url}
                      alt="  creator Brad Sousa being photographed"
                    />
                    <div className="influencers_profile-listing-owner-holder">
                      <div className="influencers_profile-listing-owner-info-holder">
                        <div className="influencers_profile-listing-owner-name">
                          {influ.fullName}
                          {/* <img
              className="influencers_verified-badge"
              alt=""
              src="https://d5ik1gor6xydq.cloudfront.net/websiteImages/creatorMarketplace/verifiedBadge.svg"
            /> */}
                        </div>
                        <div className="influencers_profile-listing-owner-location">
                          {influ.influencerCity}
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* ****** platform Price ****** */}
                  <div className="card_platform_price">
                    <p className="platform">{influ.packages[0].platform}</p>
                    <p className="price"><span className="currency">Rs:</span> {numeral(influ.packages[0].packagePrice).format('0,00.00')}</p>
                  </div>
                  {/* ****** Description ***** */}
                  <p className="description">
                    {influ.influencerTitle.slice(0, 90)}{influ.influencerTitle.length > 90 ? <span style={{ color: "black", fontSize: 12 }}>... see More</span> : ""}
                  </p>
                </div>
              )
            })
          ) : (
            <>
              <div style={{ display: "flex", justifyContent: "center", margin: "0 auto" }}>
                <HashLoader />
              </div>
            </>
          )
        }


      </div>
    </div>
  );
};

export default TiktokInfluencer;
