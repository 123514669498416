import React, { useState } from "react";
import "./StepSix.css";
import toast from "react-hot-toast";
import { Notify } from "../../../../components/Notify";
import Loading from "../../../../components/Loading/Loading";

const StepSix = ({ step, setStep, niches, setNiches }) => {

  const [isActive, setIsActive] = useState(false);
  const [loading, setLoading] = useState(false);

  const categoryHandler = (category, i) => {
    // ******* check the category is not Double add *******
    setIsActive(!isActive);
    let check = niches.find((val) => val === category);

    if (niches.includes(category)) {
      // ******* on Double click item will remove from Array *********
      let deleteItem = niches.filter((val) => val !== category);
      setNiches(deleteItem);
      // console.log("DeletedItem==>>", deleteItem);
    }

    if (!check) {
      // ***** 8 categories will be selected. *****
      if (niches.length <= 2) {
        // ***** set Item in item Array *****
        setNiches([...niches, category]);
      } else {
        Notify("error", "You Can select Just 3 categories!");
      }
    }
  };



  const categoryInputHandler = () => {
    setLoading(true);
    if (!niches.length) {
      setLoading(false);
      Notify("error", "Select at least 1 Niche!");
    } else {
      setTimeout(() => {
        setLoading(false);
        setStep(step + 1);
      }, 2000)
    }
  }

  const categories = [
    "Lifestyle",
    "Fashion",
    "Beauty",
    "Health & Fitness",
    "Travel",
    "food & Drink",
    "Model",
    "Comedy & Entertainment",
    "Art & Photography",
    "Music  & Dance",
    "Entrepreneur & business",
    "Family 7 Children",
    "Animals & Pets",
    "Athlete & Sports",
    "Adventure & Outdoors",
    "Celebrity  & Public Figure",
    "Education",
    "Actor",
    "Gaming",
    "Technology",
    "Healthcare",
    "Skilled Trade",
    "Automotive"
  ];
  return (
    <div className="stepSix">
      <div>
        <h1 className="stepSix-heading">
          Select the niches that match your content
        </h1>

        <div className="itemContainer">
          <div className="item_cont">
            {categories.map((category, i) => {
              return (
                <div
                  key={i}
                  className="items"
                  style={{
                    backgroundColor: niches.includes(category) ? "#05a081" : "",
                  }}
                  onClick={() => categoryHandler(category, i)}
                >
                  <p>{category}</p>
                </div>
              );
            })}
          </div>
        </div>

        {/* signUp Button */}
        <div className="continue_container">
          {
            loading ? (
              <button className="continueBtn" ><Loading /></button>
            ) : (
              <button className="continueBtn" onClick={categoryInputHandler}>Continue</button>
            )
          }
        </div>
      </div>
    </div>
  );
};

export default StepSix;
