import React, { useState, useEffect } from 'react'
import "./Order.css";
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { fetchOrders } from '../../Redux/Reducers/Orders';
import { useSelector, useDispatch } from 'react-redux';
import { Notify } from '../../components/Notify';
import Loading from '../../components/Loading/Loading';
import HashLoader from '../../components/HashLoader';
import { Avatar, Box, CircularProgress } from '@mui/material';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import numeral from 'numeral';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputBase from '@mui/material/InputBase';
import Swal from 'sweetalert2';
import axios from 'axios';
import { BASE_URL } from '../../config/config';
import { useNavigate } from 'react-router-dom';



const Orders = ({ CURRENT_USER_PROFILE }) => {
  const ordersReducer = useSelector((state) => state.orders);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [orderList, setOrderList] = useState([]);
  const [openOrder, setOpenOrder] = useState(false);
  const [openOrderData, setOpenOrderData] = useState({});

  const [orderStatus, setOrderStatus] = useState('');
  const navigate = useNavigate();
  // const [open, setOpen] = React.useState(false);

  const dispatch = useDispatch();


  console.log("ordersReducer", ordersReducer)

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };



  const openOrderDetalHandler = (row) => {

    if (row.payment_code === "000" || row.payment_code === "00") {
      setOpenOrder(!openOrder);
      setOpenOrderData(row);
    } else {
      Swal.fire({
        title: row.payment_status,
        text: 'Your Order Has Been Cancel You can Order Again!',
        inputAttributes: {
          autocapitalize: 'off'
        },
        backdrop: true,
        showCancelButton: true,
        confirmButtonText: 'Order Again',
        confirmButtonColor: "#05a081",
        showLoaderOnConfirm: true,
        preConfirm: () => navigate(`/${row.influencer_userName}`),
        allowOutsideClick: () => !Swal.isLoading()
      })
    }

  }

  function getOrderList() {
    dispatch(fetchOrders(CURRENT_USER_PROFILE._id)).then((res) => {
      if (res?.type === "orders/fetchOrders/fulfilled") {
        setOrderList(res?.payload);
      }
    })
  }

  const orderStatusHandler = async (id, order_status) => {
    Swal.fire({
      title: 'Are You Confirm?',
      inputAttributes: {
        autocapitalize: 'off'
      },
      backdrop: true,
      showCancelButton: true,
      confirmButtonText: 'Confirm',
      confirmButtonColor: "#05a081",
      showLoaderOnConfirm: true,
      preConfirm: async () => {
        await axios.patch(`${BASE_URL}/order-Status`, { id, order_status }).then((res) => {
          setTimeout(() => {
            getOrderList();
          }, 1000);
        })
      },
      allowOutsideClick: () => !Swal.isLoading()
    })
  };


  const row_orderStatus = (order_status) => {
    if (order_status === "PENDING") {
      return <Chip style={{ width: 90, fontSize: 10 }} label={`${order_status}`} color="warning" variant="outlined" />
    } else if (order_status === "COMPLETED") {
      return <Chip style={{ width: 90, fontSize: 10 }} label={`${order_status}`} color="success" variant="outlined" />
    } else if (order_status === "DENIED") {
      return <Chip style={{ width: 90, fontSize: 10 }} label={`${order_status}`} color="error" variant="outlined" />
    } else {
      return <Chip style={{ width: 90, fontSize: 10 }} label={`${order_status}`} color="primary" variant="outlined" />
    }
    // row.order_status === "PENDING" ? (
    //   <Chip style={{ width: 90, fontSize: 10 }} label={`${row.order_status}`} color="warning" variant="outlined" />
    // ) : row.order_status === "COMPLETED" ? (
    //   <Chip style={{ width: 90, fontSize: 10 }} label={`${row.order_status}`} color="success" variant="outlined" />
    // ) : row.order_status === "DENIED" ? (
    //   <Chip style={{ width: 90, fontSize: 10 }} label={`${row.order_status}`} color="error" variant="outlined" />
    // ) : <Chip style={{ width: 90, fontSize: 10 }} label={`${row.order_status}`} color="primary" variant="outlined" />
  }



  console.log("event", orderList);


  useEffect(() => {
    if (CURRENT_USER_PROFILE) {
      getOrderList();
    }
  }, [CURRENT_USER_PROFILE])



  



  return (
    <div className='List_header'>
      <div className='list_title'>Orders <span style={{ fontSize: 16 }}>({orderList && orderList.length})</span></div>

      <Paper sx={{ width: '100%', overflow: 'hidden' }}>
        <TableContainer sx={{ maxHeight: 440 }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <TableCell>
                  Order Date
                </TableCell>
                <TableCell>
                  Order Id
                </TableCell>
                <TableCell>
                  Transaction Id
                </TableCell>
                <TableCell>
                  Profile
                </TableCell>
                <TableCell>
                  Name
                </TableCell>
                <TableCell>
                  Email
                </TableCell>
                {/* <TableCell>
                  Content Type
                </TableCell> */}
                {/* <TableCell>
                  Content Description
                </TableCell>
                <TableCell>
                  Package Offer
                </TableCell>
                <TableCell>
                  Order Platform
                </TableCell> */}
                <TableCell>
                  Package Price
                </TableCell>
                <TableCell>
                  Paid Amount
                </TableCell>
                {/* <TableCell>
                  Order Status
                </TableCell> */}
                <TableCell>
                  Order Status
                </TableCell>
                <TableCell>
                  Payment Status
                </TableCell>
                <TableCell>
                  Cancel Order
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {ordersReducer.loading ? (
                <Box sx={{ display: 'flex' }}>
                  <CircularProgress />
                </Box>
              ) : (
                orderList && orderList
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    return (

                      <TableRow hover role="checkbox" tabIndex={-1} key={row._id}>
                        <TableCell onClick={() => openOrderDetalHandler(row)}>
                          {dayjs(row?.createdAt).format("DD-MM-YYYY")}
                        </TableCell>
                        <TableCell onClick={() => openOrderDetalHandler(row)}>
                          {row?.basket_id}
                        </TableCell>
                        <TableCell onClick={() => openOrderDetalHandler(row)}>
                          {row?.transaction_id}
                        </TableCell>
                        <TableCell onClick={() => openOrderDetalHandler(row)}>
                          <Avatar alt="profile Image" src={row?.influencer_profile} />
                        </TableCell>
                        <TableCell onClick={() => openOrderDetalHandler(row)}>
                          {row.recieve_order_name}
                        </TableCell>
                        <TableCell onClick={() => openOrderDetalHandler(row)}>
                          {row.recieve_order_email}
                        </TableCell>
                        {/* <TableCell onClick={() => openOrderDetalHandler(row)}>
                          {row.order_content_type}
                        </TableCell> */}
                        {/* <TableCell onClick={() => openOrderDetalHandler(row)}>
                          {row.order_package_description.slice(0, 50)}
                        </TableCell>
                        <TableCell onClick={() => openOrderDetalHandler(row)}>
                          {row.order_package_offer.slice(0, 50)}
                        </TableCell>
                        <TableCell onClick={() => openOrderDetalHandler(row)}>
                          {row.order_platform}
                        </TableCell> */}
                        <TableCell onClick={() => openOrderDetalHandler(row)}>
                          {row.order_package_price} RS
                        </TableCell>
                        <TableCell onClick={() => openOrderDetalHandler(row)}>
                          {numeral(parseFloat(row.order_package_price) + parseFloat(row.order_package_price * (15 / 100))).format("00.00")} RS
                        </TableCell>
                        <TableCell onClick={() => openOrderDetalHandler(row)}>

                          {
                            row.payment_code === "000" || row.payment_code === "00" ? (
                              row_orderStatus(row.order_status)
                            ) : (
                              <Chip style={{ width: 90, fontSize: 10 }} label={`Cancel`} color="error" variant="outlined" />
                            )
                          }

                        </TableCell>
                        {/* <TableCell>

                          {row.order_status === "CREATED" ? (
                            <Button style={{width: 120, fontSize: 10}} onClick={() => orderStatusHandler("CREATED")} size='small' variant="contained" color="success">
                              Accept Order
                            </Button>
                          ) :  (
                            <Button style={{width: 120, fontSize: 10}} onClick={() => orderStatusHandler("COMPLETED")} size='small' variant="contained" color="success">
                              Completed
                            </Button>
                          ) }

                        </TableCell> */}
                        {/* <TableCell>
                          <Button onClick={() => orderStatusHandler(row.order_status)} size='small' variant="contained" color="error">
                            Order Completed
                          </Button>
                        </TableCell> */}
                        <TableCell>
                          <Chip style={{ width: 90, fontSize: 10 }} label={`${row.payment_status || "Success"}`} color={row.payment_code === "000" || row.payment_code === "00" === "Success" ? "success" : "error"} variant="outlined" />
                        </TableCell>
                        {
                          row.payment_code === "000" || row.payment_code === "00" ? (
                            <TableCell>
                              <Button disabled={row.order_status === "DENIED" || row.order_status === "COMPLETED"} onClick={() => orderStatusHandler(row._id, "DENIED")} style={{ width: 120, fontSize: 10 }} size='small' variant="contained" color="error">
                                Cancel Order
                              </Button>
                            </TableCell>
                          ) : (
                            <TableCell>
                              <Button onClick={() => navigate(`/${row.influencer_userName}`)} style={{ width: 120, fontSize: 10, backgroundColor: "#05a081" }} size='small' variant="contained">
                                Order Again
                              </Button>
                            </TableCell>
                          )
                        }
                      </TableRow>


                    );
                  }))}

            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          // rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={orderList && orderList.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>

      {
        openOrder ? <CustomizedDialogs setOpenOrder={setOpenOrder} openOrderData={openOrderData} orderStatusHandler={orderStatusHandler} /> : null
      }

    </div>
    // <div className='List_header'>
    //   <div className='no_list'>Your orders will be displayed here. To create an order, purchase a package from an influencer.</div>
    //   <div className='explore_influencer' onClick={() => navigate("/influencers")}>Explore Influencer</div>

    // </div>
  )
}


export default Orders;


const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

function CustomizedDialogs({ setOpenOrder, openOrderData, orderStatusHandler }) {
  const [open, setOpen] = React.useState(true);

  console.log("openOrderData", openOrderData)


  const handleClose = () => {
    setOpen(false);
    setOpenOrder(false);
  };

  return (
    <div>

      <BootstrapDialog
        // maxWidth="xl"
        fullWidth={true}
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
          Order Detail ({openOrderData?.recieve_order_name}'s)
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <img style={{ borderRadius: 50 }} width={100} height={100} src={openOrderData?.influencer_profile} />
          </div>
        </DialogContent>
        <DialogContent dividers>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Chip style={{ width: 130, fontSize: 10 }} label={`Order Deadline: ${openOrderData.order_deadline}`} size='small' color="success" variant="outlined" />
            {
              openOrderData.order_status === "PENDING" ? (
                <Chip style={{ width: 90, fontSize: 10 }} label={`${openOrderData.order_status}`} size='small' color="warning" variant="outlined" />
              ) : openOrderData.order_status === "COMPLETED" ? (
                <Chip style={{ width: 90, fontSize: 10 }} label={`${openOrderData.order_status}`} size='small' color="success" variant="outlined" />
              ) : openOrderData.order_status === "DENIED" ? (
                <Chip style={{ width: 90, fontSize: 10 }} label={`${openOrderData.order_status}`} size='small' color="error" variant="outlined" />
              ) : <Chip style={{ width: 90, fontSize: 10 }} label={`${openOrderData.order_status}`} size='small' color="primary" variant="outlined" />
            }

          </div>

          <div className='order_detail'>
            <div>
              <div className='order_detail_field_name'>Order Date: <span className='order_detail_field_detail'>{dayjs(openOrderData?.createdAt).format("DD-MM-YYYY")}</span></div>
              <div className='order_detail_field_name'>Order Time: <span className='order_detail_field_detail'>{dayjs(openOrderData?.createdAt).format("hh-mm-A")}</span></div>
              <div className='order_detail_field_name'>Order Id: <span className='order_detail_field_detail'>{openOrderData?.basket_id}</span></div>
              <div className='order_detail_field_name'>Transaction Id: <span className='order_detail_field_detail'>{openOrderData?.transaction_id}</span></div>
              <div className='order_detail_field_name'>Influencer Name: <span className='order_detail_field_detail'>{openOrderData?.recieve_order_name}</span></div>
              <div className='order_detail_field_name'>Influencer Email: <span className='order_detail_field_detail'>{openOrderData?.recieve_order_email}</span></div>
              <div className='order_detail_field_name'>Order Content Type: <span className='order_detail_field_detail'>{openOrderData?.order_content_type}</span></div>
              <div className='order_detail_field_name'>Order Platform: <span className='order_detail_field_detail'>{openOrderData?.order_platform}</span></div>
              <div className='order_detail_field_name'>Order Package Description: <span className='order_detail_field_detail'>{openOrderData?.order_package_description}</span></div>
            </div>
            <div>
              <div className='order_detail_field_name'>Order Requirements: <span className='order_detail_field_detail'>{openOrderData?.order_requirments}</span></div>
              <div className='order_detail_field_name'>Order Package Offer: <span className='order_detail_field_detail'>{openOrderData?.order_package_offer}</span></div>
              <div className='order_detail_field_name'>Order Package Price: <span className='order_detail_field_detail'>{openOrderData?.order_package_price} Rs</span></div>
              <div className='order_detail_field_name'>Order Package Total Price: <span className='order_detail_field_detail'>{openOrderData?.order_package_price_include_tax} Rs</span></div>
            </div>
          </div>
        </DialogContent>

        <DialogContent dividers>
          <div style={{ display: "flex", justifyContent: "end" }}>
            <Button disabled={openOrderData.order_status === "DENIED" || openOrderData.order_status === "COMPLETED"} onClick={() => {
              orderStatusHandler(openOrderData._id, "DENIED")
              setOpen(false);
              setOpenOrder(false);
            }} size='small' variant="contained" color="error">
              Cancel Order
            </Button>
          </div>
        </DialogContent>

      </BootstrapDialog>
    </div>
  );
}