import React, { useState } from 'react';
import "./BrandCreatedProfile.css";
import { Helmet } from 'react-helmet';
import { AiFillInstagram } from 'react-icons/ai';
import { BiEdit } from 'react-icons/bi';
import { BsLink45Deg, BsTwitter, BsYoutube } from 'react-icons/bs';
import { IoLogoFacebook, IoLogoTiktok } from 'react-icons/io5';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import HashLoader from '../../components/HashLoader';
import ProfileImageModal from '../UserProfile/ProfileImageModal';
import ProfileModal from '../../components/ProfileImageModal/ProfileImageModal';
import ProfileGalleyImages from '../../components/ProfileGalleyImages/ProfileGalleyImages';


const BrandCreatedProfile = () => {
  const brandProfile = useSelector((state) => state.User.brandProfile);
  const [openFirstImage, setopenFirstImage] = useState(false);
  const [openProfile, setOpenProfile] = useState(false);

  const [modalImage, setmodalImage] = useState(null);
  const [openGalleryImages, setopenGalleryImages] = useState(false);


  const modals = (e) => {
    setopenGalleryImages(!openGalleryImages)
  }

  return (
    <>
      <Helmet>
        <title>Collabscafe | Brand Profile ({brandProfile ? brandProfile?.brand : "Loading..."})</title>
      </Helmet>
      {
        brandProfile ? (
          <div className="userProfile">
            {/* {user && user.email === influencerProfile.email &&  */}

            <div className="editBrandProfile_Contaier">
              <BiEdit className="editBrandProfile" />
              <Link className="editBrandProfile_link" to="/edit-brand-profile">
                <span>Edit Profile</span>
              </Link>
            </div>
            {/* } */}
            {/* Images Section */}
            <div className={`${!openFirstImage && "grid"} images_Container`}>
              {
                brandProfile?.contentImage?.map((elem, index) => {
                  return (
                    <>
                      <div key={index} style={{ backgroundImage: `url(${elem.url})` }}
                        onClick={() => modals(brandProfile?.contentImage)}
                      >
                      </div>

                    </>
                  )
                })
              }

            </div>

            {

              openGalleryImages && <ProfileGalleyImages openGalleryImages={openGalleryImages}
                setopenGalleryImages={setopenGalleryImages}
                images={brandProfile?.contentImage}
              />
            }

            {/* userProfile_Details */}
            <div className="userProfile_Container">
              <div className="userProfile_desc">
                <p className="userProfile_Title">
                  Brand Title
                  {/* {influencerProfile && influencerProfile.influencerTitle} */}
                </p>
                <p className="userProfile_description">
                  {brandProfile?.brandDescription}
                  {/* {influencerProfile &&
                                        influencerProfile.influencerDescription} */}
                </p>
              </div>
              <div className="userProfile_Images_links_Detail">
                <div className="userProfiles_Details">
                  {/* User Profile Image */}
                  <img
                    onClick={() => setOpenProfile(!openProfile)}
                    className="userProfile_influencer_Image"
                    src={brandProfile?.profileImage?.url}
                    // src={"https://res.cloudinary.com/drg7uoyge/image/upload/v1675104474/brandsProfile/pao6emdyhrrsdkvcc7y9.png"}
                    alt="User_Profile.png"
                  />
                  {
                    openProfile && <ProfileModal openProfile={openProfile}
                      setOpenProfile={setOpenProfile}
                      image={brandProfile?.profileImage?.url}
                    />
                  }
                  {/* User Profile Name */}
                  <div className="userProfile_influencer_Name">
                    {brandProfile?.brand}
                    {/* {influencerProfile && influencerProfile.fullName} */}
                  </div>
                  <div className="userProfile_influencer_location">
                    {brandProfile?.brandCity}
                    {/* {influencerProfile && influencerProfile.influencerCity} */}
                  </div>
                  <div className="userProfile_influencer_Social_Icons_Container">
                    {
                      brandProfile?.socialLink[0]?.brandInsta && (
                        <a
                          href={brandProfile?.socialLink[0]?.brandInsta}
                          target="_blank"
                        >
                          <AiFillInstagram className="userProfile_influencer_Social_Icons" />
                        </a>
                      )}
                    {
                      brandProfile?.socialLink[0]?.brandTiktok && (
                        <a
                          href={brandProfile?.socialLink[0]?.brandTiktok}
                          target="_blank"
                        >
                          <IoLogoTiktok className="userProfile_influencer_Social_Icons" />
                        </a>
                      )}
                    {brandProfile?.socialLink[0]?.brandTwitter && (
                      <a
                        href={brandProfile?.socialLink[0]?.brandTwitter}
                        target="_blank"
                      >
                        <BsTwitter className="userProfile_influencer_Social_Icons" />
                      </a>
                    )}
                    {brandProfile?.socialLink[0]?.brandFacebook && (
                      <a
                        href={brandProfile?.socialLink[0]?.brandFacebook}
                        target="_blank"
                      >
                        <IoLogoFacebook className="userProfile_influencer_Social_Icons" />
                      </a>
                    )}
                    {brandProfile?.socialLink[0]?.brandYoutube && (
                      <a
                        href={brandProfile?.socialLink[0]?.brandYoutube}
                        target="_blank"
                      >
                        <BsYoutube className="userProfile_influencer_Social_Icons" />
                      </a>
                    )}
                    {brandProfile?.socialLink[0]?.brandWebsite && (
                      <a
                        href={brandProfile?.socialLink[0]?.brandWebsite}
                        target="_blank"
                      >
                        <BsLink45Deg className="userProfile_influencer_Social_Icons" />
                      </a>
                    )}
                  </div>
                </div>
              </div>
            </div>
            {/* userProfile_Packages */}
            <div className="userProfile_Packages_Container">
              <div className="userProfile_Packages">
                {/* userProfile_Packages_Heading */}
                {/* <div className="userProfile_Packages_heading">
                                    <p>Packages</p>
                                    <select
                                        name="selectPlatform"
                                        value={selectPlatform}
                                        onChange={selectPlatformHandler}
                                        className="userProfile_Packages_Select"
                                    >
                                        <option value="All">All</option>
                                        {influencerProfile &&
                                            influencerProfile.packages.map((plat, i) => {
                                                return (
                                                    <option key={i} value={plat.platform}>
                                                        {plat.platform}
                                                    </option>
                                                );
                                            })}
                                    </select>
                                </div> */}
                {/* userProfile_Packages_Card */}

                {/* {
                                    // if selectPlatform equal to All then all will populate
                                    selectPlatform === "All"
                                        ? influencerProfile &&
                                        influencerProfile.packages.map((plat, i) => {
                                            return (
                                                <div
                                                    className="userProfile_Packages_Card"
                                                    key={i}
                                                    onClick={() => packagesHandler(plat, i)}
                                                >
                                                    <p className="userProfile_Packages_Card_platform">
                                                        {plat.platform}
                                                    </p>
                                                    <div className="userProfile_Packages_Card_Title_Cont">
                                                        <p>{plat.packageOffer}</p>
                                                        <div className="circle_selected_Cont">
                                                            <p>Rs: {plat.packagePrice}</p>

                                                            <div
                                                                className={
                                                                    selected === i
                                                                        ? "circle_selected"
                                                                        : "circle_Not_selected"
                                                                }
                                                            ></div>
                                                        </div>
                                                    </div>
                                                    <div className="userProfile_Packages_Card_desc">
                                                        {plat.packageDescription}
                                                    </div>
                                                </div>
                                            );
                                        })
                                        : // if selectPlatform equal to Platforms then Platforms will populate
                                        influencerProfile &&
                                        influencerProfile.packages
                                            .filter((plat) => plat.platform === selectPlatform)
                                            .map((plat, i) => {
                                                return (
                                                    <div
                                                        className="userProfile_Packages_Card"
                                                        key={i}
                                                        onClick={() => packagesHandler(plat, i)}
                                                    >
                                                        <p className="userProfile_Packages_Card_platform">
                                                            {plat.platform}
                                                        </p>
                                                        <div className="userProfile_Packages_Card_Title_Cont">
                                                            <p>{plat.packageOffer}</p>
                                                            <div className="circle_selected_Cont">
                                                                <p>Rs: {plat.packagePrice}</p>

                                                                <div
                                                                    className={
                                                                        selected === i
                                                                            ? "circle_selected"
                                                                            : "circle_Not_selected"
                                                                    }
                                                                ></div>
                                                            </div>
                                                        </div>
                                                        <div className="userProfile_Packages_Card_desc">
                                                            {plat.packageDescription}
                                                        </div>
                                                    </div>
                                                );
                                            })
                                } */}
              </div>
              {/* <div className="userProfile_Packages_Select_Cont">
                <p className="userProfile_Packages_Select_title">
                  Title
                </p>

                <p className="userProfile_Packages_Select_Offer">
                  abc
                </p>
                <p className="userProfile_Packages_Select_Price">
                  abc
                </p>
                <p className="userProfile_Packages_Select_desc">
                  abc
                </p>
              
                <div className="continue_container">
                  <button className="continueBtn">Continue</button>
                </div>
                <p className="stepOne_label">Send Custom Offer</p>
              </div> */}
              {/* FAQS */}
              {/* <div>
                                <p className="faq">FAQ</p>
                                {influencerProfile &&
                                    influencerProfile.faqs.map((accord, index) => {
                                        return (
                                            <div
                                                key={index}
                                                style={{ padding: "20px 0px 20px 0px" }}
                                            >
                                                <Accourdion
                                                    id={index}
                                                    question={accord.faqsQuestion}
                                                    answer={accord.faqsDescription}
                                                // accourdion={accourdion}
                                                />
                                            </div>
                                        );
                                    })}
                            </div> */}
            </div>
          </div>
        ) : (
          <HashLoader />
        )
      }

    </>
  )
}

export default BrandCreatedProfile;
