import React, { useState, useEffect } from 'react';
import { useSelector } from "react-redux";
import { CustomizedButton } from "../../../components/CustomizedButton";
import { BASE_URL } from "../../../config/config";
import { Notify } from "../../../components/Notify";
import axios from 'axios';

const Faqs = () => {

    const influencerProfile = useSelector((state) => state.User.influencerProfile);
    const [faqs, setFaqs] = useState([]);

    let handleChange = (i, e) => {
        let newFormValues = [...faqs];
        newFormValues[i][e.target.name] = e.target.value;
        setFaqs(newFormValues);
    };

    let addMoreFaqs = () => {
        if (faqs.length < 3) {
            setFaqs([
                ...faqs,
                {
                    faqsQuestion: "",
                    faqsDescription: ""
                },
            ]);
        }
    };

    const removeFaqs = (i) => {
        let newFormValues = [...faqs];
        newFormValues.splice(i, 1);
        if (faqs.length > 1) {
            setFaqs(newFormValues);
        }
    }


    const UpdateFaqs = async () => {
        const id = faqs.length - 1;
        if (!faqs[id].faqsQuestion) {
            Notify("error", "Enter Your FAQ Question!");
        } else if (!faqs[id].faqsDescription) {
            Notify("error", "Enter Your FAQ Description!");
        } else if (faqs[id].faqsDescription.length < 10) {
            Notify("error", "FAQ Description Should be greater then 10 characters!");
        } else {
            const id = influencerProfile._id;
            try {
                const response = await axios.patch(`${BASE_URL}/updateInfluencerFaq/${id}`, faqs);
                if (response) {
                    Notify("success", "Changes Saved!");
                    setTimeout(() => {
                        window.location.reload();
                    }, 1000)
                }

            } catch (error) {
                Notify("error", error.message);
            }

        }

    }


    useEffect(() => {
        let packages = influencerProfile.faqs.map((val) => {
            return {
                faqsQuestion: val.faqsQuestion,
                faqsDescription: val.faqsDescription
            }
        })
        setFaqs(packages)
    }, [influencerProfile])

    return (
        <div className="updateFaqs">
            <h1 className="stepNine-heading">
                Add FAQ's to Answer Questions About You And Your Services
            </h1>

            <div className="faqsCardContainer">
                {faqs.map((element, index) => {
                    return (

                        <div className="faq_card" key={index}>
                            <div className="faq_content">
                                <div className="faq_HeadingContainer">
                                    <p className="faq_heading">FAQ</p>
                                    {
                                        index >= 1 && (
                                            <p
                                                className="deleteFaqs"
                                                onClick={() => removeFaqs(index)}
                                            >
                                                +
                                            </p>
                                        )
                                    }
                                </div>
                                <div>
                                    <input
                                        className="faq_Ques_Input"
                                        type="text"
                                        placeholder="E.g. What brands have you worked with?"
                                        name="faqsQuestion"
                                        defaultValue={element.faqsQuestion || ""}
                                        onChange={(e) => handleChange(index, e)}
                                    />
                                </div>
                                <div>
                                    <textarea
                                        className="faq_Ques_description"
                                        cols={8}
                                        rows={8}
                                        placeholder="I have worked with FashionNova, HiSmile and more."
                                        name="faqsDescription"
                                        defaultValue={element.faqsDescription || ""}
                                        onChange={(e) => handleChange(index, e)}
                                    ></textarea>
                                </div>
                            </div>
                        </div>

                    )
                })}

            </div>

            <div className="addMoreButtonCont">
                <p className="addMoreButton" onClick={() => addMoreFaqs()}>Add More Item</p>
            </div>
            <div className='saveChangesBtn'>
                <CustomizedButton onClick={UpdateFaqs} className='saveChanges'>Save</CustomizedButton>
            </div>

        </div>
    )
}

export default Faqs;