import React, { useState, useEffect } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import "./Steps.css";
import StepEmailOtpVerify from "./StepEmailOtpVerify/StepEmailOtpVerify";
import StepOne from "./StepOne/StepOne";
import StepTwo from "./StepTwo/StepTwo";
import StepThree from "./StepThree/StepThree";
import StepFour from "./StepFour/StepFour";
import StepFive from "./StepFive/StepFive";
import StepSix from "./StepSix/StepSix";
import StepSeven from "./StepSeven/StepSeven";
import StepEight from "./StepEight/StepEight";
import StepNine from "./StepNine/StepNine";
import StepTen from "./StepTen/StepTen";
import Profile from "../../../images/profile.webp";
import Upload from "../../../images/upload.png";
import toast from "react-hot-toast";
import axios from "axios";
import CircularProgress from '@mui/material/CircularProgress';
import { useSelector } from "react-redux";
import { BiDownArrowAlt, BiLoaderCircle } from 'react-icons/bi';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { BASE_URL, NUM_CHECKER } from "../../../config/config";
import { currentInfluencerProfile } from "../../../Redux/Reducers/UserReducer";
import { currentUser } from "../../../Redux/Reducers/UserReducer";
import { useDispatch } from "react-redux";
import { Notify } from "../../../components/Notify";
import Loading from "../../../components/Loading/Loading";
import ExampleProfile from "../../../components/ExampleProfile/ExampleProfile";


const Steps = () => {
  const user = useSelector((state) => state.User.user);
  let [searchParams, setSearchParams] = useSearchParams();
  let [step, setStep] = useState(1);
  const [loader, setLoader] = useState(false);
  const [continueToogle, setContinueToogle] = useState(false);
  const [OpenReminder, setOpenReminder] = useState(false);
  const [openProfile, setOpenProfile] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // console.log(searchParams);

  // ******* Profile Steps State *******
  const [influencerCity, setInfluencerCity] = useState("");
  const [influencerTitle, setInfluencerTitle] = useState("");
  const [influencerDescription, setInfluencerDescription] = useState("");
  const [influencerGender, setInfluencerGender] = useState("");
  // ******* social channels step *******
  const [socialLink, setSocialLink] = useState({
    influencerInsta: "",
    influencerInstaFollower: "0-1k",
    influencerTiktok: "",
    influencerTiktokFollower: "0-1k",
    influencerYoutube: "",
    influencerYoutubeFollower: "0-1k",
    influencerTwitter: "",
    influencerTwitterFollower: "0-1k",
    influencerFacebook: "",
    influencerFacebookFollower: "0-1k",
    influencerWebsite: "",
  });
  // ****** Select Niches ******
  const [niches, setNiches] = useState([]);
  // Images Section
  // Profile Image
  const [profileImage, setProfileImage] = useState(Profile);
  // Content Image
  const [contentImage, setContentImage] = useState([]);
  const [coverImage, setCoverImage] = useState(Upload);
  const [ImageOne, setImageOne] = useState(Upload);
  const [ImageTwo, setImageTwo] = useState(Upload);
  const [ImageThree, setImageThree] = useState(Upload);

  // 

  // Influencer Packages
  const [packagesInputs, setPackagesInput] = useState([
    {
      platform: "",
      contentType: "",
      packageOffer: "",
      packageDescription: "",
      packageDeadline: "2 Days",
      packagePrice: "",
    },
    {
      platform: "",
      contentType: "",
      packageOffer: "",
      packageDescription: "",
      packageDeadline: "2 Days",
      packagePrice: "",
    },
    {
      platform: "",
      contentType: "",
      packageOffer: "",
      packageDescription: "",
      packageDeadline: "2 Days",
      packagePrice: "",
    },

  ]);
  // FAQS 
  const [faqs, setFaqs] = useState([
    {
      faqsQuestion: "",
      faqsDescription: ""
    }
  ])


  // Step 10
  const [phoneNumber, setPhoneNumber] = useState("");
  const [toogle, setToogle] = useState(false);



  const phoneNumberHandler = () => {
    setLoader(true);
    if (!phoneNumber) {
      Notify("error", "Mobile Number is Required!");
      setLoader(false);
    } else {
      setToogle(true);
      setLoader(false);
      // axios.get(`${NUM_CHECKER}=${`92${phoneNumber}`}`)
      //   .then(response => {
      //     // console.log(response.data);
      //     const verify = response.data.valid;
      //     if (verify) {
      //       Notify("success", "Mobile Number is Valid Thanks!");
      //       setToogle(true);
      //       setLoader(false);
      //       // console.log(response.data)
      //     } else {
      //       setLoader(false);
      //       Notify("error", "Mobile Number is Not Valid!");
      //     }

      //   })
      //   .catch(error => {
      //     setLoader(false);
      //     Notify("error", error.message);
      //   });
    }

  }
  // openPopUp
  const openPopUp = (e) => {
    setOpenReminder(true);
  }

  console.log("contentImage===>>>", contentImage)

  const createProfile = () => {
    const formData = new FormData();
    formData.append("uid", user.uid);
    formData.append("userName", user.userName);
    formData.append("fullName", user.fullName);
    formData.append("email", user.email);
    formData.append("approved", false);
    formData.append("influencerCity", influencerCity);
    formData.append("influencerTitle", influencerTitle);
    formData.append("influencerDescription", influencerDescription);
    formData.append("influencerGender", influencerGender);
    formData.append("socialLink", JSON.stringify(socialLink));
    formData.append("niches", JSON.stringify(niches));
    formData.append("profileImage", profileImage);
    formData.append("packagesInputs", JSON.stringify(packagesInputs));
    formData.append("faqs", JSON.stringify(faqs));
    formData.append("phoneNumber", phoneNumber);

    contentImage.map((val) => {
      return formData.append("contentImage", val);
    })

    setContinueToogle(true);

    // const config = {
    //   headers: {
    //     "content-type": "application/json",
    //   },
    // };

    axios.post(`${BASE_URL}/influencerCreateProfile`, formData).then((res) => {
      Notify("success", res.data.message);
      navigate("/influencer-profile-review");
      dispatch(currentInfluencerProfile(res.data.createProfile));
      dispatch(currentUser(
        {
          fullName: user.fullName,
          userName: user.userName,
          email: user.email,
          uid: user.uid,
          verified: user.verified,
          profileCompleted: true,
          role: user.role
        }
      ));
      setContinueToogle(false);
    })
      .catch((error) => {
        setContinueToogle(false);
        Notify("error", "Internal Server Error");
      });
  }



  const stepBack = () => {
    setStep(step - 1);
    // setContentImage([]);
  }


  // Close on Outside Click
  const closeOutside = () => {
    openProfile && setOpenProfile(false);

  }

  useEffect(() => {
    setSearchParams({ s: step });
  }, [step, setSearchParams]);





  return (
    <div onClick={closeOutside}>
      {step > 0 && (
        <div className={step >= 7 && step <= 8 ? "stepsContainerOne" : "stepsContainer"} >
          <div className="steps_cont">
            <div className="stepsBackCont">
              {step > 1 && <p onClick={stepBack}>Back</p>}
              <p onClick={() => setOpenProfile(!openProfile)}>View Example Profile</p>
              {/* Profile Modal */}
              <div>
                {
                  openProfile && (
                    <div
                      className="exampleProfile-backdrop"
                    >
                      <div
                        className="exampleProfile-content"
                        onClick={e => {
                          // do not close modal if anything inside modal content is clicked
                          e.stopPropagation();
                        }}
                      >
                        {/* Close and More */}
                        <div className='exampleProfile_modalHeading_Container'>
                          {/* <p className='exampleProfile_modalHeading'>Example Profile</p> */}
                          <p className="exampleProfile_modalClose" onClick={() => setOpenProfile(false)}>+</p>
                        </div>

                        <ExampleProfile />
                      </div>
                    </div>
                  )
                }
              </div>
            </div>
            <div className="stepsCount">
              <p>{step}/10</p>
            </div>
          </div>

        </div>
      )}
      {/* {step === 0 && <StepEmailOtpVerify step={step} setStep={setStep} />} */}
      {step === 1 && <StepOne step={step} setStep={setStep} influencerCity={influencerCity} setInfluencerCity={setInfluencerCity} />}
      {step === 2 && <StepTwo step={step} setStep={setStep} influencerTitle={influencerTitle} setInfluencerTitle={setInfluencerTitle} />}
      {step === 3 && <StepThree step={step} setStep={setStep} influencerDescription={influencerDescription} setInfluencerDescription={setInfluencerDescription} />}
      {step === 4 && <StepFour step={step} setStep={setStep} influencerGender={influencerGender} setInfluencerGender={setInfluencerGender} />}
      {step === 5 && <StepFive step={step} setStep={setStep} socialLink={socialLink} setSocialLink={setSocialLink} />}
      {step === 6 && <StepSix step={step} setStep={setStep} niches={niches} setNiches={setNiches} />}
      {step === 7 && <StepSeven
        step={step} setStep={setStep} profileImage={profileImage} setProfileImage={setProfileImage}
        contentImage={contentImage} setContentImage={setContentImage}
        coverImage={coverImage}
        setCoverImage={setCoverImage}
        ImageOne={ImageOne}
        setImageOne={setImageOne}
        ImageTwo={ImageTwo}
        setImageTwo={setImageTwo}
        ImageThree={ImageThree}
        setImageThree={setImageThree}
      />}
      {step === 8 && <StepEight step={step} setStep={setStep} packagesInputs={packagesInputs} setPackagesInput={setPackagesInput} />}
      {step === 9 && <StepNine step={step} setStep={setStep} faqs={faqs} setFaqs={setFaqs} />}
      {step === 10 && <StepTen step={step} setStep={setStep} phoneNumber={phoneNumber} setPhoneNumber={setPhoneNumber} toogle={toogle} />}
      {/* signUp Button */}
      {step === 10 && (
        toogle ? (
          <div className="InfluencerProfileContainer">
            <div className="continue_container" >
              <button className="continueBtn" onClick={openPopUp}>Create Your Profile</button>
            </div>
            <div>
              {
                OpenReminder && (
                  <div
                    className="modal-backdrop"
                  >
                    <div
                      className="modal-content"
                      onClick={e => {
                        // do not close modal if anything inside modal content is clicked
                        e.stopPropagation();
                      }}
                    >
                      {/* Close and More */}
                      <div className='modalHeading_Container'>
                        <p className='modalHeading'>Important Notes</p>
                        <p className="modalClose" onClick={() => setOpenReminder(false)}>+</p>
                      </div>
                      {/* Importnat Notes */}
                      <div>
                        <div className="importantNotes_Text">
                          <CheckCircleIcon className="checkedIcon" fontSize="small" /> Please Fill all of your information and packages with detail.
                        </div>
                        <div className="importantNotes_Text">
                          <CheckCircleIcon className="checkedIcon" fontSize="small" /> Make Sure all of your information should be correct and valid.
                        </div>
                        <div className="importantNotes_Text">
                          <CheckCircleIcon className="checkedIcon" fontSize="small" /> Collabscafe will review your profile, if your profile needed some suggestions/improvment Collabscafe will give to start earning.
                        </div>
                        <div className="importantNotes_Text">
                          <CheckCircleIcon className="checkedIcon" fontSize="small" /> Make Sure your Mobile number should be valid.
                        </div>
                        <div className="importantNotes_Text">
                          <CheckCircleIcon className="checkedIcon" fontSize="small" /> Collabscafe will send you an Email after your profile verification.
                        </div>
                      </div>
                      {/* create Your Profile */}
                      <div className="continue_container" >
                        {/*  */}
                        {!continueToogle ? (
                          <button className="continueBtn" disabled={continueToogle === true} onClick={createProfile}>Create Your Profile</button>
                        ) : (

                          <button className="continueBtn">
                            <CircularProgress size={20} style={{ color: "white" }} />
                          </button>
                        )
                        }
                      </div>
                    </div>
                  </div>
                )
              }
            </div>
          </div>
        ) : (
          <div className="InfluencerProfileContainer">
            <div className="continue_container" >
              <button className="continueBtn" onClick={phoneNumberHandler}>Continue</button>
            </div>
          </div>
        )
      )}


    </div>
  );
};

export default Steps;
