import React, { useState, useEffect } from "react";
import "./Home.css";
import {
  SearchBox,
  FeaturedInfluencers,
  InstagramInfluencers,
  TiktokInfluencer,
  Categories,
  SeenIn,
  HowCollabsCafeWorks,
  CollabsCafeGuide,
  YoutubeInfluencers,
  UserGeneratedContent,
  Banner,
  SubFooter,
  Footer,
} from "../index";
import { Helmet } from "react-helmet";
import { useDispatch } from "react-redux";
import { fetchInfluencerProfile } from "../../Redux/Reducers/fetchInfluencerProfile";


const Home = () => {
  const [profilesList, setProfilesList] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchInfluencerProfile()).then((res) => {
      setProfilesList(res?.payload?.verifiedProfiles);
    });
  }, [dispatch])

  return (
    <div>

      <Helmet>
        <title>Collabscafe | Find and Hire Influencers</title>
      </Helmet>
      <div className="find_hire_heading">
        <h1>Find and Hire Influencers in Seconds</h1>
        <p>
          Find Instagram, TikTok, and YouTube influencers to create unique
          content for your brand
        </p>
      </div>
      <SearchBox />
      <FeaturedInfluencers profilesList={profilesList}/>
      <InstagramInfluencers profilesList={profilesList}/>
      <TiktokInfluencer profilesList={profilesList}/>
      <Categories />
      <SeenIn />
      <HowCollabsCafeWorks />
      <CollabsCafeGuide />
      <YoutubeInfluencers profilesList={profilesList}/>
      {/* <YoutubeInfluencers profilesList={profilesList}/> */}
      <UserGeneratedContent profilesList={profilesList}/>
      <Banner
        heading={"Find and Hire Influencers"}
        subHeading={"Search Instagram, TikTok, and YouTube influencers"}
        btn={"Search Influencers"}
        link={""}
      />
      <SubFooter />
      <Footer />

    </div>
  );
};

export default Home;
