import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Social from "./Components/Social";
import { CustomizedButton } from "../../../components/CustomizedButton";
import axios from 'axios';
import { BASE_URL } from "../../../config/config";
import { Notify } from "../../../components/Notify";
import { currentBrandProfile } from '../../../Redux/Reducers/UserReducer';
import { fetchBrandProfile } from '../../../Redux/Reducers/fetchBrandProfile';

const SocialMedia = () => {

    const brand_Profile = useSelector((state) => state.brand_Profile.brand_Profile);
    const dispatch = useDispatch()


    const [socialLink, setSocialLink] = useState({
        brandInsta: "",
        brandInstaFollower: "0-1k",
        brandTiktok: "",
        brandTiktokFollower: "0-1k",
        brandYoutube: "",
        brandYoutubeFollower: "0-1k",
        brandTwitter: "",
        brandTwitterFollower: "0-1k",
        brandFacebook: "",
        brandFacebookFollower: "0-1k",
        brandWebsite: "",
    });

    const {
        brandFacebook,
        brandInsta,
        brandTiktok,
        brandTwitter,
        brandWebsite,
        brandYoutube,
    } = brand_Profile && brand_Profile.socialLink[0];



    const UpdateSocialLinks = async () => {
        const id = brand_Profile._id;
        const data = {
            brandInsta: socialLink.brandInsta ? socialLink.brandInsta : brandInsta,
            brandTiktok: socialLink.brandTiktok ? socialLink.brandTiktok : brandTiktok,
            brandYoutube: socialLink.brandYoutube ? socialLink.brandYoutube : brandYoutube,
            brandTwitter: socialLink.brandTwitter ? socialLink.brandTwitter : brandTwitter,
            brandFacebook: socialLink.brandFacebook ? socialLink.brandFacebook : brandFacebook,
            brandWebsite: socialLink.brandWebsite ? socialLink.brandWebsite : brandWebsite,
        }
        try {
            const response = await axios.patch(`${BASE_URL}/updatebrandProfileSocialLink/${id}`, data);
            if (response) {
                dispatch(fetchBrandProfile(brand_Profile?.uid)).then((res) => {
                    if (res.type === "brand_Profile/fetchBrandProfile/fulfilled") {
                        Notify("success", "Changes Saved!");
                        dispatch(currentBrandProfile(res?.payload));
                    }
                })
            }


        } catch (error) {
            Notify("error", error.message);
        }
    }



    return (
        <div className='socialMedia'>
            <Social
                socialLink={socialLink}
                setSocialLink={setSocialLink}
            //   socialLink={socialLink}
            //     setSocialLink={setSocialLink}
            //     influFb={influencerFacebook}
            //     influFbFollower={influencerFacebookFollower}
            //     influInsta={influencerInsta}
            //     influInstaFollower={influencerInstaFollower}
            //     influTiktok={influencerTiktok}
            //     influTiktokFollower={influencerTiktokFollower}
            //     influTwitter={influencerTwitter}
            //     influTwitterFollower={influencerTwitterFollower}
            //     influWebsite={influencerWebsite}
            //     influYoutube={influencerYoutube}
            //     influYoutubeFollower={influencerYoutubeFollower}
            />
            <div className='saveChangesBtn'>
                <CustomizedButton
                    onClick={UpdateSocialLinks}
                    className='saveChanges'>Save</CustomizedButton>
            </div>
        </div>
    )
}

export default SocialMedia;