import React from 'react'
import "./SideBar.css";
import { Link, Navigate } from 'react-router-dom';
import CollabsCafeLogo from "../../images/logoSidebar.png";

const SideBar = () => {
    return (
        <div className="sidebar">
            <div className='sideBar_logo_Cont'>
                <Link to="/">
                    <img
                        className="sideBar_logo"
                        src={CollabsCafeLogo}
                        alt="logo.png"
                    />
                </Link>
            </div>
            <Link to="/admin-dashboardInfluencers" className='link'>
                <p className='active'>All Influencers</p>
            </Link>
            <Link to="/admin-dashboardBrands" className='link'>
                <p >All Brands</p>
            </Link>
            <Link to="/admin-dashboard-Influencer-for-approval" className='link'>
                <p>Influencers Profiles for Approval</p>
            </Link>
            <p>All Transactions</p>
            <p>Influencers Profiles</p>
        </div>
    )
}

export default SideBar;