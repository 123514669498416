import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL } from "../../config/config";


// const INITIAL_STATE = {
//     loading: false,
//     influencerProfiles : [],
//  error: 
// }

export const fetchInfluencerProfile = createAsyncThunk("influencers/fetchInfluencerProfile", async () => {
    let response = await axios.get(`${BASE_URL}/getInfluencerVerifiedAccount`);
    let data = await response.data;
    return data;

});

export const getOneInfluencer = createAsyncThunk("influencers/getOneInfluencer", async (path) => {
    let response = await axios.get(`${BASE_URL}/getInfluencerProfile${path}`);
    let data = await response.data;
    return data;
});



const influencerProfileReducer = createSlice({
    name: "influencers",
    initialState: {
        loading: false,
        influencerProfiles: [],
        singleInfluencerProfile: {},
        error: ""
    },
    extraReducers: {
        [fetchInfluencerProfile.fulfilled]: (state, action) => {
            state.influencerProfiles = action.payload;
            state.loading = false;
        },
        [fetchInfluencerProfile.pending]: (state) => {
            state.loading = false;
        },
        [fetchInfluencerProfile.rejected]: (state, action) => {
            state.error = action.error.message
        },
        [getOneInfluencer.fulfilled]: (state, action) => {
            state.influencerProfiles = action.payload;
            state.loading = false;
        },
        [getOneInfluencer.pending]: (state) => {
            state.loading = false;
            // state.error = action.error.message
        },
        [getOneInfluencer.rejected]: (state, action) => {
            state.error = action.error.message
        },
    }
});

export default influencerProfileReducer.reducer;