import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL } from "../../config/config";

// search


export const searchInfluencers = createAsyncThunk("searchBox/searchInfluencers",
    async (id, { rejectWithValue }) => {
        try {
            let response = await axios.get(`${BASE_URL}/search/${id}`);
            console.log("responseresponseresponse", response)
            let data = await response.data;
            return data;
        } catch (error) {
            return rejectWithValue(error?.response?.data?.msg);
        }

    }
);


const searchReducer = createSlice({
  name: "searchBox",
  initialState: {
      loading: false,
      influ: [],
      error: ""
  },
  extraReducers: {
      [searchInfluencers.fulfilled]: (state, action) => {
          state.influ = action.payload;
          state.loading = false;
      },
      [searchInfluencers.pending]: (state) => {
          state.loading = true;
      },
      [searchInfluencers.rejected]: (state, action) => {
          state.error = action.error.message
          state.loading = false;
      }
  }
});

// Action creators are generated for each case reducer function

export default searchReducer.reducer;