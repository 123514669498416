import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL } from "../../config/config";



export const FavoritesList = createAsyncThunk("brand_Favorites/FavoritesList",
    async (list, { rejectWithValue }) => {
        try {
            let response = await axios.post(`${BASE_URL}/brandFavorities`, list);
            let data = await response.data;
            return data;
        } catch (error) {
            return rejectWithValue(error?.response?.data?.msg);
        }

});

export const fetcheFavoritesList = createAsyncThunk("brand_Favorites/fetcheFavoritesList",
    async (id, { rejectWithValue }) => {
        try {
            let response = await axios.get(`${BASE_URL}/fetchBrandFavorities/${id}`);
            let data = await response.data.data;
            return data;
        } catch (error) {
            return rejectWithValue(error?.response?.data?.msg);
        }

});


const brandFavoritesReducer = createSlice({
    name: "brand_Favorites",
    initialState: {
        loading: false,
        favorites: {},
        favoritesList: [],
        error: ""
    },
    extraReducers: {
        [FavoritesList.fulfilled]: (state, action) => {
            state.favorites = action.payload;
            state.loading = false;
        },
        [FavoritesList.pending]: (state) => {
            state.loading = true;
        },
        [FavoritesList.rejected]: (state, action) => {
            state.error = action.error.message
        },
        [fetcheFavoritesList.fulfilled]: (state, action) => {
            state.favoritesList = action.payload;
            state.loading = false;
        },
        [fetcheFavoritesList.pending]: (state) => {
            state.loading = true;
        },
        [fetcheFavoritesList.rejected]: (state, action) => {
            state.error = action.error.message
        },
    }
});

export default brandFavoritesReducer.reducer;