import React, { useState } from "react";
import "./StepEmailOtpVerify.css";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { currentUser } from "../../../../Redux/Reducers/UserReducer";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { auth } from "../../../../config/firebase-config";
import { BASE_URL } from "../../../../config/config";
import { Notify } from "../../../../components/Notify";
import Loading from "../../../../components/Loading/Loading";


const StepEmailOtpVerify = ({ setinfluencerInfo }) => {
  const [verifyotp, setverifyotp] = useState("");
  const [loading, setLoading] = useState(false);
  // get current user email
  const user = useSelector((state) => state.User.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();


  const verifyOtpHandler = async () => {
    setLoading(true);
    if (verifyotp) {
      let postData = {
        verifyotp: verifyotp,
        uid: user?.uid
      }
      await axios.post(`${BASE_URL}/verifyOtp`, postData).then((res) => {
        Notify("success", res.data);
        navigate("/create-page");
        setLoading(false);
        auth.currentUser.emailVerified = true;
        if (user) {
          dispatch(currentUser(
            {
              fullName: user.fullName,
              userName: user.userName,
              email: user.email,
              uid: user.uid,
              verified: true,
              profileCompleted: user.profileCompleted,
              role: user.role
            }
          ))
          setinfluencerInfo({
            fullName: user.fullName,
            userName: user.userName,
            email: user.email,
            uid: user.uid,
            verified: true,
            profileCompleted: user.profileCompleted,
            role: user.role
          })
        }

        // setTimeout(() => {
        //   window.location.reload();
        // }, 2000)
      }).catch((error) => {
        Notify("error", error.response.data.message);
        setLoading(false);
      })
    } else {
      Notify("error", "Please Enter Your Otp Code!");

      setLoading(false);
    }

  };


  return (
    <div className="stepEmailOtpVerify">
      <div>
        <h1 className="stepEmailOtpVerify-heading">Verify your email</h1>
        <p className="stepEmailOtpVerify_desc">
          We sent an email to {user !== null && user.email}. Check your inbox and
          verify your email.
        </p>
        <div className="input_Container">
          <input type="text" placeholder="6-Digit Code"
            value={verifyotp}
            onChange={(e) => setverifyotp(e.target.value)}
          />
        </div>

        {/* signUp Button */}
        <div className="continue_container">
          {
            loading ? (
              <button className="continueBtn" >
                <Loading />
              </button>

            ) : (
              <button className="continueBtn" onClick={verifyOtpHandler}>
                Continue
              </button>
            )
          }
        </div>
        {/* <p className="stepEmailOtpVerify_label">I didn't receive an email</p> */}
      </div>
    </div>
  );
};

export default StepEmailOtpVerify;
