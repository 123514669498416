import React, { useEffect, useState } from 'react';
import "./Login.css";
import { signInWithEmailAndPassword, signOut } from "firebase/auth";
import { auth } from "../../config/firebase-config"
import toast from "react-hot-toast";
import { useNavigate, Link } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { currentUser, currentBrand } from "../../Redux/Reducers/UserReducer";
import axios from 'axios';
import { Notify } from "../../components/Notify";
import Loading from "../../components/Loading/Loading";
import { Helmet } from 'react-helmet';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import Bg01 from "../../images/bg01.jpg";
import Bg02 from "../../images/bg02.jpg";
import Bg03 from "../../images/bg03.jpg";
import { BASE_URL } from "../../config/config";

const Login = ({ setIsUser }) => {
    const users = useSelector((state) => state.User.user);
    const brand = useSelector((state) => state.User.brand);
    const [loading, setLoading] = useState(false);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [getted, setgetted] = useState("");
    const navigate = useNavigate();
    const [toogle, setToogle] = useState(false);
    const [influencerInfo, setinfluencerInfo] = useState(null);
    const [brandInfo, setBrandInfo] = useState(null);
    const dispatch = useDispatch();


    const login = async () => {
        setLoading(true);
        if (!email) {
            Notify("error", "Oops Please Enter Your Email");
            setLoading(false);
        } else if (!password) {
            Notify("error", "Oops Please Enter Your Password");
            setLoading(false);
        } else if (password.length < 6) {
            Notify("error", "Oops Password Should be Atleast 6 character!");
            setLoading(false);
        } else {
            try {
                const logedIn = await signInWithEmailAndPassword(auth, email, password);
                axios.get(`${BASE_URL}/getUser/${logedIn?.user?.uid}`).then((res) => {
                    let result = res?.data?.user;
                    if (result.is_blocked) {
                        signOut(auth).then((res) => {
                            Notify("error", "Your Account is Blocked");
                        });
                        setLoading(false);
                        setTimeout(() => {
                            window.location.reload();
                          }, 1000)
                        return;
                    }
                    Notify("success", "Successfuly Signed In");
                    setLoading(false);
                    if (result.role === "Influencer") {
                        dispatch(currentUser(result));
                        setIsUser(true);
                        if (!result.verified) {
                            navigate("/stepEmailOtpVerify");
                        } else if (!result.profileCompleted) {
                            navigate("/create-page");
                        } else {
                            navigate("/");
                        }
                    } else if (result.role === "Brand") {
                        dispatch(currentBrand(result));
                        setIsUser(true);
                        // if(result.profileCompleted) { 
                        //     navigate("/profile-complete");
                        // }else {

                        // }
                        navigate("/");
                        // console.log("profile-complete", result);
                    }

                })
                // setToogle(true);
                // fetchedUserInfo(loggedInUser).then((res) => {
                //     console.log("Res", res);
                // } )


                // if influencerInfo avail then it will dispatch
                // if (influencerInfo) {
                // if (currentInfluencer) {
                //     dispatch(currentUser(currentInfluencer));
                // } else if (currentBrand) {
                //     dispatch(currentBrand(currentBrand));
                // }
                // console.log("CurrentInfluencer", currentInfluencer);
                // console.log("CurrentBrand", currentBrand);
                // }

                // window.location.reload();

                // setinfluencerInfo(null);

            } catch (error) {
                error.message = error.message.replace(/\(/g, "");
                error.message = error.message.replace(/\)/g, "");
                error.message = error.message.replace(/\-/g, " ");
                Notify("error", error.message.slice(21).toUpperCase());
                setLoading(false);
            }
        }

    }

    // useEffect(() => {
    //     if (toogle) {
    //         fetchedUserInfo(loggedInUser);
    //     }


    // }, [toogle])
    // profileCompleted
    // useEffect(() => {
    //     if (toogle) {
    //         if (influencerInfo) {
    //             if (!influencerInfo.verified) {
    //                 navigate("/stepEmailOtpVerify");
    //             } else if (!influencerInfo.profileCompleted) {
    //                 navigate("/create-page");
    //             } else {
    //                 navigate("/");
    //             }
    //         }
    //         if (brand) {
    //             navigate("/");
    //         }
    //     }
    // }, [toogle, users, brand])

    const slider = {
        arrows: false,
        autoplay: true,
        // dots: true,
        infinite: true,
        speed: 250,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplaySpeed: 2000
    };

    return (
        <div className='login'>
            <div className='w-70'>

                {/* <Slider {...slider}>
                    <div>
                        <img src={Bg01} alt="bg" />
                    </div>
                    <div>
                        <img src={Bg02} alt="bg" />

                    </div>
                    <div>
                        <img src={Bg03} alt="bg" />

                    </div>
                </Slider> */}
            </div>
            <div className=' w-30 mx-8'>
                <Helmet>
                    <title>Collabscafe | Login</title>
                </Helmet>
                <h1 className="login-heading">Welcome Back</h1>
                <div className="input_Container">
                    <input
                        type="text"
                        placeholder="Email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                </div>
                <div>

                </div>
                <div className="input_Container">
                    <input
                        type="password"
                        placeholder="Password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                </div>
                <div className='resetPassword'>
                    <Link className='link' to="/reset-password">
                        <p className='resetPassword'>Forget Your Password</p>
                    </Link>
                </div>
                {/* signUp Button */}
                <div className="continue_container">
                    {
                        loading ? (<button className="continueBtn" >
                            <Loading />
                        </button>) : (<button className="continueBtn" onClick={login}>
                            Login
                        </button>)
                    }
                </div>
            </div>

        </div>
    )
}

export default Login;