import React, { useState } from 'react'
import { BiDownArrowAlt } from 'react-icons/bi';

const GenderFiltered = ({ openGender, setOpenGender, gender, setGender }) => {

    const [changeGender, setChangedGender] = useState("");


    const handleGender = () => {
        setGender(changeGender);
        setOpenGender(false);
    }

    return (
        <div>
            <p className='filtersBtn' onClick={() => setOpenGender(!openGender)}>Gender <BiDownArrowAlt className='downArrow' /></p>
            {openGender && (
                <div onClick={e => {
                    // do not close modal if anything inside modal content is clicked
                    e.stopPropagation();
                }}
                    className='countryOptions'>
                    <select
                        className="fromWhereYouHeard"
                        onChange={(e) => setChangedGender(e.target.value)}
                    >
                        <option value="" disabled="" hidden="">
                            {gender ? gender : "All"}
                        </option>
                        <option value="male">Male</option>
                        <option value="female">Female</option>
                        <option value="others">Others</option>
                    </select>

                    {/* Clear and Save Button */}
                    <div className='clear_Save_Cont'>
                        {/* <p className='clear'>Clear</p> */}
                        <p className='save' onClick={handleGender}>Save</p>
                    </div>

                </div>
            )}
        </div>
    )
}

export default GenderFiltered