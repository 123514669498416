import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Outlet } from 'react-router-dom';


export const InfluencerProtectedRoute = () => {
    const user = useSelector((state) => state.User.user);
    const isAuthenticated = user;
    return (
        !
        isAuthenticated ? <Navigate to="/" /> : <Outlet />
    )
}


export const BrandProtectedRoute = () => {
    const brand = useSelector((state) => state.User.brand);
    const isAuthenticated = brand;
    return (
        !isAuthenticated ? <Navigate to="/" /> : <Outlet />
    )
}

// const ProtectedRoute = () => {
//     const user = useSelector((state) => state.User.user);
//     const brand = useSelector((state) => state.User.brand);

//     const isAuthenticated = user;
//     return (
//         isAuthenticated != true ? <Navigate to="/" /> : <Outlet />
//     )
// }

// export default ProtectedRoute