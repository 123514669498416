import React from "react";
import "./LovedCreators.css";
import Chip from "@mui/material/Chip";

const LovedCreators = () => {
  return (
    <div className="lovedCreators">
      <h1 className="lovedCreatorsHeading">Loved by 60,000+ Creators</h1>
      <div className="creators_Container">
        <div className="creators">
          <Chip className="chip" label="Lifestyle" />
          <Chip className="chip" label="Fashion" />
          <Chip className="chip" label="Beauty" />
          <Chip className="chip" label="Travel" />
          <Chip className="chip" label="Health & Fitness" />
          <Chip className="chip" label="Family & Children" />
          <Chip className="chip" label="Music & Dance" />
          <Chip className="chip" label="Comedy & Entertainment" />
        </div>
      </div>
    </div>
  );
};

export default LovedCreators;
