import React, { useEffect, useState } from 'react'
import { AiOutlineHeart } from 'react-icons/ai'
import "./Lists.css"
import Checkbox from '@mui/material/Checkbox';
import FavoriteBorder from '@mui/icons-material/FavoriteBorder';
import Favorite from '@mui/icons-material/Favorite';
import { useDispatch, useSelector } from 'react-redux';
import { FavoritesList, fetcheFavoritesList } from '../../Redux/Reducers/BrandFavoritesReducer';
import numeral from 'numeral';
import { Notify } from '../../components/Notify';


const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

const Lists = () => {
    const brand = useSelector((state) => state.User.brand);
    const favorites = useSelector((state) => state.brand_Favorites);
    const [favoritesList, setFavoritiesList] = useState([]);
    const dispatch = useDispatch();



    // Favorites Change
    const addFavoritesChange = (check, influData) => {

        const data = {
            isChecked: check,
            brandId: brand._id,
            influencerList: influData
        }

        dispatch(FavoritesList(data)).then((res) => {
            if (res?.type === "brand_Favorites/FavoritesList/fulfilled") {
                Notify("success", res?.payload?.msg);
                // get One List
                dispatch(fetcheFavoritesList(brand._id)).then((res) => {
                    if (res?.type === "brand_Favorites/fetcheFavoritesList/fulfilled") {
                        setFavoritiesList(res?.payload);
                    }
                });
            } else if (res?.type === "brand_Favorites/FavoritesList/rejected") {
                Notify("error", res?.payload);
            }
        })
    }


    useEffect(() => {
        dispatch(fetcheFavoritesList(brand._id)).then((res) => {
            if (res?.type === "brand_Favorites/fetcheFavoritesList/fulfilled") {
                setFavoritiesList(res?.payload);
            }
        })
        return;
    }, [brand])



    return (
        <div className='List_header'>
            <div className='list_title'>Lists</div>

            {/* {
                favorites.loading && <h1>Loading</h1>
            } */}
            <div className='all_list'>
                {
                    favoritesList.length > 0 ? (
                        favoritesList.map((list, index) => {
                            const { influencerList } = list;

                            return (
                                <div key={index} className="allInfluencer_featured-profile-listing-holder" >
                                    <div className="allInfluencer_profile-listing-img-holder">
                                        <div className="heart_list">
                                            {/* <AiOutlineHeart color='white' size={26} /> */}
                                            <Checkbox
                                                checked={true}
                                                onChange={(e) => addFavoritesChange(e.target.checked, influencerList)}
                                                {...label} icon={<FavoriteBorder />}
                                                checkedIcon={<Favorite />} color={"error"} />
                                        </div>
                                        <img
                                            loading="lazy"
                                            className="allInfluencer_profile-listing-img"
                                            src={influencerList.contentImage[0] && influencerList.contentImage[0].url}
                                            alt="  creator Brad Sousa being photographed"
                                        />
                                        <div className="allInfluencer_profile-listing-owner-holder">
                                            <div className="allInfluencer_profile-listing-owner-info-holder">
                                                <div className="allInfluencer_profile-listing-owner-name">
                                                    {influencerList.fullName}

                                                    {/* <img
                                                                        className="allInfluencer_verified-badge"
                                                                        alt=""
                                                                        src="https://d5ik1gor6xydq.cloudfront.net/websiteImages/creatorMarketplace/verifiedBadge.svg"
                                                                    /> */}
                                                </div>
                                                <div className="allInfluencer_profile-listing-owner-location">
                                                    {influencerList?.influencerCity}
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {/* ****** platform Price ****** */}
                                    <div className="allInfluencer_card_platform_price">
                                        <p className="allInfluencer_platform">{influencerList.packages[0].platform}</p>
                                        <p className="price"><span className="currency">Rs: {numeral(influencerList?.packages[0]?.packagePrice).format('0,00.00')}</span> </p>



                                    </div>
                                    {/* ****** Description ***** */}
                                    <p className="allInfluencer_description">
                                        Youtuber | Programmer
                                    </p>
                                </div>
                            )
                        })
                    ) : (
                        <div className='no_list'>Your saved influencers will be displayed here. To add an influencer to a list, click the "save" button on their profile.</div>
                    )
                }
            </div>
        </div>
    )
}

export default Lists