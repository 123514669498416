// message
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL } from "../../config/config";

// Create Chat

export const createUserChat = createAsyncThunk("Chat/createUserChat",
    async (data, { rejectWithValue }) => {

        try {
            let response = await axios.post(`${BASE_URL}/createChat`, data);
            let result = await response.data;
            return result;
        } catch (error) {
            console.log("Error", error)
            // return rejectWithValue(error?.response?.data?.msg);
        }

    }
);



// Get User Chats
export const getUserChats = createAsyncThunk("Chat/getUserChats",
    async (id, { rejectWithValue }) => {

        try {
            let response = await axios.get(`${BASE_URL}/userChat/${id}`);
            let data = await response.data;
            return data;
        } catch (error) {
            console.log("Error", error)
            // return rejectWithValue(error?.response?.data?.msg);
        }

    }
);


// Notifications

export const sendNotification = createAsyncThunk("Chat/sendNotification",
    async (notify, { rejectWithValue }) => {

        try {
            let response = await axios.post(`${BASE_URL}/createNotification`, notify);
            let data = await response.data;
            return data;
        } catch (error) {
            console.log("Error", error)
            // return rejectWithValue(error?.response?.data?.msg);
        }

    }
);

export const getnotification = createAsyncThunk("Chat/getnotification",
    async (id, { rejectWithValue }) => {

        try {
            let response = await axios.get(`${BASE_URL}/getNotifications/${id}`);
            let data = await response.data;
            return data;
        } catch (error) {
            console.log("Error", error)
            // return rejectWithValue(error?.response?.data?.msg);
        }

    }
);






export const sendMessage = createAsyncThunk("Chat/sendMessage",
    async (messageData, { rejectWithValue }) => {
        console.log("messageData", messageData)
        try {
            let response = await axios.post(`${BASE_URL}/createMessage`, messageData);
            let data = await response.data;
            return data;
        } catch (error) {
            console.log("Error", error)
            // return rejectWithValue(error?.response?.data?.msg);
        }

    }
);

export const fetchedMessage = createAsyncThunk("Chat/fetchedMessage",
    async (id, { rejectWithValue }) => {

        try {
            let response = await axios.get(`${BASE_URL}/getMessages/${id}`);
            let result = await response.data;
            console.log("data", result)
            return result;
        } catch (error) {
            console.log("Error", error)
            // return rejectWithValue(error?.response?.data?.msg);
        }

    }
);


const chatReducer = createSlice({
    name: "Chat",
    initialState: {
        loading: false,
        chat: {},
        allChats: [],
        allMessages: [],
        newMessage: {},
        allNotifications: [],
        sendNotifications: {},
        error: ""
    },
    extraReducers: {
        [sendMessage.fulfilled]: (state, action) => {
            state.newMessage = action.payload;
            state.loading = false;
        },
        [sendMessage.pending]: (state) => {
            state.loading = true;
        },
        [sendMessage.rejected]: (state, action) => {
            state.error = action.error.message;
            state.loading = false;
        },
        [fetchedMessage.fulfilled]: (state, action) => {
            state.allMessages = action.payload;
            state.loading = false;
        },
        [fetchedMessage.pending]: (state) => {
            state.loading = true;
        },
        [fetchedMessage.rejected]: (state, action) => {
            state.error = action.error.message;
            state.loading = false;
        },

        // New 
        // Create user Chats
        [createUserChat.fulfilled]: (state, action) => {
            state.chat = action.payload;
            state.loading = false;
        },
        [createUserChat.pending]: (state) => {
            state.loading = true;
        },
        [createUserChat.rejected]: (state, action) => {
            state.error = action.error.message;
            state.loading = false;
        },
        // get User Chats
        [getUserChats.fulfilled]: (state, action) => {
            state.allChats = action.payload;
            state.loading = false;
        },
        [getUserChats.pending]: (state) => {
            state.loading = true;
        },
        [getUserChats.rejected]: (state, action) => {
            state.error = action.error.message;
            state.loading = false;
        },
        // Send Notifications
        [sendNotification.fulfilled]: (state, action) => {
            state.sendNotifications = action.payload;
            state.loading = false;
        },
        [sendNotification.pending]: (state) => {
            state.loading = true;
        },
        [sendNotification.rejected]: (state, action) => {
            state.error = action.error.message;
            state.loading = false;
        },
        // get Notifications
        [getnotification.fulfilled]: (state, action) => {
            state.allNotifications = action.payload;
            state.loading = false;
        },
        [getnotification.pending]: (state) => {
            state.loading = true;
        },
        [getnotification.rejected]: (state, action) => {
            state.error = action.error.message;
            state.loading = false;
        },
    }
});

export default chatReducer.reducer;