import React, { useState } from 'react'
import toast from "react-hot-toast";
import Loading from "../../../../components/Loading/Loading";
import { AddInfluencerProfile } from "../../../../Redux/Reducers/InfluencerProfileReducer";
import { Notify } from "../../../../components/Notify";
import { AiFillInstagram, AiFillYoutube } from 'react-icons/ai';
import { IoLogoFacebook, IoLogoTiktok } from 'react-icons/io5';
import { ImTwitter } from 'react-icons/im';
import { BsLink45Deg } from 'react-icons/bs';

const BrandStepTwo = ({ step, setStep, brandSocial, setBrandSocial }) => {
  const [loading, setLoading] = useState(false);

  const [openInsta, setOpenInsta] = useState(false);
  const [opentiktok, setOpentiktok] = useState(false);
  const [openyoutube, setOpenYoutube] = useState(false);
  const [opentwitter, setOpenTwitter] = useState(false);
  const [openfacebook, setOpenFacebook] = useState(false);
  const [openwebsite, setOpenWebsite] = useState(false);

  const { brandInsta, brandTiktok, brandYoutube, brandTwitter, brandFacebook, brandWebsite } = brandSocial;
  const socialLinkHandler = () => {
    setLoading(true);

    setTimeout(() => {
      setLoading(false);
      setStep(step + 1);
    }, 2000)
  };

  const skipHandler = () => {
      setStep(step + 1);
  };

  return (
    <div className="stepFive">
      <div>
        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          <h1 className="stepFive-heading">Add your social channels</h1>
          <button style={{ width: 80, fontSize: 12 }} className="continueBtn" onClick={skipHandler}>
            SKIP
          </button>
        </div>
        {/* Instagram */}
        {!openInsta ? (
          <div
            className="input_Container_social"
            onClick={() => setOpenInsta(true)}
          >
            <div className="input_container_Item">
              <AiFillInstagram className="icons" />
              <p className="items_text">Add Instagram</p>
            </div>
          </div>
        ) : (
          <div className="inputs_cont">
            <input
              type="text"
              className="inputName"
              placeholder="Instagram UserName"
              value={brandSocial.brandInsta}
              onChange={(e) => setBrandSocial({ ...brandSocial, brandInsta: e.target.value })}
            />

          </div>
        )}
        {/* TikTok */}
        {!opentiktok ? (
          <div
            className="input_Container_social"
            onClick={() => setOpentiktok(true)}
          >
            <div className="input_container_Item">
              <IoLogoTiktok className="icons" />
              <p className="items_text">Add Tiktok</p>
            </div>
          </div>
        ) : (
          <div className="inputs_cont">
            <input
              type="text"
              className="inputName"
              placeholder="Tiktok UserName"
              value={brandSocial.brandTiktok}
              onChange={(e) => setBrandSocial({ ...brandSocial, brandTiktok: e.target.value })}
            />

          </div>
        )}
        {/* Youtube */}
        {!openyoutube ? (
          <div
            className="input_Container_social"
            onClick={() => setOpenYoutube(true)}
          >
            <div className="input_container_Item">
              <AiFillYoutube className="icons" />
              <p className="items_text">Add YouTube</p>
            </div>
          </div>
        ) : (
          <div className="inputs_cont">
            <input
              type="text"
              className="inputName"
              placeholder="YouTube URL"
              value={brandSocial.brandYoutube}
              onChange={(e) => setBrandSocial({ ...brandSocial, brandYoutube: e.target.value })}

            />

          </div>
        )}
        {/* Twitter */}
        {!opentwitter ? (
          <div
            className="input_Container_social"
            onClick={() => setOpenTwitter(true)}
          >
            <div className="input_container_Item">
              <ImTwitter className="icons" />
              <p className="items_text">Add Twitter</p>
            </div>
          </div>
        ) : (
          <div className="inputs_cont">
            <input
              type="text"
              className="inputName"
              placeholder="Twitter Url"
              value={brandSocial.brandTwitter}
              onChange={(e) => setBrandSocial({ ...brandSocial, brandTwitter: e.target.value })}

            />

          </div>
        )}

        {/* Facebook */}
        {!openfacebook ? (
          <div
            className="input_Container_social"
            onClick={() => setOpenFacebook(true)}
          >
            <div className="input_container_Item">
              <IoLogoFacebook className="icons" />
              <p className="items_text">Add Facebook</p>
            </div>
          </div>
        ) : (
          <div className="inputs_cont">
            <input
              type="text"
              className="inputName"
              placeholder="Facebook Url"
              value={brandSocial.brandFacebook}
              onChange={(e) => setBrandSocial({ ...brandSocial, brandFacebook: e.target.value })}

            />

          </div>
        )}
        {/* Website */}
        {!openwebsite ? (
          <div
            className="input_Container_social"
            onClick={() => setOpenWebsite(true)}
          >
            <div className="input_container_Item">
              <BsLink45Deg className="icons" />
              <p className="items_text">Add Website</p>
            </div>
          </div>
        ) : (
          <div className="inputs_cont">
            <input type="text" className="inputName" placeholder="Website Url"
              value={brandSocial.brandWebsite}
              onChange={(e) => setBrandSocial({ ...brandSocial, brandWebsite: e.target.value })}
            />
          </div>
        )}

        {/* signUp Button */}
        <div className="continue_container">
          {
            loading ? (
              <button className="continueBtn" >
                <Loading />
              </button>
            ) : (
              <button className="continueBtn" onClick={socialLinkHandler}>
                Continue
              </button>
            )
          }

        </div>
      </div>
    </div>
  )
}

export default BrandStepTwo