import React, { useEffect, useState } from 'react'
import SideBar from "../../../components/SideBar/SideBar";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import IconButton from '@mui/material/IconButton';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import axios from "axios";
import NoData from "../../../images/no-data.jpg";
import toast from "react-hot-toast";
import { AiFillInstagram } from "react-icons/ai";
import { IoLogoTiktok, IoLogoFacebook } from "react-icons/io5";
import { BsTwitter, BsYoutube, BsLink45Deg } from "react-icons/bs";
import {BASE_URL} from "../../../config/config";
import { Notify } from '../../../components/Notify';

const InfluencerForApproval = () => {
    const [influencersData, setInfluencersData] = useState([]);


    const getAllInfluencersProfile = async () => {
        const response = await axios.get(`${BASE_URL}/influencersProfile`);
        const data = response.data.inflProfile;
        setInfluencersData(data);
      
    }

    const filterApproved = influencersData.filter((val) => !val.approved)

    useEffect(() => {
        getAllInfluencersProfile();
    }, []);

    return (
        <div>
            <SideBar />
            <div className="content">

                <div className='content_Inner'>
                    <h1 className="account-heading">All Influencers For Approval</h1>
                    {filterApproved.length < 1 && (
                        <div className='noDataImage'>
                            <img src={NoData} />
                        </div>
                    )}
                    <div className='allInfluencers_Container'>
                        {
                            filterApproved.map((influencerProfile) => {
                                return (
                                    <div key={influencerProfile._id}>
                                        <InfluencerProfileApprovalExpand influencerProfile={influencerProfile} />
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

const InfluencerProfileApprovalExpand = ({ influencerProfile }) => {
    const [expand, setExpand] = useState(false);
    

    const approvedProfile = async (id) => {
        // console.log(e);
        try {
            const approve = await axios.patch(`${BASE_URL}/approveInfluencerProfile/${id}`);
            // console.log(approve);
            Notify("success", approve.data.message);
            // toast.success(approve.data.message);
            // Swal.fire({
            //     // title: 'Are you sure?',
            //     text: `${approve.data.message}`,
            //     icon: 'success',
            //     timer: 3000
            //   })
              setTimeout(() => {
                  window.location.reload();
              }, 1000);
           
        } catch (error) {
            toast.error(error.message);
        }
    }
    return (
        <div>
            <div className='allInfluencers_dropdownContainer' onClick={() => setExpand(!expand)}>
                {/* profile Image */}
                <div className='allInfluencers_Profile_Cont'>
                    <img className='allInfluencers_Profile' src={influencerProfile.profileImage.url} />
                    {/* profile Name */}
                    <p className='allInfluencers_ProfileName'>{influencerProfile.fullName}</p>
                </div>

                {/* profile Name */}
                <IconButton >
                    {
                        expand ? <ExpandLessIcon /> : <ExpandMoreIcon />
                    }

                </IconButton>
            </div>
            {
                expand && (
                    <div>
                        <div className='expandInfluencerProfile'>
                            <div>
                                <div className='profiles_menues'>User Name: <span>{influencerProfile.userName}</span></div>
                                <div className='profiles_menues'>Full Name: <span>{influencerProfile.fullName}</span></div>
                                <div className='profiles_menues'>Email: <span>{influencerProfile.email}</span></div>
                                {/* <div className='profiles_menues'>Role: <span>Influencer</span></div>
                                <div className='profiles_menues'>Approved: <span>{influencerProfile.approved}</span></div> */}
                                <div className='profiles_menues'>City: <span>{influencerProfile.influencerCity}</span></div>
                                <div className='profiles_menues'>Title: <span>{influencerProfile.influencerTitle}</span></div>
                                <div className='profiles_menues'>Description: <span className='desc'>{influencerProfile.influencerDescription}</span></div>
                            </div>
                            <div>
                                <div className='profiles_menues'>Gender: <span>{influencerProfile.influencerGender}</span></div>
                                {/* <div className='profiles_menues'>Social Links: <span>Fb</span></div> */}
                                <div className='profiles_menues'>Niches: {
                                    influencerProfile.niches.map((nich, i) => {
                                        return (
                                            <span key={i}>
                                                {nich}
                                            </span>
                                        )
                                    })
                                }</div>
                                <div className='profiles_menues'>Phone Number: <span>{influencerProfile.phoneNumber}</span></div>
                                <div className='profiles_socialLink'>Social Links:
                                    <div>
                                        {
                                            influencerProfile.socialLink[0].influencerInsta && <a href={influencerProfile.socialLink[0].influencerInsta} target="_blank"><AiFillInstagram className='profile_socialLinkIcon' /></a>
                                        }
                                        {
                                            influencerProfile.socialLink[0].influencerTiktok && <a href={influencerProfile.socialLink[0].influencerTiktok} target="_blank"><IoLogoTiktok className='profile_socialLinkIcon' /></a>
                                        }
                                        {
                                            influencerProfile.socialLink[0].influencerFacebook && <a href={influencerProfile.socialLink[0].influencerFacebook} target="_blank"><IoLogoFacebook className='profile_socialLinkIcon' /></a>
                                        }
                                        {
                                            influencerProfile.socialLink[0].influencerTwitter && <a href={influencerProfile.socialLink[0].influencerTwitter} target="_blank"><BsTwitter className='profile_socialLinkIcon' /></a>
                                        }
                                        {
                                            influencerProfile.socialLink[0].influencerYoutube && <a href={influencerProfile.socialLink[0].influencerYoutube} target="_blank"><BsYoutube className='profile_socialLinkIcon' /></a>
                                        }
                                        {
                                            influencerProfile.socialLink[0].influencerWebsite && <a href={influencerProfile.socialLink[0].influencerWebsite} target="_blank"><BsLink45Deg className='profile_socialLinkIcon' /></a>
                                        }
                                    </div>
                                </div>
                                <div className='profiles_menues'>Faqs: <span>{
                                    influencerProfile.faqs.map((faq, i) => {
                                        return (
                                            <div key={i}>
                                                <p>Question: {faq.faqsQuestion}</p>
                                                <p>Answer: {faq.faqsDescription}</p>
                                            </div>
                                        )
                                    })
                                }</span></div>

                            </div>
                        </div>
                        {/* Packages */}
                        <p className='profiles_Packages_Heading'>Packages</p>
                        <div className='profile_Packages_Card'>
                            {
                                influencerProfile.packages.map((packages, i) => {
                                    return (
                                        <div key={i} className='profile_Package'>
                                            <p>Platform : <span>{packages.platform}</span></p>
                                            <p>PackageOffer : <span>{packages.packageOffer}</span></p>
                                            <p>ContentType : <span>{packages.contentType}</span></p>
                                            <p>PackageDescription : <span>{packages.packageDescription}</span></p>
                                            <p>PackagePrice : <span>{packages.packagePrice} Rs</span></p>
                                        </div>
                                    )
                                })
                            }
                        </div>
                        {/* Approved And Denied Button */}
                        <div className='approved_DeniedBtn_cont'>
                            <button className='approvedBtn' onClick={() => approvedProfile(influencerProfile.uid)}>Approve</button>
                        </div>
                    </div>
                )
            }

            <div className='separator'></div>
        </div>
    )
}


export default InfluencerForApproval;













