import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL } from "../../config/config";



export const fetchOrders = createAsyncThunk("orders/fetchOrders", async (id) => {
    let response = await axios.get(`${BASE_URL}/get-orders/${id}`);
    let data = await response.data.data;
    return data;
});


const ordersReducer = createSlice({
    name: "orders",
    initialState: {
        loading: false,
        orders: [],
        error: ""
    },
    extraReducers: {
        [fetchOrders.fulfilled]: (state, action) => {
            state.orders = action.payload;
            state.loading = false;
        },
        [fetchOrders.pending]: (state) => {
            state.loading = true;
        },
        [fetchOrders.rejected]: (state, action) => {
            state.error = action.error.message;
        },
        
    }
});

export default ordersReducer.reducer;