export const options = [
    "Lifestyle",
    "Fashion",
    "Beauty",
    "Health & Fitness",
    "Travel",
    "Food & Drink",
    "Model",
    "Comedy & Entertainment",
    "Art & Photography",
    "Music & Dance",
    "Entrepreneur & Business",
    "Family & Children",
    "Animals & Pets",
    "Athlete & Sports",
    "Adventure & Outdoors",
    "Celebrity & Public Figure",
  ];

  export const platforms = ["Any", "Instagram", "Tiktok", "User Generated", "Youtube"];