import React, { useState, useEffect } from 'react'
import "./Account.css";
import axios from 'axios';
import { SubFooter, Footer } from "../index";
import { useSelector } from 'react-redux';
import { signOut } from "firebase/auth";
import { useNavigate } from 'react-router-dom';
import { auth } from "../../config/firebase-config";
import { updateEmail } from "firebase/auth";
import { Notify } from "../../components/Notify";
import { BASE_URL } from "../../config/config";
// import { updateEmail } from "firebase/auth";

import toast from "react-hot-toast";

const Account = () => {
  const influencerProfile = useSelector((state) => state.User.influencerProfile);
  const user = useSelector((state) => state.User.user);
  const brand = useSelector((state) => state.User.brand);
  const navigate = useNavigate();
  const [email, setEmail] = useState(null);
  const [buttonDisabled, setBtnDisabled] = useState(false);

  const UpdateEmailInputHandler = (e) => {
    setBtnDisabled(true);
    setEmail(e.target.value);
  }

  console.log("user", user)

  const updateUserEmail = async () => {
    const uid = influencerProfile ? influencerProfile.uid : null;
    const data = {
      email: email
    }
    try {
      await updateEmail(auth.currentUser, email)
      try {
        const response = await axios.patch(`${BASE_URL}/updateInfluencerEmail/${uid}`, data);
        if (response) {
          Notify("success", response.data);
          setTimeout(() => {
            window.location.reload();
          }, 1000)
        }
      } catch (error) {
        Notify("error", error.message);
      }

      // await axios.patch(`${BASE_URL}/updateInfluencerEmail/${uid}`, data).then((result) => {
      //   Notify("success", result.data);
      // setTimeout(() => {
      //   window.location.reload();
      // }, 1000)
      // }).catch((error) => {
      //   Notify("error",error.message);
      // })


    } catch (error) {
      Notify("error", error.message);
    }
    // updateEmail(auth.currentUser, email).then(() => {
    //   // Email updated!
    //   try {
    //     Notify("success","Successfully Update Email!");
    //     const emailUpdate = await axios.patch(`${BASE_URL}/updateInfluencerEmail`)
    //   } catch (error) {

    //   }
    //   // ...
    // }).catch((error) => {
    //   // An error occurred
    //   Notify("error",error.message);
    //   // ...
    // });
  }


  // const deleteAccount = async () => {
  //   try {
  //     await deleteUser(auth);
  //     toast.success("Successfuly Account Deleted.");
  //   } catch (error) {
  //     toast.error("Soory It has Some Problem.");
  //   }
  // }

  const userLogout = async () => {
    try {
      await signOut(auth);
      toast.success("Successfuly Sign out.");
      setTimeout(() => {
        navigate("/");
        window.location.reload();
      }, 3000)
    } catch (error) {
      toast.error("Soory It has Some Problem.");
    }
    // dispatch(logout());
  }


  useEffect(() => {
    if (user) {
      setEmail(user.email);
    }
    if (brand) {
      setEmail(brand.email);
    }
  }, [user, brand])

  // console.log("User===>", user);
  return (
    <div>
      <div className='account'>
        <div className="account-heading">Account</div>
        {/* change Email */}
        <div className="input_Container_Account">
          <p className='changeEmail_Label'>Change Your Email</p>
          <input
            type="text"
            placeholder="Email"
            name="email"
            defaultValue={email}
            onChange={UpdateEmailInputHandler}
          />
        </div>
        {/* change Email Button */}
        <div className="accountSave_container">
          {
            buttonDisabled ? (
              <button className="accountSaveBtn" onClick={updateUserEmail}>
                Save
              </button>

            ) : (
              <button className="accountSaveBtnDisabled" >
                Save
              </button>
            )
          }
        </div>
        {/* Delete Account */}
        {/* <p className='deleteAccountBtn' >Delete Account</p> */}
        {/* Sign Out Button */}
        <div className="account_signOut">
          <button className="account_signOutBtn" onClick={userLogout}>
            Sign Out
          </button>
        </div>
      </div>
      <SubFooter />
      <Footer />
    </div>
  )
}

export default Account;