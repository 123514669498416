import React from 'react'
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Detail from '../Items/Detail';
import SocialMedia from '../Items/SocialMedia';
// import Detail from "../Items/Detail";
// import SocialMedia from "../Items/SocialMedia";
// import Faqs from "../Items/Faqs";
// import Packages from "../Items/Packages";
import ImagesSection from "../Items/ImagesSection";


const BrandEditProfileTab = () => {

    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <>
       
            <Box sx={{ width: '100%', marginTop: "20px" }}>
                <Tabs
                    // textColor="primary"
                    // style={{backgroundColor: "gray"}}
                    TabIndicatorProps={{ style: { background: '#05a081' } }}
                    variant="scrollable"
                    scrollButtons="auto"
                    value={value} onChange={handleChange} aria-label="basic tabs example">
                    <Tab style={{ fontWeight: "bold", color: "gray" }} label="Details" {...a11yProps(0)} />
                    <Tab style={{ fontWeight: "bold", color: "gray" }} label="Social Media" {...a11yProps(1)} />
                    <Tab style={{ fontWeight: "bold", color: "gray" }} label="Images" {...a11yProps(2)} />
                    {/* <Tab style={{fontWeight: "bold", color: "gray"}} label="Packages" {...a11yProps(3)} />
                    <Tab style={{fontWeight: "bold", color: "gray"}} label="FAQ" {...a11yProps(4)} /> */}
                    {/* <Tab style={{fontWeight: "bold", color: "gray"}} label="Portfolio" {...a11yProps(5)} /> */}
                </Tabs>
                <TabPanel value={value} index={0}>
                    <Detail />
                    {/* abc */}
                </TabPanel>
                <TabPanel value={value} index={1}>
                    <SocialMedia />
                </TabPanel>
                <TabPanel value={value} index={2}>
                    
                    <ImagesSection />
                </TabPanel>

                {/* <TabPanel value={value} index={5}>
                Item Six
            </TabPanel> */}
            </Box>
        </>
    )
}


function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ marginTop: 5 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export default BrandEditProfileTab