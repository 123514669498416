import React, { useEffect, useState } from 'react'
import { useSelector } from "react-redux";
import Upload from "../../../images/upload.png";
import toast from "react-hot-toast";
import Profile from "../../../images/profile.webp";
import { Notify } from "../../../components/Notify";
import Loading from "../../../components/Loading/Loading";
import { CustomizedButton } from "../../../components/CustomizedButton";
import { BiDownArrowAlt, BiLoaderCircle } from 'react-icons/bi';
import { BsCameraFill } from 'react-icons/bs';
import { BASE_URL } from "../../../config/config";
import { MdDelete } from "react-icons/md";
import axios from 'axios';
import CropImage from "../../../components/CropImage/CropImage";

const ImagesSection = () => {
    const influencerProfile = useSelector((state) => state.User.influencerProfile);
    let influencerProfileImage = influencerProfile ? influencerProfile.profileImage.url : Profile;

    const [profileImage, setProfileImage] = useState(influencerProfileImage);
    const [contentImage, setContentImage] = useState([]);
    const [loading, setLoading] = useState(false);
    const [coverImage, setCoverImage] = useState(null);
    const [profileImagePreview, setprofileImagePreview] = useState("");
    const [ImageOne, setImageOne] = useState(null);
    const [ImageTwo, setImageTwo] = useState(null);
    const [ImageThree, setImageThree] = useState(null);
    const [indexOne, setIndexOne] = useState(null);
    const [indexTwo, setIndexTwo] = useState(null);
    const [indexThree, setIndexThree] = useState(null);
    const [openModal, setOpenModal] = useState(false);
    const [readText, setReadText] = useState("");
    // const[UploadImage, setUploadImage] = useState(Upload);

    const imageMimeType = /image\/(png|jpg|jpeg)/i;

    const handleProfile = (event) => {

        let file = event.target.files[0];
        // setProfileImage(file);
        if (!file.type.match(imageMimeType)) {
            Notify("error", "Image should be in PNG | JPG | JPEG Format");
            return;
          }
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = () => {

            setOpenModal(true);
      setProfileImage(reader.result);
      setReadText("profileImage");
        };
    };

    const handleCover = (event) => {
        let fileCover = event.target.files[0];
        // if(contentImage.length < 4) {
        //     contentImage.push(fileCover);
        // }
        if (!fileCover.type.match(imageMimeType)) {
            Notify("error", "Image should be in PNG | JPG | JPEG Format");
            return;
          }

        const reader = new FileReader();
        reader.readAsDataURL(fileCover);
        reader.onloadend = () => {

            setOpenModal(true);
      setCoverImage(reader.result);
      setReadText("coverImage");
        };
    };

    const handleImageOne = (event) => {
        console.log("event", event)
        let fileCover = event.target.files[0];
        // if(contentImage.length < 4) {
        //     contentImage.push(fileCover);
        // }
        setIndexOne(fileCover);
        if (!fileCover.type.match(imageMimeType)) {
            Notify("error", "Image should be in PNG | JPG | JPEG Format");
            return;
          }

        const reader = new FileReader();
        reader.readAsDataURL(fileCover);
        reader.onloadend = () => {
            setOpenModal(true);
            setImageOne(reader.result);
            setReadText("imageOne");
        };
    };

    const handleImageTwo = (event) => {
        let fileCover = event.target.files[0];
        // if(contentImage.length < 4) {
        //     contentImage.push(fileCover);
        // }
        setIndexTwo(fileCover);
        if (!fileCover.type.match(imageMimeType)) {
            Notify("error", "Image should be in PNG | JPG | JPEG Format");
            return;
          }

        const reader = new FileReader();
        reader.readAsDataURL(fileCover);
        reader.onloadend = () => {
            setOpenModal(true);
            setImageTwo(reader.result);
            setReadText("imageTwo");
        };
    };
    const handleImageThree = (event) => {
        let fileCover = event.target.files[0];
        // if(contentImage.length < 4) {
        //     contentImage.push(fileCover);
            
        // }
        if (!fileCover.type.match(imageMimeType)) {
            Notify("error", "Image should be in PNG | JPG | JPEG Format");
            return;
          }
        const reader = new FileReader();
        reader.readAsDataURL(fileCover);
        reader.onloadend = () => {
            setOpenModal(true);
      setImageThree(reader.result);
      setReadText("imageThree");
        };
    };

    // Delete Image One
    const deleteImageOne = () => {
        setImageOne(Upload);
        let deletedItem = contentImage.filter((val) => val.name !== indexOne.name);
        setContentImage(deletedItem);
    }

    // Delete Image Two
    const deleteImageTwo = () => {
        setImageTwo(Upload);
        let deletedItem = contentImage.filter((val) => val.name !== indexTwo.name);
        setContentImage(deletedItem);
    }

    // Delete Image Three
    const deleteImageThree = () => {
        setImageThree(Upload);
        let deletedItem = contentImage.filter((val) => val.name !== indexThree.name);
        setContentImage(deletedItem);
    }


    // let image0 = influencerProfile.contentImage[0] && influencerProfile.contentImage[0].url;
    // let image1 = influencerProfile.contentImage[1] && influencerProfile.contentImage[1].url;
    // let image2 = influencerProfile.contentImage[2] && influencerProfile.contentImage[2].url;
    // let image3 = influencerProfile.contentImage[3] && influencerProfile.contentImage[3].url;

    // console.log("influencerProfile", influencerProfile.contentImage)

    const UpdateImagesSection = async () => {
    let contentImages = [];
  
    if(coverImage !== Upload) {
        contentImages.push(coverImage);
        // console.log("ImageOne", ImageOne)
    }
    if(ImageOne !== Upload) {
        contentImages.push(ImageOne);
        // console.log("ImageOne", ImageOne)
    }
    if(ImageTwo !== Upload) {
        contentImages.push(ImageTwo);
        // console.log("ImageTwo", ImageTwo);
    }
    if(ImageThree !== Upload) {
        contentImages.push(ImageThree);
        // console.log("ImageThree", ImageThree);
    }
    // contentImage.push(coverImage);
    // console.log("ImageTwo", ImageTwo);
    // console.log("ImageThree", ImageThree);
    // console.log("influencerProfile", influencerProfile.contentImage)
    // if(contentImages < 4) {
        console.log("contentImages", contentImages);
    // }
    if(contentImages.length > 1) {

        setLoading(true)
        const id = influencerProfile._id;
        const formData = new FormData();
        formData.append("profileImage", profileImage);
        
        // formData.append("public_id", influencerProfile.profileImage.public_id);
        // formData.append("ProfileContentImage", contentImages);
        contentImages.map((val) => {
           return formData.append("ProfileContentImage", val);
          })
        // for (let i = 0; i < contentImage.length; i++) {
        //    return formData.append('contentImage', contentImage[i])
        //  }
        try {
            const updateProfileImage = await axios.post(`${BASE_URL}/updateInfluencerImages/${id}`, formData);
            if (updateProfileImage) {
                Notify("success", "Changes Saved!");
                setLoading(false)
                setTimeout(() => {
                    window.location.reload();
                }, 2000)
            } else {
                // alert("hello")
                setLoading(false)
            }
            // console.log("updateProfileImage", updateProfileImage);

        } catch (error) {
            Notify("error", error.message);
            setLoading(false)
        }
    }else {
        Notify("error", "Select Atleast 1 Image");
    }
    }

    //   const profileImagesHandler = () => {
    //     setLoading(true);
    //     if (profileImage === Profile) {
    //       setLoading(false);
    //       Notify("error", "Select Your Profile Photo!");

    //     } else if (coverImage === Upload) {
    //       setLoading(false);
    //       Notify("error", "Select Your Cover Photo!");

    //     } else {
    //       setTimeout(() => {
    //         setLoading(false);
    //         setStep(step + 1);
    //       }, 2000);
    //     }
    //   }


    useEffect(() => {
        setCoverImage(influencerProfile && influencerProfile.contentImage[0] ? influencerProfile && influencerProfile.contentImage[0].url : Upload)
        setImageOne(influencerProfile && influencerProfile.contentImage[1] ? influencerProfile && influencerProfile.contentImage[1].url : Upload)
        setImageTwo(influencerProfile && influencerProfile.contentImage[2] ? influencerProfile && influencerProfile.contentImage[2].url : Upload)
        setImageThree(influencerProfile && influencerProfile.contentImage[3] ? influencerProfile && influencerProfile.contentImage[3].url : Upload)
    }, [influencerProfile])

    return (
        <div className="Update-images-section">
            {/* <h1 className="stepSeven-heading">
                Add up to 5 images of you and your content
            </h1> */}
            <div className="profileImage_Container">
                <input
                    type="file"
                    id="actual-btn"
                    style={{ display: "none" }}
                    onChange={(event) => handleProfile(event)}
                />
                <label htmlFor="actual-btn">
                    <img className="profile_Image" src={profileImage} alt="Profile.png" />
                    <BsCameraFill size={20} />
                </label>
            </div>
            {/* ********  check if profile image cover image then crop will work ******** */}
      {
        readText === "profileImage" ?
          openModal && <CropImage openModal={openModal} setOpenModal={setOpenModal} image={profileImage} setImage={setProfileImage} /> :
          openModal && readText === "coverImage" ?
            <CropImage openModal={openModal} setOpenModal={setOpenModal} image={coverImage} setImage={setCoverImage} /> :
            openModal && readText === "imageOne" ?
              <CropImage openModal={openModal} setOpenModal={setOpenModal} image={ImageOne} setImage={setImageOne} /> :
              openModal && readText === "imageTwo" ?
                <CropImage openModal={openModal} setOpenModal={setOpenModal} image={ImageTwo} setImage={setImageTwo} /> :
                openModal && readText === "imageThree" ?
                  <CropImage openModal={openModal} setOpenModal={setOpenModal} image={ImageThree} setImage={setImageThree} /> : null
      }

            {/* Images Container */}
            <div className="images_Container">
                <div className="coverImage_Container">
                    <div className="file_area">
                        <div className="cover-img-badge">Cover Image</div>
                        <input
                            type="file"
                            id="cover-btn"

                            style={{ display: "none" }}
                            onChange={(event) => handleCover(event)}
                        />
                        <label htmlFor="cover-btn">
                            <img className="image" src={coverImage ? coverImage : influencerProfile && influencerProfile.contentImage[0].url} alt="coverImage.png" />
                        </label>
                    </div>
                </div>
                <div className="Images_Cont">
                        <div className='img-row-sq'>
                    <div className="image_One">
                        <div className="file_area">
                            {ImageOne !== Upload && (
                                        <div className="img-badge" onClick={deleteImageOne}>
                                            <MdDelete />
                                        </div>
                                    )}
                            <input
                                type="file"
                                id="imageOne"
                                style={{ display: "none" }}
                                onChange={(event) => handleImageOne(event)}
                            />
                            <label htmlFor="imageOne">
                                <img className="image" src={ImageOne} alt="ImageOne.png" />
                            </label>
                        </div>
                    </div>


                    <div className="image_Two">
                        <div className="file_area">
                            {ImageTwo !== Upload && (
                                        <div className="img-badge" onClick={deleteImageTwo}>
                                            <MdDelete />
                                        </div>
                                    )}
                            <input
                                type="file"
                                id="ImageTwo"
                                style={{ display: "none" }}
                                onChange={(event) => handleImageTwo(event)}
                            />
                            <label htmlFor="ImageTwo">
                                <img className="image" src={ImageTwo} alt="ImageTwo.png" />
                            </label>
                        </div>
                    </div>
</div>
                    <div className="image_Three">
                        <div className="file_area">
                            {ImageThree !== Upload && (
                                        <div
                                            className="img-badge"
                                            onClick={deleteImageThree}
                                        >
                                            <MdDelete />
                                        </div>
                                    )}
                            <input
                                type="file"
                                id="ImageThree"
                                style={{ display: "none" }}
                                onChange={(event) => handleImageThree(event)}
                            />
                            <label htmlFor="ImageThree">
                                <img className="image" src={ImageThree} alt="ImageThree.png" />
                            </label>
                        </div>
                    </div>


                </div>
            </div>
            <div className='saveChangesBtn'>
                {
                    !loading ? (
                        <CustomizedButton disabled={!influencerProfile} onClick={UpdateImagesSection} className='saveChanges'>Save</CustomizedButton>
                    ) : (
                        <CustomizedButton className='saveChanges'>Loading...</CustomizedButton>
                    )
                }
            </div>

        </div>
    )
}

export default ImagesSection


// const getWithPromiseAll = async() => {
            //     console.time("promise all");
            //     let data = await Promise.all(ProfileContentImage.map(async (val, index) => {
            //         let locaFilePath = contentImages[0].public_id;
            //        const deleteImages = await cloudinary.uploader.destroy(locaFilePath)
            //        if(deleteImages.result === "ok") {
            //         console.log("Deleted!");
            //        const updatedImages = await cloudinary.uploader.upload(val[0], {
            //             folder: "influencersProfile"
            //         })
            //         if (updatedImages) {
            //             let images = {
            //                 public_id: result.public_id || contentImages[0].public_id,
            //                 url: result.url || contentImages[0].url
            //             }
            //             allImages.push(images);
            //             console.log("allImages Inner", allImages);
            //            const updateImageDb = await InfluencerProfile.updateMany({ _id: id }, {
            //                 $set: {
            //                     contentImage: allImages
            //                 }
            //             })
            //             console.log(updateImageDb)
            //         }
            //        } 
            //     //   return "";
            //     }))
            //     console.timeEnd("promise all", data);
            //  }
             
            //  getWithPromiseAll();