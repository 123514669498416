import React from 'react'
import "./CampaignGuide.css";
import campaign from "../../images/campaign.png";


const CampaignGuide = () => {
    return (
        <div className='campaignGuide'>
            <div className='campaignGuide_Container'>
                <div className='campaignGuide_Image'>
                    <img src={campaign} alt="marketPlace.png" />
                </div>
                <div className='campaignGuide_desc'>
                    <h1 className='campaignGuide_heading'>Save Hours With Campaigns and Work With Influencers at Scale</h1>
                    <div className='campaignGuide_Item'>
                        <p className='campaignGuide_Item_Title'>Create Campaigns</p>
                        <p className='campaignGuide_Item_desc'>Centralize your images, requirements, content examples, and more in a campaign brief.</p>
                    </div>
                    <div className='campaignGuide_Item'>
                        <p className='campaignGuide_Item_Title'>Add Influencers</p>
                        <p className='campaignGuide_Item_desc'>Search and add influencers to your campaign in seconds.</p>
                    </div>
                    <div className='campaignGuide_Item'>
                        <p className='campaignGuide_Item_Title'>Send to Influencers</p>
                        <p className='campaignGuide_Item_desc'>Order from influencers in a single click by sending your campaign.</p>
                    </div>

                </div>

            </div>
        </div>
    )
}

export default CampaignGuide;