import React, { useState } from 'react'
import Loading from '../../../../components/Loading/Loading';
import { Notify } from '../../../../components/Notify';

const BrandStepThree = ({ step, setStep, brandDescription, setBrandDescription }) => {
  const [loading, setLoading] = useState(false);

  const descriptionHandler = () => {
    setLoading(true);
   
      setTimeout(() => {
        setLoading(false);
        setStep(step + 1);
      }, 2000)
  };

  const skipHandler = () => {
    setStep(step + 1);
  }
  return (
    <div className="stepThree">
      <div>
        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          <h1 className="stepThree-heading">
            Describe your brand
          </h1>
          <button style={{ width: 80, fontSize: 12 }} className="continueBtn" onClick={skipHandler}>
            SKIP
          </button>
        </div>


        <div className="input_Container">
          <textarea
            cols={8}
            rows={8}
            value={brandDescription}
            onChange={(e) => setBrandDescription(e.target.value)}
            placeholder="What do you sell? What is your Mission? Be specific as this is how influencers will learn more about you brand."
          ></textarea>
        </div>

        {/* signUp Button */}
        <div className="continue_container">
          {
            loading ? (
              <button className="continueBtn">
                <Loading />
              </button>
            ) : (
              <button className="continueBtn" onClick={descriptionHandler}>
                Continue
              </button>
            )
          }

        </div>
      </div>
    </div>
  )
}

export default BrandStepThree