import React, { useState, useEffect, useLayoutEffect } from "react";
import "./App.css";
import { Routes, Route, useParams, json, useLocation  } from "react-router-dom";
import Home from "./pages/Home/Home";
import Creator from "./pages/Creator/Creator";
import Layout from "./Layout";
import MobileNavHeader from "./components/MobileNavHeader/MobileNavHeader";
import CreatorSignUp from "./pages/CreatorSignup/CreatorSignUp";
import StepEmailOtpVerify from "./pages/CreatePage/Steps/StepEmailOtpVerify/StepEmailOtpVerify";
import CreatePage from "./pages/CreatePage/CreatePage";
import Brand from "./pages/Brand/Brand";
import BrandSignup from "./pages/BrandSignup/BrandSignup";
import Login from "./pages/Login/Login";
import UserProfile from "./pages/UserProfile/UserProfile";
import Influencers from "./pages/Influencers/Influencers";
import Account from "./pages/Account/Account";
import BrandProfile from "./pages/BrandProfile/BrandProfile";
import Dashboard from "./pages/Admin/Dashboard/Dashboard";
import AllInfluencers from "./pages/Admin/AllInfluencers/AllInfluencers";
import AllBrands from "./pages/Admin/AllBrands/AllBrands";
import InfluencerForApproval from "./pages/Admin/InfluencerForApproval/InfluencerForApproval";
import EditInfluencerProfile from "./pages/EditInfluencerProfile/EditInfluencerProfile";
import InfluencerProfileReview from "./pages/InfluencerProfileReview/InfluencerProfileReview";
import ResetPassword from "./pages/ResetPassword/ResetPassword";
import { BASE_URL } from "./config/config";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { createUserWithEmailAndPassword, onAuthStateChanged } from "firebase/auth";
import { auth } from "./config/firebase-config";
import { currentUser, logout, currentBrand, currentInfluencerProfile, currentBrandProfile } from "./Redux/Reducers/UserReducer";
// import { AddInfluencerProfile } from "./Redux/Reducers/InfluencerProfileReducer";
import { fetchInfluencerProfile } from "./Redux/Reducers/fetchInfluencerProfile";
import Favourites from "./pages/Favourites/Favourites";
import Chats from "./pages/Chats/Chats";
import CreateOffer from "./pages/CreateOffer/CreateOffer";
import CreateOfferTab from "./pages/CreateOfferTab/CreateOfferTab";
import CreateOffer2 from "./pages/CreateOffer/CreateOffer2";
import BrandCreatedProfile from "./pages/BrandCreatedProfile/BrandCreatedProfile";
import { Notify } from "./components/Notify";
import { fetchBrandProfile } from "./Redux/Reducers/fetchBrandProfile";
import BrandEditProfile from "./pages/BrandEditProfile/BrandEditProfile";
import Billing from "./pages/Billing/Billing";
import Lists from "./pages/Lists/Lists";
import { BrandProtectedRoute, InfluencerProtectedRoute } from "./ProtectedRoute/ProtectedRoute";
import Orders from "./pages/Orders/Orders";
import BrandRequest from "./pages/BrandRequest/BrandRequest";
import NotFound from "./pages/NotFound/NotFound";
import Checkout from "./pages/Checkout/Checkout";
import OurTeam from "./pages/OurTeam/OurTeam";
import BrandRequestView from "./pages/BrandRequestView/BrandRequestView";
import io from "socket.io-client";
import InfluencerOrder from "./pages/InfluencerOrder/InfluencerOrder";
import PaymentSuccess from "./pages/PaymentSuccess/PaymentSuccess";
import PaymentError from "./pages/PaymentError/PaymentError";
import InfluencerBilling from "./pages/InfluencerBilling/InfluencerBilling";
import InfluencerPaymentRequest from "./pages/InfluencerPaymentRequest/InfluencerPaymentRequest";
import BrandOffers from "./pages/BrandOffers/BrandOffers";
import BrandRequestCheckout from "./pages/BrandRequestCheckout/BrandRequestCheckout";
import { getnotification } from "./Redux/Reducers/ChatReducer";
import HashLoader from "./components/HashLoader";


function App() {
  const current_influencerProfile = useSelector((state) => state.User.influencerProfile);
  const current_brand_Profile = useSelector((state) => state.brand_Profile.brand_Profile);
  const brand = useSelector((state) => state.User.brand);

  const [influencerInfo, setinfluencerInfo] = useState(null);
  const [brandInfo, setBrandInfo] = useState(null);
  const [influencerProfile, setInfluencerProfile] = useState(null);
  const [brandProfile, setBrandProfile] = useState(null);
  const [isUser, setIsUser] = useState(false);
  const [loading, setLoading] = useState(false);
  // Chat States
  const [currentChat, setCurrentChat] = useState(null);
  const [messages, setMessages] = useState([]);
  const [notifications, setNotifications] = useState([]);

  const location = useLocation();



  // Chat

  const dispatch = useDispatch();
  let CURRENT_USER = current_influencerProfile || current_brand_Profile;



  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      if (user) {
        setTimeout(() => {
          axios.get(`${BASE_URL}/getUser/${user.uid}`).then((res) => {
            let result = res?.data?.user;
            if (result?.role === "Influencer") {
              setinfluencerInfo(result);
              dispatch(currentUser(result));
              setLoading(false);
            } else if (result?.role === "Brand") {
              setBrandInfo(result);
              dispatch(currentBrand(result));
              setLoading(false);
              // navigate("/");
            } else {
              dispatch(logout());
              setLoading(false);
            }
          }, 2000);
        })
      } else {
        setLoading(false);
      }
    });

  }, [])

  const getInfluencerProfile = async (uid) => {
    try {
      const response = await axios.get(`${BASE_URL}/api/getCurrentInfluencer/${uid}`);
      const data = await response.data.logInProfile;

      if (data.approved) {
        setInfluencerProfile(data);
        dispatch(currentInfluencerProfile(data));
      }
    } catch (error) {
      Notify("error", "Some Thing went Wrong!");
    }
  }

  const getBrandProfile = async (uid) => {
    dispatch(fetchBrandProfile(uid)).then((res) => {
      if (res.type === "brand_Profile/fetchBrandProfile/fulfilled") {
        setBrandProfile(res?.payload);
        dispatch(currentBrandProfile(res?.payload));
      }
    })
  }

  useEffect(() => {
    if (influencerInfo) {
      influencerInfo.profileCompleted && getInfluencerProfile(influencerInfo.uid);
    }
  }, [influencerInfo])

  useEffect(() => {
    if (brandInfo) {
      brandInfo.profileCompleted && getBrandProfile(brandInfo.uid);
    }
  }, [brandInfo])

  useEffect(() => {
    setLoading(true);
    dispatch(fetchInfluencerProfile());
  }, [dispatch])


  // Add User
  // useEffect(() => {
  //   if (CURRENT_USER !== null) {
  //     socketio.emit("new-user-add", CURRENT_USER.uid);
  //     socketio.on("get-users", (users) => {
  //       setOnlineUsers(users);
  //     });
  //   }
  // }, [CURRENT_USER])

  // Get All Chats
  // useEffect(() => {
  //   if (CURRENT_USER) {
  //     const fetchAllChats = async () => {
  //       try {
  //         const response = await axios.get(`${BASE_URL}/userChat/${CURRENT_USER?.uid}`);
  //         const data = await response.data;
  //         setAllChats(data);
  //       } catch (error) {
  //         Notify("error", "Some Thing Went Wrong!");
  //       }
  //     }

  //     fetchAllChats();
  //   }
  // }, [CURRENT_USER]);

  // Send Message to the socket server
  // useEffect(() => {
  //   if (sendMessage !== null) {
  //     socketio.emit("send-message", sendMessage)
  //   }
  // }, [sendMessage])


  useEffect(() => {
    const fetchUserNotifications = () => {
      dispatch(getnotification(CURRENT_USER?.uid)).then((res) => {
        if (res?.type === "Chat/getnotification/fulfilled") {
          let filterNotify = res.payload.filter((v) => v.senderId !== CURRENT_USER?.uid);
          if (filterNotify) {
            setNotifications(filterNotify);
          }
        }
      })
    }
    if (CURRENT_USER !== null) fetchUserNotifications();
  }, [CURRENT_USER]);



    // Scroll to top if path changes
    useLayoutEffect(() => {
      window.scrollTo(0, 0);
    }, [location.pathname]);


   


  return (
    
    <div style={{ height: "100vh" }}>
      {
        navigator.onLine ? (


          loading ? (
            <div style={{ height: "100%", display: "flex", justifyContent: "center", alignItems: 'center', margin: "0 auto" }}>
              <HashLoader />
            </div>
          ) : (

            <>
              <div>
                {/* <Header /> */}
                <Routes>
                  <Route element={<Layout
                    notifications={notifications}
                  />}>
                    <Route path="/" element={<Home />} />
                    <Route path="/login" element={<Login isUser={isUser} setIsUser={setIsUser} />} />
                    <Route path="/creator" element={<Creator />} />
                    <Route path="/our-team" element={<OurTeam />} />
                    <Route path="/creator-signup/:name" element={<CreatorSignUp />} />
                    <Route path="/brand-Signup" element={<BrandSignup />} />
                    <Route path="/:userProfile" element={<UserProfile />} />
                    <Route path="/reset-password" element={<ResetPassword />} />
                    <Route path="/influencers" element={<Influencers />} />
                    <Route path="/brand" element={<Brand />} />
                    <Route path="/orders" element={<Orders CURRENT_USER_PROFILE={CURRENT_USER} />} />
                    <Route path="/account" element={<Account />} />
                    <Route path="/payment-success" element={<PaymentSuccess brandProfile={brandProfile} />} />
                    <Route path="/payment-error" element={<PaymentError brandProfile={brandProfile} />} />

                    {/* Brand */}
                    <Route path="/brand/:brandProfile" element={<BrandCreatedProfile />} />

                    <Route path="/not-found" element={<NotFound />} />
                    <Route path="/Brand-Request-View/:id" element={<BrandRequestView />} />
                    <Route path="/chat" element={
                      <Chats
                        CURRENT_USER={CURRENT_USER}
                        currentChat={currentChat}
                        setCurrentChat={setCurrentChat}
                        messages={messages}
                        setMessages={setMessages}
                        notifications={notifications}
                        setNotifications={setNotifications}
                        brandProfile={brandProfile}
                      />
                    } />

                    <Route path="/influencer-orders" element={<InfluencerOrder CURRENT_USER_PROFILE={CURRENT_USER} />} />
                    {/* Influencer Protected Routes */}
                    <Route element={<InfluencerProtectedRoute />}>
                      <Route path="/stepEmailOtpVerify" element={<StepEmailOtpVerify setinfluencerInfo={setinfluencerInfo} />} />
                      <Route path="/influencer-profile-review" element={<InfluencerProfileReview />} />
                      <Route path="/edit-influencer-profile" element={<EditInfluencerProfile />} />
                      <Route path="/create-page" element={<CreatePage />} />
                      <Route path="/brands-Request" element={<BrandRequest CURRENT_USER_PROFILE={CURRENT_USER} />} />
                      <Route path="/influencer-billing" element={<InfluencerBilling CURRENT_USER_PROFILE={CURRENT_USER} />} />
                      <Route path="/influencer-payment-request/:id/:userName" element={<InfluencerPaymentRequest CURRENT_USER_PROFILE={CURRENT_USER} />} />
                    </Route>
                    {/* Brand Protected Routes */}
                    <Route element={<BrandProtectedRoute />}>

                      <Route path="/edit-brand-profile" element={<BrandEditProfile />} />
                      <Route path="/profile-complete" element={<BrandProfile />} />
                      {/* <Route path="/account" element={<Account />} /> */}
                      <Route path="/favourites" element={<Favourites />} />
                      {/* <Route path="/create-offer" element={<CreateOfferTab />} /> */}
                      <Route path="/billing" element={<Billing CURRENT_USER_PROFILE={CURRENT_USER} />} />

                      {/* <Route path="/create-offer" element={<CreateOffer2 />} /> */}

                      <Route path="/lists" element={<Lists />} />
                      <Route path="/create-offer/:userName" element={<CreateOffer2 />} />
                      <Route path="/your-offers" element={<BrandOffers CURRENT_USER_PROFILE={CURRENT_USER} />} />
                      <Route path="/checkout/:id" element={<Checkout CURRENT_USER_PROFILE={CURRENT_USER} />} />
                      <Route path="/brand-request-checkout/:id" element={<BrandRequestCheckout CURRENT_USER_PROFILE={CURRENT_USER} />} />

                    </Route>
                    {/* Admin Dashboard */}
                    <Route path="/admin-dashboard" element={<Dashboard brand={brand} />} />
                    <Route path="/admin-dashboardInfluencers" element={<AllInfluencers brand={brand} />} />
                    <Route path="/admin-dashboardBrands" element={<AllBrands brand={brand} />} />
                    <Route path="/admin-dashboard-Influencer-for-approval" element={<InfluencerForApproval brand={brand} />} />
                    {/* Admin Dashboard */}

                  </Route>
                </Routes>
                <MobileNavHeader />

              </div>

            </>

          )


        ) : (
          <h1>You Dont Have Internet Connection</h1>
        )
      }
    </div>

  );
}

export default App;
