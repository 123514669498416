import React from 'react'
import "./NotFound.css"
import { useNavigate } from 'react-router-dom';



const NotFound = () => {
  const navigate = useNavigate();
  return (
    <div className='error_page'>
      <div className='mt-4'>
        <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQwPlLXt4NFOxJojqmZBSUklhVjtq-MWbCYqi9oacsP0h2fkXJW6mD6A8QlT0janO8Z-iA&usqp=CAU" alt="" />
      </div>
      <div className='no_page'>No Page Found</div>
      <div>
        <p>We looked everywhere for this page.

          <br />
          Are you sure the website URL is correct?
          <br />
        </p>
      </div>
      <div className='go_to_home' onClick={() => navigate("/")}>Go To Home</div>

    </div>
  )
}

export default NotFound