import React, { useState } from 'react';
import "./BrandSignup.css";
import { createUserWithEmailAndPassword, signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import { useDispatch } from 'react-redux';
import { auth } from "../../config/firebase-config";
import toast from "react-hot-toast";
import axios from "axios";
import { useNavigate } from 'react-router-dom';
import { BASE_URL } from "../../config/config";
import { Notify } from "../../components/Notify";
import { provider } from "../../config/firebase-config"
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
// import Bg01 from "../../images/bg01.jpg";
// import Bg02 from "../../images/bg02.jpg";
// import Bg03 from "../../images/bg03.jpg";
import Loading from "../../components/Loading/Loading";
import { currentBrand } from '../../Redux/Reducers/UserReducer';

const BrandSignup = () => {
    const [loading, setLoading] = useState(false);
    const [fullName, setFullName] = useState("");
    const [brand, setBrand] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [fromWhereHeard, setFromWhereHeard] = useState("");

    const navigate = useNavigate();
    const dispatch = useDispatch();


    const signUp = async () => {
        setLoading(true);
        if (!fullName) {
            Notify("error", "Oops Please Enter Your FullName");
            setLoading(false);
        } else if (!email) {
            Notify("error", "Oops Please Enter Your Email");
            setLoading(false);
        } else if (!password) {
            Notify("error", "Oops Please Enter Your Password");
            setLoading(false);
        } else if (!fromWhereHeard) {
            Notify("error", "Oops Please Enter From Where You Heard ABout Us");
            setLoading(false);
        } else {

            try {
                const signUpBrand = await createUserWithEmailAndPassword(auth, email, password);

                const data = {
                    uid: signUpBrand.user.uid,
                    fullName: fullName,
                    brand: brand,
                    email: signUpBrand.user.email,
                    role: "Brand",
                    profileCompleted: false,
                    fromWhereHeard: fromWhereHeard
                }
                await axios.post(`${BASE_URL}/registerbrand`, data).then((result) => {
                    Notify("success", result.data.message);
                    setLoading(false);
                    setTimeout(() => {
                        navigate("/profile-complete");
                    }, 2000);
                    dispatch(currentBrand(data));
                }).catch((error) => {
                    toast.error(error.message);
                })
            } catch (error) {
                error.message = error.message.replace(/\(/g, "");
                error.message = error.message.replace(/\)/g, "");
                error.message = error.message.replace(/\-/g, " ");
                Notify("error", error.message.slice(21).toUpperCase());
                setLoading(false);

                // console.log(error);
            }
        }
    }


    const slider = {
        arrows: false,
        autoplay: true,
        // dots: true,
        infinite: true,
        speed: 250,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplaySpeed: 2000
    };
    return (
        <div className='brandSignup'>
            <div className='w-70'>
                {/* <Slider {...slider}>
                    <div>
                        <img src={Bg01} alt="bg" />
                    </div>
                    <div>
                        <img src={Bg02} alt="bg" />

                    </div>
                    <div>
                        <img src={Bg03} alt="bg" />

                    </div>
                </Slider> */}
            </div>
            <div className=' w-30'>
                <h1 className="brandSignup-heading">Create Your Account</h1>
                <div className="input_Container">
                    <input
                        type="text"
                        placeholder="Full Name"
                        value={fullName}
                        onChange={(e) => setFullName(e.target.value)}
                    />
                </div>
                <div className="input_Container">
                    <input
                        type="text"
                        placeholder="Brand Name"
                        value={brand}
                        onChange={(e) => setBrand(e.target.value)}
                    />
                </div>
                <div className="input_Container">
                    <input
                        type="text"
                        placeholder="Email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                </div>
                <div className="input_Container">
                    <input
                        type="password"
                        placeholder="Password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                </div>
                <div >
                    <select
                        className="fromWhereYouHeard"
                        value={fromWhereHeard}
                        onChange={(e) => setFromWhereHeard(e.target.value)}
                    >
                        <option value="" disabled="" hidden="">
                            How did you heard about us?
                        </option>
                        <option value="Friend/Collegue">Friend/Collegue</option>
                        <option value="FaceBook">FaceBook</option>
                        <option value="Youtube">Youtube</option>
                        <option value="Tiktok">Tiktok</option>
                        <option value="Twitter">Twitter</option>
                        <option value="Instagram">Instagram</option>
                        <option value="Redit">
                            Redit
                        </option>

                        <option value="Other">Other</option>
                    </select>

                </div>

                {/* signUp Button */}

                <div className="continue_container">
                    {
                        loading ? (<button className="continueBtn" >
                            <Loading />
                        </button>) : (<button className="continueBtn" onClick={signUp}>
                            Sign Up
                        </button>)
                    }
                </div>
                <p className="creator_signup_label">
                    By signing up, you agree to our <span className="terms">Terms</span>{" "}
                    and <span className="terms">Privacy Policy</span>.
                </p>

            </div>
        </div>
    )
}

export default BrandSignup