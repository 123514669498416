import React, { useState, useEffect } from "react";
import "./UserProfile.css";
import { AiFillInstagram } from "react-icons/ai";
import { IoLogoTiktok, IoLogoFacebook } from "react-icons/io5";
import { BsTwitter, BsLink45Deg, BsYoutube } from "react-icons/bs";
import { BiEdit } from "react-icons/bi";
import { MdOutlineWatchLater } from "react-icons/md";
import { SubFooter, Footer } from "../index";
import axios from "axios";
import toast from "react-hot-toast";
import CircularProgress from "@mui/material/CircularProgress";
import ShareInfluProfile from "../../components/ShareInfluProfile/ShareInfluProfile";
import ProfileNotApproved from "../../components/ProfileNotApproved/ProfileNotApproved";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, Navigate, Link } from "react-router-dom";
import { BASE_URL } from "../../config/config";
import HashLoader from "../../components/HashLoader";
import ProfileImageModal from "./ProfileImageModal";
import { Helmet } from "react-helmet";
import { getOneInfluencer } from "../../Redux/Reducers/fetchInfluencerProfile";
import { Notify } from "../../components/Notify";
import ProfileModal from "../../components/ProfileImageModal/ProfileImageModal";
import ProfileGalleyImages from "../../components/ProfileGalleyImages/ProfileGalleyImages";
import numeral from "numeral";

const UserProfile = () => {
    // const profile
    const user = useSelector((state) => state.User.user);
    const brand = useSelector((state) => state.User.brand);
    const path = window.location.pathname.toLowerCase();
    const [influencerProfile, setInfluencerProfile] = useState(null);
    const [packageData, setPackageData] = useState(null);
    const [loader, setLoader] = useState(true);
    const [selected, setSelected] = useState(0);
    const [selectPlatform, setSelectPlatform] = useState("All");
    const [openProfile, setOpenProfile] = useState(false);
    const [openGalleryImages, setopenGalleryImages] = useState(false);

    const [modalImage, setmodalImage] = useState("null");
    const dispatch = useDispatch();
    const navigate = useNavigate();


    // const { state } = useLocation();


    const modals = (list) => {
        setopenGalleryImages(!openGalleryImages)
        // setmodalImage(e)
    }


    // ****** get social Links *****
    const profileSocialLinks =
        influencerProfile && influencerProfile.socialLink[0];
    // ****** get social Links *****
    // Get Influencer Profile
    const getProfile = async () => {
        dispatch(getOneInfluencer(path)).then((res) => {
            if (res?.type === "influencers/getOneInfluencer/fulfilled") {
                setInfluencerProfile(res?.payload?.profile);
                if (res?.payload?.profile) {
                    setPackageData(res?.payload?.profile.packages[0]);
                }
            }
        })
    };


    const packagesHandler = (event, i) => {
        setPackageData(event);
        setSelected(i);
    };

    const selectPlatformHandler = (event) => {
        setSelectPlatform(event.target.value);
    };

    // ****** If there is No User Profile then for 10 sec loader after That message *****





    const sendCustomOffer = () => {
        if (user) {
            Notify("error", "Influencer Can Not Create Offer!")
        } else if (brand && !brand?.profileCompleted) {
            Notify("error", "Complete Your Brand Profile!");
            setTimeout(() => {
                navigate(`/profile-complete`);
            }, 1000)
        } else if (brand && brand?.profileCompleted) {
            navigate(`/create-offer/${influencerProfile?.userName}`);
        } else {
            Notify("error", "Login As a Brand!")
        }
        // console.log("influencerProfile", influencerProfile)
    }

    // useEffect(() => {
    //     if(influencerProfile) {
    //         if(influencerProfile.profileCompleted) {
    //             navigate("/profile");
    //         }
    //     }
    // }, [])

    useEffect(() => {
        // if(user && user.profileCompleted) {
        getProfile();
        setTimeout(() => {
            setLoader(false);
        }, 2000);

        // }
        // if(user && user.profileCompleted) {
        // }
        // setPackageData(influencerProfile.packages[0]);
    }, [path]);

    const continueHandler = () => {
        if (user) {
            Notify("error", "Please Register as a Brand")
        } else if (brand && !brand?.profileCompleted) {
            Notify("error", "Complete Your Brand Profile!");
            setTimeout(() => {
                navigate(`/profile-complete`);
            }, 1000)
        }
        else if (brand) {
            navigate(`/checkout/${influencerProfile?._id}`, { state: { influencerProfile, packageData } })
        } else {
            Notify("error", "Login As a Brand!");
        }
    }





    if (user) {
        if (user.profileCompleted === false) {
            return <Navigate to="/create-page" replace />
        }
        if (user.is_blocked) {
            return <Navigate to="/login" replace />
        }
    }



    // if (!influencerProfile) {

    //    return <CircularProgress className="loader" />
    //     // setTimeout(() => {
    //     //      console.log("There Is No User with this UserName");
    //     // }, 2000);
    // }
    // //   if (influencerProfile) {

    //   }


    return (
        <>
            <Helmet>
                <title>Collabscafe | Influencer Profile</title>
            </Helmet>
            {/* Check If Profile Is Available Or not */}
            {
                influencerProfile ? (
                    <div>
                        {/* Check If Profile Is Approved Or Not */}
                        {influencerProfile.approved ? (
                            <div className="userProfile">


                                {user && user.email === influencerProfile.email && <div className="editInfluencerProfile_Contaier">
                                    <BiEdit className="editInfluencerProfile" />
                                    <Link className="editInfluencerProfile_link" to="/edit-influencer-profile">
                                        <span>Edit Profile</span>
                                    </Link>
                                </div>}
                                <ShareInfluProfile userName={influencerProfile.userName} influencerProfile={influencerProfile} />
                                {/* Images Section */}
                                <div className={`grid images_Container`}>
                                    {
                                        influencerProfile.contentImage.map((elem, index) => {
                                            return (
                                                <>
                                                    <div key={index} style={{ backgroundImage: `url(${elem.url})` }}
                                                        onClick={() => modals(influencerProfile.contentImage)}
                                                    >
                                                    </div>
                                                </>
                                            )
                                        })
                                    }
                                </div>

                                {

                                    openGalleryImages && <ProfileGalleyImages openGalleryImages={openGalleryImages}
                                        setopenGalleryImages={setopenGalleryImages}
                                        images={influencerProfile.contentImage}
                                    />
                                }
                                {/* userProfile_Details */}
                                <div className="userProfile_Container">
                                    <div className="userProfile_desc">
                                        <p className="userProfile_Title">
                                            {influencerProfile && influencerProfile.influencerTitle}
                                        </p>
                                        <p className="userProfile_description">
                                            {influencerProfile &&
                                                influencerProfile.influencerDescription}
                                        </p>
                                    </div>
                                    <div className="userProfile_Images_links_Detail">
                                        <div className="userProfiles_Details">
                                            {/* User Profile Image */}
                                            <img
                                                onClick={() => setOpenProfile(!openProfile)}
                                                className="userProfile_influencer_Image"
                                                src={influencerProfile && influencerProfile.profileImage.url}
                                                alt="User_Profile.png"
                                            />
                                            {
                                                openProfile && <ProfileModal openProfile={openProfile}
                                                    setOpenProfile={setOpenProfile}
                                                    image={influencerProfile.profileImage.url}
                                                />
                                            }
                                            {/* User Profile Name */}
                                            <div className="userProfile_influencer_Name">
                                                {influencerProfile && influencerProfile.fullName}
                                            </div>
                                            <div className="userProfile_influencer_location">
                                                {influencerProfile && influencerProfile.influencerCity}
                                            </div>
                                            <div className="userProfile_influencer_Social_Icons_Container">
                                                {profileSocialLinks &&
                                                    profileSocialLinks.influencerInsta && (
                                                        <a
                                                            href={profileSocialLinks.influencerInsta}
                                                            target="_blank"
                                                        >
                                                            <AiFillInstagram className="userProfile_influencer_Social_Icons" />
                                                        </a>
                                                    )}
                                                {profileSocialLinks &&
                                                    profileSocialLinks.influencerTiktok && (
                                                        <a
                                                            href={profileSocialLinks.influencerTiktok}
                                                            target="_blank"
                                                        >
                                                            <IoLogoTiktok className="userProfile_influencer_Social_Icons" />
                                                        </a>
                                                    )}
                                                {profileSocialLinks &&
                                                    profileSocialLinks.influencerTwitter && (
                                                        <a
                                                            href={profileSocialLinks.influencerTwitter}
                                                            target="_blank"
                                                        >
                                                            <BsTwitter className="userProfile_influencer_Social_Icons" />
                                                        </a>
                                                    )}
                                                {profileSocialLinks &&
                                                    profileSocialLinks.influencerFacebook && (
                                                        <a
                                                            href={profileSocialLinks.influencerFacebook}
                                                            target="_blank"
                                                        >
                                                            <IoLogoFacebook className="userProfile_influencer_Social_Icons" />
                                                        </a>
                                                    )}
                                                {profileSocialLinks &&
                                                    profileSocialLinks.influencerYoutube && (
                                                        <a
                                                            href={profileSocialLinks.influencerYoutube}
                                                            target="_blank"
                                                        >
                                                            <BsYoutube className="userProfile_influencer_Social_Icons" />
                                                        </a>
                                                    )}
                                                {profileSocialLinks &&
                                                    profileSocialLinks.influencerWebsite && (
                                                        <a
                                                            href={profileSocialLinks.influencerWebsite}
                                                            target="_blank"
                                                        >
                                                            <BsLink45Deg className="userProfile_influencer_Social_Icons" />
                                                        </a>
                                                    )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* userProfile_Packages */}
                                <div className="userProfile_Packages_Container">
                                    <div className="userProfile_Packages">
                                        {/* userProfile_Packages_Heading */}
                                        <div className="userProfile_Packages_heading">
                                            <p>Packages</p>
                                            <select
                                                name="selectPlatform"
                                                value={selectPlatform}
                                                onChange={selectPlatformHandler}
                                                className="userProfile_Packages_Select"
                                            >
                                                <option value="All">All</option>
                                                {influencerProfile &&
                                                    influencerProfile.packages.map((plat, i) => {
                                                        return (
                                                            <option key={i} value={plat.platform}>
                                                                {plat.platform}
                                                            </option>
                                                        );
                                                    })}
                                            </select>
                                        </div>
                                        {/* userProfile_Packages_Card */}

                                        {
                                            // if selectPlatform equal to All then all will populate
                                            selectPlatform === "All"
                                                ? influencerProfile &&
                                                influencerProfile.packages.map((plat, i) => {
                                                    return (
                                                        <div
                                                            className="userProfile_Packages_Card"
                                                            key={i}
                                                            onClick={() => packagesHandler(plat, i)}
                                                        >
                                                            <p className="userProfile_Packages_Card_packageDeadline">
                                                                <MdOutlineWatchLater className="time_deadline" size={16} /> {plat.packageDeadline}
                                                            </p>
                                                            <p className="userProfile_Packages_Card_platform">
                                                                {plat.platform}
                                                            </p>
                                                            <div className="userProfile_Packages_Card_Title_Cont">
                                                                <p>{plat.packageOffer}</p>
                                                                <div className="circle_selected_Cont">
                                                                    <p>Rs: {numeral(plat.packagePrice).format('0,00.00')}</p>

                                                                    <div
                                                                        className={
                                                                            selected === i
                                                                                ? "circle_selected"
                                                                                : "circle_Not_selected"
                                                                        }
                                                                    ></div>
                                                                </div>
                                                            </div>
                                                            <div className="userProfile_Packages_Card_desc">
                                                                {plat.packageDescription}
                                                            </div>
                                                        </div>
                                                    );
                                                })
                                                : // if selectPlatform equal to Platforms then Platforms will populate
                                                influencerProfile &&
                                                influencerProfile.packages
                                                    .filter((plat) => plat.platform === selectPlatform)
                                                    .map((plat, i) => {
                                                        return (
                                                            <div
                                                                className="userProfile_Packages_Card"
                                                                key={i}
                                                                onClick={() => packagesHandler(plat, i)}
                                                            >

                                                                <p className="userProfile_Packages_Card_platform">
                                                                    {plat.platform}
                                                                </p>
                                                                <div className="userProfile_Packages_Card_Title_Cont">
                                                                    <p>{plat.packageOffer}</p>
                                                                    <div className="circle_selected_Cont">
                                                                        <p>Rs: {numeral(plat.packagePrice).format('0,00.00')}</p>

                                                                        <div
                                                                            className={
                                                                                selected === i
                                                                                    ? "circle_selected"
                                                                                    : "circle_Not_selected"
                                                                            }
                                                                        ></div>
                                                                    </div>
                                                                </div>
                                                                <div className="userProfile_Packages_Card_desc">
                                                                    {plat.packageDescription}
                                                                </div>
                                                            </div>
                                                        );
                                                    })
                                        }
                                    </div>
                                    <div className="userProfile_Packages_Select_Cont">
                                        <p className="userProfile_Packages_Card_packageDeadline">
                                            <MdOutlineWatchLater className="time_deadline" size={16} /> {packageData && packageData.packageDeadline}
                                        </p>
                                        <p className="userProfile_Packages_Select_title">
                                            {influencerProfile && influencerProfile.influencerTitle}
                                        </p>

                                        <p className="userProfile_Packages_Select_Offer">
                                            {packageData && packageData.packageOffer}
                                        </p>
                                        <p className="userProfile_Packages_Select_Price">
                                            {packageData && `Rs: ${numeral(packageData.packagePrice).format('0,00.00')}`}
                                        </p>
                                        <p className="userProfile_Packages_Select_desc">
                                            {packageData && packageData.packageDescription}
                                        </p>
                                        {/* Continue Button */}
                                        <div className="continue_container">
                                            <button className="continueBtn" onClick={continueHandler}>Continue</button>
                                        </div>
                                        <p className="stepOne_label" onClick={sendCustomOffer}>Send Custom Offer</p>
                                    </div>
                                    {/* FAQS */}
                                    <div>
                                        <p className="faq">FAQ</p>
                                        {influencerProfile &&
                                            influencerProfile.faqs.map((accord, index) => {
                                                return (
                                                    <div
                                                        key={index}
                                                        style={{ padding: "20px 0px 20px 0px" }}
                                                    >
                                                        <Accourdion
                                                            id={index}
                                                            question={accord.faqsQuestion}
                                                            answer={accord.faqsDescription}
                                                        // accourdion={accourdion}
                                                        />
                                                    </div>
                                                );
                                            })}
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <ProfileNotApproved />
                        )}
                        <SubFooter />
                        <Footer />
                    </div>
                ) : (
                    <>
                        {loader ? <div style={{ display: "flex", justifyContent: "center", margin: "0 auto" }}>
                            <HashLoader />
                        </div> : <h1 className="loader">There is No User</h1>}
                    </>
                )
            }
        </>
    );
};

const Accourdion = ({ question, answer }) => {
    const [openAccourdion, setOpenAccourdion] = useState(false);
    return (
        <div>
            <div
                style={{ cursor: "pointer" }}
                // className="collabsCafeFAQS_cont"
                onClick={() => setOpenAccourdion(!openAccourdion)}
            >
                <p className="collabsCafeFAQS_title">{question}</p>
            </div>
            {openAccourdion && (
                <div className="accoudion_Desc">
                    <p>{answer}</p>
                </div>
            )}
            {/* Line separetor */}
            <hr />
        </div>
    );
};

export default UserProfile;
