import React, { useState, useEffect } from 'react';
import "./CreateOffer.css";
import { CustomizedButton } from "../../components/CustomizedButton";
import { useParams, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { getOneInfluencer } from '../../Redux/Reducers/fetchInfluencerProfile';
import HashLoader from "../../components/HashLoader";
import { AiFillLock, AiOutlineIdcard } from 'react-icons/ai';
import { AiFillInstagram, AiFillYoutube } from "react-icons/ai";
import { IoLogoTiktok, IoLogoFacebook } from "react-icons/io5";
import { ImTwitter } from "react-icons/im";
import { BsLink45Deg } from "react-icons/bs";
import { Notify } from '../../components/Notify';
import axios from 'axios';
import { BASE_URL, CLIENT_BASE_URL } from '../../config/config';
import Loading from '../../components/Loading/Loading';



const CreateOffer2 = () => {
  const brand = useSelector((state) => state.brand_Profile.brand_Profile);
  // const brand = useSelector((state) => state.User.brand);
  const [activeStep, setActiveStep] = useState(0);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [influencerDetail, setInfluencerDetail] = useState(null);
  const steps = ["Create Offer", "Send Offer"];
  let { userName } = useParams();
  let navigate = useNavigate();


  // console.log("current_brand_Profile", current_brand_Profile)

  const [createOfferData, setCreateOfferData] = useState({
    platform: "",
    summarize: "",
    description: "",
    price: "",
    instagram: "",
    tiktok: "",
    youtube: "",
    twitter: "",
    facebook: "",
    website: "",
  })


  const continueTab = () => {
    if (activeStep < 1) {

      if (!createOfferData.platform) {
        Notify("error", "Platform is Required!");
      }
      else if (!createOfferData.summarize) {
        Notify("error", "Summarize is Required!");
      }
      else if (!createOfferData.description) {
        Notify("error", "Description is Required!");
      }
      else if (!createOfferData.price) {
        Notify("error", "Price is Required!");
      } else {
        if (createOfferData?.price < 5000) {
          Notify("error", "Custom Offer Price Should Be Rs 5000 or Greater!");
        } else {
          setActiveStep(activeStep + 1);
        }
      }
    }
    // else {
    // setActiveTab(2);
    // }
  }


  console.log("brand", brand)

  const createOffer = async () => {
    setLoading(true);
    if (!createOfferData.instagram && !createOfferData.tiktok && !createOfferData.youtube && !createOfferData.twitter && !createOfferData.facebook && !createOfferData.website) {
      Notify("error", "Atleast 1 Social Item Required!");
      setLoading(false);
    } else {

      const data = {
        influencerId: influencerDetail.uid,
        influencerEmail: influencerDetail.email,
        influencerUserName: influencerDetail.userName,
        influencer_profile_image: influencerDetail.profileImage.url,
        brand_profile_image: brand.profileImage.url,
        brandId: brand.uid,
        brandEmail: brand.email,
        brandName: brand.brand,
        isApproved: false,
        isDenied: false,
        platform: createOfferData.platform,
        summarize: createOfferData.summarize,
        description: createOfferData.description,
        price: createOfferData.price,
        instagram: createOfferData.instagram ? createOfferData.instagram : null,
        tiktok: createOfferData.tiktok ? createOfferData.tiktok : null,
        youtube: createOfferData.youtube ? createOfferData.youtube : null,
        twitter: createOfferData.twitter ? createOfferData.twitter : null,
        facebook: createOfferData.facebook ? createOfferData.facebook : null,
        website: createOfferData.website ? createOfferData.website : null
      }

      await axios.post(`${BASE_URL}/sendOfferRequest`, data).then((res) => {
        Notify("success", res?.data?.msg);
        setLoading(false);
        setTimeout(() => {
          navigate(`/${userName}`);
        }, 1000)
        // console.log("Res", res);
      }).catch((error) => {
        Notify("error", error?.response?.data?.msg);
        setLoading(false);
      })
      // console.log("data", data);
      // console.log("createOfferData", createOfferData);
    }
  }


  useEffect(() => {
    const getProfile = async () => {
      dispatch(getOneInfluencer(`/${userName}`)).then((res) => {
        if (res?.type === "influencers/getOneInfluencer/fulfilled") {
          const { uid, email, userName } = res?.payload?.profile;
          setInfluencerDetail(res?.payload?.profile);
          // console.log("Res Create Offer===>>>", res)
        }
      })
    };
    getProfile();
  }, [userName])


  return (
    // influencerDetail ? (
    <div className='create_offer_main'>
      <div className="steps">
        <ul>
          <li onClick={() => setActiveStep(0)} className={activeStep === 0 ? "active" : "normal"}>
            <span className='spans'>1</span>
            <span className='spans'>{steps[0]}</span>
          </li>
          <li
            // onClick={() => setActiveStep(1)}
            className={activeStep === 1 ? "active" : "normal"}>
            <span className='spans'>2</span>
            <span className='spans'>{steps[1]}</span>
          </li>
        </ul>
        {activeStep === 0 && <StepOne
          createOfferData={createOfferData}
          setCreateOfferData={setCreateOfferData}
          influencerDetail={influencerDetail}
        />}
        {activeStep === 1 && <StepTwo
          createOfferData={createOfferData}
          setCreateOfferData={setCreateOfferData}
        />}
        {/* {activeStep === 2 && <StepThree />} */}

        <div className='m-2 editInfluProfile'>
          <div className='saveChangesBtn'>
            {
              activeStep === 0 ? (
                <CustomizedButton className='saveChanges' onClick={continueTab}>Continue</CustomizedButton>
              ) : (
                loading ? (
                  <CustomizedButton className='saveChanges'>
                    <Loading />
                  </CustomizedButton>
                ) : (
                  <CustomizedButton className='saveChanges' onClick={createOffer}>Send Offer</CustomizedButton>
                )
              )
            }
          </div>
        </div>
      </div>

    </div>
  )
}


const StepOne = ({ createOfferData, setCreateOfferData, influencerDetail }) => {
  return (
    <div className='create_offer_tab1 editInfluProfile'> {/* First tab */}
      <h1>Create Offer</h1>
      <p>Create an offer for {influencerDetail?.fullName}. Be specific to ensure they understand your request.</p>
      <div className="updateDetailInput">
        <select className='updateGenderSelect'
          //  name="gender"
          value={createOfferData.platform}
          onChange={(e) => setCreateOfferData({ ...createOfferData, platform: e.target.value })}
        >
          <option value="" disabled="" hidden>
            Choose Platform
          </option>
          <option value="All">All</option>
          <option  >
            Tiktok
          </option>
          <option value="Instagram">Instagram</option>
          <option value="User Generated Content">User Generated Content</option>
          <option value="Youtube">Youtube</option>
        </select>
      </div>

      <div className="updateDetailInput">
        {/* <label className='labelText'>Display Name</label> */}
        <input type="text" placeholder="Summarize your request. E.g 1 Tiktok Video"
          // name="fullName"
          value={createOfferData.summarize}
          onChange={(e) => setCreateOfferData({ ...createOfferData, summarize: e.target.value })}
        />
      </div>
      <div className="updateDetailInput">
        {/* <label className='labelText'>Description</label> */}
        <textarea className='update-Description'
          // name="description"
          placeholder='What are the details of this request? How may posts or photos? What will the influencer be expected to deliver? E.g.1 Tiktok Video promoting our product to your audience'
          value={createOfferData.description}
          onChange={(e) => setCreateOfferData({ ...createOfferData, description: e.target.value })}
        ></textarea>
      </div>
      <div className="updateDetailInput">
        {/* <label className='labelText'>Title</label> */}
        <input type="number" placeholder="Other price (PKR)"
          // name="title"
          value={createOfferData.price}
          onChange={(e) => setCreateOfferData({ ...createOfferData, price: e.target.value })}
        />
      </div>
      <p className='mt-2 f_right'>On average, charges RS: 5000. Keep this in mind when pricing your offer</p>


    </div>
  )
}

const StepTwo = ({ createOfferData, setCreateOfferData }) => {

  const [openInsta, setOpenInsta] = useState(false);
  const [opentiktok, setOpentiktok] = useState(false);
  const [openyoutube, setOpenYoutube] = useState(false);
  const [opentwitter, setOpenTwitter] = useState(false);
  const [openfacebook, setOpenFacebook] = useState(false);
  const [openwebsite, setOpenWebsite] = useState(false);

  return (
    <div className='create_offer_tab1 editInfluProfile'>
      <h1>Add Your Social Channels</h1>
      <p>Adding your social media helps Collabscafe understand your brand better and leads to higher acceptance rates. </p>


      {/* Instagram */}
      {!openInsta ? (
        <div
          className="input_Container_social"
          onClick={() => setOpenInsta(true)}
        >
          <div className="input_container_Item">
            <AiFillInstagram className="icons" />
            <p className="items_text">Add Instagram</p>
          </div>
        </div>
      ) : (
        <div className="inputs_cont">
          <input
            type="text"
            className="inputName"
            placeholder="Instagram UserName"
            value={createOfferData.instagram}
            onChange={(e) => setCreateOfferData({ ...createOfferData, instagram: e.target.value })}
          />

        </div>
      )}
      {/* TikTok */}
      {!opentiktok ? (
        <div
          className="input_Container_social"
          onClick={() => setOpentiktok(true)}
        >
          <div className="input_container_Item">
            <IoLogoTiktok className="icons" />
            <p className="items_text">Add Tiktok</p>
          </div>
        </div>
      ) : (
        <div className="inputs_cont">
          <input
            type="text"
            className="inputName"
            placeholder="Tiktok UserName"
            // name="influencerTiktok"
            value={createOfferData.tiktok}
            onChange={(e) => setCreateOfferData({ ...createOfferData, tiktok: e.target.value })}

          />
        </div>
      )}
      {/* Youtube */}
      {!openyoutube ? (
        <div
          className="input_Container_social"
          onClick={() => setOpenYoutube(true)}
        >
          <div className="input_container_Item">
            <AiFillYoutube className="icons" />
            <p className="items_text">Add YouTube</p>
          </div>
        </div>
      ) : (
        <div className="inputs_cont">
          <input
            type="text"
            className="inputName"
            placeholder="YouTube URL"
            // name="influencerYoutube"
            // defaultValue={influYoutube}
            // onChange={(e) => setSocialLink({ ...socialLink, influencerYoutube: e.target.value })}
            value={createOfferData.youtube}
            onChange={(e) => setCreateOfferData({ ...createOfferData, youtube: e.target.value })}
          />
        </div>
      )}
      {/* Twitter */}
      {!opentwitter ? (
        <div
          className="input_Container_social"
          onClick={() => setOpenTwitter(true)}
        >
          <div className="input_container_Item">
            <ImTwitter className="icons" />
            <p className="items_text">Add Twitter</p>
          </div>
        </div>
      ) : (
        <div className="inputs_cont">
          <input
            type="text"
            className="inputName"
            placeholder="Twitter Url"
            // name="influencerTwitter"
            value={createOfferData.twitter}
            onChange={(e) => setCreateOfferData({ ...createOfferData, twitter: e.target.value })}
          />
        </div>
      )}

      {/* Facebook */}
      {!openfacebook ? (
        <div
          className="input_Container_social"
          onClick={() => setOpenFacebook(true)}
        >
          <div className="input_container_Item">
            <IoLogoFacebook className="icons" />
            <p className="items_text">Add Facebook</p>
          </div>
        </div>
      ) : (
        <div className="inputs_cont">
          <input
            type="text"
            className="inputName"
            placeholder="Facebook Url"
            // name="influencerFacebook"
            value={createOfferData.facebook}
            onChange={(e) => setCreateOfferData({ ...createOfferData, facebook: e.target.value })}

          />
        </div>
      )}

      {/* Website */}
      {!openwebsite ? (
        <div
          className="input_Container_social"
          onClick={() => setOpenWebsite(true)}
        >
          <div className="input_container_Item">
            <BsLink45Deg className="icons" />
            <p className="items_text">Add Website</p>
          </div>
        </div>
      ) : (
        <div className="inputs_cont">
          <input type="text" className="inputName" placeholder="Website Url"
            value={createOfferData.website}
            onChange={(e) => setCreateOfferData({ ...createOfferData, website: e.target.value })}

          />
        </div>
      )}
    </div>
  )
}



const StepThree = () => {
  return (
    <div className='create_offer_tab1 editInfluProfile'>
      {/* Third tab */}
      <h1>Send Offer</h1>
      <p>A hold on your card will be placed for 72 hours. You will only be charged if CollabsCafe accepts your offer.  </p>
      <div className="flex offer_container">
        <div className="w-50 creditContainer">
          <div className="flex mt-2 card_inputs">
            <div className="card_input">
              <div className="flex align-center w-60">
                <AiOutlineIdcard size={20} />
                {/* <label className='labelText'>Display Name</label> */}
                <input type="text" className="card_inp" placeholder="Card Number" name="fullName"
                />
              </div>
              <div className="mr-1">
                <p>MM / YY / CVC</p>
              </div>
            </div>
            <div className="updateDetailInput m-0">
              {/* <label className='labelText'>Display Name</label> */}
              <input type="text" placeholder="Zip/Postal Code" name="fullName"
              />
            </div>
          </div>
          <div className="flex align-center">
            <AiFillLock size={14} />
            <p>Secure payment with stripe</p>
          </div>
          <div className='saveChangesBtn'>
            <CustomizedButton className='saveChanges w-100'>Place Offer</CustomizedButton>
          </div>
        </div>
        <div className="w-50 flex justify-between p-1 pay_detail">
          <div>
            <p>Subtotal</p>
            <p>Fee</p>
            <p>Total</p>
          </div>
          <div className="text-right">
            <p>$23232.20</p>
            <p>$232.20</p>
            <p>$25555.20 USD</p>
          </div>
        </div>
      </div>

      <div className=" flex flex-wrap">
        <div className="width40 flex flex-row">
          <h2>1 </h2>&nbsp; &nbsp;
          <div>
            <h2>
              <span className="font16">Send Offer</span>
            </h2>
            <p className="fontpara">Send your offer to CollabsCafe. They will have 72 hours to accept it. You are only charge if they accept the offer.</p>
          </div>
        </div>
        <div className="width40 flex flex-row">
          <h2>2 </h2>&nbsp; &nbsp;
          <div>
            <h2>
              <span className="font16">Send Offer</span>
            </h2>
            <p className="fontpara">Send your offer to CollabsCafe. They will have 72 hours to accept it. You are only charge if they accept the offer.</p>
          </div>
        </div>
        <div className="width40 flex flex-row">
          <h2>3 </h2>&nbsp; &nbsp;
          <div>
            <h2>
              <span className="font16">Send Offer</span>
            </h2>
            <p className="fontpara">Send your offer to CollabsCafe. They will have 72 hours to accept it. You are only charge if they accept the offer.</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CreateOffer2