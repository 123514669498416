import React, { useState } from 'react'
import "./ShareInfluProfile.css";
import { IoCheckmarkDoneOutline, IoShareOutline } from "react-icons/io5";
import { AiOutlineCopy, AiOutlineHeart } from "react-icons/ai";
import { Notify } from "../Notify";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useSelector } from "react-redux";
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { Button } from '@mui/material';
import { AiOutlineArrowRight, AiOutlineArrowLeft } from 'react-icons/ai';
import { FacebookShareButton, FacebookIcon, WhatsappIcon, WhatsappShareButton, TwitterShareButton, TwitterIcon, LinkedinShareButton, LinkedinIcon, EmailShareButton, EmailIcon } from 'react-share';


const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

function BootstrapDialogTitle(props) {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
}

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};

const ShareInfluProfile = ({ userName, influencerProfile }) => {
    const [openProfile, setOpenProfile] = useState(false);
    const brand = useSelector((state) => state.User.brand);
    const[copied, setCopied] = useState(false);

    const url = window.location.href;

    // Close on Outside Click
    const closeOutside = () => {
        openProfile && setOpenProfile(false);

    }

    // Copy Profile
    const onCopyText = () => {
        setCopied(true);
        Notify("success", "Copied");
    };

    const handleClose = () => {
        setOpenProfile(false);
    };

    // Save Influencer Profile
    const saveInfluencerProfile = () => {
        console.log("influencerProfile", influencerProfile)
    }


    
  window.setTimeout(function () {
    setCopied(false);
  }, 3000);


    return (
        <div >
            <div className='ShareInfluProfile'>
                <IoShareOutline className='heartIcon' /><span onClick={() => setOpenProfile(!openProfile)}>Share</span>
                {
                    brand ? (
                        <>
                            <AiOutlineHeart className='heartIcon' /><span onClick={saveInfluencerProfile}>Save</span>
                        </>
                    ) : null
                }
            </div>
            {/* Profile Modal */}
            <div>
                <BootstrapDialog
                    fullWidth
                    onClose={handleClose}
                    aria-labelledby="customized-dialog-title"
                    open={openProfile}
                >
                    <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
                        <div className='modalHeading_Container'>
                            <p className='modalHeading'>Share <span className='profile_userName'>{userName}</span>'s Profile</p>
                        </div>
                    </BootstrapDialogTitle>

                    <DialogContent>

                        <div className='shareProfileLink'>
                            <p>{url}</p>
                            <CopyToClipboard text={url} onCopy={onCopyText}>
                                {/* <button className="continueBtn" >
                                    Copy Profile Link
                                </button> */}
                                {
                                    copied ? <IoCheckmarkDoneOutline /> : <AiOutlineCopy style={{ cursor: "pointer" }} />
                                }
                                
                            </CopyToClipboard>
                        </div>

                        <div className='share_icons'>
                            <FacebookShareButton
                                url={url}
                                quote={'You can Hire me as a Influencer for Your Product or Brand!'}
                                hashtag="#collabscafe #influencers #affiliatemarketing"
                            >
                                <FacebookIcon size={32} round />
                            </FacebookShareButton>
                            <WhatsappShareButton
                                url={url}
                                quote={'You can Hire me as a Influencer for Your Product or Brand!'}
                            // hashtag="#collabscafe #influencers #affiliatemarketing"
                            >
                                <WhatsappIcon size={32} round />
                            </WhatsappShareButton>
                            <TwitterShareButton
                                url={url}
                                quote={'You can Hire me as a Influencer for Your Product or Brand!'}
                            // hashtag="#collabscafe #influencers #affiliatemarketing"
                            >
                                <TwitterIcon size={32} round />
                            </TwitterShareButton>
                            <LinkedinShareButton
                                url={url}
                                quote={'You can Hire me as a Influencer for Your Product or Brand!'}
                            // hashtag="#collabscafe #influencers #affiliatemarketing"
                            >
                                <LinkedinIcon size={32} round />
                            </LinkedinShareButton>
                            <EmailShareButton
                                url={url}
                                quote={'You can Hire me as a Influencer for Your Product or Brand!'}
                            // hashtag="#collabscafe #influencers #affiliatemarketing"
                            >
                                <EmailIcon size={32} round />
                            </EmailShareButton>
                        </div>
                        {/* <div className="continue_container">
                            <CopyToClipboard text={url} onCopy={onCopyText}>
                                <button className="continueBtn" >
                                    Copy Profile Link
                                </button>
                            </CopyToClipboard>
                        </div> */}
                    </DialogContent>

                </BootstrapDialog>
            </div>
        </div>
    )
}

export default ShareInfluProfile;