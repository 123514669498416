import React, { useState } from 'react'
import "./ResetPassword.css";
import { sendPasswordResetEmail } from "firebase/auth";
import { auth } from "../../config/firebase-config"
import { Notify } from "../../components/Notify";
import Loading from "../../components/Loading/Loading";
import { useNavigate, Link } from 'react-router-dom';

const ResetPassword = () => {

    const [email, setEmail] = useState("");
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const reset_Password = async () => {
        if(email) {
            setLoading(true);
            try {
                await sendPasswordResetEmail(auth, email);
                Notify("success", "Email Has Been Send!");
                setLoading(false);
                setTimeout(() => {
                    navigate("/login");
                }, 2000)
            } catch (error) {
                Notify("error", error.message);
                setLoading(false);
            }
        }else {
            Notify("error", "Please Enter Your Email!");
        }
    }
    return (
        <div className='login'>
        <div className='w-70'></div>
        <div className=' w-30 mx-8'>
            <h1 className="login-heading">Reset Your Password</h1>
            <div className="input_Container">
                <input
                    type="text"
                    placeholder="Enter Your Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                />
            </div>


            {/* <div className='resetPassword'>
        <Link className='link' to="/login">
            <p className='resetPassword'>Back to Login</p>
        </Link>
    </div> */}
            {/* signUp Button */}
            <div className="continue_container">
                {
                    loading ? (<button className="continueBtn" >
                        <Loading />
                    </button>) : (<button className="continueBtn" onClick={reset_Password}>
                        Reset
                    </button>)
                }
            </div>
            </div>
        </div>
    )
}

export default ResetPassword;