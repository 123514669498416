import React, { useEffect, useState, useRef, } from "react";
import "./Chats.css";
import { BiArrowBack } from "react-icons/bi"
import { AiOutlineSend } from "react-icons/ai"

import { useDispatch, useSelector } from "react-redux";
import { fetchedMessage, getUserChats, sendMessage, sendNotification } from "../../Redux/Reducers/ChatReducer";
// import { io } from "socket.io-client";

import { styled } from '@mui/material/styles';

import axios from "axios";
import { BASE_URL } from "../../config/config";
import InputEmoji from 'react-input-emoji'
import Avatar from '@mui/material/Avatar';
import Badge from '@mui/material/Badge';
import { deepOrange, deepPurple } from '@mui/material/colors';
import dayjs from 'dayjs';
import HashLoader from "../../components/HashLoader";
import NoData from "../../images/no-data.jpg";
import { Notify } from "../../components/Notify";
import { currentNotification } from "../../Redux/Reducers/MessageNotification";
import { io } from "socket.io-client";
import { Chip } from "@mui/material";

var relativeTime = require('dayjs/plugin/relativeTime')
dayjs.extend(relativeTime)

const StyledBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
        backgroundColor: '#44b700',
        color: '#44b700',
        boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
        '&::after': {
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            borderRadius: '50%',
            animation: 'ripple 1.2s infinite ease-in-out',
            border: '1px solid currentColor',
            content: '""',
        },
    },
    '@keyframes ripple': {
        '0%': {
            transform: 'scale(.8)',
            opacity: 1,
        },
        '100%': {
            transform: 'scale(2.4)',
            opacity: 0,
        },
    },
}));




const Chats = ({ CURRENT_USER, brandProfile, currentChat, setCurrentChat, messages, setMessages, notifications, setNotifications }) => {
    const dispatch = useDispatch();
    const { loading, allChats } = useSelector((state) => state.Chat);
    // const [currentChat, setCurrentChat] = useState(null);
    // const [messages, setMessages] = useState([]);
    const [selectedChat, setSelectedChat] = useState(null);
    const [newMessage, setNewMessage] = useState("");
    const [onlineUsers, setOnlineUsers] = useState([]);
    // const [notifications, setNotifications] = useState([]);
    const [open, setOpen] = useState(false);
    const [width, setWidth] = useState(0);

    const [socket, setSocket] = useState(null);
    const ref = useRef(null);



    const currChatHandler = (data, index) => {
        setCurrentChat(data);
        setSelectedChat(index);
    }

    useEffect(() => {
        if (CURRENT_USER) {
            // Get All User Chats
            dispatch(getUserChats(CURRENT_USER.uid));

        }
    }, [CURRENT_USER]);


    useEffect(() => {
        // Initialize Socket Connection
        // Backend URL HERE
        const newSocket = io("wss://socket.collabscafe.com");
        // const newSocket = io("http://localhost:3002");
        setSocket(newSocket);

        // return () => {
        //     newSocket.disconnect();
        // }
    }, [CURRENT_USER]);

console.log("socket", socket)

    // Recieve Message and Notification
    useEffect(() => {
        if (socket === null) return;
        // Recieve Message 
        socket.on("getMessage", (message) => {
            if (currentChat?._id !== message.chatId) {
                alert("You Have a New Message");
            } else {

                console.log("message", message)
                setMessages((prev) => [...prev, message]);
            }
        });
        // get Notifications
        socket.on("getNotification", (res) => {
            const isChatOpen = currentChat?.members.some(id => id === res.senderId);
            if (isChatOpen) {
                setNotifications((prev) => [{ ...res, isRead: true }, ...prev]);
            } else {
                setNotifications((prev) => [res, ...prev]);
            }

        });


        return () => {
            socket.off("getMessage");
            socket.off("getNotification");
        }
    }, [socket, currentChat]);



    useEffect(() => {
        // Create a New User in Socket
        if (socket === null) return;
        socket.emit("addNewUser", CURRENT_USER?.uid);
        // Get Online Users from Socket
        socket.on("getOnlineUsers", (res) => {
            setOnlineUsers(res);
        })
        return () => {
            socket.off("getOnlineUsers");
        }
    }, [CURRENT_USER, socket]);


    const mobileViewHandler = () => {
        const { innerWidth, innerHeight } = window;
        if (innerWidth < 1000) {
            setOpen(true);
            setWidth(innerWidth);
        } else {
            setOpen(false);
            setWidth(innerWidth);
        }
    }



    return (

        <div className="chat_container">
            <div className={open ? "chat_open_container" : "chat_chats_container"}>
                <h1 className="chat_chats_heading">All Chats</h1>
                {
                    allChats.length > 0 ? (
                        allChats.map((v, index) => {
                            return (
                                <div key={index} className={selectedChat === index ? "chat-selected" : ""} onClick={() => currChatHandler(v, index)}>
                                    <Conversation
                                        data={v}
                                        CURRENT_USER_ID={CURRENT_USER.uid}
                                        loading={loading}
                                        messages={messages}
                                        onlineUsers={onlineUsers}
                                        open={open}
                                        setOpen={setOpen}
                                        mobileViewHandler={mobileViewHandler}
                                    // online={checkOnlineStatus(v)}
                                    />
                                </div>
                            )
                        })
                    ) : (
                        loading ? (
                            <p>Loading...</p>
                        ) : (
                            <h1 className="no_AvailableChat">No Chats</h1>
                        )
                    )
                }
            </div>
            <div className={open ? "chat_open_message_container" : "chat_message_container"}>
                <ChatBox
                    currentChat={currentChat}
                    CURRENT_USER_ID={CURRENT_USER.uid}
                    CURRENT_USER={CURRENT_USER}
                    messages={messages}
                    setMessages={setMessages}
                    loading={loading}
                    newMessage={newMessage}
                    setNewMessage={setNewMessage}
                    socket={socket}
                    brandProfile={brandProfile}
                    notifications={notifications}
                    setNotifications={setNotifications}
                    onlineUsers={onlineUsers}
                    width={width}
                    setOpen={setOpen}
                />
            </div>
        </div>
    );
};


const Conversation = ({ data, CURRENT_USER_ID, loading, messages, onlineUsers, open, setOpen, mobileViewHandler }) => {
    // To which Chat User
    const [userData, setUserData] = useState(null);

    // Get The User Detail
    useEffect(() => {
        const userId = data.members.find((id) => id !== CURRENT_USER_ID);
        const fetchCurrentUserChat = async () => {
            try {
                const response = await axios.get(`${BASE_URL}/getSpecificUser/${userId}`);
                const data = await response.data.userProfile;
                setUserData(data);
            } catch (error) {
                Notify("error", "Some Thing Went Wrong!");
            }
        }
        fetchCurrentUserChat();
    }, []);


    return (
        <div>
            {/* <p>{dayjs(data.createdAt).format("h-m-A")}</p> */}
            <div className="conversation_container" onClick={() => mobileViewHandler()}>
                <div>
                    <StyledBadge
                        overlap="circular"
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                        // variant={onlineUsers?.some((user) => user?.userId === data._id) ? "dot" : "standard"}
                        variant={onlineUsers?.some((user) => user?.userId === userData?.uid) ? "dot" : "standard"}
                    >
                        <Avatar alt="Remy Sharp" src={userData?.profileImage?.url} />
                    </StyledBadge>
                </div>
                <div className="conversation_detail">
                    <p className="conversation_name">{userData?.fullName}</p>
                    <div className="conversation_text_date" >
                        <p className="m-0">Last...</p>
                        {/* <p>{dayjs(data.createdAt).format("h-m-A")}</p> */}
                    </div>
                </div>

            </div>

        </div>
    )

}

const ChatBox = ({ currentChat, brandProfile, CURRENT_USER_ID, CURRENT_USER, messages, setMessages, loading, newMessage, setNewMessage, socket, notifications
    , setNotifications, onlineUsers, width, setOpen }) => {


    const scroll = useRef();
    const dispatch = useDispatch();
    const [userData, setUserData] = useState(null);



    const typingHandler = (e) => {
        setNewMessage(e);
    }



    const sendMessageHandler = () => {
        if (newMessage.length > 0) {
            const data = {
                chatId: currentChat?._id,
                senderId: CURRENT_USER_ID,
                text: newMessage,
                date: new Date(),
                userData: CURRENT_USER,
            }
            dispatch(sendMessage(data)).then((res) => {
                if (res?.type === "Chat/sendMessage/fulfilled") {
                    setMessages([...messages, res.payload]);
                    setNewMessage("");
                    // Send Message To Server
                    let recieptId = currentChat.members.find((id) => id !== CURRENT_USER_ID);
                    socket.emit("sendMessage", { chatId: currentChat?._id, senderId: CURRENT_USER_ID, text: newMessage, recieptId, CURRENT_USER });
                    // Send Notifications
                    data.recieptId = recieptId;
                    dispatch(sendNotification(data));
                }
            })
        }

    }


    // Get The User Detail
    useEffect(() => {
        const userId = currentChat && currentChat.members.find((id) => id !== CURRENT_USER_ID);
        const fetchCurrentUserChat = async () => {
            try {
                const response = await axios.get(`${BASE_URL}/getSpecificUser/${userId}`);
                const data = await response.data.userProfile;
                setUserData(data);
            } catch (error) {
                Notify("error", "Some Thing Went Wrong!");
            }
        }
        if (currentChat !== null) fetchCurrentUserChat();
    }, [currentChat]);



    // Fetching User Messages
    useEffect(() => {
        const fetchUserMessages = () => {
            dispatch(fetchedMessage(currentChat._id)).then((res) => {
                if (res?.type === "Chat/fetchedMessage/fulfilled") {
                    setMessages(res?.payload);
                }
            })
        }

        if (currentChat !== null) {
            fetchUserMessages();
        }
    }, [currentChat]);








    // Always scroll to last Message
    useEffect(() => {
        scroll.current?.scrollIntoView({ behavior: "smooth" });
    }, [messages])

    const uniqueDates = [...new Set(messages && messages.map(item => dayjs(item.createdAt).format("DD-MM-YYYY")))];


    return (
        currentChat ? (
            <div className="ChatBox_container">
                <div className="ChatBox_Header">
                    <BiArrowBack style={{ cursor: "pointer" }} onClick={() => setOpen(false)} size={20} />
                    <StyledBadge
                        overlap="circular"
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                        variant={onlineUsers?.some((user) => user?.userId === userData?.uid) ? "dot" : "standard"}
                    >
                        <Avatar alt="Remy Sharp" src={userData?.profileImage?.url} />
                    </StyledBadge>
                    <p className="chatBox_name">{userData?.fullName}</p>
                </div>
                <div className="ChatBox_message_Section">
                    <div className="chat_messages">
                        {loading ? <HashLoader /> : null}
                        {
                            messages.length > 0 ? (
                                uniqueDates.map((createdAt, i) => (
                                    <div key={i} >
                                        <div className="chat-on-dates">
                                            <div>{createdAt}</div>
                                        </div>
                                        {/* <ul> */}
                                        <div >
                                            {messages && messages.map(message => {
                                                if (dayjs(message.createdAt).format("DD-MM-YYYY") === createdAt) {
                                                    // Render the list item if the item's date matches the unique date
                                                    return (
                                                        <div ref={scroll} key={message._id} className={message.senderId === CURRENT_USER_ID ? "my_chat_msg_detail" : "chat_msg_detail"}>
                                                            {/* Render the list item */}
                                                            {/* <p>{message.text}</p> */}
                                                            <div className="flex">
                                                                <StyledBadge
                                                                    overlap="circular"
                                                                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                                                // variant={online ? "dot" : ""}
                                                                >
                                                                    <Avatar alt="Remy Sharp" src={message.senderId === CURRENT_USER_ID ? CURRENT_USER?.profileImage?.url : userData?.profileImage?.url} />
                                                                </StyledBadge>
                                                                {/* <Avatar sx={{
                                                                bgcolor: deepPurple[500],
                                                                width: 40, height: 40
                                                            }}>{message.senderId === currUserId ? currentUser?.email.slice(0, 1).toUpperCase() : userData?.email.slice(0, 1).toUpperCase()}</Avatar> */}
                                                                <div className={message.senderId === CURRENT_USER_ID ? "my_chat" : "other_chat"}>
                                                                    <h5>{message?.text}</h5>
                                                                    {/* <h5 className="msg-date">{dayjs(message?.createdAt).format("DD-MM-YYYY")}</h5> */}
                                                                    <h5 className="msg-date">{dayjs(message?.createdAt).fromNow()}</h5>
                                                                    <h5 className="msg-date">✓✓</h5>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    );
                                                } else {
                                                    // Skip rendering if the item's date does not match the unique date
                                                    return null;
                                                }
                                            })}
                                        </div>
                                        {/* </ul> */}
                                    </div>
                                ))) : (
                                null
                            )
                        }
                    </div>
                </div>
                <div className="ChatBox_send_message_section">
                    <InputEmoji
                        style={{ height: "50px" }}
                        onChange={(e) => typingHandler(e)}
                        theme="dark"
                        cleanOnEnter
                        value={newMessage}
                        onEnter={sendMessageHandler}
                        placeholder="Type a message"
                    />
                    <div className="send_btn" >
                        <AiOutlineSend size={20} color="white" onClick={sendMessageHandler} />
                    </div>
                </div>
            </div>
        ) : (

            <div className="no_AvailableChat">
                <h1>No Available Chat</h1>
            </div>
        )


    )
}

export default Chats;
