import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    newMessages: 0,
    notify: null
}

export const notification_Slice = createSlice({
    name: 'Notification',
    initialState,
    reducers: {
        currentNotification: (state, action) => {
            state.newMessages = state.newMessages + 1;
            state.notify = action.payload;
        },

    },
})

// Action creators are generated for each case reducer function
export const { currentNotification } = notification_Slice.actions;

export default notification_Slice.reducer;