import React from 'react';
import "./Dashboard.css";
import { Navigate, Link } from 'react-router-dom';
import CollabsCafeLogo from "../../../images/logoSidebar.png";
import AllInfluencers from "../AllInfluencers/AllInfluencers";
import SideBar from "../../../components/SideBar/SideBar";

const Dashboard = ({ brand }) => {
    // check if there is brand it will back
    if (brand) {
        if (brand.role === "Brand") {
            return <Navigate to="/" replace />
        }
    } else {
        return <Navigate to="/" replace />
    }


    return (
        <div className='dashboard'>
            {/* <SideBar /> */}
            <AllInfluencers />
        </div>
    )
}

export default Dashboard;