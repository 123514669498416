// TEST Environment

// export const BASE_URL = "http://localhost:5000";
// export const BASE_URL = "https://backendlive.collabscafe.com/";

// export const BASE_URL = "http://localhost:5000";
//   export const BASE_URL = "https://backendlive.collabscafe.com";

// export const CLIENT_BASE_URL = "http://localhost:3000";
 export const BASE_URL = "https://backend.collabscafe.com";


// Production Environment
// export const BASE_URL = "https://backendlive.collabscafe.com";
// export const CLIENT_BASE_URL = "https://www.test.collabscafe.com";
export const CLIENT_BASE_URL = "https://www.collabscafe.com";
export const NUM_CHECKER = "https://phonevalidation.abstractapi.com/v1/?api_key=fe154b0a7e3443059b33ccd0c46d075b&phone";
