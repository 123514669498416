import React from "react";
import "./FeaturedInfluencers.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import numeral from "numeral";
import HashLoader from "../HashLoader";
import { Link, useNavigate } from "react-router-dom";
import { BsFillArrowRightCircleFill, BsFillArrowLeftCircleFill } from "react-icons/bs"

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <BsFillArrowRightCircleFill className={className}
      style={{ ...style, display: "block", color: "#05a081", width: "25px", height: "30px" }}
      onClick={onClick} />
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <BsFillArrowLeftCircleFill className={className}
      style={{ ...style, display: "block", color: "#05a081", width: "25px", height: "30px" }}
      onClick={onClick} />
  );
}


const FeaturedInfluencers = ({ profilesList }) => {
  const navigate = useNavigate();


  const cardRedirect = (e) => {
    let name = e.userName.replace(/\s+/g, '');;
    navigate(`/${name}`, { state: e });
  }


  const filterInflu = profilesList && profilesList.filter((v) => v.userName === "iruhhmamalik" || v.userName === "bhailogdigital" || v.userName === "talkyourpay" || v.userName === "faysalazizkhan" || v.userName === "aishamohsinhassan");


  var slider2 = {
    dots: false,
    infinite: false,
    speed: 1000,
    slidesToShow: 4,
    slidesToScroll: 4,
    initialSlide: 0,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1150,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          // dots: true,
          arrows: false,
        }
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          initialSlide: 0,
          arrows: false,
          autoplay: false,
          infinite: false,
          centerPadding: "100px",
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          initialSlide: 0,
          arrows: false,
          autoplay: false,
          infinite: false,
          swipeToSlide: true,
        }
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 2,
          // slidesToScroll: 1,
          // initialSlide: 0,
          arrows: false,
          autoplay: false,
          infinite: false,
          centerPadding: "14px",
          swipeToSlide: true,
        }
      },
    ]
  };



  return (
    <div className="featured_Influencers">

      <h1 className="featured_heading">Featured</h1>
      <div className="feature_heading_desc">
        <p className="feature_heading_desc_One">
          Hire top influencers across all platforms
        </p>
        <Link className="link" to="/influencers">
          <p className="featured_seeAll">See All</p>
        </Link>
      </div>

      {/* ******* Image Card ******* */}
      <div className="listing-row">
        {/* Card One */}
        {
          filterInflu && filterInflu.length > 0 ?
            filterInflu.map((influ) => {
              return (
                <div className="featured-profile-listing-holder" key={influ._id} onClick={() => cardRedirect(influ)}>
                  <div className="profile-listing-img-holder">
                    <img
                      loading="lazy"
                      className="profile-listing-img"
                      src={influ.contentImage[0] && influ.contentImage[0].url}
                      alt="Influencer Image"
                    />
                    <div className="profile-listing-owner-holder">
                      <div className="profile-listing-owner-info-holder">
                        <div className="profile-listing-owner-name">
                          {influ.fullName}
                          {/* <img
                            className="verified-badge"
                            alt=""
                            src="https://d5ik1gor6xydq.cloudfront.net/websiteImages/creatorMarketplace/verifiedBadge.svg"
                          /> */}
                        </div>
                        <div className="profile-listing-owner-location">
                          {influ.influencerCity}
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* ****** platform Price ****** */}
                  <div className="card_platform_price">
                    <p className="platform">{influ.packages[0].platform}</p>
                    <p className="price"><span className="currency">Rs:</span> {numeral(influ.packages[0].packagePrice).format('0,00.00')}</p>
                  </div>
                  {/* ****** Description ***** */}
                  <p className="description">
                    {influ.influencerTitle.slice(0, 90)}{influ.influencerTitle.length > 90 ? <span style={{ color: "black", fontSize: 12 }}>... see More</span> : ""}
                  </p>
                </div>
              )
            })

            : (
              <>
                <div style={{ display: "flex", justifyContent: "center", margin: "0 auto" }}>
                  <HashLoader />
                </div>
              </>
            )
        }

        {/* Card One */}

      </div>
    </div>
  );
};

export default FeaturedInfluencers;
