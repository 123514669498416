import React from 'react'
import "./CreatePage.css";
import Steps from "./Steps/Steps";

const CreatePage = () => {

  return (
    <div>
      <Steps />
    </div>
  )
}




export default CreatePage;