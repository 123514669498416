import React from "react";
import "./MobileNav.css";
import {
  SearchOutlinedIcon,
  BusinessCenterIcon,
  AccountCircleOutlinedIcon,
  PersonOutlineOutlinedIcon,
} from "../index";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { AiOutlinePullRequest, AiOutlineFolderAdd } from "react-icons/ai"
import { BiMessageAlt } from "react-icons/bi"

const MobileNavHeader = () => {

  const user = useSelector((state) => state.User.user);
  const brand = useSelector((state) => state.User.brand);
  // if(!user) return;
  return (
    <>
      {
        user ? (
          <div className="mobileNav_Container">
            <div className="mobileNav">
              {/* ******** Explore Nav  ******** */}
              <div className="mobileNavs">
                <Link to="/brands-Request">
                  <AiOutlinePullRequest className="searchIcon" />
                  <p>Brand Requests</p>
                </Link>
              </div>
              {/* ******** Brand Nav  ******** */}
              <div className="mobileNavs">
                <Link to="/influencer-orders">
                  <AiOutlineFolderAdd className="searchIcon" />
                  <p>Orders</p>
                </Link>
              </div>
              {/* ******** Influencer Nav  ******** */}
              <div className="mobileNavs">
                <Link to="/chat">
                  <BiMessageAlt className="searchIcon" />
                  <p>Messages</p>
                </Link>
              </div>
              {/* ******** Login Nav  ******** */}
              <div className="mobileNavs">
                <Link to="/account">
                  <AccountCircleOutlinedIcon className="searchIcon" />
                  <p>Account</p>
                </Link>
              </div>
            </div>
          </div>
        ) : brand ? (
          <div className="mobileNav_Container">
            <div className="mobileNav">
              {/* ******** Explore Nav  ******** */}
              <div className="mobileNavs">
                <Link to="/your-offers">
                  <AiOutlinePullRequest className="searchIcon" />
                  <p>My Requests</p>
                </Link>
              </div>
              {/* ******** Brand Nav  ******** */}
              <div className="mobileNavs">
                <Link to="/orders">
                  <AiOutlineFolderAdd className="searchIcon" />
                  <p>My Orders</p>
                </Link>
              </div>
              {/* ******** Influencer Nav  ******** */}
              <div className="mobileNavs">
                <Link to="/chat">
                  <BiMessageAlt className="searchIcon" />
                  <p>Messages</p>
                </Link>
              </div>
              {/* ******** Login Nav  ******** */}
              <div className="mobileNavs">
                <Link to="/account">
                  <AccountCircleOutlinedIcon className="searchIcon" />
                  <p>Account</p>
                </Link>
              </div>
            </div>
          </div>
        ) : (
          <div className="mobileNav_Container">
            <div className="mobileNav">
              {/* ******** Explore Nav  ******** */}
              <div className="mobileNavs">
                <Link to="/influencers">
                  <SearchOutlinedIcon className="searchIcon" />
                  <p>Home</p>
                </Link>
              </div>
              {/* ******** Brand Nav  ******** */}
              <div className="mobileNavs">
                <Link to="/brand">
                  <BusinessCenterIcon className="searchIcon" />
                  <p>Sign Up Brand</p>
                </Link>
              </div>
              {/* ******** Influencer Nav  ******** */}
              <div className="mobileNavs">
                <Link to="/creator">
                  <PersonOutlineOutlinedIcon className="searchIcon" />
                  <p>Sign Up Influencer</p>
                </Link>
              </div>
              {/* ******** Login Nav  ******** */}
              <div className="mobileNavs">
                <Link to="/login">
                  <AccountCircleOutlinedIcon className="searchIcon" />
                  <p>Login</p>
                </Link>
              </div>
            </div>
          </div>
        )
      }


    </>

  );
};

export default MobileNavHeader;
