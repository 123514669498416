import React from 'react';
import "./BrandEditProfile.css";
import { useNavigate } from 'react-router-dom';
import BrandEditProfileTab from './Tabs/BrandEditProfileTabs';
import { Helmet } from 'react-helmet';


const BrandEditProfile = () => {
    const navigate = useNavigate();

    return (
        <>
            <Helmet>
                <title>Collabscafe | Edit Brand Profile</title>
            </Helmet>
            <div className='editBrandProfile-container'>
                <div className='edit_Brand_Profile'>
                    <div className='goToProfile' onClick={() => navigate(-1)}>← Back to Profile</div>
                    <h1>Edit Your Brand Profile</h1>
                    <BrandEditProfileTab />
                </div>
            </div>
        </>
    )
}

export default BrandEditProfile;