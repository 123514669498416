import React, { useState, useEffect } from 'react'
import "./Billing.css"
import { useSelector, useDispatch } from 'react-redux';
import { fetchOrders } from '../../Redux/Reducers/Orders';
import numeral from 'numeral';
import dayjs from 'dayjs';
import { Chip, Stack } from '@mui/material';


const Billing = ({ CURRENT_USER_PROFILE }) => {
    const [orderList, setOrderList] = useState([]);

    const dispatch = useDispatch();


    let totalBillingSum = () => {
        let totalSpend = 0;
        orderList?.filter((v) => v.payment_status === "Success").map((value) => {
            totalSpend += parseFloat(value.order_package_price_include_tax)
        });
        return {
            totalSpend: numeral(totalSpend).format("00.00")
        }
    }



    useEffect(() => {
        if (CURRENT_USER_PROFILE) {
            dispatch(fetchOrders(CURRENT_USER_PROFILE._id)).then((res) => {
                if (res?.type === "orders/fetchOrders/fulfilled") {
                    setOrderList(res?.payload);
                    console.log("RES FETCH ORDERS", res?.payload);
                }
            })
        }
    }, [CURRENT_USER_PROFILE])


    return (
        <div className='Billing_header'>
            <h1>
                Billing
            </h1>
            <div className='total_row'>
                <div className='total_holder'>
                    <div className='total_title'>Total Spend (2023)</div>
                    <div className='total_num'>{CURRENT_USER_PROFILE && CURRENT_USER_PROFILE.total_spends} Rs</div>
                </div>
                <div className='total_holder'>
                    <div className='total_title'>Total Pending (2023)</div>
                    <div className='total_num'>$0.00 </div>
                </div>
            </div>
            <form className='input_form'>
                <select className='input type-input'>
                    <option value="">All Spend</option>
                    <option value="">Declined</option>
                    <option value="">Refunded</option>
                </select>
                <select className='input type-input'>
                    <option value="2023">2023</option>
                    <option value="2024">2024</option>
                    <option value="2025">2025</option>
                    <option value="2026">2026</option>
                    <option value="2027">2027</option>
                    <option value="2028">2028</option>
                    <option value="2029">2029</option>
                </select>
                <select className='input type-input'>
                    <option value="">All Months</option>
                    <option value="">January</option>
                    <option value="" selected>Feburary</option>
                    <option value="" selected>March</option>
                    <option value="" selected>April</option>
                    <option value="" selected>May</option>
                    <option value="" selected>June</option>
                    <option value="" selected>July</option>
                    <option value="" selected>August</option>
                    <option value="" selected>September</option>
                    <option value="" selected>October</option>
                    <option value="" selected>November</option>
                    <option value="" selected>December</option>
                </select>
            </form>
            <div className='orders_holder'>
                <div className='order_table'>
                    <div className='order_holder'>
                        <div className='order_title orderDate'>Date</div>
                        <div className='order_title orderDetail'>Details</div>
                        <div className='order_title orderStatus'>Status</div>
                        <div className='order_title orderAmount'>Amount</div>
                    </div>
                    {
                        orderList && orderList.map((v, i) => {
                            return (
                                <div key={i} className='order_holder_items'>
                                    <div className='order_title_items orderDate'>{dayjs(v.createdAt).format("DD-MM-YYYY")}</div>
                                    <div className='order_title_items orderDetail'>
                                        {v.order_package_description.slice(0, 50)}
                                    </div>
                                    {
                                        v.payment_status === "Success" ? (
                                            <div className='order_title_items orderStatus'>
                                                {
                                                    v.order_status === "PENDING" ? (
                                                        <Chip label={`${v.order_status}`} color="warning" variant="outlined" />
                                                    ) : v.order_status === "COMPLETED" ? (
                                                        <Chip label={`${v.order_status}`} color="success" variant="outlined" />
                                                    ) : v.order_status === "DENIED" ? (
                                                        <Chip label={`${v.order_status}`} color="error" variant="outlined" />
                                                    ) : <Chip label={`${v.order_status}`} color="primary" variant="outlined" />
                                                }
                                            </div>
                                        ) : (
                                            <Chip label={`${v.payment_status}`} color="error" variant="outlined" />
                                        )
                                    }

                                    <div className='order_title_items orderAmount'>{v.order_package_price} Rs</div>
                                </div>
                            )
                        })
                    }
                    {/* <div className='no_orders'>
                        You have no payments for the time period selected.
                    </div> */}
                </div>
            </div>
        </div>
    )
}

export default Billing